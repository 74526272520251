import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonEnum } from '../enum/common.enum';
import { AuthorityName } from '../models/authority-name';

export class JwtUtils {

  private decodedToken(): any {
    const token = sessionStorage.getItem(CommonEnum.accessToken);
    if (token) {
      return new JwtHelperService().decodeToken(token);
    }
    return {};
  }

  public getRawToken(): string {
    const token = sessionStorage.getItem(CommonEnum.accessToken);
    if (token) {
      return token;
    }
    return null;
  }

  public getExpireDate(): Date {
    const expireDate = new Date(Number(sessionStorage.getItem(CommonEnum.expiresIn)) * 1000);
    if (expireDate) {
      return expireDate;
    }
    return null;
  }

  public getRawRefreshToken(): string {
    const token = sessionStorage.getItem(CommonEnum.refreshToken);
    if (token) {
      return token;
    }
    return null;
  }

  public getUserID(): string {
    return this.decodedToken()?.userId;
  }

  public getEmail(): string {
    return this.decodedToken()?.email;
  }

  public getRoles(): AuthorityName[] {
    return this.decodedToken()?.roles;
  }

  public getLanguage(): string {
    return this.decodedToken()?.language;
  }

  public getName(): string {
    return this.decodedToken()?.name;
  }

  public getSub(): string {
    return this.decodedToken()?.sub;
  }

  public getExpiresIn(): number {
    return this.decodedToken()?.exp;
  }

  public getUserName(): string {
    return this.decodedToken()?.username;
  }
}
