<div class="margin-left-20 margin-right-20">

    <div *ngIf="!isVV" nz-row class="margin-bottom-20">
      <h1 class="header">Histórico de Lisboa VIVA/navegante personalizado Nº {{ getCardNumberOrSerialNumber() }}</h1>
    </div>
    <div *ngIf="isVV" nz-row class="margin-bottom-20">
      <h1 class="header">Histórico de VIVA Viagem/navegante ocasional Nº {{ getCardNumberOrSerialNumber() }}</h1>
    </div>

    <div class="margin-bottom-10 margin-left-10 margin-right-10">

      <div nz-row>
        <div nz-col nzSpan="5">
          <h3 class="date">Data De</h3>
        </div>
        <div nz-col nzSpan="5">
          <h3 class="to">Até</h3>
        </div>
      </div>

      <div nz-row class="margin-bottom-10">

        <div nz-col nzSpan="5" class="align-left">
          <app-date-picker
            height="50px"
            [data]="startDate"
            (valueChange)="onChangedStartDate($event)">
          </app-date-picker>
        </div>

        <div nz-col nzSpan="5" class="align-left">
          <app-date-picker
            height="50px"
            [data]="endDate"
            (valueChange)="onChangedEndDate($event)">
          </app-date-picker>
        </div>

      <div nz-col nzSpan="14" nzJustify="end" class="align-left">
        <app-primary-normal-button
          borderColor=" #707070"
          back="white"
          width="55px"
          height="40px"
          (click)="clearSearch()"
          class="m-l-5"
        >
          <span class="btn-icon icon-apagar icon-delete"></span>
        </app-primary-normal-button>
        <app-primary-normal-button
          class="m-l-10"
          width="55px"
          height="40px"
          (click)="loadCardHistory()"
          >
          <span class="btn-icon icon-pesquisar icon-search"></span>
        </app-primary-normal-button>
        </div>
      </div>

        <div nz-row class="group">
            <div class="div-table-result" nz-col [nzSpan]="24">
                <app-primary-table
                    [listOfColumn]="listTitleColumn"
                    [headerDatas]="listTitleHeader"
                    [listOfData]="listHistoryData"
                    [showPagination]="true"
                    (callButtonClick)="printDocument($event)"
                    (onPageChanged)="onPageChanged($event)"
                    [dataTotal]="dataTotal"
                    [frontPagination]="false"
                    [pageSize]="defaultPageSize">
                </app-primary-table>
            </div>
        </div>

    </div>

</div>

<div class="footer-buttons">
  <div nz-row nzJustify="start">
    <div nz-col [nzSpan]="4">
      <app-primary-white-button (click)="previousView()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>
  </div>
</div>
