import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.less',
              '../../../modules/operation/pages/fine-register/fine-register.component.less',
              '../../../modules/operation/pages/invoice/invoice.component.less',
              '../../../modules/shift/pages/documents/documents.component.less',
              '../../../modules/operation/pages/payment-siit/payment-siit.component.less',
              '../../../modules/operation/pages/payment-credit/payment-credit.component.less',
              '../../../modules/operation/pages/payment-pre-payment/payment-pre-payment.component.less',
              '../../../modules/operation/pages/replacement-guide/substitution-guide/substitution-guide.component.less',
            '../../../modules/operation/pages/replacement-guide/issue-replacement-guide/issue-replacement-guide.component.less',
            '../../../modules/operation/pages/fine-register/fine-register.component.less',
            '../../../modules/operation/pages/fines/fines.component.less'],
})
export class DropdownButtonComponent implements OnInit {
  @Input() label;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() listOptions: object[] = [];
  @Input() width = '';
  @Input() height = '';
  @Input() buttonStyle?: string;
  @Input() isDisabled = false;

  @Output() onSelect = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  callSelect(event: string) {
    this.onSelect.emit(event);
  }
}
