export enum PaymentEnum {
    PaymentType = 'PAYMENT_TYPE',
    ShoppingCart = 'SHOPPING_CART',
    PaymentMoney = 'PAYMENT_MONEY',
    PaymentMultibanco = 'PAYMENT_MULTIBANCO',
    PaymentMultiple = 'PAYMENT_MULTIPLE',
    PaymentSiit = 'PAYMENT_SIIT',
    PaymentCredit = 'PAYMENT_CREDIT',
    PaymentPrePayment = 'PAYMENT_PRE_PAYMENT'
}
