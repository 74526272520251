import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { Category, NonTicketingProduct, TariffType } from './model/commons-non-ticketing-product';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { CommercialOfferService } from 'src/app/core/services/commercial-offer.service';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { NonTicketingProductRecord } from '../non-ticketing-products-select/model/non-ticketing-product-record';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { CampaignRequest, CampaignTicketingFilterResponseDTO } from '../non-ticketing-products/model/commons-non-ticketing-product';
import { TicketingNetworkService } from 'src/app/core/services/ticketing-network.service';

@Component({
    selector: 'app-non-ticketing-products-detail',
    templateUrl: './non-ticketing-products-detail.html',
    styleUrls: ['./non-ticketing-products-detail.less']
})
export class NonTicketingProductsDetailsComponent implements OnInit {

    public static DEFAULT_CAMPAIGN_PLACEHOLDER = "----";
    public static DEFAULT_CAMPAIGN_DATE_PLACEHOLDER = "--/--/--";
    public static COLLABORATOR_PRICE_ID = 2;
    
    public imageUrl = '../../../../assets/images/product-placeholder-img.PNG';
    public imageArrow = '../../../../assets/images/right_arrow.png';
    public report = '../../../../assets/images/report.png';
    public modalMessage: string = CommonEnum.msgProductAddedToCart;
    public modalSubMessage: string = CommonEnum.msgOperationSucceded;
    public reportModalMessage: string = CommonEnum.msgReportModal;
    public reportDefaultMessage: string = CommonEnum.msgReportDefaultModal;

    public listTariffType: TariffType[] = [];
    public campaignList: CampaignTicketingFilterResponseDTO[] = [];
    public request: NonTicketingProduct = {} as NonTicketingProduct;
    public info: NonTicketingProductRecord = {} as NonTicketingProductRecord;

    public isShoppingCartFull = false;
    public isNextDisabled = true;
    public selectedIds: Array<number> = [];
    public tariffSelected: TariffType = {} as TariffType;
    public campaignInfo: CampaignTicketingFilterResponseDTO = {} as CampaignTicketingFilterResponseDTO;
    public quantity = 1;
    public minimumQuantity = 1;

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    public listRecords: NonTicketingProductRecord[] = [];
    public dataTotal: number;
    public readonly defaultPageSize = 5;
    public isModalVisible = false;
    public isModalCampaignVisible = false;
    public isDisabled = true;
    
    public selected = false;
    private productId: number;
    public normalPrice = 0;
    public collaboratorPrice = 0;

    public campaignCode: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
    public campaignName: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
    public campaignStartDate: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_DATE_PLACEHOLDER;
    public campaignEndDate: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_DATE_PLACEHOLDER;
    public campaignCondition: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
    public campaignValue: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
    public campaignQuantity: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
    public campaignDiscount: string = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;

    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private storageManagerService: StorageManagerService,
        private commercialService: CommercialOfferService,
        private shoppingService: ShoppingCartService,
        private peripheralService: PeripheralService,
        private ticketingNetworkService: TicketingNetworkService
    ) {
        if (this.navigationManager.getNavigation()?.extras?.state) { 
            this.info = this.navigationManager.getNavigation()?.extras?.state.info as NonTicketingProductRecord;
            if (this.info && this.info.price) {
                if (this.info?.price) {
                    this.normalPrice = this.info.price / 100;
                }
                if (this.info?.collaboratorPrice) {
                    this.collaboratorPrice = this.info.collaboratorPrice / 100;
                }
                if (this.info?.id) {
                    this.productId = this.info.id;
                } 
            }
        }
    }

    async ngOnInit(): Promise<void> {
        this.spinService.setSpinValue(true);
        await this.getTariffTypes();
        await this.getCampaign();
        this.imageUrl = this.getImageSrc();
        this.spinService.setSpinValue(false);
    }

    async getTariffTypes() {
        await this.commercialService.getTariffTypes()
            .then((response: BasicResponseDTO<Array<TariffType>>) => {
                if (response?.success && response?.data) {
                    this.listTariffType = response.data;
                }
            }).catch(() => this.listTariffType = {} as TariffType[]);
    }

    public async getCampaign() {
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);
        if (peripheralId && this.productId) {
            const machineNumber = await this.peripheralService.getMachineNumber();
            const localId = await this.ticketingNetworkService.getTicketingInfo(machineNumber);
            const request: CampaignRequest = {
                productId: this.productId,
                date: new Date().toISOString(),
                localId: localId.data
            };
            this.campaignList = (await this.commercialService.getCampaigns(request)).data;
            if (this.campaignList.length > 0) {
                this.campaignList.forEach(campaign => {
                    this.listTariffType.push(this.transformToTariffType(campaign));
                });
            }
        }

    }

    private transformToTariffType(campaign: CampaignTicketingFilterResponseDTO): TariffType {
        return {
            label: campaign.name,
            value: this.listTariffType.length + 1,
            id: this.listTariffType.length + 1,
            campaignId: campaign.id
        };
    }

    onTariffTypeChanged(idItem: number) {
        this.clearCampaignInfo();
        this.tariffSelected = this.listTariffType.find(({ value }) => value === idItem) as TariffType;
        if(this.tariffSelected && this.info?.id) {
            this.isDisabled = false;
        }
        if (this.tariffSelected.campaignId) {
            this.campaignInfo = this.campaignList.find(({ id }) => id === this.tariffSelected.campaignId) as CampaignTicketingFilterResponseDTO;
            this.campaignCode = this.campaignInfo.id.toString();
            this.campaignName = this.campaignInfo.name;
            this.campaignStartDate = this.campaignInfo.startDate;
            this.campaignEndDate = this.campaignInfo.endDate;
            this.campaignCondition = this.campaignInfo.campaignCondition;
            this.campaignValue = this.campaignInfo.campaignValue;
            this.campaignQuantity = this.campaignInfo.quantity.toString();
            this.campaignDiscount = this.campaignInfo.discount.toString();
            this.reportDefaultMessage = this.campaignInfo.campaignDescription;
            this.quantity = 1;
            this.onNumCopyClick('-');
        } else {
            this.quantity = 1;
        }
    }

    private clearCampaignInfo() {
        this.campaignInfo = {} as CampaignTicketingFilterResponseDTO;
        this.campaignCode = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.campaignName = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.campaignStartDate =  NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_DATE_PLACEHOLDER;
        this.campaignEndDate =  NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_DATE_PLACEHOLDER;
        this.campaignCondition = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.campaignValue = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.campaignQuantity = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.campaignDiscount = NonTicketingProductsDetailsComponent.DEFAULT_CAMPAIGN_PLACEHOLDER;
        this.reportDefaultMessage = CommonEnum.msgReportDefaultModal;
    }

    async previousView() {
        const category = {
            description: this.info.categoryDescription,
            id: this.info.categoryId
        } as Category
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS_SELECT,{category});
    }

    onNumCopyClick(signal: string) {
        if (this.tariffSelected && this.tariffSelected.campaignId !== null) {
            if (this.campaignInfo.quantity > 0) {
                this.minimumQuantity = this.campaignInfo.quantity;
            } else {
                this.minimumQuantity = Math.ceil(Number(this.campaignInfo.campaignValue) / this.normalPrice);
            }
        }

        if (signal === '+') {
            this.quantity = (this.quantity || 0) + 1;
        } else {
            if (this.quantity > this.minimumQuantity) {
                this.quantity = (this.quantity || 0) - 1;
            } else {
                this.quantity = this.minimumQuantity;
            }
        }
    }

    public getImageSrc(): string {
        if (this.info.imageURL) {
            const base64Image = this.info.imageURL;
            const mimeType = 'image/jpeg'; 
            const dataURI = `data:${mimeType};base64,${base64Image}`;
            return dataURI;
          } else {
            return '../../../../assets/images/product-placeholder-img.PNG';
          }
      }

      public handleAddAnotherProduct(): void {
        this.isModalVisible = false;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS), 100);
    }

    async goToShoppingCartPage() {
        this.isModalVisible = false;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.SHOPPING_CART), 100);
    }

    public async addToCart(): Promise<void> {
        this.spinService.setSpinValue(true);
        try {
            const shoppingCartRequest = this.prepareShoppingCartRequest(this.info);
            await this.shoppingService.insert(shoppingCartRequest).then((response: any) => {
                    this.spinService.setSpinValue(false);
                    this.isModalVisible = true;
            });
            this.spinService.setSpinValue(false);
        } catch (error) {
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    public iconClicked() {
        this.isModalCampaignVisible = true;
    }

    public closeModal() {
        this.isModalCampaignVisible = false;
    }

    public prepareShoppingCartRequest(nonTicketingProductRecord: NonTicketingProductRecord): ShoppingCartItemRequest {
        let finalPrice: number;
        let isCollaboratorPrice = false;
        if (this.tariffSelected?.id != NonTicketingProductsDetailsComponent.COLLABORATOR_PRICE_ID) {
            finalPrice = nonTicketingProductRecord.price * this.quantity;
        } else {
            isCollaboratorPrice = true;
            finalPrice = nonTicketingProductRecord.collaboratorPrice * this.quantity;
        }
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            titleId: nonTicketingProductRecord.id,
            description: nonTicketingProductRecord.product,
            quantity: this.quantity,
            price: finalPrice,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
            annulId: null,
            cardNumber: null,
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardSerialNumber: null,
            isAnnul: false,
            isNonTicketingProductCollaboratorPrice: isCollaboratorPrice,
            isNonTicketing: true,
            campaignId: this.tariffSelected?.campaignId ? this.tariffSelected.campaignId : null,
            discount: this.tariffSelected?.campaignId ? this.campaignInfo?.discount : 0
        } as ShoppingCartItemRequest;
    }

}
