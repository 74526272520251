<div nz-row style="min-width: 1366px">
  <div nz-col nzSpan="6" class="left-column">
    <div nz-row nzJustify="space-around" nzAlign="middle" style="height: 100%;">
      <div nz-col nzSpan="16" *ngIf="this.isReadingLoadComponent(); else enableLogo" class="disable-pointer">
        <img src="assets/images/header/logo_carris_header.svg" alt="icon_carris_header" (click)="goToHome()"
            class="disable-pointer-events"/>
      </div>
      <ng-template #enableLogo>
        <div nz-col nzSpan="16">
            <img src="assets/images/header/logo_carris_header.svg" alt="icon_carris_header" (click)="goToHome()" class="enable-pointer"/>
        </div>
      </ng-template>
      <div nz-col nzSpan="8" class="p-l-10" *ngIf="showShoppingCart">
        <div nz-row nzAlign="middle" ngClass="store-name">
          {{storeName}}
        </div>
        <div nz-row nzAlign="middle" ngClass="store-name">
          V01.01
        </div>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="18" class="right-column">
    <ng-container *ngIf="isShiftless()">
      <div nz-row nzAlign="left" style="height: 100%;">
        <div nz-col nzSpan="4">
          <span class="btn-icon icon-utilizador white header-text"></span>
          {{ title }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showShoppingCart">
      <div nz-row nzJustify="space-around" nzAlign="middle" style="height: 100%;">
        <div nz-col nzSpan="4">
          <span class="btn-icon icon-utilizador white header-text"></span>
          {{ title }}
        </div>
        <div nz-col nzSpan="6">
          <span>Turno</span>
          {{ titleShift }}
        </div>
        <div nz-col nzSpan="1" *ngIf="this.isCorrectPages(); else disableHistoric">
            <img (click)="openShiftResume()" src="assets/images/header/icon_button_historic.svg" alt="icon_carris_header"
            class="enable-pointer"/>
        </div>
        <div nz-col nzSpan="1" *ngIf="this.isCorrectPages(); else disableShiftSuspend">
            <img (click)="suspendShiftModal()" src="assets/images/header/icon_button_shift_suspend.svg" alt="icon_carris_header"
                class="enable-pointer" />
        </div>
        <div nz-col nzSpan="1" *ngIf="this.isCorrectPages(); else disableShiftClose">
            <img (click)="closeShiftModal()" src="assets/images/header/icon_button_shift_close.svg" alt="icon_carris_header"
                class="enable-pointer" />
        </div>
        <ng-template #disableHistoric>
            <div nz-col nzSpan="1" class="disable-pointer">
                <img (click)="openShiftResume()" src="assets/images/header/icon_button_historic.svg" alt="icon_carris_header"
                     class="disable-pointer-events"/>
            </div>
        </ng-template>
        <ng-template #disableShiftSuspend>
            <div nz-col nzSpan="1" class="disable-pointer">
                <img (click)="suspendShiftModal()" src="assets/images/header/icon_button_shift_suspend.svg" alt="icon_carris_header"
                    class="disable-pointer-events"/>
            </div>
        </ng-template>
        <ng-template #disableShiftClose>
            <div nz-col nzSpan="1" class="disable-pointer">
                <img (click)="closeShiftModal()" src="assets/images/header/icon_button_shift_close.svg" alt="icon_carris_header"
                     class="disable-pointer-events" />
            </div>
        </ng-template>
        <div nz-col nzSpan="2" nzOffset="3">
          <span>Pagar</span>
        </div>
        <div nz-col nzSpan="2">
          <img src="assets/images/header/icon_button_euro.svg" alt="icon_carris_header"/>
        </div>
        <div nz-col nzSpan="4" class="ms-30">
          <app-shopping-cart
            [total]="totalValue"
            [showList]="showNavHeader"
          ></app-shopping-cart>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-alert-modal
  [(isModalVisible)]="isCloseShiftModalVisible"
  (onCloseModal)="handleCancelcloseShiftModal()"
  (onClickCancel)="handleCancelcloseShiftModal()"
  (onClickConfirm)="closeShift()"
  [isConfirmLoading]="isCloseShiftModalOkLoading"
  [confirmText]="'Sim'"
  [title]="'Fecho de Turno'"
><h3 class="modal-sub-text">Deseja fechar o Turno?</h3>
</app-alert-modal>
<app-alert-modal
  [(isModalVisible)]="isSuspendShiftModalVisible"
  (onCloseModal)="handleCancelSuspendShiftModal()"
  (onClickCancel)="handleCancelSuspendShiftModal()"
  (onClickConfirm)="suspendShift()"
  [isConfirmLoading]="isSuspendShiftModalOkLoading"
  [confirmText]="'Sim'"
  [title]="'Suspensão de Turno'"
><h3 class="modal-sub-text">Deseja suspender o Turno?</h3>
</app-alert-modal>
