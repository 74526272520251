import {DocumentRequest} from './../../modules/shift/pages/documents/interface/document.interface';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BasicDataResponse} from 'src/app/shared/models/basic-data-response';
import {HttpUtils} from 'src/app/shared/utils/http-utils';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {DocumentsFilteredRequest} from "../../shared/models/documents-filtered-request";
import {InfoDocument} from 'src/app/modules/shift/pages/documents/interface/document.interface';
import { SpinService } from './spin.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    readonly SERVICE_SHEET_TYPE: string = 'FO';

    callLoadDocuments: Subject<any> = new Subject<any>();
    error = 'Ocorreu um erro ao recuperar os dados.';

    constructor(private httpClient: HttpClient) {
    }

    async getDocuments(request: DocumentsFilteredRequest) {
        console.log('call getDocuments');
        let params = new HttpParams();
        if (request.providerDocumentNumber?.trim()) {
            params = params.set('providerDocumentNumber', `${request.providerDocumentNumber.trim()}`);
        }
        if (request.cardNumberOrSerialNumber?.trim()) {
            params = params.set('cardNumberOrSerialNumber', `${request.cardNumberOrSerialNumber.trim()}`);
        }
        if (request.startDate) {
            params = params.set('startDate', `${request.startDate}`);
        }
        if (request.endDate) {
            params = params.set('endDate', `${request.endDate}`);
        }
        params = params.set('documentType', `${request.documentType}`);
        params = params.set('size', `${request.pageSize}`);
        params = params.set('page', `${request.page}`);
        await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/billing/documents-ticketing-by-filter`, { params })
            .toPromise().then(
                resSuccess => {
                    console.log('response: ', resSuccess);
                    this.callLoadDocuments.next(resSuccess);
                }, resError => {
                    console.log('response error: ', resError);
                    this.callLoadDocuments.next(resError);
                });
    }

    async printReceipt(documentRequest: { id: number, deviceId?: string, data?: InfoDocument }) {
        console.log('Print Document Id -> ' + documentRequest.id);
        const printDocumentRequest: DocumentRequest = {
            deviceId: documentRequest.deviceId,
            duplicate: true,
            additionalCopies: null,
            saleId: 0,
        };
        this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}document/api/print/document/${documentRequest.id}`, printDocumentRequest).toPromise()
        .then(
            (res: BasicDataResponse) => {
                console.debug(res);
            },
            (e: any) => {
                console.log(e);
                try {
                    this.error = HttpUtils.getErroMessage(e);
                    console.log(this.error);
                } catch (e) {
                    console.log(e);
                }
                throw new Error(e.error.message);
            });
    }

    async printLoadDocument(loadId: number, documentRequest: {deviceId?: string, data?: InfoDocument }, operationType: number): Promise<any> {
        console.log('Print Document by LoadId -> ' + loadId);
        const printDocumentRequest: DocumentRequest = {
            deviceId: documentRequest.deviceId,
            duplicate: true,
            additionalCopies: null,
            saleId: 0,
            operationType: operationType,
        };
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}document/api/print/load/${loadId}`, printDocumentRequest).toPromise();
    }
}

