import {Subscription} from 'rxjs';
import {VoucherSelectionService} from 'src/app/modules/operation/pages/voucher-selection/service/voucher-selection.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from 'src/app/core/services/alert.service';
import {VoucherSelection} from 'src/app/shared/models/voucher-selection';
import {ShoppingCart} from '../../modules/shopping-cart';
import {Load} from 'src/app/modules/load/pages/lisboa-viva/models/load';
import {TitleDetailsService} from 'src/app/core/services/title-details.service';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import NavigationManagerService, {TicketingRoutes} from '../../../../core/services/navigation-manager.service';
import {SpinService} from '../../../../core/services/spin.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { ValidityDatesRequest } from 'src/app/shared/models/validity-dates-request';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Component({
    selector: 'app-voucher-selection',
    templateUrl: './voucher-selection.component.html',
    styleUrls: ['./voucher-selection.component.less'],
})
export class VoucherSelectionComponent implements OnInit, OnDestroy {

    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];

    familyPass: VoucherSelection = {} as VoucherSelection;
    card: CardDetailsMessage;
    listProduct: any[] = [];
    load: Load;
    shoppingCart: ShoppingCart = {} as ShoppingCart;

    newValidityStart: Date;
    newValidityEnd: Date;

    constructor(
        private navigationManager: NavigationManagerService,
        private alertService: AlertService,
        private voucherSelectionService: VoucherSelectionService,
        private titleDetailsService: TitleDetailsService,
        private spinService: SpinService,
        private storageManager: StorageManagerService
    ) {
        if (this.navigationManager.getNavigation()?.extras?.state?.load && this.navigationManager.getNavigation()?.extras?.state?.card) {
            this.load = this.navigationManager.getNavigation().extras.state.load;
            this.card = this.navigationManager.getNavigation().extras.state.card;
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }

    ngOnInit(): void {
        // family pass value at BD
        const familyPassTitleGroup = 12;
        this.loadListProduct(familyPassTitleGroup);
        window.scrollTo(0, 0);
    }

    loadListProduct(productTypeId: number): void {
        this.subscription1$ = this.voucherSelectionService.callFilter.subscribe(
            (data) => {
                if (data != null) {
                    this.listProduct = data.map(
                        (it: { id: number; description: string; price: number, tickOperCode: number, tickCode: number, titleGroupId: number }) => {
                            return {
                                id: it.id,
                                name: it.description,
                                price: it.price,
                                role: 'FAMILY_PASS',
                                tickOperCode: it.tickOperCode,
                                tickCode: it.tickCode,
                                titleGroup: it.titleGroupId
                            };
                        });
                }
            },
            (err) => {
                this.alertService.error(err.error.message);
                console.log('HTTP Error', err);
            }
        );
        this.subscriptions.push(this.subscription1$);
        this.subscription2$ = this.voucherSelectionService.filter(productTypeId).subscribe();
        this.subscriptions.push(this.subscription2$);
    }

    async onHomeClick() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async onPreviousClick() {
        await this.navigationManager.go(TicketingRoutes.FAMILY_PASS, {load: this.load, card: this.card});
    }

    async loadSelection(event): Promise<void> {
        const titleId = event.value;
        await this.getValidityDates(titleId);
        this.familyPass.cardRead = this.load.cardRead;
        this.familyPass.titleId = titleId;
        this.familyPass.expiringDateStart = this.newValidityStart;
        this.familyPass.expiringDateFinish = this.newValidityEnd;
        this.familyPass.tickOperCode = this.listProduct.find((i) => i.id === titleId).tickOperCode;
        this.familyPass.tickCode = this.listProduct.find((i) => i.id === titleId).tickCode;
        this.familyPass.titleDescription = this.listProduct.find((i) => i.id === titleId).name;
        this.familyPass.total = this.listProduct.find((i) => i.id === titleId).price.value;
        this.familyPass.titleGroup = this.listProduct.find((i) => i.id === titleId).titleGroup;

        await this.navigationManager.go(TicketingRoutes.VOUCHER_DETAILS, {
            load: this.familyPass,
            card: this.card,
            pvTitleSelect: true
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    async getValidityDates(titleId: number) {

        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        const request: ValidityDatesRequest = {
            titleId: titleId,
            cardFullDetails: cardDetails,
        };
        const response = await this.titleDetailsService.getValidityDates(request) as any;
        console.log(response);
        this.newValidityStart = response.startValidity;
        this.newValidityEnd = response.endValidity;

    }
}
