<nz-table
  #listTable
  class="table"
  nzSize="small"
  [nzLoading]="loading"
  [nzData]="listOfData"
  [nzShowPagination]="false"
  (nzPageIndexChange)="onPageIndexChanged($event)"
  [nzPageIndex]="pageIndex"
>
  <!-- COLUMNS DATA -->
  <thead>
    <tr>
      <th
        [ngClass]="tableHeadCSS"
        *ngFor="let column of listOfColumn"
        [nzWidth]="column.config != null ? column.config.colWidth : '30%'"
        [nzAlign]="column.config != null ? column.config.colAlign : 'left'"
      >
        {{ column.title }}
      </th>
    </tr>
  </thead>
  <!-- BODY DATA -->
  <tbody>
    <tr *ngFor="let data of listTable.data; let i=index">
      <td
        [ngClass]="tableRowCSS"
        *ngFor="let cols of headerDatas"
        [hidden]="!cols.visible"
        [nzAlign]="cols.config != null ? cols.config.valAlign : 'left'"
      >
        <ng-template [ngIf]="cols.checkbox == null" [ngIfElse]="checkbox">
          <ng-template [ngIf]="cols.button == null">
            <span [ngSwitch]="findType(cols?.config)">

              <span *ngSwitchCase="'MONEY'">
                <span *ngIf="isExc && i == 1">
                  -
                </span>
                {{
                  data[cols.name] / 100 | number: ".2-2":"pt"
                }} €
              </span>

              <span *ngSwitchCase="'DATE'">
                {{
                  data[cols.name] | date: cols?.config?.format || "dd/MM/yyyy"
                }}
              </span>

              <span *ngSwitchCase="'PERCENT'">
                {{
                  data[cols.name] + "%"
                }}
              </span>

              <span *ngSwitchDefault>
                {{
                  data[cols.name]
                }}
              </span>

            </span>
          </ng-template>
        </ng-template>

        <ng-template #checkbox>
          <app-primary-checkbox
            [label]="cols.checkbox.label"
            [isDisabled]="data[cols.checkbox.isDisabled] || (isSingleSelection && selected && !isSelectedId(data.id))"
            [isChecked]="data[cols.checkbox.isChecked]"
            (isCheckedChange)="changeCheckbox($event, data.id)"
          ></app-primary-checkbox>
        </ng-template>

        <ng-template #checkbox>
          <app-primary-checkbox
            [label]="cols.checkbox.label"
            [isChecked]="data.emitGuide"
            [isDisabled]="!data.actions.replacementGuide"
            (isCheckedChange)="changeCheckbox($event, data.id)"
          ></app-primary-checkbox>
        </ng-template>
      </td>
    </tr>
  </tbody>
</nz-table>
