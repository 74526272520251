import { TariffService } from 'src/app/core/services/tariff.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderDataCheckbox } from 'src/app/shared/models/table-header-data-checkbox';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthorizationLoadService } from 'src/app/core/services/authorization-load.service';
import { Load } from 'src/app/modules/load/pages/lisboa-viva/models/load';
import { CardRead } from 'src/app/shared/models/card-read';
import { LoadAuthorization } from './model/authorization-charge-model';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { DatePipe } from '@angular/common';
import NavigationManagerService, { TicketingRoutes } from '../../../../core/services/navigation-manager.service';

@Component({
    selector: 'app-authorization-charge',
    templateUrl: './authorization-charge.component.html',
    styleUrls: ['./authorization-charge.component.less'],
    providers: [DatePipe]
})
export class AuthorizationChargeComponent implements OnInit, OnDestroy {

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    dataTable: CardReadTitle[] = [];
    titleSelected: CardReadTitle;
    authorization: LoadAuthorization;
    load: Load = { cardRead: {} as CardRead } as Load;

    constructor(
        private router: Router,
        private authorizationLoadService: AuthorizationLoadService,
        private tariffService: TariffService,
        private alertService: AlertService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private datePipe: DatePipe
    ) {

        this.prepareTable();

        if (this.router.getCurrentNavigation()?.extras?.state?.load) {

            this.load = this.router.getCurrentNavigation().extras.state.load;

        } else {
            this.homeScreen();
        }
    }

    async ngOnInit() {
        await this.getAuthorizationLoadList(
            {
                cardNumber: this.load.cardRead.number,
                serialNumber: this.load.cardRead.serialNumber
            }
        );
    }

    async homeScreen() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async getAuthorizationLoadList(request: any) {

        try {
            const response = await this.authorizationLoadService.getAuthorizations(request).toPromise();
            console.log("getAuthorizationLoadList", response);

            if (!response || !response.success) {
                this.alertService.error(response.message);
                return;
            }

            this.authorization = response.data as LoadAuthorization;
            const availableTitlesToLoad: CardReadTitle[] = [];

            for (const title of this.authorization.titles) {
                const tariffBasicResponse = await this.tariffService.fetchTitleById(title.titleId).toPromise();
                const titleDTO = tariffBasicResponse.data;
                availableTitlesToLoad.push({
                    id: titleDTO.id,
                    idAuthorization: this.authorization.id,
                    idTitleAuthorization: title.id,
                    description: titleDTO.description,
                    expiringDateBalance: this.authorization.endValidity.toString(),
                    price: titleDTO.price,
                    titleGroup: titleDTO.titleGroup,
                    tariffType: titleDTO.tariffType,
                    tickOperCode: titleDTO.tickOperCode,
                    tickCode: titleDTO.tickCode,
                    validTypeId: titleDTO.validTypeId,
                    vat: titleDTO.vat,
                } as CardReadTitle);
            }
            this.dataTable = availableTitlesToLoad;

        } catch (e) {
          this.alertService.error(e.error.message);
        }
    }

    prepareTable() {
        this.listTitleColumn = [
            { title: null, config: { colAlign: 'left', colWidth: '10%' } },
            { title: 'Título', config: { colAlign: 'left', colWidth: '70%' } },
            { title: 'Validade', config: { colAlign: 'left', colWidth: '20%' } },
        ];
        this.listTitleHeader = [
            { visible: true, checkbox: {} as TableHeaderDataCheckbox } as TableHeaderData,
            { visible: false, name: 'id', button: null } as TableHeaderData,
            { visible: true, name: 'description', button: null } as TableHeaderData,
            { visible: true, name: 'expiringDateBalance', button: null } as TableHeaderData,
        ];
    }

    checkBoxEvent(event: any) {
        if (event.value === true) {
            this.titleSelected = this.dataTable.find(element => element.id === event.id);
        } else if (event.value === false) {
            this.titleSelected = null;
        }
    }

    async previousScreen() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
    }

    async loadSelection() {
        this.load.titleId = this.titleSelected.id;
        this.load.tickCode = this.titleSelected.tickCode;
        this.load.tickOperCode = this.titleSelected.tickOperCode;

        const authTitle = {
            id: this.titleSelected.id,
            idAuthorization: this.titleSelected.idAuthorization,
            idTitleAuthorization: this.titleSelected.idTitleAuthorization,
            description: this.titleSelected.description,
            price: 0,
        } as CardReadTitle;

        await this.navigationManager.go(TicketingRoutes.TITLE_DETAILS, {
            load: this.load,
            authTitle
        });
    }

    ngOnDestroy() {
    }

}
