import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { Observable, Subject } from 'rxjs';
import { BasicResponseDTO } from '../../shared/models/basic-response-dto';
import { TitleDTO, TUDTitleDTO } from 'src/app/shared/models/title-dto';
import { TitleFilter } from 'src/app/modules/load/pages/lisboa-viva/title-selection/model/TitleFilter';
import { FilterCustInfoRequest } from 'src/app/shared/models/filter-cust-info-request';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';

@Injectable({
  providedIn: 'root'
})
export class TariffService {
  callListProdType: Subject<any> = new Subject<any>();
  constructor(private httpClient: HttpClient) { }

  //CardProfileController
  getListCardProfile(titleGroupId: number): Observable<BasicResponseDTO<TitleDTO>> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/card-profile/title-group/${titleGroupId}`);
  }

  //TitleController
  fetchTitleById(titleId: number): Observable<BasicResponseDTO<TitleDTO>> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/title-info/${titleId}`);
  }

  fetchTUDTitleById(titleId: number): Observable<BasicResponseDTO<TUDTitleDTO>> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/tud-title/${titleId}`);
  }

  fetchTitleName(ticketCodes: number[]): Observable<BasicResponseDTO<TitleDTO>> {
    const requestByTicketCodes = {
      ticketCodes
    };
    return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/by-ticket-codes`, requestByTicketCodes);
  }

  getInfoTitleByTicketCodes(ticketCode: number, ticketOperCode: number): Observable<BasicResponseDTO<TitleDTO>> {
    console.log('call getInfoTitleByID');
    const request = {
        ticketCode: ticketCode,
        ticketOperatorCode: ticketOperCode
    }
    return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/by-ticket-operation-code`, request);
  }

  //ProductController
  getSupportVVProduct(): Observable<BasicResponseDTO<TitleDTO>> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/product/support-vv`);
  }

  // Get ListProdType
  public getListProdType(): Observable<BasicDataResponse> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title-group`);
  }
  // Filter Result
  public filter(request: FilterCustInfoRequest): Observable<BasicDataResponse> {
    return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/filter`, request);
  }

  //get titles by title group
  public getTitleByTitleGroup(titleGroupId: number): Observable<BasicResponseDTO<TitleDTO>> {
    return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/get-titles-by-title-group-id/${titleGroupId}`);
  }

  //get non ticketing products
  async getListNewTicketingProducts(categoryId: number, localId: number, request: any): Promise<any> {
    let params = new HttpParams();
    if (request.pageSize) {
      params = params.set('size', `${request.pageSize}`);
    }
    if (request.page) {
      params = params.set('page', `${request.page}`);
    }
    if (request.sorters) {
      params = params.set('sorters', `${request.sorters}`);
    }
    return this.httpClient
      .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}/tariff/serv/title/non-ticketing-products-by-category-and-localId/` + categoryId + "/" + localId, { params }).toPromise();
  }
  
  async getListCategories() {
    return await this.httpClient
      .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/non-ticketing-category`)
      .toPromise();
  }

}

