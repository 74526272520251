import { StorageManagerService } from './../../../../../core/services/storage-manager.service';
import { Component, OnInit } from '@angular/core';
import { Load } from '../models/load';
import { AlertService } from 'src/app/core/services/alert.service';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardRead } from 'src/app/shared/models/card-read';
import { VoucherLoadService } from 'src/app/modules/operation/pages/voucher-load/service/voucher-load.service';
import { SpinService } from 'src/app/core/services/spin.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Component({
    selector: 'app-family-pass',
    templateUrl: './family-pass.component.html',
    styleUrls: ['./family-pass.component.less'],
})
export class FamilyPassComponent implements OnInit {

    private load: Load;
    private card: CardDetailsMessage;

    public buyLoadBtn = false;
    public annulBtn = false;


    constructor(
        private navigationManager: NavigationManagerService,
        private alertService: AlertService,
        private storageManager: StorageManagerService,
        private voucherLoadService : VoucherLoadService,
        private spinService: SpinService,
    ) {
        if (this.navigationManager.getNavigation()?.extras?.state?.load &&
            this.navigationManager.getNavigation()?.extras?.state?.card &&
            this.navigationManager.getNavigation()?.extras?.state?.hasFamilyTicketLoaded != null
        ) {
            this.load = this.navigationManager.getNavigation().extras.state.load;
            this.card = this.navigationManager.getNavigation().extras.state.card;
            const hasFamilyTicketLoaded = this.navigationManager.getNavigation().extras.state.hasFamilyTicketLoaded;
            const cardRead = this.storageManager.session.get<CardRead>(CardEnum.CARD_READ) as CardRead;
            this.buyLoadBtn = !hasFamilyTicketLoaded || hasFamilyTicketLoaded && !cardRead.slotsFull;
            this.annulBtn = hasFamilyTicketLoaded;
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }

    ngOnInit(): void {
    }

    public async previousScreen(): Promise<void> {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
    }

    public async buyOrReloadtitle() {
        this.spinService.setSpinValue(true);
        const hasValidVoucher = await this.voucherLoadService.seeIfHasValidVoucher(Number(this.load.cardRead.serialNumber));
        this.spinService.setSpinValue(false);
        if(hasValidVoucher){
            await this.navigationManager.go(TicketingRoutes.VOUCHER_LOAD, { load: this.load, card: this.card });
        } else {
            await this.navigationManager.go(TicketingRoutes.VOUCHER_SELECTION, { load: this.load, card: this.card });
        }
    }

    public async saleCancellationScreen() {
        await this.navigationManager.go(TicketingRoutes.VOUCHER_CANCEL, { load: this.load, card: this.card });
    }

}
