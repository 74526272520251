import { MoloniCountry } from './../../modules/operation/modules/moloni-country';
import { BasicResponseDTO } from './../../shared/models/basic-response-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';

@Injectable({
    providedIn: 'root'
})
export class MoloniIntegrationService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    public async getAllMoloniCountries() {
        return await this.httpClient.get<BasicResponseDTO<Array<MoloniCountry>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}moloni-integration/int/moloni/countries/getAll`).toPromise();
    }

}
