import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { PrintShiftRequest } from 'src/app/shared/models/print-shift-request';

@Injectable({
    providedIn: 'root'
})
export class PreviousShiftsService {

    error = 'Ocorreu um erro ao recuperar os dados.';

    constructor(
        private httpClient: HttpClient) { }

    public async getShifts(request: any): Promise<BasicDataResponse> {
        console.log('call getShifts');
        let params = new HttpParams();
        if (request.ownerId) {
            params = params.set('ownerId', `${request.ownerId}`);
        }
        if (request.shiftId) {
            params = params.set('shiftId', `${request.shiftId}`);
        }
        if (request.startLocalDate) {
            params = params.set('startLocalDate', `${request.startLocalDate}`);
        }
        if (request.endLocalDate) {
            params = params.set('endLocalDate', `${request.endLocalDate}`);
        }
        if (request.pageSize) {
            params = params.set('size', `${request.pageSize}`);
        }
        if (request.page) {
            params = params.set('page', `${request.page}`);
        }
        if (request.sorters) {
            params = params.set('sorters', `${request.sorters}`);
        }
        return await this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/shifts-by-filter`, { params }).toPromise() as BasicDataResponse;
    }

    async printShift(request: PrintShiftRequest):Promise<BasicDataResponse> {
        return await this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}document/api/print/shift/id`, request).toPromise() as BasicDataResponse;
    }

}
