import { Injectable } from '@angular/core';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';

@Injectable({
    providedIn: 'root'
})
export class TitleDetailsService {

    error = 'Ocorreu um erro ao recuperar os dados.';
    callGetValidityDates: Subject<any> = new Subject<any>();

    constructor(private httpClient: HttpClient) { }

    public async getValidityDates(request: any) {
        const response = await this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/validity-dates`, request).toPromise()
            .then((response: any) => {
                if (response && response.success) {
                    return response.data;
                } else {
                    return null;
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
        return response;
    }

}
