import {Directive, ElementRef, Inject, Input, OnChanges, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appBlackListAlertMessage]'
})
export class BlackListAlertMessageDirective implements OnChanges {

  @Input('appBlackListAlertMessage') isSerialNumberOnBlackList = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document) {
  }

  async ngOnChanges() {
    if (this.isSerialNumberOnBlackList) {

      const boldElement = document.createElement('b');
      boldElement.innerHTML = 'Lista negra';

      const paragraphElement = document.createElement('p');
      paragraphElement.id = 'black-list';
      paragraphElement.style.color = 'darkred';
      paragraphElement.style.textAlign = 'left';
      paragraphElement.style.fontSize = '20px';
      paragraphElement.style.font = 'normal normal normal 26px/38px Roboto';
      paragraphElement.style.textTransform = 'uppercase';

      this.renderer.appendChild(paragraphElement, boldElement);
      this.renderer.appendChild(this.elementRef.nativeElement, paragraphElement);

    } else {

      const paragraphElement = document.getElementById('black-list');

      if (paragraphElement) {
        paragraphElement.remove();
      }

    }
  }

}
