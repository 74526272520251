import {NgModule} from '@angular/core';
import {ShiftResumeComponent} from './pages/shift-resume/shift-resume.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {DocumentsComponent} from './pages/documents/documents.component';
import {CommonModule} from '@angular/common';
import { PreviousShiftsComponent } from './pages/previous-shifts/previous-shifts.component';
import {ShiftAdminComponent} from './pages/admin/shift-admin.component';
import { SuspendedShiftsComponent } from './pages/suspended-shifts/suspended-shifts.component';


@NgModule({
  declarations: [
    ShiftResumeComponent,
    ShiftAdminComponent,
    DocumentsComponent,
    PreviousShiftsComponent,
    SuspendedShiftsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzGridModule,
    NzTableModule,
    NzIconModule,
  ]
})
export class ShiftModule { }
