import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataButton } from 'src/app/shared/models/table-header-data-button';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';

export const Columns = [
    { title: 'Nº Turno', config: { colAlign: 'left', colWidth: '20%' } },
    { title: 'Data de Início', config: { colAlign: 'left', colWidth: '23%' } },
    { title: 'Local', config: { colAlign: 'left', colWidth: '23%' } },
    { title: 'Operador', config: { colAlign: 'left', colWidth: '15%' } },
    { title: 'Valor Total', config: { colAlign: 'center', colWidth: '15%' } },
    { title: 'Fechar Turno', config: { colAlign: 'center', colWidth: '10%' } },
];

export const Headers = [
    {
        visible: true,
        name: 'id',
        config: null,
        button: null,
    } as TableHeaderData,
    {
        visible: true,
        name: 'startDate',
        config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy HH:mm:ss' },
        button: null,
    } as TableHeaderData,
    {
        visible: true,
        name: 'shiftLocation',
        config: null,
        button: null,
    } as TableHeaderData,
    {
        visible: true,
        name: 'ownerId',
        config: null,
        button: null,
    } as TableHeaderData,
    {
        visible: true,
        name: 'total',
        config: { valAlign: 'center', type: TableHeaderDataType.MONEY },
        button: null,
    } as TableHeaderData,
    {
        visible: true,
        name: null,
        config: { valAlign: 'center', money: false },
        button: {
            titleButton: 'Fechar Turno',
            buttonCSS: 'close-shift-icon'
        } as TableHeaderDataButton,
    } as TableHeaderData,
];

export interface SuspendedShiftTable {
    id: number;
    startDate: Date;
    ownerId: number;
    total: number;
    shiftLocation: string;
}
