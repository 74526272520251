<div nz-row>
  <div nz-col nzSpan="20" *ngIf="(total != null && showList)" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
    <span>{{ total / 100 | number : '1.2-2' }}</span>
    <span class="btn-icon icon-drop_down"></span>
  </div>
  <div nz-col nzSpan="24" *ngIf="!(total != null && showList)">
    <span class="ml-4-rm">0.00</span>
  </div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <div nz-row class="p-10 w-100">
      <div nz-col nzSpan="24" class="m-b-20 w-100">
        <app-primary-table
          [listOfColumn]="listResultColumn"
          [headerDatas]="listResultHeader"
          [listOfData]="listResult"
          showPagination="false"
        ></app-primary-table>
      </div>
      <div nz-col nzSpan="24" class="w-100">
        <app-primary-normal-button
          (click)="openShoppingCart()"
          width="auto"
        >Carrinho de compras
        </app-primary-normal-button>
      </div>
    </div>
  </ul>
</nz-dropdown-menu>
