<div class="recover-replacement-guide-content">
  <div nz-row nzJustify="start" class="recover-replacement-guide-row">
    <h1 class="viva">Recuperar Guia</h1>
  </div>
  <br/>

  <div nz-row class=" align-left row-recover-replacement-guide">
      <app-primary-input-keyboard
        label="Nº de Série"
        labelCss="labels viva"
        inputCss="input-serial-number-search"
        [inputPlaceholder]="placeholder"
        keyboardType="number"
        [(value)]="this.serialNumber"
        [isDisabled]="!this.isSearchEnable">
      </app-primary-input-keyboard>
      <app-primary-normal-button
        class="button-search-serial-number"
        (click)="loadReplacementGuideDetails()"
        [disabled]="!isSearchEnable || serialNumber == '' ? true : false"
      >
      <span class="btn-icon icon-pesquisar icon-search"></span>
    </app-primary-normal-button>
  </div>

  <div nz-row nzJustify="start" class="recover-replacement-guide-row">
    <div
      nz-col
      [nzSpan]="4"
      class="input-col recover-replacement-guide-col margin-right-10"
    ></div>
    <div nz-col [nzSpan]="4" class="recover-replacement-guide-col"></div>
  </div>
  <br/><br/>
  <div nz-row class="m-b-100">
    <div class="div-table-result" nz-col [nzSpan]="24">
      <app-primary-table
        [listOfColumn]="listTitleColumn"
        [headerDatas]="listTitleHeader"
        [listOfData]="guideDetails"
        showPagination="false"
        pageSize="5"
      >
      </app-primary-table>
    </div>
  </div>


</div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col [nzSpan]="4">
      <div nz-row nzJustify="start">
        <app-primary-white-button (click)="previousView()">
          <span class="btn-icon icon-anterior icon-middle float-left "></span>
          Voltar
        </app-primary-white-button>
      </div>
    </div>
    <div nz-col [nzSpan]="4">
      <div nz-row style="padding-right: 48px; text-align: right" nzJustify="end">
        <app-primary-normal-button *ngIf="buttonDisabled" disabled="true" width="180px">
          <span class="btn-icon icon-seguinte icon-middle float-right"></span>
          Seguinte
        </app-primary-normal-button>
        <app-primary-normal-button *ngIf="!buttonDisabled" (click)="nextView()" width="180px">
          <span class="btn-icon icon-seguinte icon-middle float-right"></span>
          Seguinte
        </app-primary-normal-button>
      </div>
    </div>
  </div>
</div>
