import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShiftService } from 'src/app/core/services/shift.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { ApplicationType } from 'src/app/shared/enum/application-type.enum';
import { AppComponentService } from 'src/app/app.component.service';
import { EventService } from 'src/app/core/services/events.service';
import { EventTypeEnum } from 'src/app/modules/shift/pages/shift-resume/model/event.interface';
import { SpinService } from 'src/app/core/services/spin.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

    isLoginButtonLoading = false;
    loginForm: FormGroup;

    // para o unsubscribe
    notifier = new Subject();
    private peripheralId: string = null;
    private machineNumber: string = null;
    private username: string = null;
    private password: string = null;

    private shiftID = null;
    private shiftStartDate: Date = new Date();
    shiftStatus: number;
    shiftResume = false;

    public isModalVisible: boolean = false;
    public isConfirmLoading: boolean = false;

    // default modal message as if it will start shift
    public modalMessage: string = CommonEnum.msgModalShiftStart;
    public modalSubMessage: string = CommonEnum.subMsgModalShiftStart;

    constructor(
        private shiftService: ShiftService,
        private alertService: AlertService,
        private cd: ChangeDetectorRef,
        private peripheralService: PeripheralService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private appComponentService: AppComponentService,
        private eventService: EventService,
        public spinService: SpinService) {

        if (this.storageManager.session.get<string>(CommonEnum.accessToken) && this.shiftService.isShiftActive()) {
            this.navigationManager.go(TicketingRoutes.HOME);
        }

        if (this.storageManager.session.get<string>(CommonEnum.accessToken)) {
            this.navigationManager.go(TicketingRoutes.SHIFTLESS);
        }

        if (this.navigationManager.hasNavigationMessage()) {

            console.log(this.navigationManager.getNavigation().extras.state);

            if (this.navigationManager.getNavigation().extras.state.messageType === 'success') {
                this.alertService.success(this.navigationManager.getNavigationMessage(), false, false);
            } else {
                this.alertService.info(this.navigationManager.getNavigationMessage(), false, false);
            }
        }

    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
        this.spinService.setSpinValue(false);
    }

    async ngOnInit() {

        this.peripheralId = await this.peripheralService.getPeripheralId();
        this.machineNumber = await this.peripheralService.getMachineNumber();
    }

    ngAfterViewInit(): void {
    }

    public async login(): Promise<void> {
        this.spinService.setSpinValue(true);
        this.isLoginButtonLoading = true;

        if (this.storageManager.persistent.get(CommonEnum.externalId) != null
            && this.storageManager.persistent.get(CommonEnum.externalId) != this.username) {
            this.isLoginButtonLoading = false;
            this.spinService.setSpinValue(false);
            this.alertService.error(CommonEnum.msgLoginShiftAlreadyOpenByAnotherUser);
            return;
        }

        const request = {
            username: this.username,
            password: this.password,
            system: ApplicationType.TICKETING
        };

        await this.shiftService.login(request).pipe(takeUntil(this.notifier)).toPromise()
            .then(async () => {
                let shiftState = null;
                try {
                    const ownerId = Number(this.storageManager.persistent.get(CommonEnum.externalId));
                    const response = await this.shiftService.getShiftStatusByOwnerId(ownerId);
                    if (response.success && response?.data != null) {
                        shiftState = response.data;
                    }
                } catch (error) {
                    this.alertService.error(CommonEnum.msgShiftStatusError);
                }
                if (shiftState != null && CommonEnum.shiftStatusSuspend === shiftState) {
                    // it means that shift is only suspended
                    this.modalMessage = CommonEnum.msgModalShiftResume;
                    this.modalSubMessage = CommonEnum.subMsgModalShiftResume;
                }

                this.isModalVisible = true;

                try {
                    await this.eventService.createEvent(EventTypeEnum.LOGIN, this.machineNumber, true, this.peripheralId);
                } catch (error) {
                    // ignore error
                }
                this.shiftService.loginChange.next();
            })
            .catch((error) => {
                this.isLoginButtonLoading = false;
                this.spinService.setSpinValue(false);
                this.alertService.error(error.error.message);
            });

    }

    public async startResumeShift(): Promise<void> {
        try {
            const shiftResponse = await this.shiftService.startResumeShift(this.machineNumber).pipe(takeUntil(this.notifier)).toPromise();
            if (shiftResponse) {
                this.appComponentService.setIsShiftActive(true);
                this.storageManager.session.set(CommonEnum.shiftStatus, CommonEnum.shiftStatusActive);
                const data = shiftResponse.data;
                this.shiftID = data.id;
                this.shiftStatus = data.status;
                this.shiftResume = data.resume;
                this.shiftStartDate = data.startedAt;
                this.storageManager.session.set(CommonEnum.shiftID, String(this.shiftID));
                this.shiftService.loginChange.next();
            }
            let messageLogin;
            if (this.shiftResume) {
                messageLogin = CommonEnum.msgShiftResume;
            } else {
                messageLogin = CommonEnum.msgNewShift;
            }
            this.isLoginButtonLoading = false;
            this.isConfirmLoading = false;
            this.isModalVisible = false;
            setTimeout(() => this.navigationManager.go(TicketingRoutes.HOME, {
                messageType: 'success',
                message: messageLogin
            }), 100);
        } catch (error) {
            this.isModalVisible = true;
            this.isLoginButtonLoading = false;
            this.isConfirmLoading = false;
            this.alertService.error(error.error.message);
        }
    }

    public usernameInputChange(event: any): void {
        this.username = event;
    }

    public passwordInputChange(event: any): void {
        this.password = event;
    }

    public handleStartShift(): void {
        this.isConfirmLoading = true;
        if (this.peripheralId == null || this.machineNumber == null) {
            this.alertService.error(CommonEnum.msgPeripheralNotConnectedError);
            this.isConfirmLoading = false;
            return;
        }
        this.startResumeShift();
    }

    public handleCancelStartShift(): void {
        this.isConfirmLoading = false;
        this.isModalVisible = false;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.SHIFTLESS), 100);
    }

}
