<nz-modal [(nzVisible)]="isModalVisible" (nzOnCancel)="closeModal($event)">
  <div>
    <i
      nz-icon
      nzType="warning"
      [nzTheme]="'twotone'"
      [nzTwotoneColor]="'#fc7806'"
    ></i>
    <p class="title">{{ title }}</p>
    <ng-content></ng-content>
  </div>
  <ng-template [nzModalFooter]>
    <button nz-button nzType="default" (click)="onClickNOK($event)" class="cancel-button">
      {{ cancelText }}
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="onClickOK($event)"
      [nzLoading]="isConfirmLoading"
      class="orange-button"
    >
      {{ confirmText }}
    </button>
  </ng-template>
</nz-modal>
