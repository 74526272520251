export interface Shift {
  shiftId ?: number;
  startDate ?: Date;
  endDate ?: Date;
  ownerId ?: number;
  documentNumber ?: string;
  status ?: ShiftStatus;
  total ?: number;
}

export interface ShiftInfo {
    actions ?: any;
    documentNumber ?: string;
    endDate ?: Date;
    id: number;
    machine ?: string;
    ownerId ?: number;
    resume: boolean;
    startDate: Date;
    status: string;
    total ?: number;
    transactions ?: number;
}

export enum ShiftStatus {

  ACTIVE = 'ACTIVE',
  SUSPEND = 'SUSPEND',
  FINISHED = 'FINISHED',

}

export class ShiftStatusHelper {
  static findAll() {
    return [
      {
        value: ShiftStatus.ACTIVE,
        label: 'Activo'
      },
      {
        value: ShiftStatus.SUSPEND,
        label: 'Suspenso'
      },
      {
        value: ShiftStatus.FINISHED,
        label: 'Finalizado'
      }
    ];
  }

  static findLabel(status: ShiftStatus) {
    return this.findAll().find(it => it.value === status)?.label || '';
  }
}
