<div class="payment-multibanco-content">

  <h1 style="text-transform:uppercase;" class="title-blue m-b-20 m-t-15">Pagamentos</h1>


  <div nz-row class="m-b-20">
    <div nz-col nzSpan="12" [nzOffset]="0"  class="text-bold-left">
      TOTAL A PAGAR
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ total | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row>
    <div  nz-col nzSpan="12" [nzOffset]="0" class="text-normal-left">
      MODO DE PAGAMENTO
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-normal-right-light-blue">
      VALOR
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="18" [nzOffset]="0">
      <hr class="line-hr"/>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="14" [nzOffset]="0" class="text-bold-left">
      Multibanco
    </div>
    <div>
      <app-primary-input-keyboard
      keyboardType="number"
      inputCss="input-money"
      (valueChange)="paymentMultibancoChange($event)"
      inputBorder="input-money-border"
      inputDivCSS="text-align-right"
      isMoney="true">
      </app-primary-input-keyboard>
    </div>
  </div>

  <div nz-row>
    <div  nz-col nzSpan="18" [nzOffset]="0">
      <hr class="dashed-hr"/>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="14" [nzOffset]="0" class="text-bold-left">
      Dinheiro
    </div>
    <div>
      <app-primary-input-keyboard
      keyboardType="number"
      inputCss="input-money"
      (valueChange)="paymentMoneyChange($event)"
      inputBorder="input-money-border"
      inputDivCSS="text-align-right"
      isMoney="true">
      </app-primary-input-keyboard>
    </div>
  </div>

  <div nz-row>
    <div  nz-col nzSpan="18" [nzOffset]="0">
      <hr class="dashed-hr"/>
    </div>
  </div>

  <div *ngIf="showButtonExceptProd" nz-row class="other-row">
    <div nz-col [nzOffset]="0" class="text-bold-left other-row-label" >
      SIIT
    </div>
    <div class="other-row-content">
      <div class="m-r-35">
        <app-dropdown-button
                [label]="selectedSiitEntity ? selectedSiitEntity.name : 'Entidade SIIT'"
                labelTemplate="btn-icon icon-drop_down icon-dropdown-arrow"
                [listOptions]="dropdownItems"
                buttonStyle="dropdown"
                (onSelect)="handleDropdownSelect($event)"
        ></app-dropdown-button>
      </div>
      <div class="text-bold-left">
        Nº&nbsp;
      </div>
      <div class="m-r-35">
        <app-primary-input-keyboard
                inputCss="input-coupon"
                (valueChange)="couponCodeOnChange($event)"
                inputBorder="input-money-border"
                inputDivCSS="text-align-left"
                [value]="this.couponCode">
        </app-primary-input-keyboard>
      </div>
      <div>
        <app-primary-input-keyboard
                keyboardType="number"
                inputCss="input-money"
                (valueChange)="paymentSIITChange($event)"
                inputBorder="input-money-border"
                inputDivCSS="text-align-right"
                isMoney="true">
        </app-primary-input-keyboard>
      </div>
    </div>
  </div>

  <div nz-row *ngIf="showButtonExceptProd">
    <div nz-col nzSpan="18" [nzOffset]="0">
      <hr class="dashed-hr"/>
    </div>
  </div>

  <div nz-row class="m-v-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      Montante em Falta
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ totalRemaining | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row class="m-b-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      Total Recebido
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ totalSum | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row class="m-b-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      A devolver ao Cliente
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ totalReturn | number: ".2-2":"pt" }} €
    </div>
  </div>

</div>


<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="12" style="text-align: left;">
      <app-primary-white-button
        (click)="onPreviousClick()"
        buttonCSS="back-button">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>

    <div nz-col nzSpan="12" style="text-align: right; padding-right: 48px">
      <app-primary-normal-button
        [critical] = "true"
        (click)="onNextClick()"
        [disabled]="disableButton()"
        buttonCSS="button-seguinte">
        <span class="btn-icon icon-seguinte icon-middle float-right"></span>
        <span class="font-size-17">Seguinte</span>
      </app-primary-normal-button>
    </div>
  </div>
</div>
