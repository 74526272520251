import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-select',
  templateUrl: './primary-select.component.html',
  styleUrls: ['./primary-select.component.less',
              '../../../modules/operation/pages/requisition/lisboa-viva/requisition-details/requisition-details.component.less']
})
export class PrimarySelectComponent implements OnInit {
  @Input() height = '';
  @Input() width = '';
  @Input() selected: any;
  @Input() placeholder = '';
  @Input() dropdownMatchSelectWidth: boolean = true;
  @Input() listOptions: object[] = [];
  @Output() selectedChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedChange(event) {
    this.selectedChange.emit(event);
  }

}
