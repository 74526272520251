import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataCheckbox } from 'src/app/shared/models/table-header-data-checkbox';
import { AlertService } from 'src/app/core/services/alert.service';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { RequisitionLvRecordsService } from './service/requisition-lv-records.service';
import { RequisitionRecord } from './model/requisition-record';
import { DatePipe } from '@angular/common';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { ShiftService } from 'src/app/core/services/shift.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { RequisitionDTO } from '../requisition-details/model/requisition';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { CancelShoppingCartItemRequest } from 'src/app/shared/models/cancel-shoppingcart-item-request';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { DateUtils } from '../../../../../../shared/utils/date-utils';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Component({
    selector: 'app-requisition-lv-history',
    templateUrl: './requisition-lv-history.component.html',
    styleUrls: ['./requisition-lv-history.component.less'],
    providers: [DatePipe]
})
export class RequisitionLvHistoryComponent implements OnInit {

    private peripheralId = '';
    private shiftId: number;

    public vatNumber: string;
    public startDate: number;
    public endDate: number;

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    public listRecords: RequisitionRecord[] = [];

    public dataTotal: number;
    public readonly defaultPageSize = 5;

    public canAnnulRequisitions = false;

    public selected = false;
    private selectedId: number;

    private sort: string[] = [];

    private ButtonClick: boolean;
    private hasAlreadyMadeRequest = false;

    constructor(
        private requisitionService: RequisitionLvRecordsService,
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private peripheralService: PeripheralService,
        private shiftService: ShiftService,
        private datepipe: DatePipe,
        private storageManagerService: StorageManagerService,
        private shoppingCartService: ShoppingCartService,
        private documenstService: DocumentsService,
        private authorizationService: AuthorizationService
    ) { }

    async ngOnInit() {
        this.canAnnulRequisitions = this.authorizationService.hasPermission(AuthorityName.REQUISITIONS_NEW);
        this.peripheralId = await this.peripheralService.getPeripheralId();
        this.shiftId = Number.parseInt(this.shiftService.getShiftID(), 10);
        this.prepareTableResult();
        await this.fetchRequisitions();
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: null, config: { colAlign: 'center', colWidth: '7%' } },
            { title: 'Nº Contribuinte', config: { colAlign: 'center', colWidth: '13%' } },
            { title: 'Nº Documento', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Data', config: { colAlign: 'center', colWidth: '25%' } },
            { title: 'Requisição', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Entrega', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Local Venda', config: { colAlign: 'center', colWidth: '5%' } },
            { title: 'Valor', config: { colAlign: 'center', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                checkbox: { isChecked: 'annul' } as TableHeaderDataCheckbox
            } as TableHeaderData,
            {
                visible: false,
                name: 'id',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'vatNumber',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'documentNumber',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'documentDate',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'requisition',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'deliveryLocal',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'saleLocation',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'center', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData,
        ];
    }


    public isClicked(): boolean {

        const input = document.getElementById('buttonSearch') as HTMLInputElement;

        if (input.click) {
            this.ButtonClick = true;
        } else {
            this.ButtonClick = false;
        }
        console.log('ButtonClick', this.ButtonClick);
        return this.ButtonClick;
    }

    private withoutTime(value: Date): Date {
        const dateMidnight = new Date(value);
        dateMidnight.setHours(0, 0, 0, 0);
        return dateMidnight;
    }

    public async loadRecords(): Promise<void> {
        const actualDate = this.withoutTime(new Date());

        if (this.startDate != null && this.endDate == null || this.startDate == null && this.endDate != null) {
            this.alertService.error(CommonEnum.msgFillBothDatesError);
        } else if (actualDate < this.withoutTime(new Date(this.startDate))) {
            this.alertService.error(CommonEnum.msgInitialDateOverCurrentDateError);
        } else if (actualDate < this.withoutTime(new Date(this.endDate))) {
            this.alertService.error(CommonEnum.msgFinalDatePreviousInitialDateError);
        } else if (this.startDate > this.endDate) {
            this.alertService.error(CommonEnum.msgInitialDateOverFinalDate);
        } else {
            await this.fetchRequisitions();
        }
    }

    private async fetchRequisitions(pageNumber: number = 0) {
        this.alertService.clear();
        this.sort = [];
        this.sort.push('createdAt,desc');
        const request = {
            vatNumber: this.vatNumber,
            dateBefore: this.startDate != null ? this.datepipe.transform(this.startDate, 'yyyy-MM-dd') : null,
            dateAfter: this.endDate != null ? this.datepipe.transform(this.endDate, 'yyyy-MM-dd') : null,
            pageSize: this.defaultPageSize,
            page: pageNumber,
            sorters: this.sort
        };
        try {
            const response = await this.requisitionService.loadRecords(request).toPromise();
            if (response?.data != null) {
                this.listRecords = response.data?.content;
                this.dataTotal = response.data.totalElements;
                if (this.selected) {
                    const record = this.listRecords.find(requisition => requisition.id === this.selectedId);
                    if (record != null) {
                        record.annul = true;
                    }
                }
            }
            if (response.data.empty) {
                this.alertService.error(CommonEnum.msgRequisitionNotFound);
            }
        } catch (error) {
            console.log('Error: ', error);
            this.listRecords = [];
            this.alertService.error(error.error.message);
        }
    }

    public async onPageChanged(value: any): Promise<void> {
        await this.fetchRequisitions(value - 1);
    }

    public onChangedInput(value: string): void {
        this.vatNumber = value;
    }

    public onChangedStartDate(value: Date): void {
        this.startDate = value === undefined ? null : DateUtils.setTimeTo0(value)?.getTime();
    }

    public onChangedEndDate(value: Date): void {
        this.endDate = value === undefined ? null : DateUtils.setTimeTo0(value)?.getTime();
    }

    public clearSearch(): void {
        this.vatNumber = null;
        this.startDate = null;
        this.endDate = null;
        this.loadRecords();
    }

    public checkBoxEvent(event: any): void {
        if (event.value) {
            this.selected = true;
            this.selectedId = event.id;
            const record = this.listRecords.find(it => it.id === this.selectedId);
            record.annul = true;
        } else {
            this.selected = false;
        }
    }

    public annulReq() {
        console.log('enter annulReq');
        this.selected = false;
        this.requisitionService.callAnnulReq.subscribe(
            async (response: any) => {
                if (response && response.data) {
                    const requisition = response.data;
                    if (!this.hasAlreadyMadeRequest) {
                        this.hasAlreadyMadeRequest = true;
                        const shoppingCartRequest = this.prepareCancelRequisitionShoppingCartItemRequest(requisition);
                        await this.shoppingCartService.cancelItem(shoppingCartRequest);
                        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                    }
                } else {
                    this.alertService.error(CommonEnum.msgAnnulRequisitionError);
                }
            },
            (err) => {
                console.log('Error: ', err);
                this.hasAlreadyMadeRequest = false;
                this.alertService.error(CommonEnum.msgAnnulRequisitionError);
            }
        );

        this.requisitionService.annulReq(this.selectedId);
    }

    private prepareCancelRequisitionShoppingCartItemRequest(requisition: RequisitionDTO): CancelShoppingCartItemRequest {
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            shoppingCartId: null,
            itemId: null,
            titleId: requisition.requisitionType.titleId,
            requisitionId: requisition.id,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_ANUL_VEND
        } as CancelShoppingCartItemRequest;
    }

    public async printInvoice() {
        console.log('enter printInvoice');
        console.log('DeviceId: ', this.peripheralId);

        try {
            const record = this.listRecords.find(it => it.id === this.selectedId);
            if (record.documentId === 0 && record.documentNumber == null) {
                // this means that the selected requisition doesn't have a document associated
                // so we don't need to try to print a non existent document
                this.alertService.info(CommonEnum.msgPrintReceiptRequisitionWithoutDoc);
                return;
            }
            await this.documenstService.printReceipt(
                { id: record.documentId, deviceId: this.peripheralId, data: null });
            this.alertService.success('Recibo impresso com sucesso.');
        } catch (error) {
            console.log('Error: ', error);
            this.alertService.error(CommonEnum.msgPrintReceiptError);
        }
    }

    public async previousView(): Promise<void> {
        let previousView = TicketingRoutes.SHIFTLESS;
        const shiftId = this.shiftService.getShiftID();
        if (this.shiftId) {
            previousView = TicketingRoutes.LV_REQUISITION;
        }
        this.navigationManager.go(previousView);
    }

    public isShiftStarted(): boolean {
        return this.shiftService.isShiftActive();
    }

}
