<app-change-money
    [(isModalVisible)]="isModalVisible"
    (onClickConfirm)="handleConfirmModal($event)"
    (onCloseModal)="onCloseModal($event)"
    valueText="{{refundTotal/100}} Euros"
>
</app-change-money>
<div class="shopping-cart-content">
    <div>
        <h1 class="cart">
            Carrinho de compras
        </h1>
    </div>

    <div nz-row>
        <div id="div-table-result" nz-col [nzSpan]="24">
            <app-primary-table
                [listOfColumn]="productsTableColumn"
                [headerDatas]="productsTableHeader"
                [listOfData]="shoppingCartTable"
                showPagination="false"
                showTotal="false"
                pageSize="7"
                spanTotal="3"
                [totalQt]="shoppingCart.totalQt"
                [total]="shoppingCart.total"
                (callButtonClick)="onCancelItem($event)">
            </app-primary-table>
        </div>
    </div>
</div>
<div class="entities-buttons">
    <div nz-row nzJustify="space-between">
        <div style="text-align: left" nz-col [nzSpan]="16">
            <div nz-row style="padding-right: 40px" nzJustify="end">
                <div *ngIf="showButtonExceptProd" nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentSiit()">
                        Siit
                    </app-primary-white-button>
                </div>
                <div nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentPrePayment()" style="justify-content: end">
                        Pré-Pag.
                    </app-primary-white-button>
                </div>
                <div nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentCredit()" style="justify-content: end">
                        Crédito
                    </app-primary-white-button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div style="text-align: left" nz-col [nzSpan]="8">
            <app-primary-white-button
                width="233px"
                style="text-align: left"
                (click)="otherOperClick()"
            >
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Outras Operações
            </app-primary-white-button>
        </div>
        <div style="text-align: left" nz-col [nzSpan]="16">
            <div nz-row style="padding-right: 40px" nzJustify="end">

                <div nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentMultiple()">
                        Múltiplo
                    </app-primary-white-button>
                </div>

                <div nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentMoney()">
                        Dinheiro
                    </app-primary-white-button>
                </div>

                <div nz-col [nzSpan]="6">
                    <app-primary-white-button *ngIf="showPaymentButtons" buttonCSS="button-money"
                        (click)="goToPaymentMultibanco()">
                        Multibanco
                    </app-primary-white-button>
                </div>




                <div nz-col [nzOffset]="12" [nzSpan]="6">
                    <app-primary-white-button *ngIf="showContinueButton" [disabled]="isContinueDisabled"
                        [critical]="true" (click)="onContinueClick()">
                        Continuar
                    </app-primary-white-button>
                </div>
            </div>

        </div>
    </div>
</div>
