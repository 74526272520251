import { TicketComponent } from './pages/ticket/ticket.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportDetailComponent} from './pages/lisboa-viva/support-detail/support-detail.component';
import {ActionSupportComponent} from './components/action-support/action-support.component';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {SharedModule} from 'src/app/shared/shared.module';
import {TitleSelectionComponent} from './pages/lisboa-viva/title-selection/title-selection.component';
import {TitleDetailsComponent} from './pages/lisboa-viva/title-details/title-details.component';
import {ChangeTitleComponent} from './pages/lisboa-viva/change-title/change-title.component';
import {VoucherConsumeComponent} from './pages/lisboa-viva/voucher-consume/voucher-consume.component';
import {FamilyPassComponent} from './pages/lisboa-viva/family-pass/family-pass.component';
import {CardHistoryComponent} from './pages/lisboa-viva/card-history/card-history.component';
import {AnnulTitleComponent} from './pages/lisboa-viva/annul-title/annul-title.component';
import {ZappingComponent} from './pages/zapping/zapping.component';
import {NzModalModule, NzPaginationModule} from 'ng-zorro-antd';
import {DirectivesModule} from '../directives/directives.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    SupportDetailComponent,
    ActionSupportComponent,
    TitleSelectionComponent,
    TitleDetailsComponent,
    ChangeTitleComponent,
    VoucherConsumeComponent,
    FamilyPassComponent,
    CardHistoryComponent,
    AnnulTitleComponent,
    ZappingComponent,
    TicketComponent
  ],
    imports: [
        CommonModule,
        NzGridModule,
        SharedModule,
        DirectivesModule,
        NzPaginationModule,
        NzIconModule,
        NzModalModule
    ],
})
export class ChargeModule {}
