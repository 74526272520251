<nz-modal [(nzVisible)]="isModalVisible" [nzClosable]="isCloseable" (nzOnCancel)="closeModal($event)">
    <div nz-row>
        <div nz-col nzSpan="24">
            <h3>Devolva a quantia de</h3>
            <h1>{{valueText}}</h1>
            <h3>ao cliente.</h3>
        </div>
    </div>

    <ng-template [nzModalFooter]>
        <div nz-row>
            <div nz-col nzSpan="24">
                <app-primary-normal-button (click)="onClickButton($event)">
                    <span class="btn-icon icon-sucesso icon-middle  m-t-10 vertical-align-middle"></span>
                    <span class="button-finish-text">Concluir</span>
                </app-primary-normal-button>
            </div>
        </div>
    </ng-template>
</nz-modal>