<div>
    <div nz-row style="text-transform: uppercase;">
        <div nz-col nz-span="24">
            <h1>Zapping</h1>
        </div>
    </div>

    <div nz-row style="text-align: left; color: #002b5a; font-size: 20px; text-transform: uppercase;">
        <div nz-col nz-span="24">
            <label>Indique o valor que pretende carregar</label>
        </div>
    </div>

    <div nz-row style="margin-top: 20px;">
        <div *ngFor="let option of values" nz-col nz-span="2" style="margin-left: 10px;">
            <app-primary-normal-button
                buttonCSS="zapping-button"
                [disabled]="option.disabled"
                (click)="changeValue(option.id)">
                {{ option.label }}
            </app-primary-normal-button>
        </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div nz-row>
        <div nz-col nzSpan="24">
            <app-primary-table
                [listOfColumn]="listTitleColumn"
                [headerDatas]="listTitleHeader"
                [listOfData]="listOfData"
                showPagination="false"
                pageSize="5">
            </app-primary-table>
        </div>
    </div>

    <div nz-row style="margin-top: 200px;">

        <div nz-col nzSpan="2">
            <app-primary-white-button (click)="previous()">
              <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>

        <div nz-col nzSpan="2" nzOffset="2">
            <app-primary-cancel-button (click)="home()">
                Cancelar
            </app-primary-cancel-button>
        </div>

        <div nz-col nzSpan="2" nzOffset="14">
            <app-primary-normal-button
                width="159px"
                nzType="primary"
                nzDanger
                buttonCSS="button-load"
                (click)="load()">
                    Carregar
                    <span class="btn-icon icon-seguinte icon-middle float-right"></span>
            </app-primary-normal-button>
        </div>
    </div>
</div>

