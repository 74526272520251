import { CardEnum } from './../../../../shared/enum/card.enum';
import { CardDataModel, CardDetailsMessage, CardContract } from './../../../../shared/models/card-details-message';
import { StorageManagerService } from './../../../../core/services/storage-manager.service';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import { CardRead } from 'src/app/shared/models/card-read';
import {SupportDetailService} from 'src/app/core/services/support-detail.service';
import {PrintCardDetailsRequest} from 'src/app/shared/models/print-card-details-request';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import { IssueReplacementGuideService } from 'src/app/core/services/issue-replacement-guide.service';
import { TitleInfo } from 'src/app/modules/operation/pages/replacement-guide/issue-replacement-guide/model/title-info';
import { RecoverReplacementGuideService } from 'src/app/core/services/recover-replacement-guide.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';

@Component({
  selector: 'app-action-support',
  templateUrl: './action-support.component.html',
  styleUrls: ['./action-support.component.less']
})
export class ActionSupportComponent implements OnInit {

  @Input() card: CardRead;
  @Input() isVV = false;
  @Input() isReplacementGuide = false;
  @Input() isSerialNumberOnBlackList = false;
  @Input() isVVInitialized = false;
  @Input() hasReplacementGuide = false;
  @Input() isShoppingCartFull = false;
  @Output() onAlert = new EventEmitter<any>();

  peripheralId: string;
  titles: TitleInfo[] = [];
  constructor(private navigationManager: NavigationManagerService,
              private router: Router,
              private supportDetailService: SupportDetailService,
              private peripheralService: PeripheralService,
              private storageManager: StorageManagerService,
              private issueReplacementGuideService: IssueReplacementGuideService
              ) {
  }

  async ngOnInit() {
    this.peripheralId = await this.peripheralService.getPeripheralId();
    this.fetchReplacementGuides();
  }

  cardHistory() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
      state: {
        cardRead: this.card
      }
    };
    this.router.navigate(['load/card-history'], navigationExtras);
  }

  async replacementGuide() {
    await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_ISSUE_REPLACEMENT_GUIDE, {serialNumber: this.card.serialNumber});
  }

  async recoverVV() {
    this.storageManager.session.set(ReadingLoadingEnum.FromReadingEmitGuide, 'true');
    await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_RECOVERY_REPLACEMENT_GUIDE, {serialNumber: this.card.serialNumber});
  }

  async deliveryVV() {
    await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_DELIVERY, {serialNumber: this.card.serialNumber});
  }

  async printCardDetails() {
    this.supportDetailService.callPrintCardDetails;
    const actionsResponse = await this.supportDetailService.fetchActions().toPromise() as any;
    const request: PrintCardDetailsRequest = {
      deviceId: this.peripheralId,
      cardRead:  actionsResponse.data,
      cardDetails: this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage,
    };
    this.supportDetailService.printCardDetails(request);
  }

  async fetchReplacementGuides(){
    this.titles = [];
    const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
    var validTitles = cardDetails.details.contracts
      .filter((contract: CardContract) => contract.ticket_code != 0 && contract.operator_code != 0)
      .map((contract: CardContract) => {
        return {
          ticket_code: contract.ticket_code,
          operator_code: contract.operator_code,
          temporal_validity : contract.temporal_validity
        };
      });
    if (validTitles.length != 0) {
      const filter = {
        cardNumber: cardDetails.details.environment.issuing_number.toString(),
        cardSerialNumber: cardDetails.low_part.toString(),
        cardDataModel: CardDataModel.K_CARD_DATAMODEL_LISBOA_VIVA_V_0,
        titles: validTitles
      };
      try {
        const response = await this.issueReplacementGuideService.fetchReplacementGuides(filter).toPromise() as any;
        this.titles = response.data; // EVERY title in response Data must have canIssueReplacement TRUE
        this.titles.forEach((title) => title.canIssueReplacement = true);
        this.hasReplacementGuide = (this.titles.length > 0);
      } catch (error) {
        console.log(error);
      }
    } else {
      this.hasReplacementGuide = false;
    }
  }
}


