<div class="content">
    <div class="m-b-30 m-t-25">
        <h1 class="annul">Passe Família</h1>
        <h2 class="card">Comprar, Carregar e Anular Passe Família</h2>
    </div>
    <div nz-row>
        <div nz-col [nzSpan]="24" id="cartoes-img"></div>
    </div>
    <div nz-row class="button-group-grid">
        <div nz-col [nzSpan]="24">
            <span style="margin-right: 20px;">
                <app-primary-large-button
                    [critical]="true"
                    (click)="buyOrReloadtitle()"
                    disabled="{{buyLoadBtn ? '' : buyLoadBtn}}">
                    <span class="btn-icon-buy icon-recarregar"></span>
                    <span class="btn-icon-load icon-requisicoes_cartao"></span>
                    Comprar / Carregar
                </app-primary-large-button>
            </span>
            <span>
                <app-primary-button
                    class="buttons"
                    [critical]="true"
                    (click)="saleCancellationScreen()"
                    disabled="{{annulBtn ? '' : annulBtn}}">
                    <span class="btn-icon icon-historico"></span>
                    Anular
                </app-primary-button>
            </span>
        </div>
    </div>
    <div class="footer-buttons" style="width: fit-content">
        <div nz-row>
            <div nz-col [nzSpan]="12" class="align-left">
                <app-primary-white-button
                    (click)="previousScreen()">
                    <span class="btn-icon icon-anterior icon-middle float-left "></span>
                    Voltar
                </app-primary-white-button>
            </div>
        </div>
    </div>
</div>
