import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { TitleFilter } from './model/TitleFilter';
import { CardReadTitle, CardReadTitleTitle } from 'src/app/shared/models/card-read-title';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { Load } from '../models/load';
import { TitleSelectionService } from 'src/app/core/services/title-selection.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { StorageManagerService } from '../../../../../core/services/storage-manager.service';
import { CardEnum } from '../../../../../shared/enum/card.enum';
import { CardDataModel, CardDetailsMessage } from '../../../../../shared/models/card-details-message';
import { TitleDTO, TitleTitleInfo } from 'src/app/shared/models/title-dto';
import { AuthorityName } from 'src/app/shared/models/authority-name';
import { SupportDetailService } from 'src/app/core/services/support-detail.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { Subscription } from 'rxjs';
import {LoadTitleService} from "../../../../../core/services/load-title.service";
import { TitleUtils } from 'src/app/shared/utils/title-utils';
import { TitleGroupEnum } from 'src/app/shared/enum/title-group.enum';

const THIRD_AGE_PROFILE_ID = 3;

@Component({
    selector: 'app-title-selection',
    templateUrl: './title-selection.component.html',
    styleUrls: ['./title-selection.component.less'],
})
export class TitleSelectionComponent implements OnInit, OnDestroy {
    load: Load;

    navigation: Navigation;
    listTitles = [];
    listLoadTitle = [];

    selectedTitles = 0;

    currentCard: CardDetailsMessage;

    titleForExchange: CardReadTitle;
    currentPageIndex = 1;
    totalTitles = 100;
    pageSize = 100;
    isExchange = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private navigationService: NavigationManagerService,
        private service: TitleSelectionService,
        private supportDetailService: SupportDetailService,
        private alertService: AlertService,
        private storageService: StorageManagerService,
        private loadTitleService: LoadTitleService
    ) {
        if (!this.navigationService.getNavigation()?.extras?.state) { this.homeClick(); }


        if (this.navigationService.getNavigation()?.extras?.state?.load) {
            this.load = this.navigationService.getNavigation().extras.state.load;
        } else {
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }

        this.currentCard = this.storageService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        if (this.navigationService.getNavigation()?.extras?.state?.titleDetails) {
            this.titleForExchange = this.navigationService.getNavigation().extras.state.titleDetails;
            this.getListLoadTitleExchangeCase();
        } else if (this.isVV(this.currentCard)) {
            this.getListLoadTitle();
        } else {
            this.getListTitles();
        }
        this.initServiceNotify();

    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onTitlesChanged(value: string) {
        this.getTitleListOfCardProfile(value);
    }

    async getTitleListOfCardProfile(profileNumber: any) {
        const profileIds = [];
        const titles = [];
        profileIds.push(profileNumber);

        const titleFilter: TitleFilter = {
            cardProfileIds: profileIds,
            cardDataModel: this.currentCard.details.environment.card_data_model,
            pageSize: this.pageSize,
            cardProfile: this.currentCard.details.profile,
            contractList: this.currentCard.details.contracts,
            cardExpiryDate: this.currentCard.details.environment.expiry_date
        };
        const response = await this.loadTitleService.getTitleListOfCardProfile(titleFilter).toPromise();
        response.data.content.forEach(title => {
            titles.push({
                id: title.id,
                name: title.description,
                role: AuthorityName.TICKETS_SELL,
                description: title.description,
                moloniId: title.moloniId,
                price: title.price,
                tariffType: title.tariffType,
                tickCode: title.tickCode,
                tickOperCode: title.tickOperCode,
                titleGroupId: title.titleGroupId,
                titles: title.titles,
                disabled: title.isTitleLoadButtonDisabled,
                discount: title.discount,
                discountValue: title.discountValue,
            });
        });
        this.listLoadTitle = titles;
    }

    async getListLoadTitle() {
        const titles = [];
        const request: any = {
            cardProfileIds: this.load.cardRead.profileIds,
            cardDataModel: this.currentCard.details.environment.card_data_model,
            pageSize: this.pageSize,
            cardProfile: this.currentCard.details.profile,
            contractList: this.currentCard.details.contracts,
            cardExpiryDate: this.currentCard.details.environment.expiry_date
        };

        const response = await this.loadTitleService.getTitleListOfCardProfile(request).toPromise();
        response.data.content.forEach(title => {
            titles.push({
                id: title.id,
                name: title.description,
                role: AuthorityName.TICKETS_SELL,
                description: title.description,
                moloniId: title.moloniId,
                price: title.price,
                tariffType: title.tariffType,
                tickCode: title.tickCode,
                tickOperCode: title.tickOperCode,
                titleGroupId: title.titleGroupId,
                discount: title.discount,
                discountValue: title.discountValue,
            });
        });
        this.listLoadTitle = titles;
    }

    getListLoadTitleExchangeCase() {

        const titleToExchange: TitleDTO = {
            id: this.titleForExchange.id,
            titleGroup: this.titleForExchange.titleGroup,
            tariffType: this.titleForExchange.tariffType,
            description: this.titleForExchange.description,
            tickOperCode: this.titleForExchange.tickOperCode,
            tickCode: this.titleForExchange.tickCode,
            validTypeId: this.titleForExchange.validTypeId,
            price: this.titleForExchange.price,
            vat: this.titleForExchange.vat,
            discount: this.titleForExchange.discount
        } as TitleDTO;

        this.service.getListLoadTitleExchangeCase(titleToExchange, this.currentCard);
    }

    async getListTitles() {
        const response = await this.supportDetailService.fetchActions().toPromise() as any;
        const profiles = response.data.profiles as any[];

        if (this.currentCard.details.profile.profile_one_code != 0
            && !CardUtils.isProfileExpired(this.currentCard.details.profile.profile_one_expiry_date)
            /* caso de perfil 3ª Idade, ao não ter data de validade aparece sempre */
            || this.currentCard.details.profile.profile_one_code == THIRD_AGE_PROFILE_ID
        ) {
            this.listTitles.push(
                {
                    value: this.currentCard.details.profile.profile_one_code,
                    label: profiles[0].name
                }
            );
        }
        if (this.currentCard.details.profile.profile_two_code != 0 &&
            !CardUtils.isProfileExpired(this.currentCard.details.profile.profile_two_expiry_date)
            /* caso de perfil 3ª Idade, ao não ter data de validade aparece sempre */
            || this.currentCard.details.profile.profile_two_code == THIRD_AGE_PROFILE_ID
        ) {
            this.listTitles.push(
                {
                    value: this.currentCard.details.profile.profile_two_code,
                    label: profiles[1].name
                }
            );
        }
        if (this.currentCard.details.profile.profile_three_code != 0 &&
            !CardUtils.isProfileExpired(this.currentCard.details.profile.profile_three_expiry_date)
            /* caso de perfil 3ª Idade, ao não ter data de validade aparece sempre */
            || this.currentCard.details.profile.profile_three_code == THIRD_AGE_PROFILE_ID
        ) {
            this.listTitles.push(
                {
                    value: this.currentCard.details.profile.profile_three_code,
                    label: profiles[2].name
                }
            );
        }
        if (this.currentCard.details.profile.profile_four_code != 0 &&
            !CardUtils.isProfileExpired(this.currentCard.details.profile.profile_four_expiry_date)
            /* caso de perfil 3ª Idade, ao não ter data de validade aparece sempre */
            || this.currentCard.details.profile.profile_four_code == THIRD_AGE_PROFILE_ID
        ) {
            this.listTitles.push(
                {
                    value: this.currentCard.details.profile.profile_four_code,
                    label: profiles[3].name
                }
            );
        }

        if (!this.isVV(this.currentCard)) {
            let haveNormalProfile = false;

            this.listTitles.forEach(e => {
                if (e.value == 1) {
                    haveNormalProfile = true;
                }
            });

            if (!haveNormalProfile) {
                this.listTitles.push(
                    { value: 1, label: 'Normal' }
                );
            }
        }

        if (this.listTitles.length != 0) {
            this.selectedTitles = this.listTitles[0].value;
            this.onTitlesChanged(this.listTitles[0].value);
        }

    }

    async homeClick() {
        await this.navigationService.go(TicketingRoutes.HOME);
    }
    async goBack() {
        await this.navigationService.go(TicketingRoutes.SUPPORT_DETAILS, { card: this.currentCard });
    }

    async loadSelection(event) {
        const ZAPPING_ID = 3201;
        const selectedTitle = this.listLoadTitle.find((i) => i.id === event.value);
        if (event.value === ZAPPING_ID) {
            await this.navigationService.go(TicketingRoutes.ZAPPING, {
                title: {
                    id: event.value,
                    name: selectedTitle.name,
                    price: selectedTitle.price,
                    tickCode: selectedTitle.tickCode,
                    tickOperCode: selectedTitle.tickOperCode,
                    titleBalance: this.isZappingOnCardTitles(event.value) ? this.setZappingBalanceFromCardRead(event.value) : 0,
                    titleGroup: selectedTitle.titleGroupId
                },
                serialNumber: this.load.cardRead.serialNumber
            });
        } else if (TitleGroupEnum.TICKETS === selectedTitle.titleGroupId) {
            await this.redirectToTicket(this.listLoadTitle, event.value);
        } else {
            const dateStart = new Date(2020, 1, 1);
            const dateFinish = new Date(2020, 8, 1);
            const newTitleTitlesArray: CardReadTitleTitle[] = [];
            const card = this.storageService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
            let titleInfo: TitleDTO;
            const newTitle: CardReadTitle = {
                id: event.value,
                expiringDateStart: dateStart,
                expiringDateFinish: dateFinish,
                description: selectedTitle.name,
                price: selectedTitle.price,
                tickCode: selectedTitle.tickCode,
                tickOperCode: selectedTitle.tickOperCode,
                titleGroup: selectedTitle.titleGroupId,
                tariffType: selectedTitle.tariffType,
                validTypeId: selectedTitle.validTypeId,
                titles: newTitleTitlesArray,
                priceWithDiscount: TitleUtils.getDiscountValue(selectedTitle.price?.value, selectedTitle.discount),
                discount: selectedTitle.discount
            };

            // composed titles
            if (selectedTitle.titles && selectedTitle.titles.length > 0) {
                // add title tiles to CardReadTitle
                selectedTitle.titles.forEach(tt => {
                    const newTitleTitle = {
                        id: tt.id,
                        expiringDateStart: dateStart,
                        expiringDateFinish: dateFinish,
                        description: tt.description,
                        price: tt.price.value,
                        tickCode: tt.tickCode,
                        tickOperCode: tt.tickOperCode,
                        titleGroup: tt.titleGroup,
                        tariffType: tt.tariffType,
                        validTypeId: tt.validTypeId,
                        toLoad: tt.toLoad
                    } as CardReadTitleTitle;

                    newTitle.titles.push(newTitleTitle);
                });

                titleInfo = this.composedTitleTitleInfo(newTitle, card);
            } else {
                titleInfo = this.unitTitleTitleInfo(card, newTitle);
            }

            let canTitleBeLoadedInCard = false;

            try {
                canTitleBeLoadedInCard = await this.service.canTitleBeLoadedInCard(titleInfo.id, this.currentCard);
            } catch (e) {
                this.alertService.error(e.message);
                return;
            }

            if (canTitleBeLoadedInCard) {
                if (newTitle?.discount && newTitle?.discount > 0) {
                    this.titleForExchange.priceWithDiscount = TitleUtils.getDiscountValue(this.titleForExchange.price, this.titleForExchange?.discount);
                }
                await this.navigationService.go(TicketingRoutes.TITLE_DETAILS, {
                    load: this.load,
                    pvTitleSelect: true,
                    newTitle,
                    titleForExchange: this.titleForExchange
                });
            } else {
                this.alertService.error(CommonEnum.msgExtendTitleError);
            }
        }
    }

    private async redirectToTicket(listTitles: any[], titleId: any) {
        const dateStart = new Date();
        const dateFinish = new Date(
            dateStart.getFullYear(),
            dateStart.getMonth() === 12 ? 1 : dateStart.getMonth() + 1,
            dateStart.getDay()
        );

        const load: Load = {
            cardRead: this.load.cardRead,
            tickCode: listTitles.find((i) => i.id === titleId).tickCode,
            tickOperCode: listTitles.find((i) => i.id === titleId).tickOperCode,
            titleId,
            expiringDateStart: dateStart.toDateString(),
            expiringDateFinish: dateFinish.toDateString(),
            titleDescription: listTitles.find((i) => i.id === titleId).description,
            total: listTitles.find((i) => i.id === titleId).price,
            titleGroup: listTitles.find((i) => i.id === titleId).titleGroup,
        } as Load;

        const newTitle: CardReadTitle = {
            id: titleId,
            expiringDateStart: dateStart,
            expiringDateFinish: dateFinish,
            description: listTitles.find((i) => i.id === titleId).name,
            titleGroup: listTitles.find((i) => i.id === titleId).titleGroup,
            price: listTitles.find((i) => i.id === titleId).price,
        } as CardReadTitle;

        await this.navigationService.go(TicketingRoutes.TICKET, {
            load,
            newTitle,
        });
    }


    initServiceNotify(): void {
        const subscription = this.service.titleLoadChange.subscribe(
            (data) => {
                console.log('DATA NO INIT', data);
                if (data != null) {
                    if (data.length === 0) {
                        this.alertService.info(CommonEnum.msgNoTitlesAvailableForExchange);
                    }
                    this.listLoadTitle = data.map((it) => {
                        return {
                            id: it.id,
                            name: it.description,
                            price: it.price,
                            role: AuthorityName.TICKETS_SELL,
                            tickCode: it.tickCode,
                            tickOperCode: it.tickOperCode,
                            validTypeId: it.validTypeId,
                            titleGroup: it.titleGroup,
                            tariffType: it.tariffType,
                            titles: it.titles,
                            moloniId: it.moloniId,
                            discount: it.discount
                        };
                    });
                }
            },
            (err) => {
                this.alertService.error(err.error.message);
                console.log('HTTP Error', err);
            }
        );
        this.subscriptions.push(subscription);
    }

    onPageChange($event: number) {
        console.log('Page clicked: ' + $event);
    }

    convertStringMoneyToNumber(valor: string) {
        valor = valor.slice(0, -2);
        valor = valor.replace(',', '');
        return Number(valor);
    }

    setZappingBalanceFromCardRead(titleId: any) {
        return this.convertStringMoneyToNumber(this.load.cardRead.titles.find((i) => i.id === titleId).expiringDateBalance);
    }

    isZappingOnCardTitles(titleId: any) {
        return this.load.cardRead.titles.find((i) => i.id === titleId) != null ? true : false;

    }

    isVV(card: CardDetailsMessage): boolean {
        return (card.details.environment.card_data_model === CardDataModel.K_CARD_DATAMODEL_UNKNOWN
            || card.details.environment.card_data_model === CardDataModel.K_CARD_DATAMODEL_SETE_COLINAS_V_0);
    }

    private composedTitleTitleInfo(newTitle: CardReadTitle, card: CardDetailsMessage) {
        const newTitleTitles: TitleTitleInfo[] = [];
        const titleInfo: TitleDTO = {
            id: newTitle.id,
            titleGroup: newTitle.titleGroup,
            tariffType: newTitle.tariffType,
            description: newTitle.description,
            tickOperCode: newTitle.tickOperCode,
            tickCode: newTitle.tickCode,
            validTypeId: newTitle.validTypeId,
            titles: newTitleTitles,
        };

        newTitle.titles.forEach(tt => {
            const ttInfo: TitleTitleInfo = this.unitTitleTitleInfo(card, tt);
            ttInfo.toLoad = tt.toLoad;
            titleInfo.titles.push(ttInfo);
        });

        return titleInfo;
    }

    private unitTitleTitleInfo(card: CardDetailsMessage, newTitle: CardReadTitle) {
        const ticketDate = this.currentCard.details.contracts.filter(contract => contract.ticket_code === newTitle.tickCode)
            .map((contract: any) => {
                return contract.ticket_date;
            })[0];

        const titleInfo: TitleDTO = {
            id: newTitle.id,
            titleGroup: newTitle.titleGroup,
            tariffType: newTitle.tariffType,
            description: newTitle.description,
            tickOperCode: newTitle.tickOperCode,
            tickCode: newTitle.tickCode,
            validTypeId: newTitle.validTypeId,
            ticketDate
        };
        return titleInfo;
    }
}
