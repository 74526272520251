import {BasicResponseDTO} from './../../shared/models/basic-response-dto';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {BasicDataResponse} from 'src/app/shared/models/basic-data-response';
import {ShoppingCartService} from './shopping-cart.service';
import {VatValidationRequest} from 'src/app/shared/models/vat-validation-request';
import {VatNumberAssociationDTO} from 'src/app/modules/operation/modules/vat-number-association-dto';
import {CCValidation} from 'src/app/modules/operation/modules/cc-validation';
import {RegisterSaleErrorTypeEnum} from '../../shared/enum/register-sale-error.enum';
import { RecordsByFilterRequest } from 'src/app/modules/operation/pages/non-ticketing-products-history/model/history-non-ticketing-product';

@Injectable({
    providedIn: 'root'
})
export class SaleService {

    error = 'Ocorreu um erro ao recuperar os dados.';

    callListAssociatedClientInfo: Subject<any> = new Subject<any>();
    callRegister: Subject<any> = new Subject<any>();

    constructor(
        private httpClient: HttpClient,
        private shoppingService: ShoppingCartService
    ) {
    }

    public async getVatNumberAssociationList(serialNumber: number) {
        const serialNumberRequest = String(serialNumber);
        try {
            const response = await this.httpClient.get<BasicResponseDTO<Array<VatNumberAssociationDTO>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/vat-number-association/${serialNumberRequest}`)
                .toPromise();
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    public async register(registerSaleRequest: any, printDocumentRequest: any) {
        registerSaleRequest.printDocumentRequest = printDocumentRequest;
        /* Print Error Message needs to match sale-service PrintException message */
        const printErrorMessage = 'Ocorreu um erro a imprimir os documentos.';
        /* Sale Error Message needs to match sale-service PrintException message */
        const saleErrorMessage = 'Ocorreu um erro a registar a venda.';

        try {
            const response: BasicDataResponse = await this
        .httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/sale`, registerSaleRequest).toPromise() as BasicDataResponse;

            if (!response.success) {
                console.log('sale.service.ts:register error on response: ', response.message);
                return response.message;
            }
            this.shoppingService.resetShoppingCartHeader();

            return {
                success: true,
                content: response.data
            };
        } catch (e) {
            const httpError: HttpErrorResponse = e;
            this.error = httpError.error.message;

            return {
                success: false,
                content: this.error,
                error: this.error == printErrorMessage ? RegisterSaleErrorTypeEnum.PRINT : RegisterSaleErrorTypeEnum.SALE,
            };
        }
    }

    public async isVatValid(request: VatValidationRequest) {
        console.log('call is-vat-valid');
        return await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/billing/is-vat-valid/${request.vat}`).toPromise()
            .then((response: BasicDataResponse) => {
                if (response && response.success) {
                    return response.data;
                }
                return false;
            });
    }

    public verifyIdentificationDocumentCC(ccNumber: CCValidation) {
        let params = new HttpParams();
        params = params.set('ccDocumentNumber', ccNumber.cc);
        return this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/billing/verify-identification-document-CC`, {params});
    }

    public async findSaleItemAuthByAuthLoadId(loadId: number): Promise<any> {
        return this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/sale-item/auth/${loadId}`)
            .toPromise();
    }

    
  //get non ticketing products
  async getListNewTicketingProducts( request: RecordsByFilterRequest): Promise<any> {
    let params = new HttpParams();
    if (request.docNumber?.trim()) {
        params = params.set('docNumber', `${request.docNumber.trim()}`);
    }
    if (request.startDate) {
        params = params.set('startDate', `${request.startDate}`);
    }
    if (request.endDate) {
        params = params.set('endDate', `${request.endDate}`);
    }
    params = params.set('size', `${request.size}`);
    params = params.set('page', `${request.page}`);
    params = params.set('sorters', `${request.sorters}`);

    return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/sale-item/non-ticketing-by-filter`, { params }).toPromise();
  }

}
