<nz-radio-group
  [(ngModel)]="selected"
  nzButtonStyle="solid"
  (ngModelChange)="callChange($event)"
>
  <span *ngFor="let o of listOptions" style="margin-right: 5px">

    <label
      class="button"
      [style.font-size]="size"
      [style.color]="color"
      nz-radio-button
      [nzValue]="o.value"
      >
        <span *ngIf="selected == o.value; else ncheck" class="white-dot"></span>
        <ng-template #ncheck>
          <span class="blue-dot"></span>
        </ng-template>
        <span *ngIf="selected == o.value; else notChecked" class="button-text-white">{{ o.label }}</span>
        <ng-template #notChecked>
          <span class="button-text">{{ o.label }}</span>
        </ng-template>
      </label>

  </span>
</nz-radio-group>
