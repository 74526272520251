import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpUtils} from 'src/app/shared/utils/http-utils';
import {BasicDataResponse} from 'src/app/shared/models/basic-data-response';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {BasicResponseDTO} from '../../shared/models/basic-response-dto';
import {ReplacementGuideDTO} from '../../modules/operation/pages/replacement-guide/model/create-repl-guide-request';

@Injectable({
  providedIn: 'root'
})
export class RecoverReplacementGuideService {

  error = 'Ocorreu um erro ao recuperar os dados.';

  constructor(private httpClient: HttpClient,
  ) {
  }

  isCardActiveReplacementGuide(cardSerialNumber: string): Observable<BasicResponseDTO<ReplacementGuideDTO>> {
    return this.httpClient.get<BasicResponseDTO<ReplacementGuideDTO>>
    (`${EnvironmentUtil.getEnv().apiIntApiUrl}replacement/api/replacement/card-serial-number/${cardSerialNumber}`);
  }

/*
  getReplacementGuideDetails(request: any) {
    console.log('call getReplacementGuideDetails');

    this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}replacement-guide/serv/replacement-guide/guide`,
      {params: request}
    ).subscribe(
      (res: BasicDataResponse) => {
        if (res && res.success) {
          this.callReplacementGuideDetails.next(res.data);
        } else {
          console.log('error on response');
          this.callReplacementGuideDetails.error(res.message);
        }
      },
      (e: any) => {
        console.log(e);
        try {
          this.error = HttpUtils.getErroMessage(e);
          console.log(this.error);
        } catch (e) {
          console.log(e);
        }
      });
  }
  */
}
