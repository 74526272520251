import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BasicResponse } from 'src/app/shared/models/basic-response';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { APIVivaResponse } from 'src/app/shared/models/api-viva-response';
import { ResetResponse } from 'src/app/shared/models/reset-response';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { NullifyResponse } from 'src/app/shared/models/nullify-response';
import { CancelFamilyShoppingCartItemRequest, CancelShoppingCartItemRequest } from '../../shared/models/cancel-shoppingcart-item-request';
import { BasicResponseDTO } from '../../shared/models/basic-response-dto';
import { ShoppingCartDTO } from 'src/app/shared/models/shopping-cart-dto';
import { ShoppingCartEnum } from 'src/app/shared/enum/shopping-cart.enum';
import { StorageManagerService } from './storage-manager.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {

    error = 'Ocorreu um erro ao recuperar os dados.';
    shoppingCartChange: Subject<any> = new Subject<any>();

    constructor(
        private httpClient: HttpClient,
        private storageManager: StorageManagerService
    ) { }

    /**
     *  This should only be used in worflows where the cart is payed and you are sure that the cart will be empty
     *  Example: sale.service.ts when we register sale,
     *  is the response is success we know that cart is payed so it should be empty,
     *  in this case we can use this.
     * @memberof ShoppingCartService
     */
    public resetShoppingCartHeader() {
        const emptyShoppingCart = {
            showHeader: true,
            cartId: null,
            listResult: [],
            totalQt: 0,
            totalValue: 0,
            totalToPay: 0,
        };
        this.shoppingCartChange.next(emptyShoppingCart);
    }

    public async list(shiftId: string): Promise<void> {
        const request: any = { shiftId };

        await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}/shopping/api/shopping/cart`, { params: request })
            .toPromise().then(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        this.storageManager.session.set(CommonEnum.shoppingCartFull, 'false');
                        if (data.content[0]) {
                            const listResult = data.content[0].items;
                            const result = {
                                showHeader: true,
                                cartId: data.content[0].id,
                                listResult,
                                totalQt: listResult.reduce((totalQt, item) => totalQt + item.quantity, 0),
                                totalValue: listResult.reduce((totalValue, item) => totalValue + (item.price * item.quantity), 0),
                                totalToPay: listResult.filter(({ quantity }) => quantity > 0).reduce((totalToPay, item) => totalToPay + (item.price * Math.abs(item.quantity)), 0),
                            };
                            this.shoppingCartChange.next(result);
                            if (ShoppingCartEnum.MAX_ITEMS_IN_SHOPPING_CART === listResult.length) {
                                this.storageManager.session.set(CommonEnum.shoppingCartFull, 'true');
                            }
                        }
                    } else {
                        this.shoppingCartChange.next(res.message);
                    }
                },
                (e: any) => {
                    try {
                        this.error = HttpUtils.getErroMessage(e);
                    } catch (e) {
                        console.log(e);
                    }
                }
            );
    }

    async insert(request: ShoppingCartItemRequest) {
        try {
            const response = await this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart`, request)
                .toPromise() as BasicResponse;

            const data = JSON.parse(response.message).data;
            const listResult = data.content;
            const shoppingCartResult = {
                showHeader: true,
                listResult,
                totalQt: listResult.reduce((totalQt, item) => totalQt + item.quantity, 0),
                total: listResult.reduce((total, item) => total + item.price, 0),
                totalToPay: listResult.filter(({ price }) => price > 0).reduce((totalToPay, item) => totalToPay + item.price, 0),
            };
            this.shoppingCartChange.next(shoppingCartResult);
        } catch (error) {
            this.shoppingCartChange.next(error);
            console.log(error);
        }
    }

    async insertMultiple(cartItems: ShoppingCartItemRequest[], shiftId: number) {
        var request = {
            cartItems,
            shiftId
        }
        try {
            const response = await this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart/multiple-items`, request)
                .toPromise() as BasicResponse;

            const data = JSON.parse(response.message).data;
            const listResult = data.content;
            const shoppingCartResult = {
                showHeader: true,
                listResult,
                totalQt: listResult.reduce((totalQt, item) => totalQt + item.quantity, 0),
                total: listResult.reduce((total, item) => total + item.price, 0),
                totalToPay: listResult.filter(({ price }) => price > 0).reduce((totalToPay, item) => totalToPay + item.price, 0),
            };
            this.shoppingCartChange.next(shoppingCartResult);
        } catch (error) {
            this.shoppingCartChange.next(error);
            console.log(error);
        }
    }

    public async cancelItem(request: CancelShoppingCartItemRequest) {
        try {
            const response = await this.httpClient
                .put<BasicResponseDTO<ShoppingCartDTO>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart/item`, request)
                .toPromise();
            this.shoppingCartChange.next(response.success);
        } catch (error) {
            this.shoppingCartChange.next(error);
            console.log(error);
        }
    }

    public async deleteItemFromCart(request: CancelShoppingCartItemRequest) {
        try {
            const response = await this.httpClient
                .put<BasicResponseDTO<ShoppingCartDTO>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cancel-item`, request)
                .toPromise();
            this.shoppingCartChange.next(response.success);
        } catch (error) {
            this.shoppingCartChange.next(error);
            console.log(error);
        }
    }

    public async cancelFamilyItem(request: CancelFamilyShoppingCartItemRequest) {
        try {
            const response = await this.httpClient
                .put<BasicResponseDTO<ShoppingCartDTO>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart/cancel/family-ticket`, request)
                .toPromise();
            this.shoppingCartChange.next(response.success);
        } catch (error) {
            this.shoppingCartChange.next(error);
            console.log(error);
        }
    }

    async reset(cardDecode: CardDecodeResponse) {
        let result;

        try {
            const response = await this.httpClient.post(`${EnvironmentUtil.getEnv().apiVivaUrl}api/card/reset`, cardDecode)
                .toPromise() as APIVivaResponse<ResetResponse>;
            console.log(response);
            result = response.success;
        } catch (error) {
            console.log(error);
        }

        return result;
    }

    async null(slot: number, cardBinary: CardDecodeResponse) {
        const request = cardBinary as any;
        request.slot = Number(slot);
        let result;
        try {
            const response = await this.httpClient.post(`${EnvironmentUtil.getEnv().apiVivaUrl}api/card/null`, request)
                .toPromise() as APIVivaResponse<NullifyResponse>;
            console.log(response);
            result = response.success;
        } catch (error) {
            console.log(error);
        }
        return result;
    }

    async getCartId(shiftId: number): Promise<number> {
        const request: any = { shiftId };

        const httpResponse = await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}/shopping/api/shopping/cart`,
            { params: request })
            .toPromise() as BasicResponseDTO<any>;

        if (httpResponse.success) {
            return httpResponse.data.content[0].id;
        }
    }

    async getCartByShiftId(shiftId: number): Promise<BasicResponseDTO<any>> {
        const request: any = { shiftId };

        return await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart`,
            { params: request })
            .toPromise() as BasicResponseDTO<any>;
    }

    public async findFineIdsInOpenCarts(): Promise<BasicResponseDTO<Array<number>>> {
        return await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}shopping/api/shopping/cart/fine-ids-in-open-carts`)
            .toPromise() as BasicResponseDTO<Array<number>>;
    }

    public async getCartByShiftIdAndAuthId(shiftId: number, authId: number): Promise<BasicResponseDTO<any>> {
        const request: any = { authId };
        return await this.httpClient.get(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}/shopping/api/shopping/cart-to-pay/${shiftId}`,
            { params: request })
            .toPromise() as BasicResponseDTO<any>;
    }

    public async getCartById(cartId: number) {
        let result;

        try {
            const cart =  await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}/shopping/api/shopping/cart/${cartId}`)
            .toPromise() as BasicResponseDTO<any>;

            if(cart.success) {
                return result = cart.data;
            }

        } catch(error) {
            return result;
        }
    }

    public async disableShoppingCart(cartId: number) {
        return await this.httpClient.put(`${EnvironmentUtil.getEnv().apiIntApiUrl}/shopping/api/shopping/cancel/${cartId}`, cartId)
        .toPromise();

    }


}
