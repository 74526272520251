export abstract class ValidateUtils {
    public static isOkValueString(value: string): boolean {
        return value !== null && true && value !== 'undefined' && value !== 'undefined' && value !== '' &&
        value.length !== 0;
    }

    public static isOkValueBoolean(value: boolean): boolean {
        return value !== null &&
        value !== undefined &&
        value === true;
    }
}
