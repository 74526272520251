import { TariffService } from 'src/app/core/services/tariff.service';
import { Subscription } from 'rxjs';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {AlertService} from 'src/app/core/services/alert.service';
import {Load} from 'src/app/modules/load/pages/lisboa-viva/models/load';
import {VoucherLoadService} from './service/voucher-load.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import {ShiftService} from 'src/app/core/services/shift.service';
import {LoadTitleRequest} from 'src/app/shared/models/load-title-request';
import {ShoppingCartItemRequest} from 'src/app/shared/models/shopping-cart-item-request';
import {ReadingLoadingEnum} from 'src/app/shared/enum/reading-loading.enum';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { CardEnum } from 'src/app/shared/enum/card.enum';

@Component({
  selector: 'app-voucher-load',
  templateUrl: './voucher-load.component.html',
  styleUrls: ['./voucher-load.component.less']
})
export class VoucherLoadComponent implements OnInit, OnDestroy {

  subscription1$: Subscription;
  subscription2$: Subscription;
  subscriptions: Subscription[] = [];

  stateResume = 'A operação foi efetuada com sucesso. Pode Retirar o Cartão do Leitor';
  isModalVisible: boolean;
  deviceId: string;
  load: Load;
  listTitles: any[] = [];
  voucherSelected: any;
  card: CardDetailsMessage;

  constructor(
    private storageManagerService: StorageManagerService,
    private navigationManager: NavigationManagerService,
    private shiftService: ShiftService,
    private alertService: AlertService,
    private service: VoucherLoadService,
    private modal: NzModalService,
    private peripheralService: PeripheralService,
    private tariffService: TariffService
  ) {
    if (this.navigationManager.getNavigation()?.extras?.state?.load && this.navigationManager.getNavigation()?.extras?.state?.card
    ) {
      this.load = this.navigationManager.getNavigation().extras.state.load;
      this.card = this.navigationManager.getNavigation().extras.state.card;
    } else {
      this.navigationManager.go(TicketingRoutes.HOME);
      this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
    }

    this.fetchPeripheralId();
    this.subscribeVoucherList();

    this.service.getTickets(Number(this.load.cardRead.serialNumber));
  }

  ngOnInit(): void { }

  subscribeVoucherList() {
    this.subscription1$ = this.service.voucherList.subscribe(
      (data: any[]) => {
        this.listTitles = data;
      },
      err => {
        this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        console.log('HTTP Error', err);
      }
    );
    this.subscriptions.push(this.subscription1$);
  }

  onChangeTitle(event): void {
   for (const entry of this.listTitles) {
      if (entry.id === event.value) {
        this.voucherSelected = entry;
      }
    }
  }

  async onClickCancel() {
    await this.navigationManager.go(TicketingRoutes.FAMILY_PASS,
        { load: this.load, card: this.card }
    );
  }

  handleConfirmModal(e: any) {
    this.isModalVisible = false;
    this.modal.closeAll();
    this.subscription2$ = this.modal.afterAllClose.subscribe(async () => {
      await this.navigationManager.go(TicketingRoutes.HOME);
    });
    this.subscriptions.push(this.subscription2$);
  }

  async onClickContinue() {

    const titleInfoResponse = await this.tariffService
      .getInfoTitleByTicketCodes(this.voucherSelected.ticketCode, this.voucherSelected.ticketOperatorCode)
      .toPromise();

    if (titleInfoResponse && titleInfoResponse.success) {

      const peripheralId = await this.peripheralService.getPeripheralId();
      const cardFullDetails = this.storageManagerService.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

      const shoppingCartRequest: ShoppingCartItemRequest = {
        shiftId: Number(this.shiftService.getShiftID()),
        titleId: titleInfoResponse.data.id,
        description: this.voucherSelected.name,
        quantity: 1,
        price: 0,
        transactionTypeId: LoadTitleTransactionType.TRANSACTION_CARR,
        annulId: null,
        cardNumber: String(cardFullDetails.details.environment.issuing_number),
        // LoadId is filled on camunda
        loadId: null,
        voucherId: null,
        requisitionId: null,
        authId: null,
        replacementId: null,
        cardSerialNumber: cardFullDetails.low_part,
        isAnnul: false
      };

      const loadTitleRequest: LoadTitleRequest = {
        peripheralId,
        serialNumber: this.load.cardRead.serialNumber,
        tickCode: this.voucherSelected.ticketCode,
        tickOperCode: this.voucherSelected.ticketOperatorCode,
        id: titleInfoResponse.data.id,
        voucherNumber: this.voucherSelected.id,
        amount: 1,
        shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID))
      };

      this.storageManagerService.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
      this.storageManagerService.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
      this.storageManagerService.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadFamilyTicket);
      await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, { shoppingCartRequest });

    } else {
      this.alertService.error(CommonEnum.msgInfoTitleError);
    }
  }

  private async fetchPeripheralId() {
    this.deviceId = await this.peripheralService.getPeripheralId();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
