import { RemoveContractRequestDTO } from './../../shared/models/remove-contract-card-dto';
import { CommonEnum } from './../../shared/enum/common.enum';
import { Injectable } from '@angular/core';
import { LoadTitleRequest } from '../../shared/models/load-title-request';
import { ShoppingCartItemRequest } from '../../shared/models/shopping-cart-item-request';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { Observable, Subject } from 'rxjs';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { CardDecodeResponse } from '../../shared/models/card-decode-response';
import { BasicResponse } from 'src/app/shared/models/basic-response';
import { CardEnum } from '../../shared/enum/card.enum';
import { StorageManagerService } from './storage-manager.service';
import { CardContract, CardDetailsMessage } from '../../shared/models/card-details-message';
import { BasicResponseDTO } from '../../shared/models/basic-response-dto';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { GuideCardLoadIdRequestDTO } from 'src/app/shared/models/guide-card-loadId-request';
import { PreviousContractResponse } from '../../shared/models/previous-contract-response';
import { CardDTO, LoadDTO } from '../../shared/models/load-dtos';
import { CardTransactionResponse } from '../../shared/models/card-transaction-response';
import { TariffService } from './tariff.service';
import { TitleFilter } from '../../modules/load/pages/lisboa-viva/title-selection/model/TitleFilter';
import { AlertService } from './alert.service';
import { SpinService } from './spin.service';
import NavigationManagerService, { TicketingRoutes } from './navigation-manager.service';
import { CancelShoppingCartItemRequest } from 'src/app/shared/models/cancel-shoppingcart-item-request';
import { ShoppingCartService } from './shopping-cart.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';

@Injectable({
    providedIn: 'root'
})
export class LoadTitleService {

    callLoadTitle: Subject<any> = new Subject<any>();
    callAnnulTitle: Subject<any> = new Subject<any>();
    annulAuthorizationTitle: Subject<any> = new Subject<any>();
    callTitleExchange: Subject<any> = new Subject<any>();
    error = 'Ocorreu um erro ao recuperar os dados.';

    constructor(
        private httpClient: HttpClient,
        private storageManagerService: StorageManagerService,
        private tariffService: TariffService,
        private alertService: AlertService,
        private spinService: SpinService,
        private navigationManager: NavigationManagerService,
        private shoppingCartService: ShoppingCartService,
        private storageManager: StorageManagerService) {
    }

    public loadTitle(
        titleRequest: LoadTitleRequest,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        mustReturnPayment: boolean = false,
        transactionType: number = LoadTitleTransactionType.TRANSACTION_VEND_CARR
    ): Observable<any> {
        const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);

        const newRequest = {
            amount: titleRequest.ticketQuantity,
            loadTitleId: titleRequest.id,
            shoppingTitleId: shoppingCartRequest.titleId,
            peripheralId,
            transaction: transactionType,
            cardFullDetails: cardDetails,
            binary: cardBinary,
            quantity: shoppingCartRequest.quantity,
            shiftId: titleRequest.shiftId,
            authId: shoppingCartRequest.authId,
            discount: shoppingCartRequest.discount
        };
        console.log(newRequest);
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/load`, newRequest);
    }

    getTitleListOfCardProfile(titleFilter: TitleFilter): Observable<BasicDataResponse> {
        return this.httpClient.post<any>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/other-load-list`, titleFilter);
    }

    public loadComposedTitle(
        titleRequest: LoadTitleRequest,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        mustReturnPayment: boolean = false
    ): Observable<any> {

        const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);

        const request = {
            amount: titleRequest.ticketQuantity ?? 1,
            loadTitleId: titleRequest.id,
            shoppingTitleId: titleRequest.id,
            peripheralId: peripheralId,
            transaction: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
            cardFullDetails: cardDetails,
            binary: cardBinary,
            quantity: shoppingCartRequest.quantity,
            shiftId: titleRequest.shiftId,
        };
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/compound-load`, request);
    }

    public async rollbackContract(identifier: any, cardSlot: number, cardContract: CardContract, cardBinary: CardDecodeResponse, encodeEvent: any): Promise<BasicResponseDTO<CardTransactionResponse>> {
        const request = {
            identifier: identifier,
            contractSlot: cardSlot,
            contract: cardContract,
            binary: cardBinary,
            event: encodeEvent,
            transactionType: 3,
            date: cardContract.ticket_date,
            contractPriority: cardContract.load_priority
        };
        return await this.httpClient.post<BasicResponseDTO<CardTransactionResponse>>
            (`${EnvironmentUtil.getEnv().carrisSystemApiUrl}/api/peripheral/encode-card`, request)
            .toPromise();
    }

    async transferTitle(
        transferContract: CardContract,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        titleRequest: LoadTitleRequest
    ): Promise<any> {
        const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get<CardDetailsMessage>(CommonEnum.peripheralId);
        const shiftId = this.storageManagerService.session.get<number>(CommonEnum.shiftID);
        const newCardFullDetails = this.storageManagerService.session.get<CardDetailsMessage>(
            CardEnum.NEW_FULL_CARD_DETAILS) as CardDetailsMessage;

        const title = await this.tariffService.getInfoTitleByTicketCodes(transferContract.ticket_code, transferContract.operator_code)
            .toPromise();

        const request = {
            cardFullDetails: cardDetails,  // para load-title-service -- old card= cardFullDetails
            peripheralId,
            transaction: LoadTitleTransactionType.TRANSACTION_TRANSF,
            transferContract,  // para load-title-service
            binary: cardBinary,              // para peripheral -- new card = cardBinary
            quantity: titleRequest.ticketQuantity,
            shiftId,
            newCardFullDetails,
            amount: titleRequest.amount,
            addVVToCart: titleRequest.addVVToCart,
            shoppingCartItem: shoppingCartRequest, // para sale-service,
            shoppingTitleId: title.data.id
        };

        return await this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/transfer`, request)
            .toPromise();
    }

    public annulTitle(
        titleRequest: LoadTitleRequest,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        annulAuthorization: boolean = false,
        fromShoppingCart: boolean = false) {
        const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        const request = {
            loadTitleId: titleRequest.id,
            shoppingTitleId: shoppingCartRequest.titleId,
            peripheralId: titleRequest.peripheralId,
            transaction: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
            cardFullDetails: cardDetails,
            binary: cardBinary,
            shiftId: shoppingCartRequest.shiftId,
        };

        if (annulAuthorization) {
            return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/cancel-load-authorization`, request).subscribe(
                (res: BasicDataResponse) => {
                    if (res && res.success) {
                        this.annulAuthorizationTitle.next(res);
                    } else {
                        this.annulAuthorizationTitle.error(HttpUtils.getOrchestratorError(res));
                    }
                },
                (e: any) => {
                    try {
                        this.error = HttpUtils.getErroMessage(e);
                        this.callLoadTitle.error(this.error);
                    } catch (e) {
                        console.log(e);
                    }
                });
        } else {
            return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/cancel`, request).subscribe(
                (res: BasicDataResponse) => {
                    if (res && res.success) {
                        this.callAnnulTitle.next(res);
                    } else {
                        this.callAnnulTitle.error(HttpUtils.getOrchestratorError(res));
                    }
                },
                async (e: any) => {
                    try {
                        const isTUD = this.storageManager.session.get<boolean>(ReadingLoadingEnum.IsTUD);
                        if (isTUD) {
                            const cancelReq: CancelShoppingCartItemRequest = {
                                shoppingCartId: await this.shoppingCartService.getCartId(shoppingCartRequest.shiftId),
                                shiftId: shoppingCartRequest.shiftId,
                                titleId: shoppingCartRequest.titleId
                            };
                            await this.shoppingCartService.deleteItemFromCart(cancelReq);
                        }
                        this.error = HttpUtils.getErroMessage(e);
                        this.callLoadTitle.error(this.error);
                        this.alertService.error(CommonEnum.msgUnexpectedAnnulError, true);
                        this.spinService.setSpinValue(false);
                        this.navigationManager.go(TicketingRoutes.HOME);
                    } catch (e) {
                        console.log(e);
                    }
                });
        }

    }

    titleExchange(request: any) {
        this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/load/exchange`, request).subscribe(
            (res: BasicResponse) => {
                if (res && res.success) {
                    const data = HttpUtils.getData(res);
                    this.callTitleExchange.next(data?.content);
                } else {
                    this.callTitleExchange.error(res);
                }
            },
            (e: any) => {
                try {
                    this.error = HttpUtils.getErroMessage(e);
                    this.callTitleExchange.error(this.error);
                    this.alertService.error(CommonEnum.msgUnexpectedExchangeError, true);
                    this.spinService.setSpinValue(false);
                    this.navigationManager.go(TicketingRoutes.HOME);
                } catch (e) {
                    console.log(e);
                }
            });
    }

    getZappingLoadValues(zappingBalance: number = 0): Observable<any> {
        let params = new HttpParams();
        params = params.set('zappingCurrentBalance', `${zappingBalance}`);
        return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/get-zappings`, { params });
    }

    public getTud(request: { titleId: number, cardSerialNumber: string }) {
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/tud`, request);
    }

    findLatest(cardSerialNumber: string): Observable<BasicResponseDTO<any>> {
        return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/last/${cardSerialNumber}`);
    }

    async getPrevContract(loadTitleId: number): Promise<PreviousContractResponse> {

        try {
            const response = await this.httpClient
                .get<BasicResponseDTO<PreviousContractResponse>>
                (`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/load/${loadTitleId}/previous-contract`)
                .toPromise();
            if (response.success) {
                return response.data;
            } else {
                console.log(response.detail);
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async deleteLoadTitle(loadTitleId: number): Promise<LoadDTO> {
        try {
            const response = await this.httpClient.delete<BasicResponseDTO<LoadDTO>>(
                `${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/load/${loadTitleId}`
            ).toPromise();
            if (response.success) {
                return response.data;
            } else {
                console.log(response.detail);
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async enableLoad(loadTitleId: number): Promise<boolean> {
        try {
            const request = {
                loadId: loadTitleId
            };
            const response = await this.httpClient.put<BasicResponseDTO<boolean>>(
                `${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/load/compensation/enable`, request
            ).toPromise();
            if (response.success) {
                return response.data;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async getCardEncodeEvent(cardContract: CardContract, slot: number, cardFullDetails: CardDetailsMessage) {
        const request = {
            cardDetails: cardFullDetails,
            contractSlot: slot,
            contractDTO: cardContract
        };
        const response = await this.httpClient.post
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/serv/load-title/get-card-encode-event`, request)
            .toPromise() as BasicDataResponse;
        return response.data as any;
    }

    async getLoadById(loadTitleId: number): Promise<LoadDTO> {
        try {
            const response = await this.httpClient
                .get<BasicResponseDTO<LoadDTO>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/load/${loadTitleId}`)
                .toPromise();
            if (response.success) {
                return response.data;
            } else {
                console.log(response.detail);
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getLastLoadIdBasedOnTitleIdAndSerialNumber(titleId: number, serialNumber: string): Promise<number> {
        let guideCardLoadIdRequest: GuideCardLoadIdRequestDTO;
        guideCardLoadIdRequest.titleId = titleId;
        guideCardLoadIdRequest.serialNumber = serialNumber;

        const response = await this.httpClient.post<BasicDataResponse>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/load/guide/card/loadId`, guideCardLoadIdRequest)
            .toPromise();

        if (response && response.success) {
            return response.data as number;
        } else {
            return null;
        }
    }

    async removeContractFromCard(loadId: number, peripheralId: number, cardFullDetails: CardDetailsMessage): Promise<Number> {
        let removeRequest: RemoveContractRequestDTO;
        removeRequest.loadId = loadId;
        removeRequest.peripheralId = peripheralId;
        removeRequest.cardFullDetails = cardFullDetails;

        const response = await this.httpClient.post<BasicDataResponse>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/serv/load-title/get-load-title-contract-slot`, removeRequest)
            .toPromise();

        if (response && response.success) {
            return response.data as number;
        } else {
            return null;
        }
    }

    async getTitleAmountToAnnul(titleId: number, cardFullDetails: CardDetailsMessage): Promise<number> {
        const request = {
            titleId,
            cardFullDetails
        };
        const response = await this.httpClient
            .post<BasicDataResponse>(`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/cancel/title-amount`, request)
            .toPromise();
        if (response && response.success) {
            return response.data as number;
        } else {
            return null;
        }
    }

    public async didLoadInitializedVVCard(loadId: number, cardFullDetails: CardDetailsMessage): Promise<BasicResponseDTO<boolean>> {
        return await this.httpClient.post<BasicResponseDTO<boolean>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}/load-title/api/operation/card/did-load-initialized-card/${loadId}`, cardFullDetails)
            .toPromise();
    }

    public async getCardInfoByCardNumber(cardNumber: string): Promise<CardDTO> {
        const response = await this.httpClient.post<BasicResponseDTO<CardDTO>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/card-info-by-cardNumber`, cardNumber)
            .toPromise();
        if (response && response.success) {
            return response.data as CardDTO;
        } else {
            return null;
        }
    }

    public async getCardInfoByCardSerialNumber(cardSerialNumber: string): Promise<CardDTO> {
        const response = await this.httpClient.post<BasicResponseDTO<CardDTO>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/card-info-by-serialNumber`, cardSerialNumber)
            .toPromise();
        if (response && response.success) {
            return response.data as CardDTO;
        } else {
            return null;
        }
    }

    public async getLastLoadByTitleIdAndCardSerialNumber(titleId: number, cardSerialNumber: number): Promise<BasicResponseDTO<LoadDTO>> {
        return await this.httpClient.get<BasicResponseDTO<LoadDTO>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/previous/title/${titleId}/cardSerialNumber/${cardSerialNumber}`)
            .toPromise();
    }

    public async decodeCardFullDetails(cardFullDetails: CardDetailsMessage) {
        return await this.httpClient.post(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card`, cardFullDetails)
            .toPromise();
    }

}
