<br>
<br>
<div nz-row class="margin-bottom-10">
    <!-- HEADER -->
    <div nz-col nzSpan="24" style="height: 90px;">
        <h1 class="title">
            Produtos Não Bilhética
        </h1>
        <div nz-row>
            <h3 class="tariff">Selecione a categoria de produtos a adicionar ao carrinho de compras</h3>
        </div>
        <div nz-col nzSpan="6" class="buttons" 
        style="
        width: 160px;
        height: 80px;
        float: right;
        bottom: 90px;">
            <span>
              <app-secondary-second-button (click)="historyView()" class="height-button">
                <span class="btn-icon icon-historico"></span>
                  <h3 class="button-label">Histórico</h3>
              </app-secondary-second-button>
            </span>
        </div>
    </div>
</div>
<div nz-row class="load" style="position:relative" class="margin-bottom-20">
    <span>
        <br>
        <div>
            <app-primary-list-button-non-ticketing text="left" [data]="listCategories" [colNum]="6"
                (callButtonClick)="newCategorySelection($event)"></app-primary-list-button-non-ticketing>
        </div>
    </span>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button (click)="previousView()">
                <span class="btn-icon icon-anterior icon-middle float-left"></span>
                Voltar
            </app-primary-white-button>
        </div>
    </div>
</div>