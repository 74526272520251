<div class="new-requisition-content">

  <div nz-row>
    <h1 class="req">Requisições Lisboa VIVA/navegante personalizado</h1>
  </div>
  <div nz-row>
    <h2 class="card">Selecione os dados de cartão a emitir</h2>
  </div>

  <label class="profile">Perfil</label>
  <div nz-row class="m-b-20">
    <app-primary-radio-group class="button" class="divColor" [listOptions]="listRequisitionType"
      (onChanged)="onRequisitionTypeChanged($event)"></app-primary-radio-group>
  </div>

  <label class="delivery">{{listDeliveryType.length > 0 ? 'Entrega' : ''}}</label>
  <div nz-row>
    <app-primary-radio-group class="button" size="16px" class="divColor" [listOptions]="listDeliveryType"
      (onChanged)="onDeliveryTypeChanged($event)"></app-primary-radio-group>
  </div>

</div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col [nzSpan]="12">
      <div nz-row nzJustify="start">
        <app-primary-white-button (click)="previousView()">
          <span class="btn-icon icon-anterior icon-middle float-left "></span>
          Voltar
        </app-primary-white-button>
      </div>
    </div>
    <div nz-col [nzSpan]="12">
      <div nz-row nzJustify="end" style="padding-right: 48px">
        <app-primary-normal-button
        width="180px"
        [disabled]="request.titleSelected && request.deliveryType ? null : true"
        (click)="onNextClick()">
          SEGUINTE
          <span class="btn-icon icon-seguinte icon-middle float-right"></span>
        </app-primary-normal-button>
      </div>
    </div>
  </div>
</div>
