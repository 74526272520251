import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Directive({
  selector: '[appHideIfUnauthorised]'
})
export class HideIfUnauthorisedDirective implements OnInit {

  @Input('appHideIfUnauthorised') permission: AuthorityName;

  constructor(private el: ElementRef,
              private authorizationService: AuthorizationService) { }

  ngOnInit() {
    if (!this.authorizationService.hasPermission(this.permission)) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
