import { CommonEnum } from '../enum/common.enum';
import {BasicResponse} from '../models/basic-response';

export class HttpUtils {

    public static getData(basicResponse: BasicResponse): any {
        try {
            const message = JSON.parse(basicResponse.message);
            if (message.data) {
                return message.data;
            }
            const response = JSON.parse(message.response);
            return response.data;
        } catch (e) {
            const msg = 'Não foi possivel recuperar os dados recebidos.';
            console.log(msg);
            // TODO Implement new exception
        }
    }

    public static getOrchestratorError(err: any): any {
        try {
            const resp = JSON.parse(err);
            if (resp.response) {
                const response = JSON.parse(resp.response);
                return response.error.message;
            }
        } catch (e) {
            try {
                const message = JSON.parse(err.message);
                const response = JSON.parse(message.response);
                return response.error.message;
            } catch (error) {
                const msg = 'Não foi possivel recuperar os dados recebidos.';
                console.log(msg);
                // TODO Implement new exception
            }
        }
    }

    public static getMessage(basicResponse: BasicResponse): any {
        try {
            return JSON.parse(basicResponse.message);
        } catch (e) {
            const msg = 'Não foi possivel recuperar os dados recebidos.';
            console.log(msg);
            // TODO Implement new exception
        }
    }

    public static getErrorResponse(e: any): any {
        try {
            const errorMessage = JSON.parse(e?.error?.message);
            const errorResponse = JSON.parse(errorMessage?.response);

            return errorResponse;

        } catch (e) {
            const msg = 'Não foi possivel recuperar error.response.';
            console.log(msg);
            throw new Error(CommonEnum.msgHttpUtilsMissingResponse);
        }
    }
    public static getErrorResponseMessage(e: any): any {
        try {
            return JSON.parse(HttpUtils.getErrorResponse(e).message);

        } catch (e) {
            const msg = 'Não foi possivel recuperar error.response.message.';
            console.log(msg);
            throw new Error(CommonEnum.msgHttpUtilsMissingMessage);
        }
    }

    public static getErrorResponseMessageDetail(e: any): string {
        try {
            return HttpUtils.getErrorResponseMessage(e).detail;

        } catch (e) {
            const msg = 'Não foi possivel recuperar error.response.message.detail.';
            console.log(msg);
            throw new Error(CommonEnum.msgHttpUtilsMissingDetail);
        }
    }

    public static getErroMessage(e: any): string {
        try {
            const errorMessage = JSON.parse(e?.error?.message);
            try {
                const errorResponse = JSON.parse(errorMessage?.response);
                return errorResponse.error.message;
            } catch (e) {
                return errorMessage;
            }
        } catch (e) {
            const msg = 'Não foi possivel recuperar os dados recebidos.';
            console.log(msg);
            // TODO Implement new exception
        }
    }

    // TODO Does not work very well
    public static isBasicResponse(object: any): object is BasicResponse {
        return 'success' in object && 'message' in object;
    }

}
