<div class="detailContent">
  <div>
    <h1>Autorização de Carregamento</h1>
  </div>
  <div class="details">
    <p>Detalhes das autorizações de carregamento</p>
  </div>
  <div class="group">
    <div class="validFrom">
      <b> </b>
    </div>
    <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader" [listOfData]="dataTable"
        showPagination="false" pageSize="5" isSingleSelection="true" (isCheckedChange)="checkBoxEvent($event)">
    </app-primary-table>

<div class="footer-buttons">
    <div  nz-row>
        <div nz-col nzSpan="12" class="align-text-left">
        <app-primary-white-button
          class="float-left"
          (click)="homeScreen()">
          Cancelar
        </app-primary-white-button>
      </div>
      <div nz-col nzSpan="12" class="align-text-right">
        <app-primary-normal-button
          class="float-right"
          [critical] = "true"
          (click)="loadSelection()"
          [disabled]="titleSelected != null ? null : true"
          buttonCSS="button-load">
          Carregar
        </app-primary-normal-button>
      </div>
    </div>
  </div>
</div>
