import { EventService } from './../services/events.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ShiftService } from '../services/shift.service';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { Router } from '@angular/router';
import { PeripheralService } from '../services/peripheral.service';
import { AlertService } from '../services/alert.service';
import { JwtUtils } from 'src/app/shared/utils/jwt-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ValidateUtils } from 'src/app/shared/utils/validate-utils';
import { CloseShiftRequest } from 'src/app/shared/models/close-shift-request';
import { SuspendShiftRequest } from 'src/app/shared/models/suspend-shift-request';
import { StorageManagerService } from '../services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from '../services/navigation-manager.service';
import { PrintShiftRequest } from 'src/app/shared/models/print-shift-request';
import { EventTypeEnum } from 'src/app/modules/shift/pages/shift-resume/model/event.interface';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
    // public title = "Inicio de turno 20/03/2020 12:45:30 | Operador 12345 | Turno 500"

    title = '';
    titleShift = '';
    totalValue = '';
    showShoppingCart = false;
    isCloseShiftModalVisible: boolean;
    isSuspendShiftModalVisible: boolean;
    isCloseShiftModalOkLoading: boolean;
    isSuspendShiftModalOkLoading: boolean;
    canCloseShift = true;
    deviceId: string;
    machineNumber: string;
    storeName: string;
    href1 = '';
    toSuspendShift = false;

    private readonly accessToken: string;
    private readonly shiftId: string;
    private jwtUtils: JwtUtils;

    header: any;
    showNavHeader = false;

    constructor(
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private cd: ChangeDetectorRef,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private router: Router,
        private eventService: EventService
    ) {
        const jwtUtils = new JwtUtils();
        this.title = jwtUtils.getUserName() || '';

        this.shiftService.loginChange.subscribe((header) => {
            if (header != null && header.clean) {
                this.clean();
                return;
            }

            this.title = jwtUtils.getUserName() || '';
            this.titleShift = this.shiftService.getShiftID();
            if (this.titleShift) {
                this.showShoppingCart = true;
            }

        });

        this.shoppingCartService.shoppingCartChange.subscribe((data) => {
            if (data != null && data.showHeader) {
                this.showNavHeader = data.showHeader;
                this.totalValue = data.totalValue;
                this.canCloseShift = data?.listResult?.length <= 0;
            }
        });
        this.shiftId = this.shiftService.getShiftID();
        this.accessToken = this.storageManager.session.get<string>(CommonEnum.accessToken);
        this.jwtUtils = jwtUtils;
    }

    public isCorrectPages(): boolean {
        let hasErrorMessage = false;
        this.href1 = this.router.url;
        const errorMessageScanFromStorage = this.storageManager.session.get(CommonEnum.errorMessageScanCard) ?
            this.storageManager.session.get(CommonEnum.errorMessageScanCard) :
            '';
        const isLoadingActive = this.storageManager.session.get(CommonEnum.loadingActive);
        if (errorMessageScanFromStorage !== '') {
            hasErrorMessage = true;
        }
        const supportDetailsRoute = '/' + TicketingRoutes.SUPPORT_DETAILS;
        const homeRoute = '/' + TicketingRoutes.HOME;
        return (supportDetailsRoute === this.href1 || homeRoute === this.href1) && !isLoadingActive && !hasErrorMessage;
    }

    public isReadingLoadComponent(): boolean {
        this.href1 = this.router.url;

        return (this.href1 === '/reading-loading-lv');
    }

    async ngOnInit() {
        this.machineNumber = await this.peripheralService.getMachineNumber();
        this.peripheralService.getPeripheralId().then(
            (deviceId: any) => {
                this.deviceId = deviceId;
            });
        this.storeName = await this.peripheralService.getStoreName();
        if (!ValidateUtils.isOkValueString(String(this.shiftId))) {
            await this.navigationManager.go(TicketingRoutes.LOGIN, {
                messageError: CommonEnum.msgUnauthorized,
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.navigationManager.hasNavigationMessage()) {
            this.alertService.success(this.navigationManager.getNavigationMessage());
            this.cd.detectChanges();
        }
    }

    public clean() {
        this.showShoppingCart = false;
    }

    public closeShiftModal(): void {
        if (this.canCloseShift) {
            this.isCloseShiftModalVisible = true;
        } else {
            this.alertService.error(CommonEnum.msgShiftCloseWithShoppingItemsError);
        }
    }

    public suspendShiftModal(): void {
        this.isSuspendShiftModalVisible = true;
    }

    public handleCancelcloseShiftModal(): void {
        this.isCloseShiftModalVisible = false;
    }

    public handleCancelSuspendShiftModal(): void {
        this.isSuspendShiftModalVisible = false;
    }

    public async closeShift(): Promise<void> {
        this.isCloseShiftModalOkLoading = true;
        this.toSuspendShift = false;
        console.log('closeShift called');

        if (!this.machineNumber) {
            this.alertService.error(CommonEnum.msgPeripheralIdentificationError);
            this.isCloseShiftModalOkLoading = false;
            this.handleCancelcloseShiftModal();
            return;
        }
        const request: CloseShiftRequest = {
            shiftId: Number(this.storageManager.session.get<string>(CommonEnum.shiftID)),
            requesterId: Number(this.jwtUtils.getUserID()),
            deviceId: this.machineNumber,
            role: 'user'
        };

        const printRequest: PrintShiftRequest = {
            id: Number(this.storageManager.session.get<string>(CommonEnum.shiftID)),
            deviceId: this.deviceId,
            includeMiniReceipt: true,
            includeReceiptCopy: true,
        };

        const response = await this.shiftService.closeShift(request, printRequest);
        if (response.success) {
            this.isCloseShiftModalOkLoading = false;
            this.isCloseShiftModalVisible = false;

            const message = CommonEnum.msgShiftClose + ` (Operador: ${this.jwtUtils.getName()})`;

            await this.shiftService.doLogout();

            await this.navigationManager.go(TicketingRoutes.LOGIN, {
                messageType: 'success',
                message
            });
        } else {
            this.handleCancelcloseShiftModal();
            this.isCloseShiftModalOkLoading = false;
            this.alertService.error(
                `Ocorreu um erro. ${response.error ? response.error : CommonEnum.msgShiftCloseError}`
            );
        }
    }

    public async suspendShift(): Promise<void> {
        this.toSuspendShift = true;
        const request: SuspendShiftRequest = {
            shiftId: Number(this.storageManager.session.get<string>(CommonEnum.shiftID)),
        };
        const message = CommonEnum.msgShiftOpen + ` (do operador ${this.jwtUtils.getName()})`;
        this.shiftService.suspendShiftChange.subscribe(
            async () => {
                await this.shiftService.doLogout();
                this.isSuspendShiftModalVisible = false;
                await this.navigationManager.go(TicketingRoutes.LOGIN, {
                    messageType: 'success',
                    message,
                });
            }
        );
        this.shiftService.suspend(request);
    }

    public async openShiftResume(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.SHIFT_RESUME);
    }

    public async goToHome(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public isShiftless(): boolean {
        return this.shiftService.isLoggedIn() && !this.showShoppingCart;
    }

}
