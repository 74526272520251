<div class="detailContent">
  <div class="title">
    <div>
      <h1>Seleção de Voucher</h1>
    </div>

    <div id="p">
      <p>Detalhes do voucher a ser consumido</p>
    </div>
  </div>

  <div class="centralDiv">
    <div class="request">
      <h3>Operadores</h3>
      <p>MM</p>
    </div>

    <div class="delivery">
      <label for="delivery"
        >Validade do Voucher <br />
        <app-primary-select
          name="delivery"
          id="delivery"
          [selected]="selectedDeliveryPlace"
          [listOptions]="listDeliveyPlace"
        >
        </app-primary-select
      ></label>
    </div>
    <div class="product">
      <p>Selecione o produto que pretende:</p>

      <app-primary-radio-group
        class="divColors"
        [selected]="selectedProduct"
        [listOptions]="listProduct"
      ></app-primary-radio-group>
    </div>
  </div>
  <div class="divButtons" nz-row nzJustify="start">
    <div nz-col [nzSpan]="4">
      <app-primary-normal-button class="star-button"
        >Cancelar</app-primary-normal-button
      >
    </div>

    <div class="load" nz-col [nzSpan]="4">
      <app-primary-normal-button
        >Seguinte</app-primary-normal-button
      >
    </div>
  </div>
</div>
