import { BasicDataResponse } from './../../../../../shared/models/basic-data-response';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BasicResponse } from 'src/app/shared/models/basic-response';
import { tap } from 'rxjs/operators';
import { HttpUtils } from 'src/app/shared/utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export class VoucherSelectionService {

  error = 'Ocorreu um erro ao recuperar os dados.';
  callFilter: Subject<any> = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  public filter( titleGroupId: number): Observable<BasicDataResponse> {

    return this.httpClient
      .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/get-titles-by-title-group-id/${titleGroupId}`)
      .pipe( tap(
          async (res: BasicDataResponse) => {
            if (res && res.success) {
              const data = res.data;
              this.callFilter.next(data);
            } else {
              console.log('error on response');
              this.callFilter.error(res.message);
            }
          },
          (e: any) => {
            try {
              if (HttpUtils.isBasicResponse(e)) {
                this.error = HttpUtils.getErroMessage(e);
              } else {
                this.error = e.getMessage;
              }
              console.log(this.error);
            } catch (e) {
              console.log(e);
            }
          }
        ));
  }

}
