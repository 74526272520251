import { IdName } from './../model/id-name';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/core/services/alert.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { EnvironmentUtil } from '../../../../../../environments/environment-util';
import { AuthorityName } from 'src/app/shared/models/authority-name';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import {TariffService} from "../../../../../core/services/tariff.service";

@Injectable({
  providedIn: 'root'
})
export class VoucherLoadService {

  voucherList: Subject<any[]> = new Subject<IdName[]>();
  private hasValidVoucher: boolean = false;

  error = 'Ocorreu um erro ao recuperar os dados.';

    constructor(
        private httpClient: HttpClient,
        private alertService: AlertService,
        private storageManagerService: StorageManagerService,
        private tariffService: TariffService) {
    }

  getTickets(cardSerialNumber: number) {

     this.httpClient
      .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}familypass-service/api/familypass/voucher/${cardSerialNumber}/get-valid`)
      .subscribe(
        async (res: any) => {
          console.log(res);
          if (res && res.success) {
            const data =  res.data;
            if (data != null) {
              const teste: any[] = [];
              for (const entry of data) {

                  const titleResponse = await this.tariffService.getInfoTitleByTicketCodes(
                      entry.ticketCode,
                      entry.ticketOperatorCode
                  ).toPromise();

                  teste.push(
                  {
                    id: entry.id,
                    name: titleResponse.data.description,
                    issueDate: new Date(entry.startDate),
                    // usedDate: new Date(entry.usedDate),
                    price: titleResponse.data.price.value,
                    ticketCode: entry.ticketCode,
                    ticketOperatorCode: entry.ticketOperatorCode,
                    validEndDate: new Date(entry.endDate),
                    role: AuthorityName.FAMILY_PASS
                  });
              }
              this.voucherList.next(teste);
            }
          } else {
            this.alertService.error(res.message);
          }
        },
        (e: any) => {
          try {
            if (HttpUtils.isBasicResponse(e)) {
              this.error = HttpUtils.getErroMessage(e);
            } else if (e?.error?.error?.message) {
              this.error = e.error.error.message;
            } else {
              this.error = e.getMessage;
            }
            this.alertService.error(this.error);
          } catch (e) {
            console.log(e);
          }
        }
      );

  }

    public loadTicket(voucher: string, titleId: number, amount: number, shoppingCartRequest: ShoppingCartItemRequest): Observable<any> {
        const cardBinary = this.storageManagerService.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
        const cardFullDetails = this.storageManagerService.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get(CommonEnum.peripheralId) as string;
        const shiftId = this.storageManagerService.session.get(CommonEnum.shiftID) as number;

        const request = {
            loadTitleId: titleId,
            shoppingTitleId: titleId,
            peripheralId,
            quantity: shoppingCartRequest.quantity,
            shiftId: shiftId,
            authId: shoppingCartRequest.authId,
            titleId: titleId,
            amount: amount,
            transaction: shoppingCartRequest.transactionTypeId,
            cardFullDetails,
            binary: cardBinary,
            voucherId: voucher
        };

        return this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/familyPass/load`, request);
    }

    public async seeIfHasValidVoucher(cardSerialNumber: number): Promise<boolean>{
        await this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}familypass-service/api/familypass/voucher/${cardSerialNumber}/get-valid`).toPromise().then(
            (response: any) => {
                if (response && response.success && response?.data !== null) {
                    this.hasValidVoucher = true;
                }
            }).catch((e) => {
                this.hasValidVoucher = false;
            });
        return this.hasValidVoucher;
    }
}
