import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-primary-rectangle-button-annul',
  templateUrl: './primary-rectangle-button-annul.component.html',
  styleUrls: ['./primary-rectangle-button-annul.component.less'],
})
export class PrimaryRectangleButtonAnnulComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() width = '';
  @Input() height = '';
  
  @Output() onClick = new EventEmitter<any>();

  onClickButton(event) {
    this.onClick.emit(event);
  }

  constructor() {}

  ngOnInit(): void {}
}
