import { BasicResponseDTO } from './../../shared/models/basic-response-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { PrePaymentEntity } from 'src/app/modules/operation/pages/payment-pre-payment/interfaces/payment-pre-paymente.interfaces';

@Injectable({
    providedIn: 'root',
})
export class PrePaymentEntityService {

    constructor(private httpClient: HttpClient) {
    }

    public async getAllEnabledPrePaymentEntities(): Promise<BasicResponseDTO<Array<PrePaymentEntity>>> {
        return await this.httpClient.get<BasicResponseDTO<Array<PrePaymentEntity>>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/pre-payment/entity/all/enabled`).toPromise();
    }

}
