import { EnvironmentUtil } from "src/environments/environment-util";

export function disableRefresh() {
    if (!(EnvironmentUtil.isLocal() || EnvironmentUtil.isDev())) {
        window.addEventListener("keyup", disableF5);
        window.addEventListener("keydown", disableF5);
        window.addEventListener("beforeunload", confirmModal, { capture: true });
    }
}

export function enableRefresh() {
    window.removeEventListener("keyup", disableF5);
    window.removeEventListener("keydown", disableF5);
    window.removeEventListener("beforeunload", confirmModal, { capture: true })
}

function disableF5(e: { which: any; keyCode: any; preventDefault: () => void; }) {
    if ((e.which || e.keyCode) == 116) e.preventDefault();
};

function confirmModal(e) {
    e.preventDefault();
    return e.returnValue = "Ao recarregar a página pode danificar o fluxo. Tem a certeza que pretende avançar?";
}
