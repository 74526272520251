import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-checkbox',
  templateUrl: './primary-checkbox.component.html',
  styleUrls: ['./primary-checkbox.component.less',
              '../../../modules/operation/pages/invoice/invoice.component.less']
})
export class PrimaryCheckboxComponent implements OnInit {

  @Input() label: string;
  @Input() isDisabled: boolean;
  @Input() isChecked: boolean;
  @Input() labelCss: string = 'label';
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  valueChange(event) {
    this.isCheckedChange.emit(event);
  }

}
