import { Injectable } from '@angular/core';
import { Navigation, NavigationExtras, Router } from '@angular/router';

export enum TicketingRoutes {
    SUPPORT_DETAILS = 'load/support-detail',
    LOGIN = 'login',
    REPLACEMENT_GUIDE_LOAD = 'load/guide-detail',
    REPLACEMENT_GUIDE_INITIAL = 'replacement-guide/initial-screen',
    REPLACEMENT_GUIDE_RECOVERY_DETAILS = 'replacement-guide/recovery-details',
    REPLACEMENT_GUIDE_RECOVERY_REPLACEMENT_GUIDE = 'replacement-guide/recover-replacement-guide',
    REPLACEMENT_GUIDE_SUBSTITUTION_GUIDE = 'replacement-guide/substitution-guide',
    LV_REQUISITION = 'requisition-lv',
    LV_REQUISITION_HISTORY = 'requisition-lv/history',
    LV_REQUISITION_NEW = 'requisition-lv/new',
    CUSTOMER_INFO = 'tariff-pdf',
    SHIFT_RESUME = 'shift/shift-resume',
    SHIFT_PREVIOUS = 'shift/previous-shifts',
    SHIFT_SUSPENDED = 'shift/suspended-shifts',
    READING_LOADING_LV = 'reading-loading-lv',
    READING_LOADING_LV_TRANSFER = 'reading-loading-lv/transfer',
    READING_LOADING_LV_GUIDE_EMIT = 'reading-loading-lv/replacement-guide/emit',
    READING_LOADING_LV_GUIDE_RECOVER = 'reading-loading-lv/replacement-guide/recover',
    SHOPPING_CART = 'shopping-cart',
    HOME = 'home',
    AUTHORIZATION_CHARGE = 'authorization-charge',
    ANNUL_TITLE = 'load/annul-title',
    TITLE_SELECTION = 'load/title-selection',
    CHANGE_TITLE = 'load/change-title',
    TITLE_DETAILS = 'load/title-details',
    ZAPPING = 'load/zapping',
    FAMILY_PASS = 'family-pass',
    VOUCHER_SELECTION = 'voucher-selection',
    REPLACEMENT_GUIDE_DELIVERY = 'replacement-guide/delivery',
    REPLACEMENT_GUIDE_ISSUE_REPLACEMENT_GUIDE = 'replacement-guide/issue-replacement-guide',
    VOUCHER_DETAILS = 'voucher-details',
    VOUCHER_LOAD = 'family-pass/voucher-load',
    VOUCHER_CANCEL = 'family-pass/voucher-cancel',
    INVOICE = 'invoice',
    PAYMENT_MONEY = 'payment-money',
    PAYMENT_MULTIBANCO = 'payment-multibanco',
    PAYMENT_MULTIPLE = 'payment-multiple',
    PAYMENT_SIIT = 'payment-siit',
    PAYMENT_CREDIT = 'payment-credit',
    PAYMENT_PRE_PAYMENT = 'payment-pre-payment',
    TICKET = 'load/ticket',
    SHIFTLESS = 'shiftless',
    DOCUMENTS_HISTORY = 'shift/documents',
    FINES = 'fines',
    FINE_REGISTER = 'fines/register',
    NON_TICKETING_PRODUCTS = 'non-ticking-products/categories',
    NON_TICKETING_PRODUCTS_SELECT = 'non-ticking-products/select',
    NON_TICKETING_PRODUCTS_DETAIL = 'non-ticking-products/detail',
    NON_TICKETING_PRODUCTS_HISTORY = 'non-ticking-products/history'
}

@Injectable({
    providedIn: 'root'
})
export default class NavigationManagerService {

    constructor(private router: Router) {
    }

    private static getFullRoutePath(route: TicketingRoutes) {
        return route.toString();
    }

    public getRouter(): Router {
        return this.router;
    }

    public getNavigationExtras<T>(): T {
        return this.router.getCurrentNavigation()?.extras.state as T;
    }

    public getNavigation(): Navigation | null {
        return this.router.getCurrentNavigation();
    }

    public hasNavigationMessage(): boolean {
        return this.getNavigation() != null &&
            this.getNavigation().extras.state != null &&
            this.getNavigation().extras.state.message != null;
    }

    private hasNavigationMessageType(): boolean {
        return this.getNavigation() != null &&
            this.getNavigation().extras.state != null &&
            this.getNavigation().extras.state.messageType != null;
    }

    public getNavigationMessageType(): string {
        if (this.hasNavigationMessageType()) {
            return this.getNavigation().extras.state.messageType;
        }
    }

    public getNavigationMessage(): string {
        if (this.hasNavigationMessage()) {
            return this.getNavigation().extras.state.message;
        }
    }

    public async go(route: TicketingRoutes, parameters: object = null) {
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true,
            state: parameters,
        };

        const urlRoute = NavigationManagerService.getFullRoutePath(route);

        return await this.router.navigate([urlRoute], navigationExtras);
    }
}
