export enum LoadTitleTransactionType {
  TRANSACTION_VEND = 1,
  TRANSACTION_CARR = 2,
  TRANSACTION_VEND_CARR = 3,
  TRANSACTION_ANUL_VEND = 4,
  TRANSACTION_ANUL_CARR = 5,
  TRANSACTION_ANUL_VEND_CARR = 6,
  TRANSACTION_TRANSF = 11,
  TRANSACTION_UNKNOWN = 25,
}
