<div >
    
        <div style="width:800px; margin:0 auto;"  nz-row>
          <div  nz-col [nzSpan]="24">
          <h3>Efectuado com sucesso</h3>
        </div>
        <div  nz-col [nzSpan]="24">
        <app-change-money [valueText]= "1000">

        </app-change-money>
      </div>
      </div>
  
    </div>
    