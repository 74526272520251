import { FineRegisterRequest } from './../../../../shared/models/fine';
import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { Fine, FineTable } from '../../../../shared/models/fine';
import { TableHeaderDataCheckbox } from '../../../../shared/models/table-header-data-checkbox';
import { TableHeaderData } from '../../../../shared/models/table-header-data';
import { TableHeaderDataType } from '../../../../shared/models/table-header-data-config';
import { TableColumn } from '../../../../shared/models/table-column';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { FineService } from 'src/app/core/services/fines.service';
import { TableHeaderDataButton } from '../../../../shared/models/table-header-data-button';
import { ShoppingCartItemRequest } from '../../../../shared/models/shopping-cart-item-request';
import { LoadTitleTransactionType } from '../../../../shared/enum/load-title-transaction-type.enum';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { ShoppingCartService } from '../../../../core/services/shopping-cart.service';
import { VatValidationRequest } from '../../../../shared/models/vat-validation-request';
import { SaleService } from '../../../../core/services/sale.service';
import { DatePipe } from '@angular/common';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { FineFiltersMaxLengthEnum } from 'src/app/shared/enum/fine.enum';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Component({
    selector: 'fines-page',
    templateUrl: './fines.component.html',
    styleUrls: ['./fines.component.less']
})
export class FinesComponent implements OnInit {

    private defaultPageNumber = 1;

    // Table variables
    public listColumn: Array<TableColumn> = [];
    public listHeader: Array<TableHeaderData> = [];
    public listFineData: Array<FineTable> = [];
    public listTotal = 0;
    public selectedIds: Array<number> = [];
    public defaultPageSize = 5;

    public fineRegisterRequests: Array<FineRegisterRequest> = [];

    // Search filter variables
    public finedPersonFiscalNumber: string;
    public finedPersonName: string;
    public noticeNumber: number;
    public fineDate: Date;
    public isVatInternational: boolean;

    public noticeNumberMaxLength = FineFiltersMaxLengthEnum.NOTICE_NUMBER_MAX_LENGTH;
    public nameMaxLength = FineFiltersMaxLengthEnum.NAME_MAX_LENGTH;
    public fiscalNumberMaxLength = FineFiltersMaxLengthEnum.FISCAL_NUMBER_MAX_LENGTH;

    public isNextDisabled = true;
    public canConsultFines = false;
    public canPreRegisterFines = false;

    // Modal handling
    public isFineModalActive = false;
    public modalFine: FineTable = null;

    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private fineService: FineService,
        private shoppingService: ShoppingCartService,
        private storageManagerService: StorageManagerService,
        private saleService: SaleService,
        private datepipe: DatePipe,
        private authorizationService: AuthorizationService
    ) {
        this.prepareTableResult();
    }

    async ngOnInit(): Promise<void> {
        this.canConsultFines = this.authorizationService.hasPermission(AuthorityName.FINE_READ_PAYMENT);
        this.canPreRegisterFines = this.authorizationService.hasPermission(AuthorityName.FINE_PRE_REGISTER);
        if (this.canConsultFines) {
            await this.getFines();
        }
    }

    private prepareTableResult(): void {
        this.listColumn = [
            { title: null, config: { colAlign: 'center', colWidth: '5%' } },
            { title: 'Data', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Nº Auto', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Valor', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'Estado', config: { colAlign: 'center', colWidth: '15%' } },
            { title: 'A pagamento', config: { colAlign: 'center', colWidth: '10%' } },
            { title: 'Notas', config: { colAlign: 'center', colWidth: '25%' } },
        ];
        this.listHeader = [
            {
                visible: true,
                checkbox: {
                    isDisabled: 'isCheckboxDisabled',
                    isChecked: 'checked'
                } as TableHeaderDataCheckbox
            } as TableHeaderData,
            {
                visible: false,
                name: 'id',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'date',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'noticeNumber',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'fineValue',
                config: { valAlign: 'center', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'fineStatus',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'canPay',
                config: { valAlign: 'center', type: TableHeaderDataType.BOOLEAN },
                button: null,
                booleanTrueValueLabel: 'Sim',
                booleanFalseValueLabel: 'Não'
            } as TableHeaderData,
            {
                visible: true,
                name: 'hasNotes',
                config: { valAlign: 'center' },
                button: {
                    btnAction: 'fineNotes',
                    iconType: 'icon-comprovativo',
                } as TableHeaderDataButton,
            } as TableHeaderData
        ];
    }

    private async getFineIdsInOpenShoppingCartsAndDisableCheckbox(): Promise<void> {
        this.spinService.setSpinValue(true);
        try {
            let fineIdList = [];
            const response = await this.shoppingService.findFineIdsInOpenCarts();
            if (response.success && response?.data != null && response?.data.length > 0) {
                fineIdList = response.data;
                if (fineIdList.length > 0) {
                    const fineResponse = await this.fineService.findFinesByIds(fineIdList);
                    if (fineResponse.success && fineResponse?.data != null) {
                        Object.keys(fineResponse.data).forEach(key => {
                            const fineInCart = this.listFineData.find(fine => fine.noticeNumber ===
                                fineResponse.data[key].noticeNumber);
                            if (fineInCart != null) {
                                fineInCart.isCheckboxDisabled = true;
                                fineInCart.checked = true;
                            }
                        });
                    }
                }
            }
            this.spinService.setSpinValue(false);
        } catch (error) {
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    public async searchFines() {
        if (!this.isVatInternational && this.finedPersonFiscalNumber) {
            const isVatValid = await this.checkIfVatValid(this.finedPersonFiscalNumber);
            if (!isVatValid) {
                this.alertService.error(CommonEnum.msgInvalidNifError);
                return;
            }
        }
        await this.getFines();
    }

    public async getFines(pageNumber: number = this.defaultPageNumber): Promise<void> {
        this.spinService.setSpinValue(true);
        const request = {
            date: this.fineDate != null ? this.datepipe.transform(this.fineDate, 'yyyy-MM-dd') : null,
            fiscalNumber: this.finedPersonFiscalNumber,
            finedPersonName: this.finedPersonName,
            noticeNumber: this.noticeNumber,
            pageElements: this.defaultPageSize,
            page: pageNumber - 1,
            orderAsc: false
        };

        try {
            const response = await this.fineService.getFinesByFilter(request).toPromise();
            if (response?.data != null) {
                this.listFineData = response.data?.content;
                this.listTotal = response.data.totalElements;
                Object.keys(this.listFineData).forEach(key => {
                    this.listFineData[key].fineValue = this.listFineData[key].fineValue * 100;
                    if (this.selectedIds.includes(this.listFineData[key].id)) {
                        this.listFineData[key].checked = true;
                    }
                });
                await this.getFineIdsInOpenShoppingCartsAndDisableCheckbox();
            }
            if (response.data.empty) {
                this.alertService.error(CommonEnum.msgFinesNotFound);
            }
            this.spinService.setSpinValue(false);
        } catch (error) {
            console.log('Error: ', error);
            this.listFineData = [] as Array<FineTable>;
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    public checkIfVatValid(vat: string) {
        const vatNumberSize = 9;
        const vatLength = vat.toString().length;

        if (vatLength === vatNumberSize) {
            const request: VatValidationRequest = {
                vat
            };
            return this.saleService.isVatValid(request);
        }

        return vatLength === 0;
    }

    public onChangedDate(value: Date): void {
        this.fineDate = value == null ? null : DateUtils.setTimeTo0(value);
    }

    public async onFinedPersonFiscalNumberChange(value: string) {
        this.finedPersonFiscalNumber = value;
    }

    public onNoticeNumberChange(value: number): void {
        this.noticeNumber = value;
    }

    public onFinedPersonNameChange(value: string): void {
        this.finedPersonName = value;
    }

    public clearSearch(): void {
        this.finedPersonFiscalNumber = null;
        this.noticeNumber = null;
        this.finedPersonName = null;
        this.fineDate = null;
    }

    public checkBoxEvent(event: any): void {
        if (event) {
            if (!event.value && this.selectedIds.includes(event.id)) {
                // removing id from the list
                this.selectedIds = this.selectedIds.filter(it => it !== event.id);
                const fineToAddToCart = this.listFineData.find(fineItem => fineItem.id === event.id);
                this.fineRegisterRequests = this.fineRegisterRequests.filter(it =>
                    it.noticeNumber !== fineToAddToCart.noticeNumber);
            } else {
                const fine = this.listFineData.find(item => item.id === event.id);
                if (fine.canPay && fine.titleId != null) {
                    this.selectedIds.push(event.id);
                    const fineDateForPay = new Date(fine?.dateForPayFineRequest);
                    const fineRegisterRequest = {
                        noticeNumber: fine?.noticeNumber,
                        paidValue: fine?.fineValue,
                        preRegister: false,
                        finedPersonFiscalNumber: fine?.finedPersonFiscalNumber,
                        finedPersonName: fine?.finedPersonName,
                        date: fineDateForPay
                    } as FineRegisterRequest;
                    this.fineRegisterRequests.push(fineRegisterRequest);
                } else {
                    this.alertService.info('Esta multa não pode ser paga');
                }
            }
        }
        this.checkNextDisableState();
    }

    public openFineNoteModal(event: any): void {
        this.modalFine = event.data as FineTable;
        if (this.modalFine.canPayReason == null) {
            this.modalFine.canPayReason = '-';
        }
        this.isFineModalActive = true;
    }

    public handleCloseModal(): void {
        this.isFineModalActive = false;
    }

    public previousView(): void {
        this.navigationManager.go(TicketingRoutes.HOME);
    }

    public registerFineView(): void {
        this.navigationManager.go(TicketingRoutes.FINE_REGISTER);
    }

    public checkNextDisableState(): void {
        this.isNextDisabled = this.selectedIds.length === 0;
    }

    public async addToCart(): Promise<void> {
        this.spinService.setSpinValue(true);
        try {
            const savedFines = await this.fineService.saveMultipleFines(this.fineRegisterRequests);
            if (savedFines && savedFines.success) {
                const cartItemsRequests: Array<ShoppingCartItemRequest> = [];
                this.buildShoppingCartItemRequest(savedFines.data, cartItemsRequests);
                this.shoppingService.insertMultiple(cartItemsRequests, Number(this.storageManagerService.session.get(CommonEnum.shiftID)))
                    .then(() => {
                        this.spinService.setSpinValue(false);
                        this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                    });
            }
        } catch (error) {
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    private buildShoppingCartItemRequest(fineList: Array<Fine>, cartItemsRequests: Array<ShoppingCartItemRequest>): void {
        fineList.forEach(savedFine => {
            const cartItemRequest = {
                shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
                titleId: savedFine?.titleId,
                description: `Auto ${savedFine.noticeNumber}€`,
                quantity: 1,
                price: savedFine?.fineValue,
                transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
                fineId: savedFine.id,
                annulId: null,
                cardNumber: null,
                loadId: null,
                requisitionId: null,
                authId: null,
                replacementId: null,
                voucherId: null,
                cardSerialNumber: null,
                isAnnul: false
            } as ShoppingCartItemRequest;
            cartItemsRequests.push(cartItemRequest);
        });
    }
}
