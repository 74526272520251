import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { AlertService } from 'src/app/core/services/alert.service';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { ShiftService } from 'src/app/core/services/shift.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { ZappingValue } from './model/zapping-value';
import { ZappingTableElement } from './model/zapping-table-element';
import { TariffService } from 'src/app/core/services/tariff.service';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';

@Component({
    selector: 'app-zapping',
    templateUrl: './zapping.component.html',
    styleUrls: ['./zapping.component.less']
})
export class ZappingComponent implements OnInit {

    private static ZAPPING_DEFAULT_TITLE_ID: number = 3201;
    public values: ZappingValue[];
    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    public listOfData: ZappingTableElement[] = [];

    private valueSelected: ZappingValue = { value: 0 } as ZappingValue;
    private title: any;
    private readonly serialNumber: string;
    private peripheralId: string;

    constructor(
        private location: Location,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private loadTitleService: LoadTitleService,
        private tariffService: TariffService
    ) {
        const navigationExtras = this.navigationManager.getNavigation()?.extras?.state;
        if (navigationExtras?.title && navigationExtras?.serialNumber) {
            this.title = navigationExtras.title;
            this.serialNumber = navigationExtras.serialNumber;
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }

    public async ngOnInit() {
        await this.getZappingLoadValues();
        this.peripheralId = await this.peripheralService.getPeripheralId();
        this.prepareTable();
    }

    private prepareTable() {
        this.listTitleColumn = [
            { title: 'TÍTULO', config: { colAlign: 'left', colWidth: '50%' } },
            { title: 'VALOR', config: { colAlign: 'center', colWidth: '50%' } },
        ];
        this.listTitleHeader = [
            { visible: true, name: 'title', config: null, button: null } as TableHeaderData,
            { visible: true, name: 'value', config: { valAlign: 'center', money: true }, button: null } as TableHeaderData,
        ];
        this.listOfData = [
            { title: 'Zapping', value: String(this.valueSelected.value) }
        ];
    }

    private refresh() {
        this.listTitleHeader = null;
        this.listTitleColumn = null;
        this.listOfData = null;
        this.prepareTable();
    }

    public changeValue(id: number) {
        this.valueSelected = this.values.find((el) => el.id === id);
        this.refresh();
    }

    public async previous() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
    }

    public async home() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public async load() {
        if (this.valueSelected.value <= 0) {
            this.alertService.info(CommonEnum.msgValueZappingNotSelected);
            return;
        }
        const titleResponse = await this.tariffService.fetchTitleById(this.valueSelected.id).toPromise();
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.valueSelected.id,
            description: titleResponse.data.description,
            quantity: 1,
            price: this.valueSelected.value,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
            // Check annulId
            annulId: null,
            cardNumber: String(cardFullDetails.details.environment.issuing_number),
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardSerialNumber: cardFullDetails.low_part,
            isAnnul: true
        };
        const loadTitleRequest: LoadTitleRequest = {
            peripheralId: this.peripheralId,
            serialNumber: this.serialNumber,
            tickCode: this.title.tickCode,
            tickOperCode: this.title.tickOperCode,
            id: ZappingComponent.ZAPPING_DEFAULT_TITLE_ID,
            voucherNumber: '',
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
            ticketQuantity: this.valueSelected.value
        } as LoadTitleRequest;
        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadContract);
        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, { shoppingCartRequest });
    }

    private async getZappingLoadValues() {
        const zappingLoadValuesResponse = await this.loadTitleService.getZappingLoadValues(this.title.titleBalance).toPromise();
        if (zappingLoadValuesResponse?.success) {
            const data = zappingLoadValuesResponse.data;
            const values = [];
            data.forEach(el => {
                values.push({
                    id: el.titleId,
                    value: el.titleValue,
                    label: el.loadableLabel,
                    disabled: !el.loadable
                } as ZappingValue);
            });
            this.values = values;
        } else {
            await this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgReturnZappingValuesError);
        }
    }

}
