import { ComposedTitles } from './../../../../../shared/enum/composed-titles.enum';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { Subscription } from 'rxjs';
import { SupportDetails } from 'src/app/shared/enum/support-details.enum';
import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { CardRead, LastEventMessage } from 'src/app/shared/models/card-read';
import { SupportDetailService } from 'src/app/core/services/support-detail.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Load } from '../models/load';
import { CardReadTitle, CardReadTitleTitle } from 'src/app/shared/models/card-read-title';
import { CardDataModel, CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { AuthorityName } from 'src/app/shared/models/authority-name';
import { SupportDetailActionEnum } from 'src/app/shared/enum/support-detail-action.enum';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { RecoverReplacementGuideService } from 'src/app/core/services/recover-replacement-guide.service';
import { BlackListService } from 'src/app/core/services/black-list.service';
import { VVState } from 'src/app/shared/enum/vvstate.enum';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { DatePipe } from '@angular/common';
import { SpinService } from '../../../../../core/services/spin.service';
import { CardEnum } from '../../../../../shared/enum/card.enum';
import { CardUtils } from '../../../../../shared/utils/card-utils';
import { TitleUtils } from '../../../../../shared/utils/title-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { TitleGroupEnum } from '../../../../../shared/enum/title-group.enum';
import { TariffService } from 'src/app/core/services/tariff.service';
import { TickCode } from 'src/app/shared/enum/tick-code.enum';
import { TickOperCode } from 'src/app/shared/enum/tick-oper-code.enum';
import { TitleFilter } from '../title-selection/model/TitleFilter';
import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { FreeTitleEnum } from 'src/app/shared/enum/free-title.enum';

@Component({
    selector: 'app-support-detail',
    templateUrl: './support-detail.component.html',
    styleUrls: ['./support-detail.component.less'],
})
export class SupportDetailComponent implements OnInit {

    private THIRD_AGE_PROFILE = 3;
    private SUBSTITUTION_GUIDE_DATA_MODEL = 3;
    public NORMAL_VV = 'VIVA Viagem/navegante ocasional Nº';
    public REPLACEMENT_GUIDE_VV = 'VIVA Viagem/navegante ocasional Guia de Substituição Nº';

    subscription2$: Subscription;
    subscriptions: Subscription[] = [];
    listFrequentLoad = [];
    listNewLoad = [];
    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    card: CardDetailsMessage;
    cardRead: CardRead = { titles: [] } as CardRead;
    public lastEventMessage: LastEventMessage;
    public composedTitleList = [];

    public stateVV: string;
    public isVVInit = false;
    public isVV: boolean;
    pageSize = 100;
    getCurrentCardDetails: any;
    public profileInfo = '';

    public isReplacementGuide = false;
    public replacementGuideHasTitles = false;
    public isSerialNumberOnBlackList = false;
    public isLVCardUnassigned = false;
    public isShoppingCartFull = false;
    public isModalVisible = false;

    private noBreakSpace = '\xa0';
    private readonly LoadAuthorizationButtonId = -3;

    public readableDetails: any;

    constructor(
        private recoverReplacementGuideService: RecoverReplacementGuideService,
        private service: SupportDetailService,
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private blackListService: BlackListService,
        private storageManager: StorageManagerService,
        private peripheralService: PeripheralService,
        private spinService: SpinService,
        private datePipe: DatePipe,
        private tariffService: TariffService,
        private loadTitleService: LoadTitleService
    ) {

    }

    async ngOnInit() {
        this.spinService.setSpinValue(true);
        this.storageManager.session.set(CommonEnum.loadingActive, 'true');
        this.isShoppingCartFull = this.storageManager.session.get(CommonEnum.shoppingCartFull) as boolean;
        const navExtras = history.state;
        try {
            if (navExtras !== undefined && navExtras.card !== undefined) {
                this.readableDetails = navExtras.readableDetails;
                if (this.readableDetails != undefined) {
                    this.storageManager.session.set(CardEnum.LAST_EVENT_MESSAGE, this.readableDetails.event);
                }
                this.lastEventMessage = this.storageManager.session.get(CardEnum.LAST_EVENT_MESSAGE) as LastEventMessage;
                await this.loadView(navExtras.card as CardDetailsMessage);
            } else {
                this.alertService.error(CommonEnum.msgCardReadError);
                setTimeout(() => history.back(), 2500);
            }

            await this.setIsLVCardUnassigned();
            await this.isCardReplaceGuide();
            await this.setIsSerialNumberOnBlackList();
            await this.peripheralService.getPeripheralId();

        } catch (e) {
            console.log('Support Details OnInit Error: ', e);
        } finally {
            this.spinService.setSpinValue(false);
            this.storageManager.session.set(CommonEnum.loadingActive, 'false');
        }
    }

    private async loadView(card: CardDetailsMessage) {

        const navExtras = this.navigationManager.getNavigationExtras<any>();

        if (navExtras?.load?.cardRead) {
            this.cardRead = navExtras.load.cardRead;
        }

        this.card = card;

        this.initServiceNotify();

        switch (this.card.details.environment.card_data_model) {
            case CardDataModel.K_CARD_DATAMODEL_UNKNOWN:
                this.stateVV = VVState.NOT_INITIALIZED;
                this.isVV = true;
                this.isVVInit = false;
                break;
            case CardDataModel.K_CARD_DATAMODEL_SETE_COLINAS_V_0:
            case CardDataModel.K_CARD_DATAMODEL_SUBSTITUTION_GUIDE_V_0:
                this.stateVV = VVState.INITIALIZED;
                this.isVV = true;
                this.isVVInit = true;
                break;
            default:
                break;
        }

        await this.loadCard(this.card);
        await this.loadHeaderDetails();
        await this.getNewLoad();
        await this.prepareTableResult();
        this.isFamilyPassButtonEnable();
    }

    prepareTableResult() {
        this.listTitleColumn = [
            { title: 'TÍTULO', config: { colAlign: 'left', colWidth: '40%' } },
            { title: 'VALIDADE', config: { colAlign: 'left', colWidth: '30%' } },
            { title: 'SALDO/QUANTIDADE', config: { colAlign: 'left', colWidth: '40%' } },
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
        ];

        this.listTitleHeader = [
            { visible: false, name: 'id', config: null, button: null } as TableHeaderData,
            { visible: true, name: 'description', config: null, button: null } as TableHeaderData,
            {
                visible: true,
                name: 'expiringDateFinish',
                config: { type: TableHeaderDataType.DATE },
                button: null
            } as TableHeaderData,
            { visible: true, name: 'expiringDateBalance', config: null, button: null } as TableHeaderData,
            {
                visible: true, name: null, config: { valAlign: 'right', money: false },
                button: {
                    iconType: 'trash-icon icon-apagar',
                    btnAction: 'Anular',
                    buttonCSS: 'trash-button',
                    role: AuthorityName.ANNUL_SALE,
                },
            } as TableHeaderData,
            {
                visible: true, name: null, config: { valAlign: 'left', money: false },
                button: {
                    iconType: 'change-icon icon-alterar',
                    btnAction: 'Trocar',
                    buttonCSS: 'change-button',
                    role: AuthorityName.TICKETS_SELL,
                },
            } as TableHeaderData,
            {
                visible: true, name: null, config: { valAlign: 'left', money: false },
                button: {
                    titleButton: 'Vender',
                    btnAction: 'Vender',
                    iconType: 'sell-icon icon-recarregar',
                    buttonCSS: 'sell-button',
                    textCSS: 'sell-button-text',
                    buttonText: 'Vender',
                    role: AuthorityName.TICKETS_SELL,
                }
            } as TableHeaderData,
            {
                visible: true, name: null, config: { valAlign: 'left', money: false },
                button: {
                    titleButton: 'Transferir',
                    btnAction: 'Transferir',
                    buttonCSS: 'sell-button',
                    textCSS: 'sell-button-text',
                    buttonText: 'Transferir',
                    role: AuthorityName.BALANCE_TRANSFER,
                }
            } as TableHeaderData,
        ];
    }

    private async loadCard(data: CardDetailsMessage): Promise<any> {
        await this.service.loadCard(data);
    }
    
    private async isFamilyPassButtonEnable(): Promise<any> {
        this.getCurrentCardDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS);

        if (CardUtils.IsLV(this.getCurrentCardDetails.details.environment.card_data_model)) {

            const familyPassCheckingNotification = this.alertService.info(CommonEnum.msgFamilyPassChecking, false, true, 0);
            familyPassCheckingNotification.messageId = CommonEnum.msgFamilyPassChecking;
    
            const res: any = await this.service.getFamilyPassLoadList();
            if (res && res.success && res.data) {
                const familyPassNewLoad = res.data;
                
                this.alertService.removeNotification(familyPassCheckingNotification.messageId);
                if (!familyPassNewLoad.isTitleLoadButtonDisabled) {
                    this.alertService.success(CommonEnum.msgFamilyPassValid, false, true, 0);
                } else {
                    this.alertService.error(CommonEnum.msgFamilyPassInvalid, false, true, 0);
                }
                const selectedTitle = this.listNewLoad.find(newLoadButton =>
                    SupportDetails.FAMILY_TICKET === newLoadButton.name);
                if (selectedTitle != null) {
                    const isFamilyPassButtonDisabled = this.isShoppingCartFull ?
                        true :
                        familyPassNewLoad.isTitleLoadButtonDisabled;
                        
                    selectedTitle.disabled = isFamilyPassButtonDisabled;
                    selectedTitle.hasFamilyTicketLoaded = familyPassNewLoad.hasFamilyTicketLoaded;
                }
            } else {
                this.alertService.removeNotification(familyPassCheckingNotification.messageId);
            }
        }
    }

    public isLastLoadEventVisible(): boolean {
        const getLastEventData = this.storageManager.session.get(CardEnum.LAST_EVENT_MESSAGE) as LastEventMessage;
        return getLastEventData?.titleDescription != null &&
            getLastEventData?.eventDescription == null &&
            getLastEventData?.dateTime != null &&
            getLastEventData?.operator != null;
    }

    public isLastValidationEventVisible(): boolean {

        const getLastEventData = this.storageManager.session.get(CardEnum.LAST_EVENT_MESSAGE) as LastEventMessage;
        if (!getLastEventData?.titleDescription ||
            getLastEventData?.titleDescription == null) {
            return getLastEventData?.eventDescription != null &&
                getLastEventData?.dateTime != null &&
                getLastEventData?.operator != null;
        }

        return getLastEventData?.eventDescription != null &&
            getLastEventData?.titleDescription != null &&
            getLastEventData?.dateTime != null &&
            getLastEventData?.operator != null;

    }

    async onButtonClickTable(event: any) {
        if (event.btnCaption === SupportDetailActionEnum.cancel) {
            await this.annulTitle(event.value);
        } else if (event.btnCaption === SupportDetailActionEnum.exchange) {
            await this.exchangeTitle(event.value);
        } else if (event.btnCaption === SupportDetailActionEnum.transfer) {
            await this.transfer(event.data);
        } else if (event.btnCaption === SupportDetailActionEnum.load) {
            if (event.data.titleGroup === TitleGroupEnum.TICKETS) {
                await this.redirectToTicket(this.listNewLoad, event.value);
            } else {
                const balance = this.convertStringMoneyToNumber(event.data.expiringDateBalance);
                await this.sellTitle(event.value, balance);
            }
        }
    }

    async transfer(readableTitle: CardReadTitle) {
        const load: Load = {
            cardRead: this.cardRead,
            tickOperCode: readableTitle.tickOperCode,
            tickCode: readableTitle.tickCode,
            titleId: readableTitle.id,
            titleDescription: readableTitle.description,
            titleGroup: readableTitle.titleGroup
        } as Load;

        const transferAmountSingleTicket: string = CardUtils.IsZapping(load.tickCode, load.tickOperCode, load.titleId)
            ? TitleUtils.getFormattedMoney(readableTitle?.price.toString())
            : readableTitle?.price.toString();

        let loadAmount = readableTitle.loadAmount;
        if (readableTitle.isSlidingTitle || readableTitle.isFixedMonth) {
            const contractInfo = this.card.details.contracts.find(contract => contract.ticket_code === readableTitle.tickCode
                && contract.operator_code === readableTitle.tickOperCode);
            loadAmount = contractInfo.temporal_validity.value;
        }

        const transferTitle: CardReadTitle = {
            id: readableTitle.id,
            tickOperCode: readableTitle.tickOperCode,
            tickCode: readableTitle.tickCode,
            titleGroup: readableTitle.titleGroup,
            expiringDateStart: null,
            expiringDateFinish: null,
            description: readableTitle.description,
            price: 0,
            // ExpiringDateBalance will show in titleDetails the amount in transfer scenario
            expiringDateBalance: readableTitle.isSingleTicket ? transferAmountSingleTicket : null,
            isSingleTicket: readableTitle.isSingleTicket,
            isFixedMonth: readableTitle.isFixedMonth,
            isSlidingTitle: readableTitle.isSlidingTitle,
            loadAmount
        } as CardReadTitle;

        await this.navigationManager.go(TicketingRoutes.TITLE_DETAILS, {
            load,
            transferTitle
        });
    }

    async exchangeTitle(titleId: number) {
        const load: Load = {
            cardRead: this.cardRead,
            titleId: 0,
        } as Load;

        await this.navigationManager.go(TicketingRoutes.CHANGE_TITLE, {

            titleToExchange: this.cardRead.titles.find((i) => i.id === titleId),
            load,
        });
    }

    async sellTitle(titleId: number, balance: number) {
        const load: Load = {
            cardRead: this.cardRead,
            tickOperCode: this.cardRead.titles.find((i) => i.id === titleId)
                .tickOperCode,
            tickCode: this.cardRead.titles.find((i) => i.id === titleId).tickCode,
            titleId: this.cardRead.titles.find((i) => i.id === titleId).id,
            titleDescription: this.cardRead.titles.find((i) => i.id === titleId)
                .description,
            titleGroup: this.cardRead.titles.find((i) => i.id === titleId).titleGroup,
            expiringDateStart: '01/01/2020',
            expiringDateFinish: '01/08/2020',
        } as Load;
        if (SupportDetailComponent.isZapping(load)) {
            await this.redirectToZapping({
                id: load.titleId,
                name: load.titleDescription,
                tickCode: load.tickCode,
                tickOperCode: load.tickOperCode,
                titleBalance: balance,
            });
            return;
        }

        const selectedTitleToSell = this.cardRead.titles.find(
            (i) => i.id === titleId) as CardReadTitle;

        if (selectedTitleToSell?.discount && selectedTitleToSell?.discount > 0) {
            const getTitleInfo = await this.tariffService.fetchTitleById(selectedTitleToSell.id).toPromise();
            const discountPrice = getTitleInfo.data.price?.value * (selectedTitleToSell.discount / 100.0);
            selectedTitleToSell.priceWithDiscount = getTitleInfo.data.price?.value - discountPrice;
            selectedTitleToSell.price = getTitleInfo.data.price?.value;
        }

        // check for composed title
        if (TickCode.CAML_URB_ESTAC_TICK_CODE === selectedTitleToSell.tickCode &&
            TickOperCode.TICKET_TICK_OPER_CODE === selectedTitleToSell.tickOperCode) {
            const titleFilter: TitleFilter = {
                titleIds: [ComposedTitles.CAML_URB_ESTAC__EMEL_ID, ComposedTitles.CAML_URB_ESTAC__EMPARQUE_ID],
                pageSize: 100
            };
            try {
                const response = await this.loadTitleService.getTitleListOfCardProfile(titleFilter).toPromise();
                if (response.success && response?.data != null) {
                    response.data.content.forEach(title => {
                        this.composedTitleList.push({
                            id: title.id,
                            name: title.description,
                            role: AuthorityName.TICKETS_SELL,
                            description: title.description,
                            moloniId: title.moloniId,
                            price: title.price,
                            tariffType: title.tariffType,
                            tickCode: title.tickCode,
                            tickOperCode: title.tickOperCode,
                            titleGroupId: title.titleGroupId,
                            titles: title.titles,
                            disabled: title.isTitleLoadButtonDisabled,
                            discount: title.discount,
                            discountValue: title.discountValue,
                        });
                    });
                }
                if (response.data.empty) {
                    this.alertService.error(CommonEnum.msgComposedTitlesNotFound);
                }
                this.spinService.setSpinValue(false);
                this.isModalVisible = true;
            } catch (error) {
                this.spinService.setSpinValue(false);
                this.alertService.error(error.error.message);
            }
            return;
        }

        await this.navigationManager.go(TicketingRoutes.TITLE_DETAILS, {
            load,
            newTitle: selectedTitleToSell as CardReadTitle,
        });
    }

    async annulTitle(titleId: number) {

        const load: Load = {
            cardRead: this.cardRead,
            tickOperCode: this.cardRead.titles.find((i) => i.id === titleId).tickOperCode,
            tickCode: this.cardRead.titles.find((i) => i.id === titleId).tickCode,
            titleId: this.cardRead.titles.find((i) => i.id === titleId).id,
            titleDescription: this.cardRead.titles.find((i) => i.id === titleId)
                .description,
            titleGroup: this.cardRead.titles.find((i) => i.id === titleId).titleGroup
        } as Load;

        await this.navigationManager.go(TicketingRoutes.ANNUL_TITLE, {
            load,
            title: this.cardRead.titles.find(
                (i) => i.id === titleId
            ) as CardReadTitle,
        });
    }

    async getNewLoad(): Promise<void> {
        this.getCurrentCardDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS);
        const res: any = await this.service.getListNewLoad();
        if (res && res.success && res.data) {
            this.listNewLoad = this.listNewLoad.concat(res.data.slice(0, 10).map((it) => {
                return {
                    id: it.id,
                    name: it.description,
                    price: it.price,
                    tickCode: it.tickCode,
                    tickOperCode: it.tickOperCode,
                    role: it.role,
                    titleGroup: it.titleGroupId,
                    hasFamilyTicketLoaded: it.hasFamilyTicketLoaded,
                    disabled: this.isShoppingCartFull ? true : it.isTitleLoadButtonDisabled,
                    discount: it.discount,
                    discountValue: it.discountValue,
                    titles: it.titles
                };
            }
            ));
        }

        if (this.listNewLoad.some(button => button.id == this.LoadAuthorizationButtonId)) {
            this.alertService.info(`Existem autorizações de carregamento para o cartão: ${this.cardRead.serialNumber}`);
        }
    }

    async redirectToZapping(selectedTitle: any) {
        await this.navigationManager.go(TicketingRoutes.ZAPPING, {
            title: selectedTitle,
            balance: selectedTitle.expiringDateBalance,
            serialNumber: this.cardRead.serialNumber
        });
    }

    async redirectToTicket(listTitles: any[], titleId: any) {
        const dateStart = new Date();
        const dateFinish = new Date(
            dateStart.getFullYear(),
            dateStart.getMonth() === 12 ? 1 : dateStart.getMonth() + 1,
            dateStart.getDay()
        );

        const load: Load = {
            cardRead: this.cardRead,
            tickCode: listTitles.find((i) => i.id === titleId).tickCode,
            tickOperCode: listTitles.find((i) => i.id === titleId).tickOperCode,
            titleId,
            expiringDateStart: dateStart.toDateString(),
            expiringDateFinish: dateFinish.toDateString(),
            titleDescription: listTitles.find((i) => i.id === titleId).description,
            total: listTitles.find((i) => i.id === titleId).price,
            titleGroup: listTitles.find((i) => i.id === titleId).titleGroup,
        } as Load;

        const newTitle: CardReadTitle = {
            id: titleId,
            expiringDateStart: dateStart,
            expiringDateFinish: dateFinish,
            description: listTitles.find((i) => i.id === titleId).name,
            titleGroup: listTitles.find((i) => i.id === titleId).titleGroup,
            price: listTitles.find((i) => i.id === titleId).price,
        } as CardReadTitle;

        await this.navigationManager.go(TicketingRoutes.TICKET, {
            load,
            newTitle,
        });
    }

    async newLoadSelection(event: { value: any }) {

        const selectedTitle = this.listNewLoad.find(i => i.id === event.value);

        if (selectedTitle === undefined || selectedTitle === null) {
            this.alertService.error(CommonEnum.msgSelectedTitleNotFoundError);
            return;
        }

        const load: Load = {
            cardRead: this.cardRead,
            titleId: 0,
        } as Load;

        if (selectedTitle.titleGroup === TitleGroupEnum.TICKETS) {
            await this.redirectToTicket(this.listNewLoad, event.value);
            return;
        }

        switch (selectedTitle.name) {
            case SupportDetails.ZAPPING:
                if (this.isZappingOnCardTitles(selectedTitle)) {
                    this.setZappingBalanceFromCardRead(selectedTitle);
                }
                await this.redirectToZapping(selectedTitle);
                break;
            case SupportDetails.FAMILY_TICKET:
                await this.navigationManager.go(TicketingRoutes.FAMILY_PASS,
                    { load, card: this.card, hasFamilyTicketLoaded: selectedTitle?.hasFamilyTicketLoaded });
                break;
            case SupportDetails.LOAD_AUTHORIZATION:
                await this.navigationManager.go(TicketingRoutes.AUTHORIZATION_CHARGE, { load });
                break;
            case SupportDetails.OTHERS:
                await this.navigationManager.go(TicketingRoutes.TITLE_SELECTION, { load });
                break;
            default:
                await this.selectTitle(this.listNewLoad, event.value);
                break;
        }

    }

    async frequentLoadSelection(event: { value: any }) {
        await this.selectTitle(this.listFrequentLoad, event.value);
    }

    async otherTitlesSelections() {
        const load: Load = {
            cardRead: this.cardRead,
            titleId: 0,
        } as Load;

        await this.navigationManager.go(TicketingRoutes.VOUCHER_SELECTION, { load });
    }

    async selectTitle(listTitles: any[], titleId: any) {
        const dateStart = new Date();
        const dateFinish = new Date(
            dateStart.getFullYear(),
            dateStart.getMonth() === 12 ? 1 : dateStart.getMonth() + 1,
            dateStart.getDay()
        );

        const titleSelected = listTitles.find((i) => i.id === titleId);

        const load: Load = {
            cardRead: this.cardRead,
            tickCode: titleSelected.tickCode,
            tickOperCode: titleSelected.tickOperCode,
            titleId,
            expiringDateStart: dateStart.toDateString(),
            expiringDateFinish: dateFinish.toDateString(),
            titleDescription: titleSelected.description,
            total: titleSelected.price,
            titleGroup: titleSelected.titleGroup,
        } as Load;

        let titleTitlesList: CardReadTitleTitle[] = [];
        const newTitle: CardReadTitle = {
            id: titleId,
            expiringDateStart: dateStart,
            expiringDateFinish: dateFinish,
            description: titleSelected.name,
            titleGroup: titleSelected.titleGroup,
            price: titleSelected.price,
            priceWithDiscount: TitleUtils.getPriceWithDiscount(listTitles, titleId),
            discount: titleSelected.discount,
            titles: titleTitlesList,
        } as CardReadTitle;

        // composed titles
        if (titleSelected.titles && titleSelected.titles.length > 0) {
            // add title tiles to CardReadTitle
            titleSelected.titles.forEach(tt => {
                const newTitleTitle = {
                    id: tt.id,
                    expiringDateStart: dateStart,
                    expiringDateFinish: dateFinish,
                    description: tt.description,
                    price: tt.price.value,
                    tickCode: tt.tickCode,
                    tickOperCode: tt.tickOperCode,
                    titleGroup: tt.titleGroup,
                    tariffType: tt.tariffType,
                    validTypeId: tt.validTypeId,
                    toLoad: tt.toLoad
                } as CardReadTitleTitle;

                newTitle.titles.push(newTitleTitle);
            });
        }

        await this.navigationManager.go(TicketingRoutes.TITLE_DETAILS, {
            load,
            newTitle
        });
    }

    initServiceNotify(): void {
        this.subscription2$ = this.service.cardLoadChange.subscribe(
            (data) => {
                if (data != null) {
                    this.cardRead = data;
                    this.cardRead.titles = [...data.titles];
                    for (var title in this.cardRead.titles) {
                        if (!this.isZappingOnCardTitles(this.cardRead.titles[title].id) && !this.cardRead.titles[title].isSingleTicket && this.cardRead.titles[title].expiringDateBalance == "0") {
                            this.cardRead.titles[title].expiringDateBalance = "";
                        }
                    }
                    this.storageManager.session.set(CardEnum.CARD_READ, this.cardRead);
                }
            },
            (err) => {
                this.alertService.error(err.error.message);
                console.log('HTTP Error: ', err);
            }
        );
        this.subscriptions.push(this.subscription2$);
    }

    onAlertMessage(event) {
        if (event.success) {
            this.alertService.success(event.message);
        } else {
            this.alertService.error(event.message);
        }
    }

    private async loadHeaderDetails() {
        const profileOriginData = await this.service.fetchActions().toPromise() as any;
        const profiles = [{
            id: this.card.details.profile.profile_one_code,
            expiryDate: this.card.details.profile.profile_one_expiry_date,
            description: profileOriginData.data?.profiles[0]?.name
        },
        {
            id: this.card.details.profile.profile_two_code,
            expiryDate: this.card.details.profile.profile_two_expiry_date,
            description: profileOriginData.data?.profiles[1]?.name
        },
        {
            id: this.card.details.profile.profile_three_code,
            expiryDate: this.card.details.profile.profile_three_expiry_date,
            description: profileOriginData.data?.profiles[2]?.name
        },
        {
            id: this.card.details.profile.profile_four_code,
            expiryDate: this.card.details.profile.profile_four_expiry_date,
            description: profileOriginData.data?.profiles[3]?.name
        }
        ];
        profiles.forEach(profile => {
            if (profile.id !== 0) {
                if (profile.id === this.THIRD_AGE_PROFILE) {
                    this.profileInfo += profile.description + ' até ' + this.formatDateFromDate(this.cardRead.expiringDate) + this.noBreakSpace;
                } else {
                    this.profileInfo += profile.description + ' até ' + this.formatDate(profile.expiryDate) + this.noBreakSpace;
                }
            }
        });
    }

    async setIsSerialNumberOnBlackList() {
        this.isSerialNumberOnBlackList = await this.blackListService.isSerialNumberOnBlackList(this.cardRead.serialNumber);
    }

    async setIsLVCardUnassigned() {
        this.isLVCardUnassigned = !this.isVV && this.cardRead.number == "0";
    }

    private async isCardReplaceGuide(): Promise<void> {

        this.isReplacementGuide = false;
        const cardInfoBySerialNumber = await this.loadTitleService.getCardInfoByCardSerialNumber(this.card.low_part.toString());
        if (this.SUBSTITUTION_GUIDE_DATA_MODEL === cardInfoBySerialNumber.model ||
            this.SUBSTITUTION_GUIDE_DATA_MODEL === this.card.details.environment.card_data_model) {
            this.isReplacementGuide = true;
        }

        try {
            const response = await this.recoverReplacementGuideService
                .isCardActiveReplacementGuide(this.cardRead.serialNumber).toPromise();

            if (response?.success && response?.data != null) {

                if (this.cardRead.titles.length > 0) {
                    this.cardRead.titles.filter(title => {
                        if (title.id === response.data.title.titleId) {
                            this.isReplacementGuide = true;
                            this.replacementGuideHasTitles = true;
                            // when title come from a replacement guide
                            // it's not supposed to have expiring date balance ('SALDO/QUANTIDADE')
                            title.expiringDateBalance = '';
                        }
                        // if replacement guide is not paid, delivery is not allowed
                        // and delivery button will be hidden
                        if (!response.data.replacementPaid) {
                            this.isReplacementGuide = false;
                        }
                    });
                }
            }

        } catch (e) {
            console.info('Card isn\'t a replacement guide.');
        }
    }

    convertStringMoneyToNumber(valor: string) {
        valor = valor.slice(0, -2);
        valor = valor.replace(',', '');
        return Number(valor);
    }

    setZappingBalanceFromCardRead(title: any) {
        title.titleBalance = this.convertStringMoneyToNumber(this.cardRead.titles.find((i) => i.id === title.id).expiringDateBalance);
    }

    public seeIfCardIsExpired(): boolean {
        if (CardDataModel.K_CARD_DATAMODEL_UNKNOWN
            === this.card?.details?.environment?.card_data_model) {
            return false;
        }
        const currentDate = new Date();
        if (this.cardRead.expiringDate) {

            const expiringDate = new Date(this.cardRead.expiringDate);
            const expiringYear = expiringDate.getFullYear();
            const expiringMonth = expiringDate.getMonth();
            const expiringDay = expiringDate.getDate();
        
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();
        
            const currentOnlyDate = new Date(currentYear, currentMonth, currentDay);
            const expiringOnlyDate = new Date(expiringYear, expiringMonth, expiringDay);
        
            return currentOnlyDate > expiringOnlyDate;
        }

        return true; // default is expired card.
    }

    /* UTILS */
    private static isZapping(load: Load): boolean {
        return load.tickCode === 33592 && load.tickOperCode === 31;
    }

    private isZappingOnCardTitles(title: any): boolean {
        return this.cardRead.titles.find((i) => i.id === title.id) != null;
    }

    private formatDate(days: number): string {
        const profileExpiryDate = new Date(1997, 0, 1);
        profileExpiryDate.setDate(profileExpiryDate.getDate() + days);
        return this.datePipe.transform(profileExpiryDate, 'dd/MM/yyyy');
    }

    private formatDateFromDate(expiryDate: Date): string {
        return this.datePipe.transform(expiryDate, 'dd/MM/yyyy');
    }

    public getLVNumber() {
        return this.card?.details?.environment?.app_issuer?.toString()?.padStart(3, '0') + ' 0' +
            this?.cardRead?.number?.toString()?.padStart(8, '0');
    }

    public closeModal(): void {
        this.isModalVisible = false;
        this.composedTitleList = [];
    }

    public async selectComposedTitle(listTitles: any[], titleId: any): Promise<void> {
        this.isModalVisible = false;
        // timeout to allow that modal is closed
        setTimeout(() => this.selectTitle(listTitles, titleId), 100);
    }
}
