<div class="detail-content">
    <div nz-row class="detail-content">
        <h1 class="thick">Seleção de Título</h1>
    </div>
    <div nz-row class="detail-content">
        <h2 class="thick">Detalhes do título a carregar</h2>
    </div>
    <div *ngIf="listTitles.length != 0" nz-row class="detail-content">
        <h3>Tarifa: </h3>
    </div>
    <div nz-row class="detail-content">
        <app-primary-radio-group [selected]="selectedTitles" [listOptions]="listTitles"
            (onChanged)="onTitlesChanged($event)">
        </app-primary-radio-group>
    </div>
    <div nz-row class="detail-content grey-background p-t-10">
        <h3 class="thick p-l-5">Por favor selecione o título a carregar:</h3>
        <app-primary-list-button buttonSize="medium" [data]="listLoadTitle" [colNum]="6"
            (callButtonClick)="loadSelection($event)"></app-primary-list-button>
    </div>
    <div nz-row class="detail-content p-t-10">
      <nz-pagination [nzPageIndex]="currentPageIndex"
                     [nzTotal]="totalTitles"
                     [nzHideOnSinglePage]="false"
                     [nzSimple]="true"
                     [nzResponsive]="true"
                     [nzPageSize]="pageSize"
                     (nzPageIndexChange)="onPageChange($event)">
      </nz-pagination>
    </div>
    <div nz-row class="footer-buttons">
      <app-primary-white-button (click)="goBack()">Voltar</app-primary-white-button>
    </div>
</div>
