export interface TableHeaderDataConfig {
    valAlign ?: string;
    money ?: boolean;
    percent ?: boolean;
    type ?: TableHeaderDataType;
    format ?: string;
    colorMapping ?: TagColorMapping[];
}

export enum TableHeaderDataType {
    TEXT = 'TEXT',
    MONEY = 'MONEY',
    DATE = 'DATE',
    TAG = 'TAG',
    PERCENT = 'PERCENT',
    BOOLEAN = 'BOOLEAN'
}

export interface TagColorMapping {
  value ?: string;
  color ?: string;
  label ?: string;
}
