import { EventService } from './events.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LoginRequest } from 'src/app/shared/models/login-request';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ValidateUtils } from 'src/app/shared/utils/validate-utils';
import { BasicResponse } from 'src/app/shared/models/basic-response';
import { JwtUtils } from 'src/app/shared/utils/jwt-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { SuspendShiftRequest } from 'src/app/shared/models/suspend-shift-request';
import { CloseShiftRequest } from 'src/app/shared/models/close-shift-request';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { BasicDataResponse } from '../../shared/models/basic-data-response';
import { StorageManagerService } from './storage-manager.service';
import { AppComponentService } from 'src/app/app.component.service';
import { PreviousShiftsService } from './previous-shifts.service';
import { PeripheralService } from './peripheral.service';
import { PrintShiftRequest } from 'src/app/shared/models/print-shift-request';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { Page, PaginationFilter } from 'src/app/shared/utils/http/http-commons-interfaces';
import { SuspendedShiftTable } from 'src/app/modules/shift/pages/suspended-shifts/interfaces/suspended-shifts-interfaces';
import { EventTypeEnum } from 'src/app/modules/shift/pages/shift-resume/model/event.interface';

@Injectable({
    providedIn: 'root'
})
export class ShiftService {

    private username = '';
    private externalId = '';
    private shiftID = null;
    private shiftStartDate: Date = new Date();
    shiftStatus: number;
    shiftResume = false;
    shiftId: number;
    deviceId: string;
    error = '';

    loginChange: Subject<any> = new Subject<any>();
    adminShifts: Subject<any> = new Subject<any>();
    closeShiftChange: Subject<any> = new Subject<any>();
    suspendShiftChange: Subject<any> = new Subject<any>();

    // Page redirect
    redirectHomeUrl = 'home';
    redirectLoginUrl = 'login';

    constructor(
        private httpClient: HttpClient,
        private storageManager: StorageManagerService,
        private appComponentService: AppComponentService,
        private previousShiftService: PreviousShiftsService,
        private eventService: EventService,
        private peripheralService: PeripheralService) {
    }

    // Refresh login token
    public refreshToken(): Observable<any> {
        const headers = new HttpHeaders({ 'refresh-token': `${new JwtUtils().getRawRefreshToken()}` });
        return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}auth/api/authentication/refresh`, { headers });
    }

    // Login with start of shift
    public login(userInfo: LoginRequest): Observable<BasicDataResponse> {

        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}auth/api/authentication/login`, userInfo).pipe(
            tap(async (res: BasicDataResponse) => {
                if (res?.success) {
                    const data = res.data;

                    this.storageManager.session.set(CommonEnum.accessToken, data.token);
                    this.storageManager.session.set(CommonEnum.refreshToken, data.refreshToken);

                    const jwtUtils: JwtUtils = new JwtUtils();

                    this.username = jwtUtils.getName();
                    this.externalId = jwtUtils.getSub();

                    this.storageManager.session.set(CommonEnum.username, String(this.username));
                    this.storageManager.persistent.set(CommonEnum.externalId, String(this.externalId));
                    this.storageManager.session.set(CommonEnum.expiresIn, String(jwtUtils.getExpiresIn()));

                } else {
                    console.log(`shift.service.ts:login ${this.error}`);
                }
            },
                (e: any) => {
                    try {
                        const response = HttpUtils.getErrorResponse(e);
                        try {
                            this.error = HttpUtils.getErrorResponseMessageDetail(e);
                        } catch (e) {
                            this.error = response.message;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                })
        );
    }

    // Suspend of shift
    public suspend(request: SuspendShiftRequest) {
        return this.httpClient.put(`${EnvironmentUtil.getEnv().apiIntApiUrl}/shift/serv/shift/suspend`, request).subscribe(
            async (response: BasicResponse) => {
                if (response?.success) {
                    this.appComponentService.setIsShiftActive(false);
                    this.loginChange.next({ clean: true });
                    this.suspendShiftChange.next();
                }
            },
            (e: any) => {
                try {
                    if (HttpUtils.isBasicResponse(e)) {
                        this.error = HttpUtils.getErroMessage(e);
                    } else {
                        this.error = e.getMessage;
                    }
                    console.log(`shift.service.ts:suspend ${this.error}`);
                } catch (e) {
                    console.log(`shift.service.ts:suspend (try-catch) ${this.error}`);
                }
            }
        );
    }

    // Close ShiftResponse
    public async closeShift(request: CloseShiftRequest, printShiftRequest: PrintShiftRequest) {
        try {
            const res = await this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/shift/close`, request)
                .toPromise() as any;
            if (res && res.success) {
                this.previousShiftService.printShift(printShiftRequest);
                this.storageManager.session.set(CommonEnum.shiftStatus, CommonEnum.shiftStatusFinished);
                this.loginChange.next({ clean: true });
                return { success: true };
            } else {
                return { success: false, error: 'Tente mais tarde' };
            }
        } catch (e) {
            return { success: false, error: HttpUtils.getErroMessage(e) };
        }
    }

    public startResumeShift(machine: string): Observable<BasicDataResponse> {
        return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/start-resume`, { machine: machine });
    }

    public isLoggedIn(): boolean {
        return ValidateUtils.isOkValueString(this.storageManager.session.get<string>(CommonEnum.accessToken));
    }

    public isSuspend(): boolean {
        return CommonEnum.shiftStatusSuspend === this.storageManager.session.get<string>(CommonEnum.shiftStatus);
    }

    public isCloseShift(): boolean {
        return CommonEnum.shiftStatusFinished === this.storageManager.session.get<string>(CommonEnum.shiftStatus);
    }

    public isShiftActive(): boolean {
        return CommonEnum.shiftStatusActive === this.storageManager.session.get<string>(CommonEnum.shiftStatus);
    }

    public getError(): string {
        return this.error;
    }

    public getShiftID(): string {
        return this.shiftID ? this.shiftID : this.storageManager.session.get<string>(CommonEnum.shiftID);
    }

    public getUsername(): string {
        return this.username ? this.username : this.storageManager.session.get<string>(CommonEnum.username);
    }

    public getExternalId(): string {
        return this.externalId ? this.externalId : this.storageManager.persistent.get<string>(CommonEnum.externalId);
    }

    public getIdentifier(): string {
        return this.username ? this.username : this.storageManager.session.get<string>(CommonEnum.username);
    }

    public getShiftStartDate(): Date {
        return this.shiftStartDate ? this.shiftStartDate : new Date(this.storageManager.session.get<string>(CommonEnum.shiftStartDate));
    }

    public async doLogout() {
        try {
            const machineNumber = await this.peripheralService.getMachineNumber();
            const controllerId = await this.peripheralService.getPeripheralId();
            await this.eventService.createEvent(EventTypeEnum.LOGOUT, machineNumber, true, controllerId);
        } catch (error) {
            // ignore error
        }
        this.storageManager.session.remove(CommonEnum.accessToken);
        this.storageManager.session.remove(CommonEnum.refreshToken);
        this.storageManager.session.remove(CommonEnum.expiresIn);
        this.storageManager.session.remove(CommonEnum.shiftID);
        this.storageManager.session.remove(CommonEnum.shiftStatus);
        this.storageManager.session.remove(CommonEnum.username);
        this.storageManager.persistent.remove(CommonEnum.externalId);
    }

    getShiftsForAdmin(request: any) {
        let params = new HttpParams();
        params = params.set('sortBy', `startDate`);
        params = params.set('sortDirection', `DESC`);

        if (request.status) {
            params = params.set('status', `${request.status}`);
        }

        if (request.ownerId) {
            params = params.set('ownerId', `${request.ownerId}`);
        }

        if (request.start) {
            params = params.set('start', `${request.start}`);
        }

        if (request.end) {
            params = params.set('end', `${request.end}`);
        }

        this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/admin/shift`, {
            params
        }).subscribe(
            (res: BasicDataResponse) => {
                if (res && res.success) {
                    this.adminShifts.next(res.data);
                } else {
                    this.adminShifts.error(res.message);
                }
            },
            (e: any) => {
                console.log(e);
                try {
                    this.error = HttpUtils.getErroMessage(e);
                    console.log(`shift.service.ts:getShiftsForAdmin ${this.error}`);
                    this.adminShifts.error(this.error);
                } catch (e) {
                    console.log(`shift.service.ts:getShiftsForAdmin (try-catch) ${this.error}`);
                }
            });
    }

    clean() {
        this.loginChange.next();
    }

    clearHeader() {
        this.loginChange?.next({ clean: true });
    }

    public getShiftInfo(shiftId: number) {
        return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/shift-info/${shiftId}`);
    }

    public async getShiftStatusByOwnerId(ownerId: number): Promise<BasicResponseDTO<string>> {
        return await this.httpClient
            .get<BasicResponseDTO<string>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/shift-status/${ownerId}`)
            .toPromise();
    }

    public async getAllSuspendedShifts(paginationFilter: PaginationFilter): Promise<BasicResponseDTO<Page<SuspendedShiftTable>>> {
        let params = new HttpParams();
        if (paginationFilter.size) {
            params = params.set('size', `${paginationFilter.size}`);
        }
        if (paginationFilter.page) {
            params = params.set('page', `${paginationFilter.page}`);
        }
        if (paginationFilter.sorters) {
            params = params.set('sorters', `${paginationFilter.sorters}`);
        }
        return await this.httpClient
            .get<BasicResponseDTO<Page<SuspendedShiftTable>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/suspended-shifts`, { params })
            .toPromise();
    }

}
