import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { RequisitionLv } from '../modules/requisition-lv';
import { RequisitionService } from './service/requisition.service';
import { ItemRequisitionType } from './model/item-requisition-type';
import { ItemDeliveryType } from './model/item-delivery-type';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Component({
  selector: 'app-new-requisition',
  templateUrl: './new-requisition.component.html',
  styleUrls: ['./new-requisition.component.less'],
})
export class NewRequisitionComponent implements OnInit {

  request: RequisitionLv = {} as RequisitionLv;

  listRequisitionType: ItemRequisitionType[] = [];
  listDeliveryType: ItemDeliveryType[] = [];

  constructor(
    private router: Router,
    private alertService: AlertService,
    private requisitionService: RequisitionService
  ) {
    this.subscriveRequisitionType();
    this.subscriveDeliveryType();

    this.requisitionService.getRequisitionProfiles();
  }

  ngOnInit(): void { }

  onRequisitionTypeChanged(idItem: string) {
    this.listDeliveryType = [];
    this.requisitionService.getDeliveryTypeByProfile(Number(idItem));
    this.request.titleSelected = this.listRequisitionType.find(({ value }) => value === idItem);
  }

  onDeliveryTypeChanged(idItem: string) {
    this.request.deliveryType = this.listDeliveryType.find(({ value }) => value === idItem);
  }

  previousView() {
    this.router.navigate(['requisition-lv']);
  }

  onNextClick() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
      state: {
        requestLv: this.request,
      },
    };
    this.router.navigate(['requisition-lv/details'], navigationExtras);
  }

  subscriveRequisitionType() {
    this.requisitionService.listRequisitionType.subscribe(
      (data: ItemRequisitionType[]) => {
        this.listRequisitionType = data;
      },
      err => {
        this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        console.log('HTTP Error', err);
      }
    );
  }

  subscriveDeliveryType() {
    this.requisitionService.listDeliveryType.subscribe(
      (data: ItemDeliveryType[]) => {
        this.listDeliveryType = data;
      },
      err => {
        this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        console.log('HTTP Error', err);
      }
    );
  }

}
