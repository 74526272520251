<div>
    <div nz-row class="margin-bottom-20">
        <!-- HEADER -->
        <div nz-col nzSpan="12">
            <h1 class="title">
                Autos / Pré-Registo
            </h1>
            <div nz-row>
                <h2 class="card">Introduzir dados do Auto a registar</h2>
            </div>
        </div>
    </div>

    <div class="flex-column">
        <div class="flex-row margin-bottom-20">
            <div class="flex-column">
                <h3 class="align-left">
                    Nº DO AUTO
                </h3>
                <app-primary-input-keyboard
                    [value]="noticeNumber"
                    (valueChange)="onNoticeNumberChange($event)"
                    [maxlength]="noticeNumberMaxLength"
                    [mask]="'0*'"
                    inputPlaceholder="Digite o nº do auto"
                    inputCss="input-search-number"
                ></app-primary-input-keyboard>
            </div>
            <div class="flex-column">
                <h3 class="align-left">
                    NIF DO AUTUADO
                </h3>
                <app-primary-input-keyboard
                    [value]="finedPersonFiscalNumber"
                    (valueChange)="onFinedPersonFiscalNumberChange($event)"
                    [maxlength]="fiscalNumberMaxLength"
                    inputPlaceholder="Digite o NIF do autuado"
                    inputCss="input-search-number"
                ></app-primary-input-keyboard>
            </div>
            <div class="vatNumberInternational">
                <app-primary-checkbox
                    [(isChecked)]="isVatInternational"
                    label="NIF Internacional"
                    labelCss="label-border"
                >
                </app-primary-checkbox>
            </div>
        </div>
        <div class="flex-row margin-bottom-20">
            <div class="flex-column">
                <h3 class="align-left">
                    NOME DO AUTUADO
                </h3>
                <app-primary-input-keyboard
                        [value]="finedPersonName"
                        (valueChange)="onFinedPersonNameChange($event)"
                        [maxlength]="nameMaxLength"
                        inputPlaceholder="Digite o nome do autuado"
                        inputCss="input-search-number"
                ></app-primary-input-keyboard>
            </div>
            <div class="flex-column">
                <h3 class="align-left">
                    DATA
                </h3>
                <app-datetime-picker
                    inputCss="input-search-number"
                    [data]="this.fineDate"
                    (valueChange)="onDateChange($event)">
                </app-datetime-picker>
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-column">
                <h3 class="align-left">
                    VALOR A PAGAR
                </h3>
                <app-dropdown-button
                    [label]="finePrice ? finePrice / 100 : 'Escolha o valor'"
                    width="150px"
                    height="50px"
                    labelTemplate="btn-icon icon-drop_down icon-dropdown"
                    buttonStyle="dropdown"
                    [listOptions]="paymentValueOptions"
                    (onSelect)="onPaymentValueChange($event)">
                </app-dropdown-button>
            </div>
        </div>
    </div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button
                    (click)="previousView()"
            >
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
        <div class="load" nz-col [nzSpan]="4">
            <app-primary-normal-button (click)="registerFine()" [disabled]="checkNextDisableState()">
                Seguinte
            </app-primary-normal-button>
        </div>
    </div>
</div>
