import { Component, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ShoppingCart } from '../../modules/shopping-cart';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Component({
    selector: 'app-money',
    templateUrl: './payment-money.component.html',
    styleUrls: ['./payment-money.component.less'],
})
export class PaymentMoneyComponent implements OnInit {
    btnNextDisable = true;
    navigation: Navigation;
    paymentForm: FormGroup;

    listResult: any[] = [];
    total = 0;
    totalMoney: number = 0.00;
    totalPayed = 0;
    totalRemaining = 0;
    totalReturn = 0;

    shoppingCart: ShoppingCart = { payment: {} } as ShoppingCart;

    moneyText = "";

    constructor(
        private formBuilder: FormBuilder,
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService) {

        this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
        this.total = this.shoppingCart.totalToPay / 100;
        this.totalRemaining = this.total;

        this.shoppingCartService.shoppingCartChange.subscribe(
            (data) => {
                if (data != null && data.showHeader) {
                    this.listResult = data.listResult;
                    this.total = data.totalToPay / 100;
                }
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            }
        );
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);

        this.paymentForm = this.formBuilder.group({
            money: new FormControl('', {
                validators: [],
            }),
        });
    }

    public async goToHome(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public async onPreviousClick(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
    }

    public async onNextClick(): Promise<void> {
        this.shoppingCart.payment.amountMoney = this.shoppingCart.totalToPay;
        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        await this.navigationManager.go(TicketingRoutes.INVOICE);
    }

    dblclick() {
        this.paymentMoneyChange(this.total.toString());
    }

    public paymentMoneyChange(event: string): void {
        // it's necessary to replace comma for dot because visually it's necessary to be a comma
        // but we need a dot so total can be recognized as a number
        this.totalMoney = Number(event.replace(',', '.'));
        const totalToPay = this.total;

        if (this.totalMoney >= totalToPay) {
            this.totalRemaining = 0;
            this.totalReturn = this.totalMoney - totalToPay;
            this.totalPayed = totalToPay;
            this.btnNextDisable = false;
        } else {
            this.totalReturn = 0;
            this.totalRemaining = totalToPay - this.totalMoney;
            this.btnNextDisable = true;
        }
    }
}
