import {Component, OnInit} from '@angular/core';
import {TitleDetail} from '../models/title-detail';
import {Navigation, NavigationExtras, Router} from '@angular/router';
import {AlertService} from 'src/app/core/services/alert.service';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';

@Component({
  selector: 'app-voucher-consume',
  templateUrl: './voucher-consume.component.html',
  styleUrls: ['./voucher-consume.component.less'],
})
export class VoucherConsumeComponent implements OnInit {
  selectedDeliveryPlace = 0;
  listDeliveyPlace: object[] = [];

  value = '';
  title = 'Input a number';
  selectedValue = null;
  total = null;
  titileDetail: TitleDetail;

  navigation: Navigation;

  selectedTypeProd = 0;
  listTypeProd: object[] = [];

  selectedCardPerfil = 0;
  listCardPerfil: object[] = [];

  selectedEntrega = 0;
  listEntrega: object[] = [];

  selectedProduct = 0;
  listProduct: object[] = [];

  listResultColumn: TableColumn[] = [];
  listResultHeader: TableHeaderData[] = [];

  listResult: any[] = [];

  constructor(private router: Router, private alertService: AlertService) {}

  ngOnInit(): void {
    this.listDeliveyPlace = [
      { label: 'Julho de 2020', value: 1 },
      { label: 'Agosto de 2020', value: 2 },
    ];

    this.titileDetail = {
      titleId: 0,
      expiringDateStart: '',
      expiringDateFinish: '',
      titleDescription: 'Requisição Lisboa VIVA/navegante personalizado',
      total: 30,
    };

    this.listProduct = [{ value: '0', label: 'Nav. Metropolitano - Família' }];

    window.scrollTo(0, 0);
  }

  homeScreen() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['home'], navigationExtras);
  }

  previousScreen() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['requisition-lv/new'], navigationExtras);
  }

  screenResumoOperacoes() {
    alert('Ecrã Resumo de Operações');
  }
}
