import { LoadTitleTransactionType } from './../../../../../shared/enum/load-title-transaction-type.enum';
import { FeeInfo } from './../models/tudInfo';
import { Load } from './../models/load';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { AlertService } from 'src/app/core/services/alert.service';
import { Component, OnInit } from '@angular/core';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import { Router } from '@angular/router';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { ShiftService } from 'src/app/core/services/shift.service';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { StorageManagerService } from '../../../../../core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from '../../../../../core/services/navigation-manager.service';
import { AuthorizationLoadService } from 'src/app/core/services/authorization-load.service';
import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { CardDetailsMessage, TemporalType } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { ValidityType } from '../models/title-detail';
import { ShoppingCartProduct } from '../../../../operation/modules/shopping-cart-product';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { TUDTitleDTO } from 'src/app/shared/models/title-dto';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { SaleService } from 'src/app/core/services/sale.service';

@Component({
    selector: 'app-annul-title',
    templateUrl: './annul-title.component.html',
    styleUrls: ['./annul-title.component.less']
})
export class AnnulTitleComponent implements OnInit {

    load: Load;
    title: CardReadTitle;
    itemId: number;
    subProductToAnnul: ShoppingCartProduct;

    validityStart: Date;
    validityEnd: Date;
    daysUsed: number;
    isChecked: boolean;
    isDisabled: boolean;
    fee: number;
    total: number;
    titleValue: number;
    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    listOfData: { description: string, amount: number }[] = [];
    fromShoppingCart = false;


    public offset: string;

    public isSingleTicket = false;
    private zapping = false;

    constructor(
        private router: Router,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private storageManager: StorageManagerService,
        private authorizationLoadService: AuthorizationLoadService,
        private loadTitleService: LoadTitleService,
        private navigationManager: NavigationManagerService,
        private shoppingCartService: ShoppingCartService,
        private tariffService: TariffService,
        private storageManagerService: StorageManagerService,
        private saleService: SaleService
    ) {

        if (this.router.getCurrentNavigation()?.extras?.state?.load
            && this.router.getCurrentNavigation()?.extras?.state?.title) {
            this.load = this.router.getCurrentNavigation().extras.state.load;
            this.title = this.router.getCurrentNavigation().extras.state.title;
            this.zapping = CardUtils.IsZapping(this.load.tickCode, this.load.tickOperCode, this.load.titleId);
            const cardFullDetails = this.storageManagerService.session.get<CardDetailsMessage>(
                CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
            this.checkIfIsSingleTicket(cardFullDetails);
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);

        }
        if (this.router.getCurrentNavigation()?.extras?.state?.itemId) {
            this.itemId = this.router.getCurrentNavigation().extras.state.itemId;
        }

        if (this.router.getCurrentNavigation()?.extras?.state?.titleValue) {
            this.titleValue = this.router.getCurrentNavigation().extras.state.titleValue;
        }

        if (this.router.getCurrentNavigation()?.extras?.state?.fromShoppingCart) {
            this.fromShoppingCart = this.router.getCurrentNavigation().extras.state.fromShoppingCart;
        }

        if (this.router.getCurrentNavigation()?.extras?.state?.subProductToAnnul) {
            this.subProductToAnnul = this.router.getCurrentNavigation().extras.state.subProductToAnnul;
        }

    }

    public checkIfIsSingleTicket(cardFullDetails: CardDetailsMessage): void {
        cardFullDetails.details.contracts.filter((contract) => {
            if (this.load.tickCode == contract.ticket_code
                && this.load.tickOperCode == contract.operator_code) {
                this.isSingleTicket = (TemporalType.K_TEMPORAL_VALIDITY_NO_LIMIT == contract.temporal_validity.temporal_type);
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.offset = DateUtils.getTimezone();
        const request = {
            cardSerialNumber: this.load.cardRead.serialNumber,
            titleId: this.load.titleId,
        } as { titleId: number, cardSerialNumber: string };

        try {
            const lastLoadResponse = await this.loadTitleService
                .getLastLoadByTitleIdAndCardSerialNumber(this.load.titleId, Number(this.load.cardRead.serialNumber));
            const loadId = lastLoadResponse.data.id;
            let lastLoadWasAuth = false;
            const responseSaleItemAuth = await this.saleService
                .findSaleItemAuthByAuthLoadId(loadId);
            if (responseSaleItemAuth.success && responseSaleItemAuth.data != null) {
                lastLoadWasAuth = true;
            }
            if (lastLoadWasAuth) {
            const responseCanCancelAuthorization: any = await this.authorizationLoadService
                .canAnnulAuthorization(request).toPromise();
            if (responseCanCancelAuthorization.data != null && responseCanCancelAuthorization.error === undefined) {
                if (responseCanCancelAuthorization.data) {
                    this.title.price = 0;
                    // when annul title loaded by load authorization --> amount to return will be 0
                        this.title.titlePrice = 0;
                    }
                }
            }
        } catch (e) { console.log(e); }

        this.fee = 0;
        this.validityStart = new Date(this.title.expiringDateStart);
        this.validityEnd = new Date(this.title.expiringDateFinish);

        // (only annul 1 month)
        if (this.title.validTypeId === ValidityType.MENSAL) {
            this.validityStart = new Date(this.validityEnd);
            this.validityStart.setDate(1);
        }

        if (this.isTUD(this.title)) {
            const tudRequest = {
                titleId: this.title.id,
                cardSerialNumber: this.load.cardRead.serialNumber
            } as { titleId: number, cardSerialNumber: string }

            const feeInfo: FeeInfo = await this.loadTitleService.getTud(tudRequest).toPromise()
                .then((response: BasicDataResponse) => { return response.data });

            if (feeInfo.fee > 0) {
                console.log('fee > 0');
                this.fee = feeInfo.fee;
                this.isChecked = true;
                this.isDisabled = false;
            } else {
                this.fee = 0;
                this.isChecked = false;
                this.isDisabled = true;
            }
            this.daysUsed = feeInfo.daysUsed;
        }

        if (this.title.isSingleTicket) {
            const cardFullDetails = this.storageManagerService.session.get<CardDetailsMessage>(
                CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
            await this.loadTitleService.getTitleAmountToAnnul(this.title.id, cardFullDetails)
                .then(data => {
                    this.title.titlePrice = data;
                });
        }

        let loadAmount = this.title.loadAmount;
        if (this.isZapping() || this.isSingleTicket) {
            // for zappings and single tickets annul is already considering amount
            loadAmount = 1;
        }
        this.total = ((this.titleValue ? this.titleValue : this.title.titlePrice) * loadAmount) - this.fee;

        if (this.subProductToAnnul) {
            this.total += this.subProductToAnnul.price;
        }
        this.prepareTableResult();
    }

    isTUD(title: CardReadTitle) {
        if (!title.isSlidingTitle || this.fromShoppingCart) {
            return false;
        }
        return true;
    }

    onCheck(value: boolean) {
        this.isChecked = value;

        if (!this.isChecked) {
            this.fee = 0;
        } else {
            this.fee = 0.125 * this.daysUsed * this.title.titlePrice;
        }

        this.total = this.title.titlePrice - this.fee;
        this.prepareTableResult();
    }

    prepareTableResult() {

        this.listTitleColumn = [
            { title: 'Descrição', config: { colAlign: 'left', colWidth: '70%' } },
            { title: 'Valor', config: { colAlign: 'left', colWidth: '30%' } },
        ];

        this.listTitleHeader = [
            { visible: true, name: 'description', config: null, button: null } as TableHeaderData,
            { visible: true, name: 'amount', config: { type: TableHeaderDataType.MONEY }, button: null } as TableHeaderData,
        ];

        let loadAmount = this.title.loadAmount;
        if (this.isZapping() || this.isSingleTicket) {
            // for zappings and single tickets annul is already considering amount
            loadAmount = 1;
        }
        const value = (this.titleValue ? this.titleValue : this.title.titlePrice) * loadAmount;
        this.listOfData = [{ description: this.title.description, amount: value }];
        if (this.subProductToAnnul) {
            this.listOfData.push({ description: this.subProductToAnnul.description, amount: this.subProductToAnnul.price });
        }

        if (this.isChecked) {
            this.listOfData.push({ description: 'Taxa de Utilização', amount: -this.fee });
        }

        this.listOfData.push({ description: 'Total a devolver', amount: this.total });
    }

    async previousScreen() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
    }

    async next() {
        // Slidding ticket should add new item to cart
        if (this.isTUD(this.title) && this.isChecked) {
            try {
                const tudTitleInfo: TUDTitleDTO = await this.tariffService.fetchTUDTitleById(this.title.id).toPromise()
                    .then((response: BasicDataResponse) => { return response.data });
                const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
                const shoppingCartInsertTUD: ShoppingCartItemRequest = {
                    shiftId: Number(this.shiftService.getShiftID()),
                    titleId: tudTitleInfo.title,
                    description: tudTitleInfo.description,
                    quantity: this.daysUsed,
                    transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
                    shoppingCartId: null,
                    // Check annulId
                    isAnnul: true,
                    annulId: null,
                    loadId: null,
                    requisitionId: null,
                    authId: null,
                    replacementId: null,
                    voucherId: 0,
                    cardNumber: this.load.cardRead.number,
                    cardSerialNumber: Number(this.load.cardRead.serialNumber),
                    appIssuer: cardDetails?.details?.environment?.app_issuer,
                };
                this.storageManager.session.set(ReadingLoadingEnum.IsTUD, 'true');
                await this.shoppingCartService.insert(shoppingCartInsertTUD);
                
            } catch (e) {
                console.log('annul-title::: get Tud Title ' + e);
            }
        }

        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.title.id,
            description: this.title.description,
            quantity: 1,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_ANUL_VEND_CARR,
            shoppingCartId: null,
            // Check annulId
            isAnnul: true,
            annulId: null,
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardNumber: this.load.cardRead.number,
            cardSerialNumber: Number(this.load.cardRead.serialNumber),
        };

        const cancelTitleRequest: LoadTitleRequest = {
            peripheralId: sessionStorage.getItem(CommonEnum.peripheralId),
            serialNumber: this.load.cardRead.serialNumber,
            tickCode: this.load.tickCode,
            tickOperCode: this.load.tickOperCode,
            id: this.load.titleId,
            voucherNumber: '',
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID))
        } as LoadTitleRequest;

        const requestCancelConsumeAuthorization = {
            cardSerialNumber: this.load.cardRead.serialNumber,
        };

        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.NullifyContractRequest, cancelTitleRequest);
        this.storageManager.session.set(ReadingLoadingEnum.CancelConsumeAuthorization, requestCancelConsumeAuthorization);
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.NullifyContract);

        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV,
            {
                fromShoppingCart: this.fromShoppingCart,
                subProductToAnnul: this.subProductToAnnul,
            });
    }

    public isZapping(): boolean {
        return this.zapping;
    }

}
