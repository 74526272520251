<div>

    <div nz-row class="margin-bottom-20">

        <div nz-col nzSpan="12">
            <h1 class="req">
                Requisições de Lisboa VIVA/navegante personalizado
            </h1>
            <h2 class="hist">
                Histórico de Requisições
            </h2>
        </div>

        <div nz-col nzSpan="12" class="margin-top-60">
            <app-primary-rectangle-button
                class="m-r-20"
                (click)="printInvoice()"
                [disabled]="selected ? null : true"
                width="180px"
            >
                Emitir 2º via
            </app-primary-rectangle-button>

            <app-primary-rectangle-button
                *ngIf="isShiftStarted() && canAnnulRequisitions"
                (click)="annulReq()"
                [disabled]="selected ? null : true"
                width="180px"
            >
                Anular
            </app-primary-rectangle-button>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzSpan="5">
            <h1 class="doc">
                Nº Contribuinte
            </h1>
        </div>
        <div nz-col nzSpan="4">
            <h1 class="date margin-left-10">
                Data De
            </h1>
        </div>
        <div nz-col nzSpan="4">
            <h1 class="date margin-left-10">
                Até
            </h1>
        </div>
    </div>

    <div nz-row class="margin-bottom-30 margin-bottom-10">

        <div nzSpan="4" class="align-left">
            <app-primary-input-keyboard
                inputCss="input-search-number"
                inputPlaceholder="Digite o número"
                keyboardType="number"
                [mask]="'0*'"
                [maxlength]="25"
                [value]="vatNumber"
                (valueChange)="onChangedInput($event)"
            >
            </app-primary-input-keyboard>
        </div>

        <div nzSpan="4" class="align-left m-l-10">
            <app-date-picker
                height="50px"
                [data]="startDate"
                (valueChange)="onChangedStartDate($event)"
            >
            </app-date-picker>
        </div>

        <div nzSpan="4" class="align-left m-l-15">
            <app-date-picker
                height="50px"
                [data]="endDate"
                (valueChange)="onChangedEndDate($event)"
            >
            </app-date-picker>
        </div>

        <div nz-col nzSpan="7" nzJustify="end" class="align-left">
            <app-primary-normal-button
                borderColor=" #707070"
                back="white"
                width="55px"
                (click)="clearSearch()"
                class="margin-left-20"
            >
                <span class="btn-icon icon-apagar delete-icon"></span>
            </app-primary-normal-button>

            <app-primary-normal-button
                id="buttonSearch"
                style="margin-left: 19px; top: 214px; left: 762px; width: 55px; height: 52px; opacity: 1; vertical-align: top;"
                width="55px"
                (click)="loadRecords()"
            >
                <span nzType="search" class="btn-icon icon-pesquisar search-icon"></span>
            </app-primary-normal-button>
        </div>

    </div>

    <div nz-row>
        <div class="div-table-result" nz-col nzSpan="24">
            <app-primary-table
                [listOfColumn]="listTitleColumn"
                [headerDatas]="listTitleHeader"
                [listOfData]="listRecords"
                [showPagination]="true"
                [isSingleSelection]="true"
                (isCheckedChange)="checkBoxEvent($event)"
                (onPageChanged)="onPageChanged($event)"
                [dataTotal]="dataTotal"
                [frontPagination]="false"
                [pageSize]="defaultPageSize"
            >
            </app-primary-table>
        </div>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="start">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button
                (click)="previousView()"
            >
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
    </div>
</div>
