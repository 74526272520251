import { Injectable } from '@angular/core';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { HttpUtils } from 'src/app/shared/utils/http-utils';

@Injectable({
    providedIn: 'root'
})
export class RequisitionLvRecordsService {

    callLoadRecords: Subject<any> = new Subject<any>();
    callAnnulReq: Subject<any> = new Subject<any>();
    callPrintInvoice: Subject<any> = new Subject<any>();
    error: any;

    constructor(private httpClient: HttpClient) { }

    public loadRecords(request: any): Observable<any> {
        let params = new HttpParams();
        if (request.vatNumber) {
            params = params.set('vatNumber', `${request.vatNumber}`);
        }
        if (request.dateAfter) {
            params = params.set('endDate', `${request.dateAfter}`);
        }
        if (request.dateBefore) {
            params = params.set('startDate', `${request.dateBefore}`);
        }
        if (request.pageSize) {
            params = params.set('size', `${request.pageSize}`);
        }
        if (request.page) {
            params = params.set('page', `${request.page}`);
        }
        if (request.sorters) {
            params = params.set('sorters', `${request.sorters}`);
        }
        return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/sale-item/requisitions-by-filter`, { params });
    }

    public annulReq(selectedId: number) {
        console.log('enter cancelRequisition');
        this.httpClient
            .delete(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/requisition/${selectedId}`)
            .subscribe(
                (res: BasicDataResponse) => {
                    if (res && res.success) {
                        this.callAnnulReq.next(res);
                    } else {
                        this.callAnnulReq.error(res.message);
                    }
                },
                (e: any) => {
                    console.log(e);
                    try {
                        this.error = HttpUtils.getErroMessage(e);
                        console.log(this.error);
                        this.callAnnulReq.error(this.error);
                    } catch (e) {
                        console.log(e);
                    }
                });
    }

    public printInvoice(request: any) {
        console.log('enter printInvoice');

        try {
            const response = this.httpClient
                .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/serv/reqlv/print-invoice`, request).toPromise();

            this.callPrintInvoice.next(true);

        } catch (error) {
            this.callPrintInvoice.error(error);
            try {
                this.error = HttpUtils.getErroMessage(error);
                console.log(this.error);
                this.callPrintInvoice.error(this.error);
            } catch (e) {
                console.log(e);
            }
        }

    }

}
