import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { SupportDetailActionEnum } from '../../enum/support-detail-action.enum';
import { TableHeaderData } from '../../models/table-header-data';
import { TableHeaderDataButton } from '../../models/table-header-data-button';
import { Router } from '@angular/router';
import {
    TableHeaderDataConfig,
    TableHeaderDataType,
    TagColorMapping,
} from '../../models/table-header-data-config';
import { DateUtils } from '../../utils/date-utils';

declare const require: any;

@Component({
    selector: 'app-primary-table',
    templateUrl: './primary-table.component.html',
    styleUrls: ['./primary-table.component.less',
        '../../../modules/load/pages/lisboa-viva/support-detail/support-detail.component.less',
        '../../../modules/shift/pages/documents/documents.component.less',
        '../../../modules/shift/pages/shift-resume/shift-resume.component.less']
})
export class PrimaryTableComponent implements OnInit {
    @Input() listOfColumn: object[] = [];
    @Input() headerDatas: TableHeaderData[] = [];
    @Input() showPagination = true;
    @Input() pageSize = '3';
    @Input() spanTotal: number;
    @Input() showTotal = false;
    @Input() showQt = false;
    @Input() total = 0;
    @Input() totalQt = 0;
    @Input() loading = false;
    @Input() isSingleSelection = false;
    @Input() isExc = false;
    @Input() href1: string = '';
    @Input() frontPagination: boolean = true;
    @Input() dataTotal: number;
    @Input() pageIndex: number = 1;
    @Input() tableHeadCSS: string = 'table-header';
    @Input() tableRowCSS: string = 'table-row';
    @Input() offset: string = DateUtils.getTimezone();


    @Output() callButtonClick = new EventEmitter();
    @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<
        boolean
    >();

    private list = [];
    selected = false;
    selectedId: number;

    constructor(private authorizationService: AuthorizationService, private router: Router) { }

    isSupportDetail() {
        this.href1 = this.router.url;

        return (this.href1 === '/load/support-detail');
    }

    ngOnInit(): void {
    }

    @Input()
    set listOfData(val: object[]) {
        this.list = val;

        if (typeof val !== 'undefined' && val.length > Number(this.pageSize)) {
            this.showPagination = true;
        }
    }

    get listOfData() {
        return this.list;
    }

    isExpired(i: number) {
        var key = "expiringDateFinish";
        var expiringDateValue: string;
        var today = new Date();

        expiringDateValue = this.listOfData[i][key];

        var id = this.listOfData[i]["id"];

        const expiringDate = new Date(expiringDateValue);

        return (expiringDateValue !== 'undefined' && expiringDate < today) || id == -1;

    }

    onButtonClick(event, btnCaption, data) {
        const btnInfo: any = {
            value: event.value,
            btnCaption,
            data,
        };
        this.callButtonClick.emit(btnInfo);
    }

    findType(config: TableHeaderDataConfig) {
        if (config?.type) {
            return config?.type;
        }
        if (config?.money) {
            return TableHeaderDataType.MONEY;
        }
        return TableHeaderDataType.TEXT;
    }

    changeCheckbox(event, id) {
        if (this.isSingleSelection) {
            if (event) {
                this.selectedId = id;
                this.selected = true;
            } else {
                this.selected = false;
            }
        }
        const itemSelectedInfo: any = {
            value: event,
            id,
        };
        this.isCheckedChange.emit(itemSelectedInfo);
    }

    onPageIndexChanged(event) {
        this.onPageChanged.emit(event);
    }

    isSelectedId(id: number) {
        return this.selectedId === id;
    }

    loadColorByData(data: any, colorMapping: TagColorMapping[]) {
        return colorMapping.find((it) => it.value === data).color;
    }

    loadLabelByData(data: any, colorMapping: TagColorMapping[]) {
        return colorMapping.find((it) => it.value === data).label;
    }

    isVisible(button: TableHeaderDataButton, data: any) {
        let visible = true;

        const jsonPath = require('jsonpath');

        if (button.checkVisibilityField && !jsonPath.value(data, '$.' + button.checkVisibilityField)) {
            visible = false;
        }
        switch (button.btnAction) {
            case SupportDetailActionEnum.cancel:
                visible = data.canCancel && this.authorizationService.hasPermission(button.role) ? true : false;
                break;
            case SupportDetailActionEnum.load:
                visible = data.canLoad && this.authorizationService.hasPermission(button.role) ? true : false;
                break;
            case SupportDetailActionEnum.exchange:
                visible = data.canExchange && this.authorizationService.hasPermission(button.role) ? true : false;
                break;
            case SupportDetailActionEnum.transfer:
                visible = data.canTransfer && this.authorizationService.hasPermission(button.role) ? true : false;
                break;
            case 'fineNotes': {
                visible = data.hasNotes;
            }
        }
        //TODO(Ruben) refatorizar para separar tabela para as multas

        return visible;
    }

}
