<div class="issue-replacement-guide-content">
  <!-- Caption-->
  <div nz-row nzJustify="left" *ngIf="!hasCardInfo()">
    <h1 class="reading">Sem Leitura</h1>
  </div>
  <div nz-row nzJustify="left" *ngIf="!hasCardInfo()">
    <h2 class="issue">Emitir guia de Substituição</h2>
  </div>
  <div nz-row nzJustify="left" *ngIf="hasCardInfo()">
    <h1 class="reading">Emitir guia de Substituição</h1>
  </div>

  <div nz-row nzJustify="left">
    <h2 class="search-label">Pesquisar por Lisboa VIVA/navegante personalizado</h2>
  </div>
  <!-- Search Method  -->
  <div nz-row nzAlign="top" nzJustify="start">
    <app-dropdown-button
      [label]="labelDropdown"
      labelTemplate="btn-icon icon-drop_down icon-dropdown-arrow"
      [listOptions]="dropdownList"
      (onSelect)="onChangedDropdown($event)"
      buttonStyle="dropdown"
      [isDisabled]="hasCardInfo()"
      width="150px"
      height="50px"
      ></app-dropdown-button>

    <div *ngIf="!hasCardInfo(); else disableInput">
      <app-primary-input-keyboard
        class="class-keyboard-input"
        [inputPlaceholder]="labelDropdown"
        [value]="serialNumber"
        (valueChange)="onChangedInput($event)"
        [isDisabled]="hasCardInfo()"
        inputCss="input-issue-replacement-number-search">
      </app-primary-input-keyboard>
    </div>

    <ng-template #disableInput>
      <app-primary-input-keyboard
        class="class-keyboard-input"
        [inputPlaceholder]="labelDropdown"
        [value]="serialNumber"
        (valueChange)="onChangedInput($event)"
        [isDisabled]="hasCardInfo()"
        inputCss="disable-input-serial-number">
      </app-primary-input-keyboard>
    </ng-template>

    <app-primary-normal-button
      class="button-search-issue-replacement-guide"
      (click)="searchBy(input)"
      [disabled]="hasCardInfo()"
    >
      <span class="btn-icon icon-pesquisar icon-search"></span>
    </app-primary-normal-button>
  </div>

  <div nz-row class="m-t-20 m-b-20">
    <label class="label">Selecione o título para o qual pretende emitir uma guia de
      substituição
    </label>
  </div>

  <!-- Table -->
  <div nz-row>
    <div nz-col [nzSpan]="24">
      <app-primary-table
        [listOfColumn]="listTitleColumn"
        [headerDatas]="listTitleHeader"
        [listOfData]="titlesDataTable"
        showPagination="false"
        pageSize="4"
        (callButtonClick)="changeEmitGuide($event)"
      >
      </app-primary-table>
    </div>
  </div>
</div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="4">
        <app-primary-white-button (click)="previousView()">
          <span class="btn-icon icon-anterior icon-middle float-left "></span>
          Voltar
        </app-primary-white-button>
    </div>
  </div>
</div>
