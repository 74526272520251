<div id="detailContent">
  <div nz-row nzJustify="left">
    <h1 class="info">Informação a cliente</h1>
  </div>
  <div nz-row>
    <div id="tariff-img" nz-col [nzSpan]="24"></div>
  </div>
</div>

<div class="footer-buttons">
  <div nz-row nzJustify="start">
    <div nz-col [nzSpan]="4">
      <app-primary-white-button
        class="previous-button"
        (click)="previousButton()">
        <span class="btn-icon icon-anterior icon-middle float-left"></span>
        Voltar
      </app-primary-white-button>
    </div>

    <div class="next-button" nz-col [nzSpan]="4">
      <app-primary-white-button (click)="nextButton()" buttonCSS="button-search">
        <span class="btn-icon icon-pesquisar icon-middle float-left"></span>
        Pesquisar
      </app-primary-white-button>
    </div>
  </div>
</div>
