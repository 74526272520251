
<div class="margin-right-20">

  <div nz-row>
    <h1>Gestão de Turnos</h1>
  </div>

  <div class="margin-bottom-10 margin-right-10">

    <div nz-row class="margin-bottom-10" nzJustify="start">

      <div nz-col [nzSpan]="6" class="align-left">
        <app-primary-input placeholder="Nº do Utilizador" height="40px" (change)="onChangeOwnerId($event?.target?.value)"></app-primary-input>
      </div>

      <div nz-col [nzSpan]="5" class="align-left">
        <span>Status </span>
        <app-primary-select
          [selected]="selectedStatus"
          [listOptions]="listStatus"
          (selectedChange)="onStatusChanged($event)"
        ></app-primary-select>
      </div>


      <div nz-col [nzSpan]="9" class="align-left">
        <span>De</span><span class="red">*</span>
        <app-date-picker height="40px" [data]="startDate" (valueChange)="onChangedStartDate($event)"
                         class="margin-left-5"></app-date-picker>
        <span class="margin-left-10">a</span><span class="red">*</span>
        <app-date-picker height="40px" [data]="endDate" (valueChange)="onChangedEndDate($event)"
                         class="margin-left-5"></app-date-picker>
      </div>

      <div nz-col [nzSpan]="3" class="align-right">
        <app-primary-normal-button width="50px" height="40px" (click)="loadData()">
          <i nz-icon nzType="search"></i>
        </app-primary-normal-button>
        <app-primary-normal-button color="red" width="50px" height="40px" (click)="clearSearch()" class="margin-left-5">
          <i nz-icon nzType="delete"></i>
        </app-primary-normal-button>
      </div>
    </div>

    <div nz-row>
      <div class="div-table-result" nz-col [nzSpan]="24">
        <app-primary-table
          [listOfColumn]="listTitleColumn"
          [headerDatas]="listTitleHeader"
          [listOfData]="listShiftData"
          (callButtonClick)="callEndShift($event)"
          pageSize="10">
        </app-primary-table>
      </div>
    </div>

  </div>

</div>

<div class="footer-buttons">
  <div nz-row nzJustify="start">
    <div nz-col [nzSpan]="4" class="margin-right-10">
      <app-primary-white-button (click)="previousView()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>
    <div nz-col [nzSpan]="4" class="margin-right-40">
      <app-primary-white-button (click)="homeClick()">
        Inicio
      </app-primary-white-button>
    </div>
  </div>
</div>
