<br>
<br>
<div nz-row class="margin-bottom-10">
    <!-- HEADER -->
    <div nz-col nzSpan="24" style="height: 90px;">
        <h1 class="title">
            Produtos Não Bilhética
        </h1>
        <div nz-row>
            <h3 class="tariff">Selecione o preço e quantidade do produto a adicionar ao carrinho de compras</h3>
        </div>
        <br>
    </div>
</div>

<div class="box-container">
    <div nz-row [nzGutter]="[15,15]" class="box">
        <div nz-col class="gutter-row align-vertically-top" nzSpan="6">
            <img [src]="imageUrl" alt="">
        </div>
        <div nz-col class="gutter-row" nzSpan="18">
            <div nz-row>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Descrição</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ info?.product }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Categoria&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ info?.categoryDescription }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row class="align-text-left">
                        <div nz-col>
                            <label class="product-info-header">Preço Venda a Público&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ normalPrice }} €</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row class="align-text-left">
                        <div nz-col>
                            <label class="product-info-header">Preço colaborador&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ collaboratorPrice }} €</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div nz-row [nzGutter]="[15,15]" class="box2" *ngIf="this.tariffSelected.campaignId != null">
        <div nz-col class="gutter-row" nzSpan="24">
            <div nz-row>
                <div nz-col class="gutter-row" [nzSpan]="10">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Cód. Campanha</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignCode }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="6">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Nome&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignName }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Desc. Campanha&nbsp;</label>
                        </div>
                    </div>
                    <div style="width: 60px; text-align: center;">
                        <a (click)="iconClicked()">
                            <img [src]="report" alt="" style="width: 40px; height: auto; margin-left: 50px;">
                        </a>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Data Início&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignStartDate }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="12">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Data Fim&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignEndDate }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row style="margin-top: 15px">
                <div nz-col class="gutter-row" [nzSpan]="6">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Condição&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignCondition }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="6">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Valor&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignValue }} €</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="6">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Quantidade&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignQuantity }}</label>
                        </div>
                    </div>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="6">
                    <div nz-row>
                        <div nz-col>
                            <label class="product-info-header">Desconto&nbsp;</label>
                        </div>
                    </div>
                    <div nz-row class="mgt-10">
                        <div nz-col class="value">
                            <label class="label product-info">{{ campaignDiscount }} %</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div nz-row class="button-group-grid mgt-20">
    <div nz-row class="margin-bottom-20">
        <label class="tariff">Preço</label>
    </div>
</div>
<div nz-row class="margin-bottom-20">
    <app-primary-radio-group class="button" class="divColor" [listOptions]="listTariffType"
        (onChanged)="onTariffTypeChanged($event)"></app-primary-radio-group>
</div>
<div nz-row class="margin-bottom-20">
    <label class="tariff">Quantidade</label>
</div>
<div nz-row>
    <div nz-col>
        <app-primary-white-button buttonCSS="button-copy" (click)="onNumCopyClick('-')">
            -
        </app-primary-white-button>
    </div>
    <div nz-col class="input-copy">
        <app-primary-input [(value)]="quantity" inputCSS="copy-number"></app-primary-input>
    </div>
    <div nz-col>
        <app-primary-white-button buttonCSS="button-copy" (click)="onNumCopyClick('+')">
            +
        </app-primary-white-button>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button (click)="previousView()">
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
        <div class="load" nz-col [nzSpan]="6">
            <app-primary-normal-button (click)="addToCart()" [disabled]="isDisabled">
                Adicionar ao carrinho
            </app-primary-normal-button>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isModalCampaignVisible" nzClosable="true" (nzOnCancel)="closeModal()" nzMaskClosable=false
    [nzFooter]=null nzWidth="900px">
    <div>
        <p class="modal-text">{{ reportModalMessage }}</p>
        <h3 class="modal-sub-report-text">{{ reportDefaultMessage }}</h3>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="isModalVisible" nzClosable="false" nzMaskClosable=false nzWidth="900px">
    <div class="container">
        <img [src]="imageArrow" alt="">
    </div>
    <div>
        <p class="modal-text">{{ modalMessage }}</p>
        <h3 class="modal-sub-text">{{ modalSubMessage }}</h3>
    </div>
    <ng-template [nzModalFooter]>
        <button nz-button nzType="default" (click)="handleAddAnotherProduct()" class="modal-nok-button">
            Adicionar outro produto
        </button>
        <button nz-button nzType="primary" (click)="goToShoppingCartPage()" class="modal-ok-button">
            Ir para carrinho de compras
        </button>
    </ng-template>
</nz-modal>