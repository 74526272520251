import { HttpStatusCode } from 'src/app/shared/enum/http-status-code';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { ShoppingCartService } from '../../../../core/services/shopping-cart.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { Navigation, Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { IssueReplacementGuideService } from 'src/app/core/services/issue-replacement-guide.service';
import { WebsocketMessage, WebsocketMessageType } from 'src/app/shared/models/websocket-message';
import { CardRead } from 'src/app/shared/models/card-read';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { SupportDetailService } from 'src/app/core/services/support-detail.service';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import NavigationManagerService, { TicketingRoutes } from '../../../../core/services/navigation-manager.service';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { SpinService } from '../../../../core/services/spin.service';
import { ReplacementGuideDTO } from '../replacement-guide/model/create-repl-guide-request';
import { HandlerComponent, SocketClientService } from '../../../../core/services/websocket-client.service';

@Component({
    selector: 'app-reading-loading-lv',
    templateUrl: './reading-loading-lv-guide-recover.component.html',
    styleUrls: ['./reading-loading-lv-guide-recover.component.less']
})
export class ReadingLoadingLvGuideRecoverComponent implements OnInit, OnDestroy, HandlerComponent {

    // Page Titles
    bigTitle: string;
    smallTitle: string;
    description: string;

    // Button title
    actionButtonTitle: string;

    // Replacement needed
    private mustReceivePayment: boolean;
    private goToShoppingCart: boolean;

    navigation: Navigation;
    private action: string;
    private cartRequest: ShoppingCartItemRequest;
    private titleRequest: LoadTitleRequest;

    cardRead: CardRead;
    isCancelDisabled = false;

    // obs
    alertText: string;

    public showCancelButton = false;

    constructor(
        private spinService: SpinService,
        private router: Router,
        private peripheralService: PeripheralService,
        private alertService: AlertService,
        private loadTitleService: LoadTitleService,
        private supportDetailService: SupportDetailService,
        private issueReplacementGuideService: IssueReplacementGuideService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private shoppingCartService: ShoppingCartService,
        private socketClient: SocketClientService
    ) {
        this.socketClient.setHandlerComponent(this);
        this.navigation = this.router.getCurrentNavigation();
    }

    ngOnInit(): void {
        // Websocket is shared by all components, but if somehow is not connected, lets wait for it to connect
        if (!this.socketClient.isConnected) {
            // Loading Screen set true until websocket connects (prevent user click on emit button too quickly)
            this.spinService.setSpinValue(true);
        }
        this.getPageTitles();
    }

    private getPageTitles() {
        this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
        switch (this.action) {
            case ReadingLoadingEnum.RecoverGuide:
                this.bigTitle = 'Devolver Guia de substituição';
                this.description = 'Por favor apresente um suporte Lisboa VIVA/navegante personalizado válido';
                this.smallTitle = 'Transferência de título da Guia de Substituição';
                this.alertText = 'Guia de Substituição devolvida com sucesso.';
                this.actionButtonTitle = 'Transferência de título';
                this.showCancelButton = true;
                break;
            case ReadingLoadingEnum.CleanReplacedCardContract:
                this.bigTitle = 'Devolver Guia de substituição';
                this.description = 'Guia de substituição';
                this.smallTitle = 'Por favor coloque o suporte original no leitor';
                this.alertText = 'O título da guia foi removido do cartão.';
                this.actionButtonTitle = 'Limpar suporte';
                this.showCancelButton = false;
                break;
        }
    }

    ngOnDestroy() {
        this.spinService.setSpinValue(false);
        this.storageManager.session.remove(ReadingLoadingEnum.Action);
        this.storageManager.session.remove(ReadingLoadingEnum.CustomersDetails);
        this.storageManager.session.remove(ReadingLoadingEnum.LoadContractRequest);
        this.storageManager.session.remove(ReadingLoadingEnum.ShoppingCartRequest);
        this.storageManager.session.remove(ReadingLoadingEnum.FromReadingEmitGuide);
        this.storageManager.session.remove(ReadingLoadingEnum.CreateReplGuideRequest);
        this.storageManager.session.remove(CardEnum.PREVIOUS_CARD_BINARY);
        this.storageManager.session.remove(CardEnum.PREVIOUS_FULL_CARD_DETAILS);
    }

    async actionButtonClick() {
        this.isCancelDisabled = true;
        this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
        await this.peripheralService.detectCard()
            .then(async (response) => {
                if (response.success) {
                    const cardBinary = response.data as CardDecodeResponse;
                    this.storageManager.session.set(CardEnum.CARD_DETECTION_BINARY, cardBinary);
                    this.spinService.setSpinValue(true);
                    await this.peripheralService.startCardScan().toPromise()
                        .then(async (read) => {
                            if (!read.success) {
                                this.spinService.setSpinValue(false);
                            }
                        })
                        .catch(error => {
                            this.spinService.setSpinValue(false);
                            this.alertService.error(CommonEnum.msgReadCardError);
                        });
                }
            })
            .catch((error: any) => {
                const errorMessage = error.status === HttpStatusCode.NOT_FOUND ||
                    error.status === HttpStatusCode.INTERNAL_SERVER_ERROR
                    ? CommonEnum.msgDetectCardError
                    : CommonEnum.msgWriteCardError + error.message;
                this.alertService.error(errorMessage);
                this.spinService.setSpinValue(false);
            });
    }

    async handleMessage(messageBody: WebsocketMessage) {
        // Update page titles
        this.getPageTitles();
        // Handle message
        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId)
                    .toPromise();
                if (!response.success) {
                    this.isCancelDisabled = false;
                    this.alertService.error(response.detail);
                }
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (messageBody.content) as CardDetailsMessage;
                if (cardDetails.details.environment === undefined) {
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
                    switch (this.action) {
                        case ReadingLoadingEnum.RecoverGuide:
                            // cardDetails and cardBinary is from VV (new card)
                            this.storageManager.session.set(CardEnum.FULL_CARD_DETAILS, cardDetails);
                            const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
                            this.cartRequest = this.storageManager.session.get(
                                ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                            this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                            const deliveryGuide = this.storageManager.session.get(
                                ReadingLoadingEnum.CreateReplGuideRequest) as ReplacementGuideDTO;

                            this.titleRequest.serialNumber = cardBinary.lowPart.toString();
                            this.titleRequest.cardNumber = cardDetails.details.environment.issuing_number.toString();

                            this.goToShoppingCart = deliveryGuide.warranty;
                            this.mustReceivePayment = false;

                            if (CardUtils.IsVV(cardDetails.details.environment.card_data_model)) {
                                this.spinService.setSpinValue(false);
                                this.isCancelDisabled = false;
                                this.alertService.error(CommonEnum.msgLVCardError);
                            }

                            if (deliveryGuide === {} as ReplacementGuideDTO) {
                                this.spinService.setSpinValue(false);
                                this.isCancelDisabled = false;
                                this.alertService.error(CommonEnum.msgCardWriteGenericError);
                            }

                            const replacementResponse = await this.callReturnReplacementWorkflow(deliveryGuide, cardBinary);

                            if (replacementResponse.success) {
                                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.CleanReplacedCardContract);
                                this.spinService.setSpinValue(false);
                            }
                            this.getPageTitles();
                            break;
                        case ReadingLoadingEnum.CleanReplacedCardContract:
                            const replacedCardBinary = this.storageManager.session.get(CardEnum.PREVIOUS_CARD_BINARY) as CardDecodeResponse;
                            const replacedCardFullDetails = this.storageManager.session
                                .get(CardEnum.PREVIOUS_FULL_CARD_DETAILS) as CardDetailsMessage;
                            const guideContractLoadRequest = this.storageManager.session
                                .get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                            if (cardDetails?.low_part === replacedCardBinary?.lowPart &&
                                cardDetails?.high_part === replacedCardBinary?.highPart) {

                                const contracts = replacedCardFullDetails.details.contracts;
                                let contractSlot: number;
                                for (let i = 0; i < contracts.length; i++) {
                                    if (contracts[i].ticket_code === guideContractLoadRequest.tickCode) {
                                        contractSlot = i + 1;
                                    }
                                }

                                const nullifyResponse = await this.shoppingCartService.null(contractSlot, replacedCardBinary);
                                if (nullifyResponse) {
                                    // if annulled replacement then we need to check if:
                                    // title was really annuled or if title is still in the card
                                    // otherwise show error message and stay in this page
                                    this.storageManager.session
                                        .set(ReadingLoadingEnum.Action, ReadingLoadingEnum.CheckIfCleanCardContractWasSucessful);
                                    await this.peripheralService.detectCard()
                                        .then(async (responseDetect) => {
                                            if (responseDetect.success) {
                                                await this.peripheralService.startCardScan().toPromise();
                                            } else {
                                                this.spinService.setSpinValue(false);
                                                this.alertService.info(responseDetect.message);
                                            }
                                        })
                                        .catch((error: any) => {
                                            this.spinService.setSpinValue(false);
                                            this.alertService.error(CommonEnum.msgDetectCardError);
                                        });
                                }
                            } else {
                                this.spinService.setSpinValue(false);
                                this.alertService.error(CommonEnum.msgCardContractDeleteRequest);
                            }
                            break;
                        case ReadingLoadingEnum.CheckIfCleanCardContractWasSucessful:
                            const contractLoadRequest = this.storageManager.session
                                .get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                            const contractList = cardDetails.details.contracts;
                            let nullSuccessful = true;
                            const titleLoadedInCard = contractList.find((contract) =>
                                contract.ticket_code === contractLoadRequest.tickCode
                                && contract.operator_code === contractLoadRequest.tickOperCode);

                            if (titleLoadedInCard != null) {
                                nullSuccessful = false;
                                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.CleanReplacedCardContract);
                                this.alertService.error(CommonEnum.msgCleaningCardError);
                            }
                            if (nullSuccessful) {
                                await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                            }
                            this.spinService.setSpinValue(false);
                            break;
                        default:
                            this.alertService.error(CommonEnum.msgUnknownActionError + this.action);
                            break;
                    }
                }
                break;
        }
    }

    async callReturnReplacementWorkflow(createReplGuideRequest: ReplacementGuideDTO, binary: CardDecodeResponse) {
        return await this.issueReplacementGuideService
            .callReturnReplacementWorkflow(createReplGuideRequest, this.titleRequest, this.cartRequest,
                binary, this.mustReceivePayment).toPromise()
            .catch(() => {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgCardWriteGenericError);
            });
    }

    async navigateBack() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    onConnect(): void {
        // Disable loading after websocket connected and ready (prevent error of user click too quickly on recover guide button)
        this.spinService.setSpinValue(false);
    }
}
