import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {SiitEntity} from "../../modules/operation/modules/siit-entity";
import {BasicDataResponse} from "../../shared/models/basic-data-response";

@Injectable({
    providedIn: 'root',
})
export class SiitEntityService {

    constructor(private httpClient: HttpClient) {
    }

    public async fetchAllEnabledSiitEntities() {
        return await this.httpClient.get(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/siit/entity/all/enabled`).toPromise();
    }

}
