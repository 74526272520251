import { TariffPdfComponent } from './pages/tariff-pdf/tariff-pdf.component';
import { VoucherSelectionComponent } from './../operation/pages/voucher-selection/voucher-selection.component';
import { VoucherDetailsComponent } from './pages/voucher-details/voucher-details.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInfoComponent } from './pages/customer-info/customer-info.component';
import { OperationRoutingModule } from './operation-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PaymentMultibancoComponent } from './pages/payment-multibanco/payment-multibanco.component';
import { PaymentMoneyComponent } from './pages/payment-money/payment-money.component';
import { PaymentMultipleComponent } from './pages/payment-multiple/payment-multiple.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { OperationSummaryComponent } from './pages/operation-summary/operation-summary.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { NewRequisitionComponent } from './pages/requisition/lisboa-viva/new-requisition/new-requisition.component';
import { RequisitionLvHistoryComponent } from './pages/requisition/lisboa-viva/requisition-lv-history/requisition-lv-history.component';
import { RequisitionDetailsComponent } from './pages/requisition/lisboa-viva/requisition-details/requisition-details.component';
import { RequisitionComponent } from './pages/requisition/lisboa-viva/requisition/requisition.component';
import { InitialScreenComponent } from './pages/replacement-guide/initial-screen/initial-screen.component';
import { RecoverReplacementGuideComponent } from './pages/replacement-guide/recover-replacement-guide/recover-replacement-guide.component';
import { IssueReplacementGuideComponent } from './pages/replacement-guide/issue-replacement-guide/issue-replacement-guide.component';
import { SubstitutionGuideComponent } from './pages/replacement-guide/substitution-guide/substitution-guide.component';
import { RecoveryDetailsComponent } from './pages/replacement-guide/recovery-details/recovery-details.component';
import { VoucherCancelComponent } from './pages/voucher-cancel/voucher-cancel.component';
import { VoucherLoadComponent } from './pages/voucher-load/voucher-load.component';
import { AuthorizationChargeComponent } from './pages/authorization-charge/authorization-charge.component';
import { ReadingLoadingLvComponent } from './pages/reading-loading-lv/reading-loading-lv.component';
import { TitleLoadingComponent } from './pages/replacement-guide/title-loading/title-loading.component';
import { DirectivesModule } from '../directives/directives.module';
import { DeliverReplacementGuideDetailsComponent } from 'src/app/modules/operation/pages/replacement-guide/deliver-replacement-guide-details/deliver-replacement-guide-details.component';
import { NzModalModule } from 'ng-zorro-antd';
import { PaymentSiitComponent } from './pages/payment-siit/payment-siit.component';
import {ReadingLoadingLvTransferComponent} from "./pages/reading-loading-lv-transfer/reading-loading-lv-transfer.component";
import {ReadingLoadingLvGuideEmitComponent} from "./pages/reading-loading-lv-guide-emit/reading-loading-lv-guide-emit.component";
import {ReadingLoadingLvGuideRecoverComponent} from "./pages/reading-loading-lv-guide-recover/reading-loading-lv-guide-recover.component";
import {FinesComponent} from "./pages/fines/fines.component";
import { FineRegisterComponent } from './pages/fine-register/fine-register.component';
import { PaymentCreditComponent } from './pages/payment-credit/payment-credit.component';
import { PaymentPrePaymentComponent } from './pages/payment-pre-payment/payment-pre-payment.component';
import { NonTicketingProductsComponent } from './pages/non-ticketing-products/non-ticketing-products';
import { NonTicketingProductsSelectComponent } from './pages/non-ticketing-products-select/non-ticketing-products-select';
import { NonTicketingProductsDetailsComponent } from './pages/non-ticketing-products-detail/non-ticketing-products-detail';
import { NonTicketingProductsHistoryComponent } from './pages/non-ticketing-products-history/non-ticketing-products-history.';

@NgModule({
  declarations: [
    CustomerInfoComponent,
    ShoppingCartComponent,
    PaymentMultibancoComponent,
    PaymentMoneyComponent,
    PaymentMultipleComponent,
    PaymentSiitComponent,
    InvoiceComponent,
    ReadingLoadingLvComponent,
    ReadingLoadingLvTransferComponent,
    ReadingLoadingLvGuideEmitComponent,
    ReadingLoadingLvGuideRecoverComponent,
    OperationSummaryComponent,
    RequisitionComponent,
    NewRequisitionComponent,
    RequisitionDetailsComponent,
    TariffPdfComponent,
    VoucherSelectionComponent,
    VoucherDetailsComponent,
    InitialScreenComponent,
    RecoverReplacementGuideComponent,
    IssueReplacementGuideComponent,
    SubstitutionGuideComponent,
    RecoveryDetailsComponent,
    RequisitionLvHistoryComponent,
    VoucherCancelComponent,
    VoucherLoadComponent,
    AuthorizationChargeComponent,
    TitleLoadingComponent,
    DeliverReplacementGuideDetailsComponent,
    FinesComponent,
    FineRegisterComponent,
    PaymentCreditComponent,
    PaymentPrePaymentComponent,
    NonTicketingProductsComponent,
    NonTicketingProductsSelectComponent,
    NonTicketingProductsDetailsComponent,
    NonTicketingProductsHistoryComponent
  ],
  imports: [
    ReactiveFormsModule,
    NzGridModule,
    NzFormModule,
    NzInputModule,
    FormsModule,
    CommonModule,
    OperationRoutingModule,
    SharedModule,
    NzAffixModule,
    NzTabsModule,
    NzDropDownModule,
    NzTableModule,
    NzIconModule,
    NgxCurrencyModule,
    DirectivesModule,
    NzModalModule
  ],
})
export class OperationModule {}
