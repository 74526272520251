<div nz-row>
  <div *ngFor="let item of data" class="m-5">
    <span [appHideIfUnauthorised]="item.role">
      <app-secondary-white-button [buttonSize]="buttonSize" [disabled]=item.disabled (onClick)="onButtonClick($event)" [value]="item">
        <div *ngIf="item.name.length < 22; else elseBlock">
          <p>{{ item.name }}</p>
        </div>
        <ng-template #elseBlock>
          <p class="string-to-big">{{ item.name }}</p>
        </ng-template>
      </app-secondary-white-button>
    </span>
  </div>
</div>
