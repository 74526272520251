<div>

<div class="top-div">
  <div>
    <h1>{{bigTitle}}</h1>
  </div>
  <div>
    <b>{{smallTitle}}</b>
  </div>
</div>


<div nz-row class="m-b-60">
  <div nz-col [nzSpan]="24" id="cartoes-img"></div>
</div>
<div  nz-row nzJustify="center"><label>{{description}}</label></div>

<div class="footer-buttons" nz-row nzJustify="start" *ngIf="showCancelButton">
  <app-primary-white-button
  (click)="navigateBack()"
  [disabled] = "wait"
  >CANCELAR</app-primary-white-button>
</div>

</div>
