import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appHideIfChildProfile]'
})
export class HideIfChildProfileDirective implements OnInit {

  private CHILD_PROFILE_ID = 2;

  @Input('appHideIfChildProfile') profilesId: Array<number>;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this?.profilesId?.includes(this.CHILD_PROFILE_ID)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
  
}
