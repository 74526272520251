<nz-date-picker 
nzFormat="dd/MM/yyyy" 
[style.height]="height" 
[style.width]="width" 
style="border-radius: 6px 6px 6px 6px;" 
[(ngModel)]="data"
(ngModelChange)="onChange($event)" 
[nzDisabledDate]="disabledDate"
onkeydown="return false">
</nz-date-picker>
