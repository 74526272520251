import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {HeaderShoppingCartComponent} from './header/shopping-cart/header-shopping-cart.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [HeaderComponent, HeaderShoppingCartComponent],
  imports: [
    CommonModule,
    NzGridModule,
    NzIconModule,
    NzDropDownModule,
    SharedModule
  ],
  exports: [HeaderComponent]
})

export class CoreModule {  // Ensure that CoreModule is only loaded once
}
