<nz-modal [(nzVisible)]="isModalVisible" (nzOnCancel)="closeModal($event)">
    <div nz-row>
      <div nz-col nzSpan="24">
        <h1>{{valueText}}</h1>
      </div>
    </div>

    <ng-template [nzModalFooter]>
      <div nz-row>
        <div nz-col nzSpan="24">
          <app-primary-normal-button (click)="onClickButton($event)" >Confirmar</app-primary-normal-button>
        </div>
      </div>
  </ng-template>
</nz-modal>