import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-operation-resume',
  templateUrl: './operation-resume.component.html',
  styleUrls: ['./operation-resume.component.less']
})
export class OperationResumeComponent implements OnInit {
  @Input() valueText = '';

  @Input() isModalVisible: boolean;
  @Output() isModalVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onClickConfirm = new EventEmitter<any>();
  @Output() onCloseModal = new EventEmitter<any>();

  onClickButton(event) {
    this.onClickConfirm.emit(event);
  }

  closeModal(event) {
    this.onCloseModal.emit(event);
  }

  constructor() { }

  ngOnInit(): void { }

}
