import { Component, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ShoppingCart } from '../../modules/shopping-cart';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { SiitEntity } from '../../modules/siit-entity';
import { DropdownItem, PaymentSiitComponent } from '../payment-siit/payment-siit.component';
import { BasicDataResponse } from '../../../../shared/models/basic-data-response';
import { SiitEntityService } from '../../../../core/services/siit-entity.service';
import { EnvironmentUtil } from 'src/environments/environment-util';

@Component({
    selector: 'app-money',
    templateUrl: './payment-multiple.component.html',
    styleUrls: ['./payment-multiple.component.less'],
})
export class PaymentMultipleComponent implements OnInit {
    public static BLANK = '';
    payAll = false;
    btnNextDisable = true;
    siitFieldAllHaveValue = false;
    navigation: Navigation;
    paymentForm: FormGroup;
    shoppingCart: ShoppingCart = { payment: {} } as ShoppingCart;
    showButtonExceptProd = false;

    siitEntities: SiitEntity[] = [];
    selectedSiitEntity: SiitEntity;
    dropdownItems: DropdownItem[] = [];

    listResult: any[] = [];
    public total = 0;
    private totalMoney = 0;
    private totalMultibanco = 0;
    private totalSIIT = 0;
    public totalSum = 0;
    totalPayed = 0;

    couponCode = '';

    // criar o service
    totalRemaining = 0;
    totalReturn = 0;

    constructor(
        private formBuilder: FormBuilder,
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private siitEntityService: SiitEntityService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService) {

        this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
        this.total = this.shoppingCart.totalToPay / 100;
        this.totalRemaining = this.total;

        this.getEntities();

        this.shoppingCartService.shoppingCartChange.subscribe(
            (data) => {
                if (data != null && data.showHeader) {
                    this.listResult = data.listResult;
                    this.total = data.totalToPay / 100;
                }
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            }
        );
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.showButtonExceptProd = !EnvironmentUtil.isProd();
        this.paymentForm = this.formBuilder.group({
            money: new FormControl('', {
                validators: [],
            }),
            multibanco: new FormControl('', {
                validators: [],
            }),
        });
    }

    private async getEntities() {
        const entitiesResponse = this.siitEntityService.fetchAllEnabledSiitEntities();
        entitiesResponse.then((response: BasicDataResponse) => {
            if (response?.success && response?.data) {
                this.siitEntities = response.data;
                this.dropdownItems = response.data.map((entity: SiitEntity) => {
                    return {
                        value: entity.id,
                        label: entity.name
                    };
                });
            }
        });
    }

    async goToHome() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async onPreviousClick() {
        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
    }

    async onNextClick() {
        this.shoppingCart.couponCode = this.couponCode;
        this.shoppingCart.siitEntityId = this.selectedSiitEntity?.id;
        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        await this.navigationManager.go(TicketingRoutes.INVOICE);
    }

    public paymentMoneyChange(event: string): void {
        // it's necessary to replace comma for dot because visually it's necessary to be a comma
        // but we need a dot so total can be recognized as a number
        this.totalMoney = Number(event.replace(',', '.'));
        this.calcTotals();
    }

    public paymentMultibancoChange(event: string): void {
        // it's necessary to replace comma for dot because visually it's necessary to be a comma
        // but we need a dot so total can be recognized as a number
        this.totalMultibanco = Number(event.replace(',', '.'));
        this.calcTotals();
    }

    public paymentSIITChange(event: string): void {
        // it's necessary to replace comma for dot because visually it's necessary to be a comma
        // but we need a dot so total can be recognized as a number
        this.totalSIIT = Number(event.replace(',', '.'));
        this.calcTotals();
    }

    private calcTotals(): void {
        this.totalSum = Number(this.totalMultibanco || 0) + Number(this.totalMoney || 0) + Number(this.totalSIIT || 0);
        this.totalSum = Math.round((this.totalSum + Number.EPSILON) * 100) / 100;
        const totalToPay = this.total;

        if (this.totalSum === totalToPay) {
            this.totalRemaining = 0;
            this.totalReturn = this.totalSum - totalToPay;
            this.shoppingCart.payment.amountTpa = Math.round((this.totalMultibanco || 0) * 100);
            this.shoppingCart.payment.amountMoney = Math.round((this.totalMoney || 0) * 100);
            this.shoppingCart.payment.amountOther = Math.round((this.totalSIIT || 0) * 100);
            // client has to receive change
            if (this.totalReturn > 0) {
                // money is negative because is the change that client has to receive
                this.shoppingCart.payment.amountMoney = (Math.round(this.totalMoney || 0) - Math.round(this.totalReturn)) * 100;
            }
            this.payAll = true;
        } else if (this.totalSum > totalToPay) {
            this.alertService.info(CommonEnum.msgTotalPaymentBiggerThanTotalShoppingCart);
            this.totalRemaining = 0;
            this.totalReturn = this.totalSum - totalToPay;
            this.payAll = false;
        } else {
            this.totalReturn = 0;
            this.totalRemaining = totalToPay - this.totalSum;
            this.payAll = false;
        }
    }

    couponCodeOnChange(event: any) {
        this.couponCode = event;
    }

    public disableButton() {
        if (this.payAll && (this.seeIfAllSIITFieldsHaveValue())) {
            this.btnNextDisable = false;
        } else if (this.seeIfAllSIITFieldsHaveValue()) {
            this.btnNextDisable = true;
        } else if (this.seeIfAllSIITFieldsHaveValue() === false && this.payAll) {
            this.btnNextDisable = true;
        } else {
            this.btnNextDisable = true;
        }
        return this.btnNextDisable;
    }

    private seeIfAllSIITFieldsHaveValue() {
        if ((PaymentSiitComponent.BLANK !== this.couponCode && (this.totalSIIT === 0 || !this.selectedSiitEntity))
            || (this.totalSIIT !== 0 && (PaymentSiitComponent.BLANK === this.couponCode || !this.selectedSiitEntity))
            || (this.selectedSiitEntity && (PaymentSiitComponent.BLANK === this.couponCode || this.totalSIIT === 0))) {
            this.siitFieldAllHaveValue = false;
        } else {
            this.siitFieldAllHaveValue = true;
        }
        return this.siitFieldAllHaveValue;
    }

    public handleDropdownSelect(value): void {
        this.selectedSiitEntity = this.siitEntities.find((entity) => entity.id === value);
    }

}
