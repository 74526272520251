<div>
    <div class="top-div">
        <div>
            <h1>{{bigTitle}}</h1>
        </div>
        <div>
            <b>{{smallTitle}}</b>
        </div>
    </div>


    <div nz-row class="m-b-60">
        <div nz-col [nzSpan]="24" id="cartoes-img"></div>
    </div>

    <div nz-col>
        <div nz-row nzJustify="center">
            <label class="p-b-30 font-size-25">{{description}}</label>
        </div>
        <div nz-row nzJustify="center">
            <app-primary-white-button
                    width="fit-content"
                    (click)="actionButtonClick()"
                    [disabled]="false"
            >{{ actionButtonTitle }}
            </app-primary-white-button>
        </div>
    </div>

    <div class="footer-buttons" nz-row nzJustify="start" *ngIf="showCancelButton" style="width: fit-content">
        <app-primary-white-button
                (click)="navigateBack()"
                [disabled]="isCancelDisabled"
        >CANCELAR
        </app-primary-white-button>
    </div>
</div>
