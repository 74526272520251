<!-- Marco Moreira 07/07/2020-->
<div class="title-loading-content">
  <!-- Caption-->
  <div nz-row nzJustify="left" class="issue-replacement-guide-row">
    <h1 class="loading">Carregamento de Título</h1>
  </div>
  <div nz-row nzJustify="left">
    <h2 class="issue">
      Carregamento de Navegante Metropolitano em Guia de Substituição
    </h2>
  </div>

  <div nz-row class="m-t-30">
    <div nz-col [nzSpan]="24" id="cartoes-img"></div>
  </div>

  <div>
    <h1 class="issue-guide m-t-30">
      Por favor apresente uma guia de substituição não localizada
    </h1>
  </div>

</div>
<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="4">
      <app-primary-cancel-button (click)="homeScreen()">
        Cancelar
      </app-primary-cancel-button>
    </div>
    <div nz-col nzSpan="7">
      <div nz-row style="padding-right: 48px" nzJustify="end">
        <app-primary-normal-button>Outras operações</app-primary-normal-button>
      </div>
    </div>
  </div>
</div>
