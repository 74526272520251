import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-operation-summary',
  templateUrl: './operation-summary.component.html',
  styleUrls: ['./operation-summary.component.less']
})
export class OperationSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
