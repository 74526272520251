<!-- Marco Moreira 07/07/2020-->
<div class="detailContent">
    <!-- Caption-->
    <div nz-row nzJustify="start" class="issue-replacement-guide-row">
        <h1>Troca de título electrónico - Anulação de título</h1>
    </div>
    <div nz-row nzJustify="start">
        <p><strong>Por favor confirme os detalhes do título a ser trocado</strong></p>
    </div>

    <!-- Details-->
    <div nz-row class="issue-replacement-guide-row">
        <div nz-col nzSpan="4">
            <div nz-row nzJustify="start">
                <h3>Anular de</h3>
            </div>
            <div nz-row nzJustify="start">
               <p>{{ data[0].expiringDateStart | date: 'dd/MM/yyyy' }}</p>
            </div>
        </div>
        <div nz-col nzSpan="4">
            <div nz-row nzJustify="start">
                <h3>Até</h3>
            </div>
            <div nz-row nzJustify="start">
                  <p>{{ data[0].expiringDateFinish | date: 'dd/MM/yyyy' }}</p>
            </div>
        </div>
    </div>

    <!-- Table -->
    <div>
        <app-primary-table
              [listOfColumn]="listTitleColumn"
              [headerDatas]="listTitleHeader"
              showTotal="true"
              showPagination="false"
              spanTotal="2"
              total="{{ data[0].titlePrice * -1 }}"
              [listOfData]="data"
              >
        </app-primary-table>
    </div>

     <!-- Navigation -->
    <div class="footer-buttons">
      <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="12">
          <div nz-row nzJustify="start">
            <app-primary-white-button (click)="previousView()">
              <span class="btn-icon icon-anterior icon-middle float-left "></span>
              Anterior
            </app-primary-white-button>
          </div>
        </div>
        <div nz-col [nzSpan]="12">
          <div nz-row nzJustify="end" style="padding-right: 48px">
            <app-primary-normal-button
            width="180px"
            (click)="nextView()">
              Seguinte
              <span class="btn-icon icon-seguinte icon-middle float-right"></span>
            </app-primary-normal-button>
          </div>
        </div>
      </div>
    </div>

</div>


