import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'src/app/core/guards/auth.guard';
import {SupportDetailComponent} from './pages/lisboa-viva/support-detail/support-detail.component';
import {TitleSelectionComponent} from './pages/lisboa-viva/title-selection/title-selection.component';
import {TitleDetailsComponent} from './pages/lisboa-viva/title-details/title-details.component';
import {ChangeTitleComponent} from './pages/lisboa-viva/change-title/change-title.component';
import {CardHistoryComponent} from './pages/lisboa-viva/card-history/card-history.component';
import {AnnulTitleComponent} from './pages/lisboa-viva/annul-title/annul-title.component';
import { ZappingComponent } from './pages/zapping/zapping.component';
import { TicketComponent } from './pages/ticket/ticket.component';

const routes: Routes = [
  {
    path: 'load/support-detail',
    component: SupportDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/title-selection',
    component: TitleSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/title-details',
    component: TitleDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/change-title',
    component: ChangeTitleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/card-history',
    component: CardHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/annul-title',
    component: AnnulTitleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/zapping',
    component: ZappingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'load/ticket',
    component: TicketComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChargeRoutingModule {}
