<div class="m-t-50">
<app-primary-title title="Início de Turno" subTitle="Bilheteira"></app-primary-title>
</div>
<div nz-row nzJustify="center" class="m-t-80">
  <app-primary-input-keyboard label="NºUtilizador" inputCss="login-input" labelCss="login-label"
  inputIcon="icon-utilizador login-icon" inputBorder="login-border" (valueChange)="usernameInputChange($event)" (enterClick)="login()">
  </app-primary-input-keyboard>
</div>
<div nz-row nzJustify="center" class="m-t-40">
  <app-primary-input-keyboard inputType="password" label="Palavra-passe" inputCss="login-input"
    labelCss="login-label" inputIcon="icon-login_password login-icon"
    inputBorder="login-border" (valueChange)="passwordInputChange($event)" (enterClick)="login()"></app-primary-input-keyboard>
</div>
<div nz-row nzJustify="center" class="m-t-40">
  <app-primary-normal-button
    buttonCSS="login-button"
    (click)="login()">
    Entrar
  </app-primary-normal-button>
</div>
<nz-modal [(nzVisible)]="isModalVisible" nzClosable=false nzMaskClosable=false>
  <div>
    <p class="modal-text">{{modalMessage}}</p>
    <h3 class="modal-sub-text">{{modalSubMessage}}</h3>
  </div>
  <ng-template [nzModalFooter]>
    <button nz-button nzType="default" (click)="handleCancelStartShift()" class="modal-nok-button">
      Não
    </button>
    <button nz-button nzType="primary" (click)="handleStartShift()" [nzLoading]="isConfirmLoading" class="modal-ok-button">
      Sim
    </button>
  </ng-template>
</nz-modal>
