import { PrimaryTableButton } from './components/primary-table-button/primary-table-button.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimaryButtonComponent} from './components/primary-button/primary-button.component';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {PrimarySelectComponent} from './components/primary-select/primary-select.component';
import {FormsModule} from '@angular/forms';
import {PrimaryRadioGroupComponent} from './components/primary-radio-group/primary-radio-group.component';
import {AlertModalComponent} from './components/alert-modal/alert-modal.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {PrimaryTableComponent} from './components/primary-table/primary-table.component';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzFormModule} from 'ng-zorro-antd/form';
import {PrimaryNormalButtonComponent} from './components/primary-normal-button/primary-normal-button.component';
import {PrimaryListButtonComponent} from './components/primary-list-button/primary-list-button.component';
import {NzListModule} from 'ng-zorro-antd/list';
import {PrimaryInputComponent} from './components/primary-input/primary-input.component';
import {NzInputModule} from 'ng-zorro-antd/input';
import {PrimaryCheckboxComponent} from './components/primary-checkbox/primary-checkbox.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {DropdownButtonComponent} from './components/dropdown-button/dropdown-button.component';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {ChangeMoneyComponent} from './components/change-money/change-money.component';
import {PrimaryTextAreaComponent} from './components/primary-text-area/primary-text-area.component';
import {DateTimePickerComponent} from './components/datetime-picker/date-time-picker.component';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {OperationResumeComponent} from './components/operation-resume/operation-resume.component';
import {NzTagModule} from 'ng-zorro-antd';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {PrimaryWhiteButtonComponent} from './components/primary-white-button/primary-white-button.component';
import {PrimaryRectangleButtonComponent} from './components/primary-rectangle-button/primary-rectangle-button.component';
import {PrimaryPreviousButtonComponent} from './components/primary-previous-button/primary-previous-button.component';
import {PrimaryCancelButtonComponent} from './components/primary-cancel-button/primary-cancel-button.component';
import {SecondaryWhiteButtonComponent} from './components/secondary-white-button/secondary-white-button.component';
import {SecondarySecondButtonComponent} from './components/secondary-second-button/secondary-second-button.component';
import {DirectivesModule} from '../modules/directives/directives.module';
import {PrimaryTitleComponent} from './components/primary-title/primary-title/primary-title.component';
import {PrimaryInputKeyboardComponent} from './components/primary-input-keyboard/primary-input-keyboard.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import {TitleDetailsTableComponent} from "./components/title-details-table/title-details-table.component";
import { PrimaryLargeButtonComponent } from './components/primary-large-button/primary-large-button.component';
import { PrimaryListButtonNonTicketingComponent } from './components/primary-list-button-non-ticketing/primary-list-button-non-ticketing.component';
import { PrimaryRectangleButtonAnnulComponent } from './components/primary-rectangle-button-annul/primary-rectangle-button-annul.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    TitleDetailsTableComponent,
    PrimaryButtonComponent,
    PrimarySelectComponent,
    PrimaryRadioGroupComponent,
    PrimaryTableComponent,
    AlertModalComponent,
    PrimaryNormalButtonComponent,
    PrimaryListButtonComponent,
    PrimaryListButtonNonTicketingComponent,
    PrimaryRectangleButtonAnnulComponent,
    PrimaryInputComponent,
    PrimaryCheckboxComponent,
    DropdownButtonComponent,
    ChangeMoneyComponent,
    PrimaryTextAreaComponent,
    DateTimePickerComponent,
    DatePickerComponent,
    OperationResumeComponent,
    PrimaryWhiteButtonComponent,
    PrimaryRectangleButtonComponent,
    PrimaryPreviousButtonComponent,
    PrimaryCancelButtonComponent,
    SecondaryWhiteButtonComponent,
    SecondarySecondButtonComponent,
    PrimaryTitleComponent,
    PrimaryInputKeyboardComponent,
    PrimaryTableButton,
    PrimaryLargeButtonComponent
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzAlertModule,
    NzSelectModule,
    NzRadioModule,
    NzTableModule,
    NzTagModule,
    FormsModule,
    NzFormModule,
    NzIconModule,
    NzModalModule,
    NzListModule,
    NzInputModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzInputModule,
    NzDatePickerModule,
    DirectivesModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    PrimaryButtonComponent,
    PrimarySelectComponent,
    PrimaryRadioGroupComponent,
    PrimaryTableComponent,
    AlertModalComponent,
    PrimaryNormalButtonComponent,
    PrimaryListButtonComponent,
    PrimaryListButtonNonTicketingComponent,
    PrimaryRectangleButtonAnnulComponent,
    PrimaryInputComponent,
    PrimaryCheckboxComponent,
    DropdownButtonComponent,
    ChangeMoneyComponent,
    PrimaryTextAreaComponent,
    DateTimePickerComponent,
    DatePickerComponent,
    OperationResumeComponent,
    PrimaryWhiteButtonComponent,
    PrimaryRectangleButtonComponent,
    PrimaryPreviousButtonComponent,
    PrimaryCancelButtonComponent,
    SecondaryWhiteButtonComponent,
    SecondarySecondButtonComponent,
    PrimaryTitleComponent,
    PrimaryInputKeyboardComponent,
    PrimaryTableButton,
    PrimaryLargeButtonComponent,
    TitleDetailsTableComponent
  ],
})
export class SharedModule {
}
