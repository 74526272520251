import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-tariff-pdf',
  templateUrl: './tariff-pdf.component.html',
  styleUrls: ['./tariff-pdf.component.less'],
})
export class TariffPdfComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  previousButton() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['home'], navigationExtras);
  }

  nextButton() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['customer-info'], navigationExtras);
  }
}
