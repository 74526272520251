import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Navigation, Router} from '@angular/router';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {AlertService} from 'src/app/core/services/alert.service';
import {ShiftService} from '../../../../core/services/shift.service';
import {Shift, ShiftStatus, ShiftStatusHelper} from '../shift-resume/model/shift-response';
import {TableHeaderDataType} from '../../../../shared/models/table-header-data-config';

@Component({
  selector: 'app-card-history',
  templateUrl: './shift-admin.component.html',
  styleUrls: ['./shift-admin.component.less']
})
export class ShiftAdminComponent implements OnInit {

  navigation: Navigation = this.router.getCurrentNavigation();
  startDate = new Date();
  endDate = new Date();

  listTitleColumn: TableColumn[] = [];
  listTitleHeader: TableHeaderData[] = [];
  listShiftData: Shift[] = [];
  selectedStatus: any;
  listStatus = ShiftStatusHelper.findAll();
  selectedOwnerId: any;

  constructor(private router: Router,
              private location: Location,
              private shiftService: ShiftService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.endDate.setHours(23, 59, 0);
    this.startDate.setDate(this.endDate.getDate() - 60);
    this.startDate.setHours(0, 0, 0);

    this.prepareTableResult();
    this.loadData();
  }

  onChangedStartDate(value: Date) {
    this.startDate = value;
  }

  onChangedEndDate(value: Date) {
    this.endDate = value;
  }

  prepareTableResult() {
    this.listTitleColumn = [
      {title: 'Status', config: {colAlign: 'left', colWidth: '15%'}},
      {title: 'Id do Utilizador', config: {colAlign: 'left', colWidth: '15%'}},
      {title: 'Nº do Documento', config: {colAlign: 'left', colWidth: '15%'}},
      {title: 'Data de Inicio', config: {colAlign: 'left', colWidth: '20%'}},
      {title: 'Data de Termino', config: {colAlign: 'left', colWidth: '20%'}},
      {title: 'Ações', config: {colAlign: 'left', colWidth: '15%'}},
    ];
    this.listTitleHeader = [
      {
        visible: true,
        name: 'status',
        config: {
          type: TableHeaderDataType.TAG,
          colorMapping: [
            {
              value: ShiftStatus.ACTIVE,
              color: 'green',
              label: ShiftStatusHelper.findLabel(ShiftStatus.ACTIVE)
            },
            {
              value: ShiftStatus.SUSPEND,
              color: 'yellow',
              label: ShiftStatusHelper.findLabel(ShiftStatus.SUSPEND)
            },
            {
              value: ShiftStatus.FINISHED,
              color: 'blue',
              label: ShiftStatusHelper.findLabel(ShiftStatus.FINISHED)
            },
          ]
        },
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'ownerId',
        config: null,
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'documentNumber',
        config: null,
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'startDate',
        config: {type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy HH:mm'},
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'endDate',
        config: {type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy HH:mm'},
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'checkLoadButton',
        config: {valAlign: 'center'},
        button: {titleButton: 'Finalizar', checkVisibilityField: 'canFinish'}
      } as TableHeaderData,
    ];
  }

  loadData() {
    this.shiftService.adminShifts.subscribe(
      (data: any) => {
        data.items.forEach(it => it.canFinish = it.status !== ShiftStatus.FINISHED);
        this.listShiftData = data.items;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

    const request = {
      start: this.startDate.getTime(),
      end: this.endDate.getTime(),
      status: this.selectedStatus,
      ownerId: this.selectedOwnerId,
    };

    this.shiftService.getShiftsForAdmin(request);
  }

  clearSearch() {
    window.location.reload();
  }

  previousView() {
    this.location.back();
  }

  homeClick() {
    this.router.navigate(['home']);
  }

  onStatusChanged($event: any) {
    this.selectedStatus = $event;
  }

  onChangeOwnerId(value: any) {
    console.log(value);
  }

  callEndShift($event: any) {
    console.log($event);
  }
}
