import {Directive, ElementRef, Inject, Input, OnChanges, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appUnassignedLVAlertMessage]'
})
export class UnassignedLVAlertMessageDirective implements OnChanges {

  @Input('appUnassignedLVAlertMessage') isCardCustomized = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document) {
  }

  async ngOnChanges() {
    if (this.isCardCustomized) {

      const boldElement = document.createElement('b');
      boldElement.innerHTML = 'Cartão não personalizado';

      const paragraphElement = document.createElement('p');
      paragraphElement.id = 'unassigned-lv';
      paragraphElement.style.color = 'darkred';
      paragraphElement.style.textAlign = 'left';
      paragraphElement.style.fontSize = '20px';
      paragraphElement.style.font = 'normal normal normal 26px/38px Roboto';
      paragraphElement.style.textTransform = 'uppercase';

      this.renderer.appendChild(paragraphElement, boldElement);
      this.renderer.appendChild(this.elementRef.nativeElement, paragraphElement);

    } else {

      const paragraphElement = document.getElementById('unassigned-lv');

      if (paragraphElement) {
        paragraphElement.remove();
      }

    }
  }

}
