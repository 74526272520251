<div>
    <div nz-row class="voucher-cancel-row">
        <div nz-col nzSpan="24" class=" align-text-left">
            <h1>Anulação de venda de produto</h1>
            <h3>Por favor confirme os detalhes do título a anular.</h3>
        </div>
    </div>
    <div nz-row class="voucher-cancel-row">
        <div nz-col nzSpan="24">
            <app-primary-table [listOfColumn]="listResultColumn" [headerDatas]="listResultHeader"
                [listOfData]="data ? data : []" showPagination="false" showTotal="true" total={{total(data)}} >
            </app-primary-table>
        </div>
    </div>
    <div class="footer-buttons">
        <div  nz-row>
            <div nz-col nzSpan="12" class="align-text-left">
            <app-primary-white-button
              (click)="onClickCancel()">
              <span class="btn-icon icon-anterior icon-middle float-left "></span>
              Voltar
            </app-primary-white-button>
          </div>
          <div nz-col nzSpan="12" class="align-text-right">
            <app-primary-normal-button
              [critical] = "true"
              (click)="scanCard()"
              [disabled]="data && data.length >= 1 ? null : true"
              buttonCSS="button-load">
              Anular
              <span class="btn-icon icon-seguinte icon-middle float-right"></span>
            </app-primary-normal-button>
          </div>
        </div>
      </div>
</div>
