import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableHeaderData} from '../../models/table-header-data';
import {TableHeaderDataConfig, TableHeaderDataType,} from '../../models/table-header-data-config';

declare const require: any;

@Component({
    selector: 'title-details-table',
    templateUrl: './title-details-table.component.html',
    styleUrls: ['./title-details-table.component.less']
})
export class TitleDetailsTableComponent implements OnInit {
    @Input() listOfColumn: object[] = [];
    @Input() headerDatas: TableHeaderData[] = [];

    @Input() loading = false;
    @Input() isSingleSelection = false;
    @Input() isExc = false;
    @Input() pageIndex: number = 1;
    @Input() tableHeadCSS: string = 'table-header';
    @Input() tableRowCSS: string = 'table-row';

    @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<
        boolean
    >();

    private list = [];
    selected = false;
    selectedId: number;

    constructor() { }

    ngOnInit(): void { }

    @Input()
    set listOfData(val: object[]) {
        this.list = val;
    }

    get listOfData() {
        return this.list;
    }

    findType(config: TableHeaderDataConfig) {
        if (config?.type) {
            return config?.type;
        }
        if (config?.money) {
            return TableHeaderDataType.MONEY;
        }
        if (config?.percent) {
            return TableHeaderDataType.PERCENT;
        }
        return TableHeaderDataType.TEXT;
    }

    changeCheckbox(event, id) {
        if (this.isSingleSelection) {
            if (event) {
                this.selectedId = id;
                this.selected = true;
            } else {
                this.selected = false;
            }
        }
        const itemSelectedInfo: any = {
            value: event,
            id,
        };
        this.isCheckedChange.emit(itemSelectedInfo);
    }

    onPageIndexChanged(event) {
        this.onPageChanged.emit(event);
    }

    isSelectedId(id: number) {
        return this.selectedId === id;
    }

}
