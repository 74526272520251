import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShiftService} from 'src/app/core/services/shift.service';
import {ShoppingCartService} from 'src/app/core/services/shopping-cart.service';
import {FamilyPassVoucher} from 'src/app/shared/models/family-pass-voucher';
import {AlertService} from 'src/app/core/services/alert.service';
import {ShoppingCart} from 'src/app/modules/operation/modules/shopping-cart';
import {Load} from 'src/app/modules/load/pages/lisboa-viva/models/load';
import {FamilyPassVoucherService} from 'src/app/core/services/family-pass-voucher.service';
import {VoucherSelection} from 'src/app/shared/models/voucher-selection';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {TableColumn} from 'src/app/shared/models/table-column';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {SpinService} from '../../../../core/services/spin.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { HttpStatusCode } from 'src/app/shared/enum/http-status-code';
import { WebsocketMessage, WebsocketMessageType } from 'src/app/shared/models/websocket-message';
import { HandlerComponent, SocketClientService } from 'src/app/core/services/websocket-client.service';

@Component({
    selector: 'app-title-details',
    templateUrl: './voucher-details.component.html',
    styleUrls: ['./voucher-details.component.less'],
})
export class VoucherDetailsComponent implements OnInit, OnDestroy, HandlerComponent {

    load: Load;
    card: CardDetailsMessage;

    familyPass: VoucherSelection = {} as VoucherSelection;
    shoppingCart: ShoppingCart = {} as ShoppingCart;

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    data = [];

    disabled: boolean;
    isLoadingVoucher: boolean = false;

    constructor(
        private spinService: SpinService,
        private navigationManager: NavigationManagerService,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private familyPassVoucherService: FamilyPassVoucherService,
        private peripheralService: PeripheralService,
        private storageManager: StorageManagerService,
        private socketClient: SocketClientService
    ) {
        this.socketClient.setHandlerComponent(this);
        if (this.navigationManager.getNavigation()?.extras?.state?.load && this.navigationManager.getNavigation()?.extras?.state?.card) {
            this.load = this.navigationManager.getNavigation().extras.state.load;
            this.card = this.navigationManager.getNavigation().extras.state.card;
            this.data.push({titleDescription: this.load.titleDescription, value: this.load.total});
            this.prepareTable();
        } else {
            if (!this.isLoadingVoucher) {
            this.navigationManager.go(TicketingRoutes.HOME);
            }
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }
    onConnect(): void {
       this.spinService.setSpinValue(false);
    }
    ngOnDestroy() {
        this.storageManager.session.remove(CardEnum.CARD_BINARY_DETECT_VOUCHER);
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
    }

    onHomeClick() {
        this.navigationManager.go(TicketingRoutes.HOME);
    }

    onPreviousClick() {
        this.navigationManager.go(TicketingRoutes.VOUCHER_SELECTION, {load: this.load, card: this.card, requestLv: this.familyPass});
    }

    async familyPassVoucher() {
        this.spinService.setSpinValue(true);
        this.isLoadingVoucher = true;
        const currentCardBinary = this.storageManager.session.get(CardEnum.CARD_BINARY_DETECT_VOUCHER) as CardDecodeResponse;
        const request: FamilyPassVoucher = {
            cardSerialNumber: Number(this.load.cardRead.serialNumber),
            tickCode: this.load.tickCode,
            tickOperCode: this.load.tickOperCode,
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.load.titleId,
            description: this.load.titleDescription,
            quantity: 1,
            price: this.load.total
        };

        if (!VoucherDetailsComponent.isSameCardAsSupportDetailsRead(Number(this.load.cardRead.serialNumber), currentCardBinary.lowPart)) {
            this.alertService.error(CommonEnum.msgCardReadIsNotTheOriginal, true);
            this.disabled = true;
        } else {
            await this.familyPassVoucherService.insert(request).then(async response => {
                if (response && response.success) {
                    await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                } else {
                    this.isLoadingVoucher = false;
                    this.alertService.error(response.message);
                }
            });
            this.spinService.setSpinValue(false);
        }
    }

    async scanCard() {
        await this.peripheralService.detectCard()
        .then(async (response) => {
            if (response.success) {
                const cardBinary = response.data as CardDecodeResponse;
                this.storageManager.session.set(CardEnum.CARD_BINARY_DETECT_VOUCHER, cardBinary);
                this.spinService.setSpinValue(true);
                await this.peripheralService.startCardScan().toPromise()
                    .then(async (read) => {
                        if (!read.success) {
                            this.spinService.setSpinValue(false);
                        }
                    })
                    .catch(error => {
                        this.spinService.setSpinValue(false);
                        this.alertService.error(CommonEnum.msgReadCardError);
                    });
            }
        })
        .catch((error: any) => {
            const errorMessage = error.status === HttpStatusCode.NOT_FOUND ||
                error.status === HttpStatusCode.INTERNAL_SERVER_ERROR
                ? CommonEnum.msgDetectCardError
                : CommonEnum.msgWriteCardError + error.message;
            this.alertService.error(errorMessage);
            this.spinService.setSpinValue(false);
        });
    }

    async handleMessage(messageBody: WebsocketMessage) {
        // Handle message
        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId)
                    .toPromise();
                if (!response.success) {
                    this.alertService.error(response.detail);
                }
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (messageBody.content) as CardDetailsMessage;
                if (cardDetails.details.environment === undefined) {
                    this.spinService.setSpinValue(false);
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    await this.familyPassVoucher();
                }
                break;
        }
    }

    public static isSameCardAsSupportDetailsRead(supportDetailsSerialNumber: number, currentReadSerialNumber: number): boolean {
        return supportDetailsSerialNumber == currentReadSerialNumber;
    }

    prepareTable() {
        this.listTitleColumn = [
            {title: 'TÍTULO', config: {colAlign: 'left', colWidth: '50%'}},
            {title: 'VALOR', config: {colAlign: 'right', colWidth: '50%'}},
        ];
        this.listTitleHeader = [
            {visible: false, name: 'id', config: null, button: null} as TableHeaderData,
            {visible: true, name: 'titleDescription', config: null, button: null} as TableHeaderData,
            {visible: true, name: 'value', config: {valAlign: 'right', money: true}, button: null} as TableHeaderData,
        ];
    }

}


