<app-primary-title
  title="SEM TURNO"
  subTitle="Selecione uma operação"
></app-primary-title>

<div *ngIf="!isQaEnvironment(); else showQAImage">
  <div nz-row class="m-b-60">
    <div nz-col [nzSpan]="24" id="cartoes-img"></div>
  </div>
</div>

<ng-template #showQAImage>
  <div nz-row class="m-b-60">
    <div nz-col [nzSpan]="24" id="cartoes-qa"></div>
  </div>
</ng-template>

<div nz-row class="button-group-grid">
    <div nz-col [nzSpan]="24">
        <span>
            <app-primary-button class="buttons" buttonCSS="home-generic-button" (click)="previousShifts()">
                Turnos Anteriores
            </app-primary-button>
        </span>

        <span *ngIf="showButtonExceptProd && canConsultAndEditSuspendedShifts">
            <app-primary-button class="buttons" buttonCSS="home-generic-button" (click)="suspendedShifts()">
                Turnos Suspensos
            </app-primary-button>
        </span>

        <span>
            <app-primary-button class="buttons" buttonCSS="home-generic-button" (click)="documentsHistory()">
                Histórico de Documentos
            </app-primary-button>
        </span>

        <span *ngIf="canConsultOrEditRequisitions">
            <app-primary-button class="buttons" buttonCSS="home-generic-button" (click)="requisitionHistory()">
                Histórico de Requisições
            </app-primary-button>
        </span>
        <span>
            <app-primary-button buttonCSS="home-information-button m-t-10" class="button" (click)="logout()">
                Terminar Sessão
            </app-primary-button>
        </span>
    </div>
</div>
