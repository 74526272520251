
<div class="shift-resume-content">
  <div class="shift-resume-row">
    <h1 class="shift m-b-0">Turno</h1>
  </div>
  <div nz-row>
    <div nz-col [nzSpan]="16">
      <h3 class="shift-init">Início de Turno: {{ getStartDate() }}</h3>
    </div>
    <div nz-col [nzSpan]="6">
      <div class="print-div">
        <app-primary-rectangle-button class="print" (click)="printShiftResume()">
          Imprimir Resumo de Turno
        </app-primary-rectangle-button>
      </div>
    </div>
  </div>

  <div nz-row class="shift-resume-row">
    <div style="width: 100%; margin-bottom: 25px">
      <app-primary-table
        class="table"
        [loading]="resumeLoading"
        [listOfColumn]="listResultColumnS"
        [headerDatas]="listResultHeaderS"
        [listOfData]="saleItemResumeShift ? saleItemResumeShift : []"
        pageSize="16"
        [showPagination]="false"
        [frontPagination]="false"
        tableRowCSS="table-row-shift-resume"
      >
      </app-primary-table>
    </div>
  </div>
</div>
<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col [nzSpan]="8">
      <div nz-row nzJustify="start">
        <div nz-col [nzSpan]="5">
          <app-primary-white-button (click)="toHomeScreen()">
            <span class="btn-icon icon-anterior icon-middle float-left"></span>
            Voltar
          </app-primary-white-button>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="16">
      <div nz-row nzJustify="end" style="padding-right: 40px">
        <div nz-col [nzSpan]="6">
          <app-primary-white-button (click)="previousDocuments()">
            Histórico Documentos
          </app-primary-white-button>
        </div>
        <div nz-col [nzSpan]="6">
          <app-primary-white-button (click)="previousShift()">
            Turnos <br />Anteriores
          </app-primary-white-button>
        </div>
      </div>
    </div>
  </div>
</div>
