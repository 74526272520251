export enum WebsocketMessageType {

  ExchangeApdu = 'EXCHANGE_APDU',
  CardDetails = 'CARD_DETAILS',
  PrintReceipt = 'PRINT_RECEIPT'

}

export interface WebsocketMessage {
  type: WebsocketMessageType;
  requestId: string;
  content: any;
}
