import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Directive({
  selector: '[appHideIfNoContent]'
})
export class HideIfNoContentDirective implements OnInit {

  @Input('appHideIfNoContent') permissions: AuthorityName[];

  constructor(private el: ElementRef,
              private authorizationService: AuthorizationService) { }

  ngOnInit() {
    if (!this.authorizationService.hasOneOf(this.permissions)) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
