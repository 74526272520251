import { Injectable } from '@angular/core';
import { AuthorityName } from 'src/app/shared/models/authority-name';
import { JwtUtils } from 'src/app/shared/utils/jwt-utils';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    permissions: AuthorityName[];
    error: any;

    constructor() { }

    public hasPermission(permission: AuthorityName): boolean {
        return new JwtUtils().getRoles()?.filter(it => it === permission)?.length > 0;
    }

    public hasOneOf(permissions: AuthorityName[]): boolean {
        this.permissions = new JwtUtils().getRoles();
        return permissions.filter(value => this.permissions.includes(value)).length > 0;
    }

}

