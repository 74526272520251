<div class="margin-left-30">
    <div nz-row>
        <h1>Anulação de título</h1>
    </div>
    <div nz-row class="margin-top-10">
        <p><strong>Por favor confirme os detalhes do(s) item(s) para anulação e/ou devolução</strong></p>
    </div>
    <div *ngIf="!isZapping() && !this.isSingleTicket">
        <div nz-row class="margin-top-10 align-left blue">
            <div nz-col nzSpan="3">
                <b>Anular de</b>
            </div>
            <div nz-col nzSpan="3" nzOffset="1">
                <b>até</b>
            </div>
        </div>
        <div nz-row class="align-left">
            <div nz-col nzSpan="3">
                <b>{{ validityStart | date: 'dd/MM/yyyy' }}</b>
            </div>
            <div nz-col nzSpan="3" nzOffset="1">
                <b>{{ validityEnd | date : 'dd/MM/yyyy' }}</b>
            </div>
        </div>
        <div *ngIf="isTUD(this.title)" nz-row class="margin-top-20 align-left blue">
            <div nz-col nzSpan="3">
                <b>Taxas</b>
            </div>
            <div nz-col nzSpan="3" nzOffset="1">
                <b>Dias Utilizados</b>
            </div>
        </div>
        <div *ngIf="isTUD(this.title)" nz-row class="align-left">
            <div nz-col nzSpan="3">
                <app-primary-checkbox *ngIf="isDisabled" label="Taxa de Utilização" isChecked="false" isDisabled="true">
                </app-primary-checkbox>
                <app-primary-checkbox *ngIf="!isDisabled" label="Taxa de Utilização" isChecked="true"
                    (isCheckedChange)="onCheck($event)">
                </app-primary-checkbox>
            </div>
            <div nz-col nzSpan="3" nzOffset="1">
                <b>{{ daysUsed }}</b>
            </div>
        </div>
    </div>
    <div nz-row class="margin-top-20">
        <div nz-col [nzSpan]="24">
            <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader"
                [listOfData]="listOfData" showPagination="false" pageSize="5">
            </app-primary-table>
        </div>
    </div>
</div>
<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col nzSpan="12" style="text-align: left;">
            <app-primary-white-button style="border-radius: 6px" (click)="previousScreen()">
            <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>

        <div>
            <app-primary-normal-button (click)="next()">Confirmar</app-primary-normal-button>
        </div>
    </div>
</div>
