export interface CardDetailsMessage {
  card_family: CardFamily;
  card_physical_type: CardPhysicalType;
  high_part: number;
  low_part: number;
  details: CardDetails;
}

export interface CardEnvironment {
  app_issuer: number;
  card_data_model: CardDataModel;
  country_id: number;
  expiry_date: number;
  issuing_date: number;
  issuing_number: number;
  money_country: number;
  network_id: number;
  pay_method: number;
  struct_version: number;
}

export interface CardReadEnvironment {
    issueDate: Date;
    expiryDate: Date;
    isExpired: boolean;
    isReplacement: boolean;
    isLisboaViva: boolean;
    isVivaViagem: boolean;
    isInitialized: boolean;
    cardNumber: number;
    serialNumber: number;
    slotsFull: boolean;
    owner: string;
}

export interface CardReadProfile {
    id: number;
    name: string;
    expiryDate: Date;
    isExpired: boolean;
}

export interface CardDetails {
  contracts: CardContract[];
  environment: CardEnvironment;
  last_event: CardEvent;
  number_of_contracts: number;
  profile: CardProfile;
  ratification: number;
}

export interface CardEvent {
  best_contracts: BestContract[];
  contracts_used: number;
  event_date_time: number;
  event_type: CardEventType;
  first_contract: number;
  first_contract_two: number;
  first_date_time: number;
  first_date_time_two: number;
  hist_rfu: number;
  hist_rfu1: number;
  hist_rfu2: number;
  hist_rfu3: number;
  hist_rfu4: number;
  interchange: number;
  operator_code: number;
  route_code: number;
  run_code: number;
  stop_index: number;
  stop_sub_index: number;
  validator_id: number;
  vehicle_id: number;
}

export interface BestContract {
  contract_priority: number;
  contract_status: ContractStatus;
}

export interface CardProfile {
  birth_date: number[];
  company: number;
  holder_number: number;
  profile_one_code: number;
  profile_one_expiry_date: number;
  profile_two_code: number;
  profile_two_expiry_date: number;
  profile_three_code: number;
  profile_three_expiry_date: number;
  profile_four_code: number;
  profile_four_expiry_date: number;
  reduction_rate: number;
  rfu: number;
  owner: string;
}

export interface CardContract {
  id?: number; // titleID needed to transfer
  counter: ContractCounter;
  duration_days: number;
  duration_hours: number;
  interchange: ContractInterchange;
  operator_code: number;
  spatial_validities: SpatialValidity[];
  struct_version: number;
  temporal_validity: TemporalValidity;
  ticket_class: number;
  ticket_code: number;
  ticket_date: number;
  ticket_machine: number;
  ticket_num_daily: number;
  ticket_reload_change: number;
  trip_limit_type: TripLimitType;
  usage: number;
  validity_duration_type: ValidityDurationType;
  validity_periods: number;
  load_priority: number;
}

export interface SpatialValidity {
  spatial_type: SpatialType;
  validity: SpatialValidityUnion;
}

// tslint:disable-next-line:no-empty-interface
export interface SpatialValidityUnion {

}

export interface TemporalValidity {
  start_date: number;
  start_time: number;
  value: number;
  temporal_type: TemporalType;
}

export interface ContractCounter {
  counter_type: CounterType;
  date: number;
  rfu: number;
  rfu_value: number;
  value: number;
}

export interface CardLastEventMessages {
    lastEvent?: string;
    secondLastEvent?: string;
}

/**************************************
 * ENUMS
 *************************************/

export enum SpatialType {
  K_SPATIAL_VALIDITY_EMPTY = 0,
  K_SPATIAL_VALIDITY_ZONE_MONO,
  K_SPATIAL_VALIDITY_ZONE_MULTI,
  K_SPATIAL_VALIDITY_ROUTE_MONO,
  K_SPATIAL_VALIDITY_PARKING,
  K_SPATIAL_VALIDITY_EXTEND_ZONE_MULTI,
  K_SPATIAL_VALIDITY_OPERATOR_SPECIFIC_SERVICE
}

export enum TemporalType {
  K_TEMPORAL_VALIDITY_NO_LIMIT = 0,
  K_TEMPORAL_VALIDITY_DAYS_LIMIT,
  K_TEMPORAL_VALIDITY_MONTHS_LIMIT,
  K_TEMPORAL_VALIDITY_HOURS_LIMIT
}

export enum CounterType {
  K_COUNTER_DATE_VALUE = 0,
  K_COUNTER_VALUE = 1,
  K_COUNTER_VALUE_RFU = 2,
  K_COUNTER_DATE_VALUE_RFU = 3,
  K_COUNTER_DATE_STORED_VALUE = 4
}

export enum ValidityDurationType {
  K_VALIDITY_DURATION_END_EXPLOITATION = 0,
  K_VALIDITY_DURATION_END_SERVICE,
  K_VALIDITY_DURATION_IN_HOURS,
  K_VALIDITY_DURATION_IN_DAYS
}

export enum TripLimitType {
  K_CONTRACT_TRIP_LIMIT_TYPE_NONE = 0,
  K_CONTRACT_TRIP_LIMIT_TYPE_TRIP_NUMBERS,
  K_CONTRACT_TRIP_LIMIT_TYPE_FARE_ZONES,
  K_CONTRACT_TRIP_LIMIT_TYPE_STORED_VALUE_5CENT,
  K_CONTRACT_TRIP_LIMIT_TYPE_ROUND_TRIP,
  K_CONTRACT_TRIP_LIMIT_TYPE_TEMPORAL_DURATION,
  K_CONTRACT_TRIP_LIMIT_TYPE_TRIP_NUMBERS_RFU,
  K_CONTRACT_TRIP_LIMIT_TYPE_STORED_VALUE_1CENT,
}

export enum ContractInterchange {
  K_CONTRACT_INTERCHANGE_NOT_AUTH = 0,
  K_CONTRACT_INTERCHANGE_AUTH
}

export enum CardValidityState {
  T_CARD_VALIDITY_STATE_VALID = 0,
  T_CARD_VALIDITY_STATE_INVALIDATED
}

export enum CardEventType {
  K_EVENT_TYPE_IN_S = 0,
  K_EVENT_TYPE_EN_T = 1,
  K_EVENT_TYPE_EN_P = 2,
  K_EVENT_TYPE_RE_T = 3,
  K_EVENT_TYPE_EX_T = 4,
  K_EVENT_TYPE_EX_P = 5,
  K_EVENT_TYPE_PS_T = 6,
  K_EVENT_TYPE_RL_T = 7,
  K_EVENT_TYPE_RC_T = 10,
  K_EVENT_TYPE_EN_TR = 13,
  K_EVENT_TYPE_EX_TR = 14,
  K_EVENT_TYPE_EN_P_LV = 16,
  K_EVENT_TYPE_EX_P_LV = 17,
}

export enum CardFamily {
  K_CARD_FAMILY_INNOV_PROTOCOL_MASK = 0x01,
  K_CARD_FAMILY_ISOB_PROTOCOL_MASK = 0x02,
  K_CARD_FAMILY_ISOA_PROTOCOL_MASK = 0x04,
  K_CARD_FAMILY_CTX_MASK = 0x08,
  K_CARD_FAMILY_CONTACT_PROTOCOL_MASK = 0x10,
  K_CARD_FAMILY_MIFARE_MASK = 0x20,
  K_CARD_FAMILY_SRI_MASK = 0x40
}

export enum CardPhysicalType {
  K_CARD_TYPE_GTML = 0,
  K_CARD_TYPE_GTML2,
  K_CARD_TYPE_CTS_512B,
  K_CARD_TYPE_CDLIGHT,
  K_CARD_TYPE_CD97,
  K_CARD_TYPE_CT2000,
  K_CARD_TYPE_CTS_256B,
  K_CARD_TYPE_MIFARE_UL,
  K_CARD_TYPE_SR176,
  K_CARD_TYPE_CD21,
  K_CARD_TYPE_MIFARE_1K,
  K_CARD_TYPE_MIFARE_4K,
  K_CARD_TYPE_SRIX_512B,
  K_CARD_TYPE_SRI_512B,
  K_CARD_TYPE_SRT_512B,
  K_CARD_TYPE_MODS_512,
  K_CARD_TYPE_MODS_2K,
  K_CARD_TYPE_SR4K,
  K_CARD_TYPE_MAGNETIC,
  K_CARD_TYPE_PAYPASS,
  K_CARD_TYPE_TANGO,
  K_CARD_TYPE_CALYPSO_REV3,
  K_CARD_TYPE_SRI_2K,
  K_CARD_TYPE_HCE,
  K_CARD_TYPE_MIFARE_UL_EV1_11,
  K_CARD_TYPE_MIFARE_UL_EV1_21,
  K_CARD_TYPE_CLAP,
  K_CARD_TYPE_MIFARE_DESF_EV1,
  K_CARD_TYPE_MAX,
  K_CARD_TYPE_UNKNOWN = 0xFF
}

export enum CardDataModel {
  K_CARD_DATAMODEL_UNKNOWN = 0,
  K_CARD_DATAMODEL_LISBOA_VIVA_V_0 = 1,
  K_CARD_DATAMODEL_SETE_COLINAS_V_0 = 2,
  K_CARD_DATAMODEL_SUBSTITUTION_GUIDE_V_0 = 3,
  K_CARD_DATAMODEL_LISBOA_CARD_V_0 = 4,
  K_CARD_DATAMODEL_VIVA_V_1 = 5,
  K_CARD_DATAMODEL_TIP_V_1 = 6,
  K_CARD_DATAMODEL_TIP_TICK_V_1 = 7,
  K_CARD_DATAMODEL_TIP_TICK256_V_1 = 8,
  K_CARD_DATA_MODEL_MAX = 9
}

export enum ContractStatus {
  K_CONTRACT_NOT_EXPIRED = 0,
  K_CONTRACT_EXPIRED,
  K_CONTRACT_STATUS_UNKNOWN
}

export enum NullContract {
    NULL_CONTRACT_ID = 0
}
