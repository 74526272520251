import { TitleLoadingComponent } from './pages/replacement-guide/title-loading/title-loading.component';
import { TariffPdfComponent } from './pages/tariff-pdf/tariff-pdf.component';
import { VoucherSelectionComponent } from './pages/voucher-selection/voucher-selection.component';
import { VoucherDetailsComponent } from './pages/voucher-details/voucher-details.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CustomerInfoComponent } from './pages/customer-info/customer-info.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { PaymentMultibancoComponent } from './pages/payment-multibanco/payment-multibanco.component';
import { PaymentMoneyComponent } from './pages/payment-money/payment-money.component';
import { PaymentSiitComponent } from './pages/payment-siit/payment-siit.component';
import { PaymentMultipleComponent } from './pages/payment-multiple/payment-multiple.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { OperationSummaryComponent } from './pages/operation-summary/operation-summary.component';
import { RequisitionDetailsComponent } from './pages/requisition/lisboa-viva/requisition-details/requisition-details.component';
import { NewRequisitionComponent } from './pages/requisition/lisboa-viva/new-requisition/new-requisition.component';
import { RequisitionLvHistoryComponent } from './pages/requisition/lisboa-viva/requisition-lv-history/requisition-lv-history.component';
import { RequisitionComponent } from './pages/requisition/lisboa-viva/requisition/requisition.component';
import { InitialScreenComponent } from './pages/replacement-guide/initial-screen/initial-screen.component';
import { RecoverReplacementGuideComponent } from './pages/replacement-guide/recover-replacement-guide/recover-replacement-guide.component';
import { IssueReplacementGuideComponent } from './pages/replacement-guide/issue-replacement-guide/issue-replacement-guide.component';
import { SubstitutionGuideComponent } from './pages/replacement-guide/substitution-guide/substitution-guide.component';
import { FamilyPassComponent } from '../load/pages/lisboa-viva/family-pass/family-pass.component';
import { RecoveryDetailsComponent } from './pages/replacement-guide/recovery-details/recovery-details.component';
import { VoucherCancelComponent } from './pages/voucher-cancel/voucher-cancel.component';
import { VoucherLoadComponent } from './pages/voucher-load/voucher-load.component';
import { AuthorizationChargeComponent } from './pages/authorization-charge/authorization-charge.component';
import { ReadingLoadingLvComponent } from './pages/reading-loading-lv/reading-loading-lv.component';
import {
    DeliverReplacementGuideDetailsComponent
} from 'src/app/modules/operation/pages/replacement-guide/deliver-replacement-guide-details/deliver-replacement-guide-details.component';
import {ReadingLoadingLvTransferComponent} from './pages/reading-loading-lv-transfer/reading-loading-lv-transfer.component';
import {ReadingLoadingLvGuideEmitComponent} from './pages/reading-loading-lv-guide-emit/reading-loading-lv-guide-emit.component';
import {ReadingLoadingLvGuideRecoverComponent} from './pages/reading-loading-lv-guide-recover/reading-loading-lv-guide-recover.component';
import {FinesComponent} from './pages/fines/fines.component';
import {FineRegisterComponent} from './pages/fine-register/fine-register.component';
import { PaymentCreditComponent } from './pages/payment-credit/payment-credit.component';
import { PaymentPrePaymentComponent } from './pages/payment-pre-payment/payment-pre-payment.component';
import { NonTicketingProductsComponent } from './pages/non-ticketing-products/non-ticketing-products';
import { NonTicketingProductsSelectComponent } from './pages/non-ticketing-products-select/non-ticketing-products-select';
import { NonTicketingProductsDetailsComponent } from './pages/non-ticketing-products-detail/non-ticketing-products-detail';
import { NonTicketingProductsHistoryComponent } from './pages/non-ticketing-products-history/non-ticketing-products-history.';

const routes: Routes = [
  {
    path: 'customer-info',
    component: CustomerInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shopping-cart',
    component: ShoppingCartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-multibanco',
    component: PaymentMultibancoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-money',
    component: PaymentMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-siit',
    component: PaymentSiitComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-credit',
    component: PaymentCreditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-pre-payment',
    component: PaymentPrePaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-multiple',
    component: PaymentMultipleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fines',
    component: FinesComponent,
    canActivate: [AuthGuard],
  },
  {
      path: 'fines/register',
      component: FineRegisterComponent,
      canActivate: [AuthGuard],
  },
  {
    path: 'invoice',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'operation-summary',
    component: OperationSummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requisition-lv',
    component: RequisitionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requisition-lv/details',
    component: RequisitionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requisition-lv/new',
    component: NewRequisitionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reading-loading-lv',
    component: ReadingLoadingLvComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reading-loading-lv/transfer',
    component: ReadingLoadingLvTransferComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reading-loading-lv/replacement-guide/emit',
    component: ReadingLoadingLvGuideEmitComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reading-loading-lv/replacement-guide/recover',
    component: ReadingLoadingLvGuideRecoverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tariff-pdf',
    component: TariffPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voucher-selection',
    component: VoucherSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voucher-details',
    component: VoucherDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/initial-screen',
    component: InitialScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/recover-replacement-guide',
    component: RecoverReplacementGuideComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/issue-replacement-guide',
    component: IssueReplacementGuideComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/substitution-guide',
    component: SubstitutionGuideComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/title-loading',
    component: TitleLoadingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'family-pass',
    component: FamilyPassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/recovery-details',
    component: RecoveryDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-guide/delivery',
    component: DeliverReplacementGuideDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'family-pass/voucher-cancel',
    component: VoucherCancelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'family-pass/voucher-load',
    component: VoucherLoadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'authorization-charge',
    component: AuthorizationChargeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requisition-lv/history',
    component: RequisitionLvHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'non-ticking-products/categories',
    component: NonTicketingProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'non-ticking-products/select',
    component: NonTicketingProductsSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'non-ticking-products/detail',
    component: NonTicketingProductsDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'non-ticking-products/history',
    component: NonTicketingProductsHistoryComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OperationRoutingModule {}
