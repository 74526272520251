import { ReplacementGuideCard, ReplacementGuideDTO, ReplacementGuideStatus, ReplacementGuideTitle } from '../model/create-repl-guide-request';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RecoverReplacementGuideService } from 'src/app/core/services/recover-replacement-guide.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { RecoverReplacementGuideDetails } from 'src/app/shared/models/recover-replacement-guide-details';
import { TableHeaderDataConfig, TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { TitleDTO } from 'src/app/shared/models/title-dto';
import { TariffService } from 'src/app/core/services/tariff.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { CardRead } from 'src/app/shared/models/card-read';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import { LoadDTO } from 'src/app/shared/models/load-dtos';


@Component({
    selector: 'app-recover-replacement-guide',
    templateUrl: './recover-replacement-guide.component.html',
    styleUrls: ['./recover-replacement-guide.component.less'],
})
export class RecoverReplacementGuideComponent implements OnDestroy, OnInit {

    serialNumber = '';
    replacementGuideDTO: ReplacementGuideDTO = {} as ReplacementGuideDTO;
    guideDetails: RecoverReplacementGuideDetails[] = [];
    title: TitleDTO = {} as TitleDTO;
    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    buttonDisabled = true;
    isSearchEnable = true;
    hasCardReadData = true;
    cardDetails: CardDetailsMessage;
    public placeholder = 'Número de Série';

    constructor(
        private navigationManager: NavigationManagerService,
        private recoverReplacementGuideService: RecoverReplacementGuideService,
        private tariffService: TariffService,
        private alertService: AlertService,
        private storageManager: StorageManagerService,
    ) {
        if (this.navigationManager.getNavigation().extras?.state?.serialNumber) {
            this.serialNumber = this.navigationManager.getNavigation().extras.state.serialNumber;
        }

        this.hasCardReadData = this.storageManager.session.has(CardEnum.FULL_CARD_DETAILS);
        if (this.hasCardReadData) {
            this.cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        }

        this.prepareTableResult();
    }

    async ngOnInit() {
        if (this.serialNumber) {
            await this.loadReplacementGuideDetails();
            this.isSearchEnable = !this.hasCardReadData;
        }
    }

    async loadReplacementGuideDetails() {

        try {
            const response = await this.recoverReplacementGuideService
                .isCardActiveReplacementGuide(this.serialNumber.toString()).toPromise();

            if (response?.success && response?.data != null) {

                const tariffBasicResponse = await this.tariffService
                    .fetchTitleById(response.data.title.titleId).toPromise();

                if (tariffBasicResponse?.success && tariffBasicResponse?.data != null) {

                    this.title = tariffBasicResponse.data;
                    this.replacementGuideDTO = response.data;

                    this.guideDetails = [{
                        titleDescription: this.title.description,
                        validityStart: this.replacementGuideDTO.title.startDate,
                        validityEnd: this.replacementGuideDTO.title.endDate
                    }];

                    this.buttonDisabled = false;
                }

            }
        } catch (e) {
            this.alertService.info(`Não foram encontrados Títulos válidos para o Nº de Série pesquisado.`);
        }

    }

    prepareTableResult() {
        this.listTitleColumn = [
            { title: 'Título', config: { colAlign: 'left', colWidth: '50%' } },
            { title: 'Data início', config: { colAlign: 'center', colWidth: '25%' } },
            { title: 'Data fim', config: { colAlign: 'center', colWidth: '25%' } },
        ];
        this.listTitleHeader = [
            { visible: true, name: 'titleDescription', config: null, button: null } as TableHeaderData,
            {
                visible: true, name: 'validityStart', button: null,
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy', valAlign: 'center' } as TableHeaderDataConfig,
            } as TableHeaderData,
            {
                visible: true, name: 'validityEnd', button: null,
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy', valAlign: 'center' } as TableHeaderDataConfig
            } as TableHeaderData
        ];
    }

    async previousView() {
        if (this.hasCardReadData) {
            await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: this.cardDetails });
        } else {
            await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_INITIAL);
        }
    }

    async nextView() {
        const read = this.storageManager.session.get(ReadingLoadingEnum.FromReadingEmitGuide);
        if (read) {
            const replacementGuide: ReplacementGuideDTO = {} as ReplacementGuideDTO;
            replacementGuide.oldCard = this.replacementGuideDTO?.oldCard;
            replacementGuide.status = this.replacementGuideDTO?.status;
            replacementGuide.title = this.replacementGuideDTO?.title;
            const transfLoadId = this.title.id;
            await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_SUBSTITUTION_GUIDE, {
                replacementGuide,
                transfLoadId
            });

        } else {
            await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_RECOVERY_DETAILS,
                {
                    guide: this.replacementGuideDTO,
                    title: this.title,
                    serialNumber: this.serialNumber
                }
            );
        }
    }

    ngOnDestroy() {

    }

}





