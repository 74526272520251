import { ServerResponse } from './../model/server-response';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { TotalSalesShiftResponse } from '../model/total-sales-shift-response';
import { SalesShiftResponse } from '../model/sales-shift-response';
import { EnvironmentUtil } from '../../../../../../environments/environment-util';
import { AlertService } from 'src/app/core/services/alert.service';
import { SaleItemResumeShift } from 'src/app/modules/operation/modules/sale-item-resume-shift';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { ShiftInfo } from '../model/shift-response';

@Injectable({
    providedIn: 'root'
})
export class CurrentShiftService {

    error = 'Ocorreu um erro ao recuperar os dados.';

    totalSalesShiftResponse: Subject<TotalSalesShiftResponse[]> = new Subject<TotalSalesShiftResponse[]>();

    salesShiftResponse: Subject<SalesShiftResponse[]> = new Subject<SalesShiftResponse[]>();

    startDate: string;

    constructor(private httpClient: HttpClient, private alertService: AlertService) { }


    public getSaleShiftResume(shiftId: number): Observable<BasicResponseDTO<Array<SaleItemResumeShift>>> {
        return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/sale/shift/${shiftId}`);
    }

    public async getInfoFromShift(shiftId: number): Promise<Observable<BasicResponseDTO<ShiftInfo>>> {
        return this.httpClient.get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/shift-info/${shiftId}`);
    }

    printInvoice(request: any) {
        const body = {
            shiftId: request.shiftId,
            deviceId: request.deviceId
        };

        this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}shift/serv/shift/print-shift`, body)
            .subscribe(
                (res: ServerResponse) => {
                    // console.log(res);
                    if (res && res.success) {
                        this.alertService.success(res.message);
                    } else {
                        console.log('error on response');
                        this.alertService.error(res.message);
                    }
                },
                (e: any) => {
                    try {
                        if (HttpUtils.isBasicResponse(e)) {
                            this.error = HttpUtils.getErroMessage(e);
                        } else {
                            this.error = e.getMessage;
                        }
                        console.log(this.error);
                        this.alertService.error(this.error);
                    } catch (e) {
                        console.log(e);
                    }
                }
            );
    }

}
