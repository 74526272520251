<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <title>Bilheteira</title>
</head>
<body>
    <app-header></app-header>
    <nz-modal [(nzVisible)]="isShiftSuspensionModalVisible" nzClosable="false" [nzFooter]=null [nzWidth]=700>
        <div nz-row>
            <img src='../assets/images/header/icon_turno_suspenso.svg'>
            <div nz-col nzSpan="24">
                <div>
                    <h1>
                        Suspensão de Turno
                    </h1>
                </div>
                <p class="suspend-shift-warning-message">
                    Turno será suspenso em {{shiftSuspensionCountdown}} segundos. Se não pretender suspender selecione OK
                </p>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <app-primary-cancel-button (click)="modalHandleOk()" buttonCSS="cancel-automatic-shift-suspension-button">
                    Ok
                </app-primary-cancel-button>
            </div>
        </div>
    </nz-modal>
    <nz-spin [nzSpinning]="isSpinning">
        <div id="boxed-layout">
            <nz-layout>
                <nz-content>
                    <div style="padding: 16px 24px 24px 24px">
                        <router-outlet></router-outlet>
                    </div>
                </nz-content>
            </nz-layout>
        </div>
    </nz-spin>
</body>
</html>
