<div *ngIf="!isVV" nz-row>
  <div *ngIf="this.hasReplacementGuide" nz-col nzSpan="8" class="buttons">
    <span
      [appHideIfUnauthorised]="'ISSUE_GUIDE_WITH_READ'"
      [appHideIfChildProfile]="this.card.profileIds"
      [appGenericHide]="this.isSerialNumberOnBlackList"
    >
      <app-secondary-second-button class="height-button" (click)="replacementGuide()" [disabled]="isShoppingCartFull">
        <span class="btn-icon icon-danificado"></span>
        <h3 class="button-label">Danificado</h3>
      </app-secondary-second-button>
    </span>
  </div>
  <div nz-col nzSpan="8" class="buttons" [appGenericHide]="this.isSerialNumberOnBlackList" [appHideIfUnauthorised]="'CARD_HISTORY'">
      <span>
        <app-secondary-second-button (click)="cardHistory()" class="height-button">
          <span class="btn-icon icon-historico"></span>
            <h3 class="button-label">Histórico</h3>
        </app-secondary-second-button>
      </span>
  </div>
  <div nz-col nzSpan="8" class="buttons">
    <app-secondary-second-button (click)="printCardDetails()" class="height-button">
      <span class="btn-icon icon-imprimir"></span>
        <h3 class="button-label">Imprimir</h3>
    </app-secondary-second-button>
  </div>
</div>

<div *ngIf="isVV" nz-rowstyle="position: relative;">
    <div nz-col [nzSpan]="26" class="vv-style" [ngStyle]="{'top': isVVInitialized? '-22px' : '-0px'}">
        <div nz-col nzSpan="8" class="buttons">
            <app-secondary-second-button (click)="printCardDetails()" class="height-button">
                <span class="btn-icon icon-imprimir"></span>
                <h3 class="button-label">Imprimir</h3>
            </app-secondary-second-button>
        </div>
        <div nz-col nzSpan="8" class="buttons" [appGenericHide]="this.isSerialNumberOnBlackList" [appHideIfUnauthorised]="'CARD_HISTORY'">
            <span>
              <app-secondary-second-button (click)="cardHistory()" class="height-button">
                <span class="btn-icon icon-historico"></span>
                  <h3 class="button-label">Histórico</h3>
              </app-secondary-second-button>
            </span>
        </div>
        <div *ngIf="isReplacementGuide && isVVInitialized" nz-col nzSpan="8" class="buttons">
            <span [appHideIfUnauthorised]="'ISSUE_GUIDE_WITH_READ'" [appHideIfChildProfile]="this.card.profileIds"
                [appGenericHide]="this.isSerialNumberOnBlackList">
                <app-secondary-second-button class="height-button" (click)="recoverVV()"
                    [disabled]="isShoppingCartFull">
                    <span class="btn-icon icon-danificado"></span>
                    <h3 class="button-label">Danificado</h3>
                </app-secondary-second-button>
            </span>
        </div>
        <div *ngIf="isReplacementGuide && isVVInitialized" nz-col nzSpan="8" class="buttons">
            <span [appHideIfChildProfile]="this.card.profileIds" [appGenericHide]="this.isSerialNumberOnBlackList">
                <app-secondary-second-button class="height-button" (click)="deliveryVV()"
                    [disabled]="isShoppingCartFull">
                    <span class="btn-icon icon-drop_up"></span>
                    <h3 class="button-label">Devolver Guia</h3>
                </app-secondary-second-button>
            </span>
        </div>
    </div>
</div>
