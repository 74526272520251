import {LoadTitleService} from 'src/app/core/services/load-title.service';
import {CommonEnum} from 'src/app/shared/enum/common.enum';
import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {AlertService} from 'src/app/core/services/alert.service';
import {ShoppingCartItemRequest} from 'src/app/shared/models/shopping-cart-item-request';
import {LoadTitleRequest} from 'src/app/shared/models/load-title-request';
import {ShiftService} from 'src/app/core/services/shift.service';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import {CardEnum} from 'src/app/shared/enum/card.enum';
import {ReadingLoadingEnum} from 'src/app/shared/enum/reading-loading.enum';
import { TariffService } from 'src/app/core/services/tariff.service';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import { Load } from '../lisboa-viva/models/load';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.less']
})
export class TicketComponent implements OnInit {

  public listTitleColumn: TableColumn[] = [];
  public listTitleHeader: TableHeaderData[] = [];
  public listOfData: any[] = [];
  public ticketQuantity: number = 1;
  private ticketQuantityMaxValue: number = 16777215;


  newTitle: CardReadTitle;
  newLoad: Load;
  private serialNumber: string;
  private peripheralId: string;
  private totalTicket: number;

  constructor(
    private location: Location,
    private storageManagerService: StorageManagerService,
    private alertService: AlertService,
    private shiftService: ShiftService,
    private peripheralService: PeripheralService,
    private navigationManager: NavigationManagerService,
    private storageManager: StorageManagerService,
    private tariffService: TariffService
  ) {
    const navigationExtras = this.navigationManager.getNavigation()?.extras?.state;
    const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
    this.serialNumber = cardDetails.low_part.toString();

    if (navigationExtras?.newTitle && navigationExtras?.load) {
      this.newTitle = navigationExtras.newTitle;
      this.newLoad = navigationExtras.load;
      this.totalTicket = this.ticketQuantity * this.newTitle.price;
      console.log(this.newTitle);

    } else {
      this.navigationManager.go(TicketingRoutes.HOME);
      this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
    }
  }

  public async ngOnInit() {
    this.peripheralId = await this.peripheralService.getPeripheralId();
    this.prepareTable();
  }

  private prepareTable() {
    this.listTitleColumn = [
      {title: 'TÍTULO', config: {colAlign: 'left', colWidth: '50%'}},
      {title: 'VALOR', config: {colAlign: 'center', colWidth: '50%'}},
    ];
    this.listTitleHeader = [
      {visible: true, name: 'title', config: null, button: null} as TableHeaderData,
      {visible: true, name: 'value', config: {valAlign: 'center', money: true}, button: null} as TableHeaderData,
    ];
    this.listOfData = [
      {title: this.newTitle.description, value:  String(this.totalTicket)}
    ];
  }

  private refresh() {
    this.listTitleHeader = null;
    this.listTitleColumn = null;
    this.listOfData = null;
    this.prepareTable();
  }

  public async previous() {
    const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
    await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, {card: cardDetails});
  }

  public async home() {
    await this.navigationManager.go(TicketingRoutes.HOME);
  }

  public async load() {
    if(this.ticketQuantity > this.ticketQuantityMaxValue){
        this.alertService.error(CommonEnum.msgMaxValueAllowedError);
        return;
    }
    if (this.totalTicket <= 0) {
      this.alertService.info(CommonEnum.msgValueZappingNotSelected);
      return;
    }
    const titleResponse = await this.tariffService.fetchTitleById(this.newTitle.id).toPromise();
    const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

    const shoppingCartRequest: ShoppingCartItemRequest = {
      shiftId: Number(this.shiftService.getShiftID()),
      titleId: this.newTitle.id,
      description: titleResponse.data.description,
      quantity: this.ticketQuantity,
      price: this.newTitle.price,
      transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
      // Check annulId
      annulId: null,
      cardNumber: String(cardFullDetails.details.environment.issuing_number),
      loadId: null,
      requisitionId: null,
      authId: null,
      replacementId: null,
      voucherId: 0,
      cardSerialNumber: cardFullDetails.low_part,
      isAnnul: true
    };
    const loadTitleRequest: LoadTitleRequest = {
      peripheralId: this.peripheralId,
      serialNumber: this.serialNumber,
      tickCode: this.newLoad.tickCode,
      tickOperCode: this.newLoad.tickOperCode,
      id: this.newTitle.id,
      voucherNumber: '',
      shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
      ticketQuantity: this.ticketQuantity
    } as LoadTitleRequest;

    this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
    this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
    this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadContract);
    await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, {shoppingCartRequest});
  }

  onNumCopyClick(signal: string) {
    if (signal === '+') {
      this.ticketQuantity++;
    } else {
      if (this.ticketQuantity > 1) {
        this.ticketQuantity--;
      }
    }
    this.totalTicket = this.ticketQuantity * this.newTitle.price;
    this.refresh();
  }

  onQuantityInputChange(event: any) {
    this.ticketQuantity = event;
    this.totalTicket = this.ticketQuantity * this.newTitle.price;
    this.refresh();
  }

}
