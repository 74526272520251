import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less']
})
export class DatePickerComponent implements OnInit {

  @Input() data;
  @Input() height = '';
  @Input() width = '';
  @Input() allowAllDates?: boolean = false;
  @Input() futureDateOnly?: boolean = false;
  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange(event) {
    this.valueChange.emit(event);
  }

  disabledDate = (current: Date): boolean => {
    var today = new Date();
    // Can select days before today and today
    if(this.allowAllDates){
        return false;
    } else if (this.futureDateOnly) {
        return differenceInCalendarDays(current, today) < 0;
    }
    return differenceInCalendarDays(current, today) > 0;
  };
}
