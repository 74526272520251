<div>
    <div nz-row>
        <h1 class="caption">Carregar Voucher no cartão</h1>
    </div>
    <div nz-row>
        <h2 class="description">Escolher o título para carregar em cartão</h2>
    </div>
    <div nz-row class="voucher-load-row">
        <h2 class="">Selecione o título que pretende carregar em cartão: </h2>
    </div>
    <div nz-row class="voucher-load-row">
        <div nz-col nzSpan="24">
            <app-primary-list-button buttonSize="medium" [data]="listTitles" [colNum]="6"
                (callButtonClick)="onChangeTitle($event)"></app-primary-list-button>
        </div>
    </div>
    <div *ngIf="voucherSelected" nz-row class="voucher-load-row">
        <div nz-col nzSpan="24" class=" align-text-left">
            <h3 class="">Detalhes do Voucher a ser Consumido:</h3>
            <h3>Data emissão Voucher:<b>
                    {{voucherSelected ?.issueDate | date:'dd/MM/yy' : 'Sem dados'}}</b></h3>
            <h3>Data fim da validade:<b>
                    {{voucherSelected ?.validEndDate | date:'dd/MM/yy' : 'Sem dados'}}</b></h3>
        </div>
    </div>
    <div class="footer-buttons">
        <div  nz-row>
            <div nz-col nzSpan="12" class="align-text-left">
            <app-primary-white-button
              (click)="onClickCancel()">
              <span class="btn-icon icon-anterior icon-middle float-left "></span>
              Voltar
            </app-primary-white-button>
          </div>
          <div nz-col nzSpan="12" class="align-text-right">
            <app-primary-normal-button
              [critical] = "true"
              (click)="onClickContinue()"
              [disabled]="voucherSelected && voucherSelected.id ? null : true"
              buttonCSS="button-load">
              Carregar
              <span class="btn-icon icon-seguinte icon-middle float-right"></span>
            </app-primary-normal-button>
          </div>
        </div>
      </div>
</div>

<app-operation-resume valueText="{{stateResume}}" [(isModalVisible)]="isModalVisible"
    (onClickConfirm)="handleConfirmModal($event)">
</app-operation-resume>
