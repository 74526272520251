import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '../../core/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {StorageManagerService} from '../../core/services/storage-manager.service';
import NavigationManagerService, {TicketingRoutes} from '../../core/services/navigation-manager.service';
import {PeripheralService} from '../../core/services/peripheral.service';
import {WebsocketMessage, WebsocketMessageType} from '../../shared/models/websocket-message';
import {APIVivaResponse} from '../../shared/models/api-viva-response';
import {NullifyResponse} from '../../shared/models/nullify-response';
import {CardDecodeResponse} from '../../shared/models/card-decode-response';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {HandlerComponent, SocketClientService} from "../../core/services/websocket-client.service";

@Component({
  selector: 'app-delete-contract',
  templateUrl: './delete-contract.component.html',
  styleUrls: ['./delete-contract.component.less']
})
export class DeleteContractComponent implements OnInit, HandlerComponent {

  ready: boolean;
  private cardBinary: CardDecodeResponse;

  constructor(private route: ActivatedRoute,
              private alertService: AlertService,
              private httpClient: HttpClient,
              private navigationManager: NavigationManagerService,
              private peripheralService: PeripheralService,
              private socketClient: SocketClientService) {
    this.socketClient.setHandlerComponent(this);
    this.ready = false;
  }

  private slot = 0;

  async ngOnInit() {
    this.route.params.subscribe(async params => {
      this.slot = params.slot;
    });
  }

  async handleMessage(messageBody: WebsocketMessage) {
    switch (messageBody.type) {
      case WebsocketMessageType.ExchangeApdu:
        const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId).toPromise();
        if (!response.success) {
          this.alertService.error(response.detail);
        }
        break;
    }
  }

  async onConnect(): Promise<void> {
    const response = await this.peripheralService.detectCard();
    this.cardBinary = response.data;
    this.ready = true;
    this.alertService.info('Ready to delete contract at slot ' + this.slot);
  }

  async delete() {
    if (!this.slot) {
        await this.deleteAll();
    } else {
        await this.deleteSpecificSlot(this.slot);
    }
    await this.navigationManager.go(TicketingRoutes.HOME);
  }

  private async deleteSpecificSlot(slot: number) {
      const request = this.cardBinary as any;
      request.slot = Number(slot);
      console.log(request);
      return await this.httpClient
          .post<APIVivaResponse<NullifyResponse>>(`${EnvironmentUtil.getEnv().apiVivaUrl}api/card/null`, request).toPromise();
  }

    private async deleteAll() {
        for (let i = 1; i < 5; i++) {
            await this.deleteSpecificSlot(i);
        }
    }
}
