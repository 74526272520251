<nz-select
  [nzPlaceHolder]="placeholder"
  [nzDropdownMatchSelectWidth]="dropdownMatchSelectWidth"
  (ngModelChange)="onSelectedChange($event)"
  [(ngModel)]="selected"
  [nzOptions]="listOptions"
  [style.height]="height"
  [style.width]="width"
  nzSize="large"
  nzAllowClear
>
</nz-select>

