<div class="detailContent">
  <div class="title">
    <div>
      <h1>Detalhes da Recuperação de VIVA Viagem/navegante ocasional</h1>
    </div>
  </div>

  <div class="checkbox-div">
    <app-primary-checkbox (isCheckedChange)="checkboxEventHandler($event)" label="Garantia">
    </app-primary-checkbox>
  </div>

  <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader" [listOfData]="dataTable"
    showPagination="false" pageSize="1" showTotal="true" spanTotal=0 total=" {{isChecked ? 0 : 50}}">
  </app-primary-table>

  <div nz-row nzJustify="start" class="footer-buttons">
    <div nz-col [nzSpan]="12" class="align-left">
      <app-primary-white-button nzType="primary" nzDanger (click)="onPreviousClick()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
      <app-primary-cancel-button class="m-l-20" (click)="cancelClick()">Cancelar</app-primary-cancel-button>
    </div>
    <div nz-col [nzSpan]="12" class="align-right">
      <app-primary-normal-button class="m-r-50" nzType="primary" nzDanger (click)="loadCard()" width="180px">
        <span class="btn-icon icon-seguinte icon-middle float-right"></span>
        Seguinte
      </app-primary-normal-button>
    </div>
  </div>
</div>
