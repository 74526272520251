<div>
  <div class="top-title" nz-row>
    <div nz-col [nzSpan]="24">
      <h1 class="req">Requisições de Lisboa VIVA/navegante personalizado</h1>
    </div>
  </div>
  <div nz-row>
    <div nz-col [nzSpan]="24" id="cartoes-img"></div>
  </div>
</div>

<div class="footer-buttons">
  <div nz-row>
    <div nz-col [nzSpan]="12" class="align-left">
      <app-primary-white-button class="previous-button" (click)="initialScreen()">
        <span class="btn-icon icon-anterior icon-middle float-left blue"></span>
        Voltar
      </app-primary-white-button>
    </div>
    <div nz-col [nzSpan]="12" style="padding-right: 48px" class="align-right">
      <span [appHideIfUnauthorised]="'REQUISITIONS_NEW'">
        <app-primary-white-button (click)="newReq()">
          Nova Req.
        </app-primary-white-button>
      </span>
      <span [appHideIfUnauthorised]="'REQUISITIONS_HISTORY'">
        <app-primary-white-button style="margin-left: 5px" class="star-button" (click)="reqHistory()">
          Histórico
        </app-primary-white-button>
      </span>
    </div>
  </div>
</div>
