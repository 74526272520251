import { NzModalModule } from 'ng-zorro-antd/modal';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from './modules/home/home.module';
import {CoreModule} from './core/core.module';
import {IconsProviderModule} from './icons-provider.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {NG_ZORRO_CONFIG} from './configs/nzNotificationConfig';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NZ_I18N, pt_PT} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import pt from '@angular/common/locales/pt';
import {OperationRoutingModule} from './modules/operation/operation-routing.module';
import {OperationModule} from './modules/operation/operation.module';
import {ChargeRoutingModule} from './modules/load/load-routing.module';
import {ChargeModule} from './modules/load/load.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import {ShiftRoutingModule} from './modules/shift/shift-routing.module';
import {ShiftModule} from './modules/shift/shift.module';
import {HeaderInterceptor} from './core/interceptors/header-http-interceptor';
import {JwtInterceptor} from './core/interceptors/auth-token-interceptor';
import { ResetCardComponent } from './maintenance/reset-card/reset-card.component';
import { DeleteContractComponent } from './maintenance/delete-contract/delete-contract.component';
import { HttpCancelService } from 'src/app/core/services/http-cancel-service.service';
import { ManageHttpInterceptor } from 'src/app/core/interceptors/managehttp.interceptor';

import { DatePipe } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedModule } from './shared/shared.module';

registerLocaleData(pt);

@NgModule({
  declarations: [
    AppComponent,
    ResetCardComponent,
    DeleteContractComponent,
  ],
  imports: [
    NzSpinModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HomeModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzNotificationModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OperationRoutingModule,
    OperationModule,
    ChargeRoutingModule,
    ChargeModule,
    ShiftRoutingModule,
    ShiftModule,
    NgIdleKeepaliveModule.forRoot(),
    NzModalModule,
    SharedModule
  ],
  providers: [
    { provide: NZ_I18N,
      useValue: pt_PT
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: NZ_CONFIG,
      useValue: NG_ZORRO_CONFIG
    },
    DatePipe,
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
