import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import Keyboard from 'simple-keyboard';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-primary-input-keyboard',
    templateUrl: './primary-input-keyboard.component.html',
    styleUrls: [
        './primary-input-keyboard.component.less',
        '../../../../../node_modules/simple-keyboard/build/css/index.css',
        '../../../modules/home/pages/login/login.component.less',
        '../../../modules/operation/pages/payment-money/payment-money.component.less',
        '../../../modules/operation/pages/invoice/invoice.component.less',
        '../../../modules/operation/pages/payment-multiple/payment-multiple.component.less',
        '../../../modules/operation/pages/payment-siit/payment-siit.component.less',
        '../../../modules/operation/pages/requisition/lisboa-viva/requisition-details/requisition-details.component.less',
        '../../../modules/operation/pages/requisition/lisboa-viva/requisition-lv-history/requisition-lv-history.component.less',
        '../../../modules/operation/pages/fines/fines.component.less',
        '../../../modules/shift/pages/documents/documents.component.less',
        '../../../modules/shift/pages/previous-shifts/previous-shifts.component.less',
        '../../../modules/operation/pages/replacement-guide/recover-replacement-guide/recover-replacement-guide.component.less',
        '../../../modules/operation/pages/replacement-guide/issue-replacement-guide/issue-replacement-guide.component.less',
        '../../../modules/operation/pages/replacement-guide/substitution-guide/substitution-guide.component.less',
        '../../../modules/operation/pages/fine-register/fine-register.component.less',
        '../../../modules/load/pages/ticket/ticket.component.less']
})
export class PrimaryInputKeyboardComponent implements OnInit, OnDestroy {

    public customPattern = { '0': { pattern: new RegExp('\[0-9.\]')} };

    private DISPLAY = {
        '{alt}': 'Alt',
        '{tab}': 'Tab',
        '{shift}': 'Shift',
        '{shiftactivated}': 'Shift',
        '{enter}': 'Enter',
        '{bksp}': 'Backspace',
        '{lock}': 'Caps',
        '{altright}': 'Right Shift',
        '{space}': 'Espaço',
        '{default}': 'Default',
        '{back}': 'Back'
    };
    private NUMERIC_LAYOUT = {default: ['1 2 3', '4 5 6', '7 8 9', ', 0 .', '{bksp}']};
    private PORTUGUESE_LAYOUT = {
        default: [
            '\\ 1 2 3 4 5 6 7 8 9 0 \' « {bksp}',
            '{tab} q w e r t y u i o p + \u0301',
            '{lock} a s d f g h j k l ç º \u0303 {enter}',
            '{shift} < z x c v b n m , . - {shift}',
            '{space}'
        ],
        shift: [
            '| ! " # $ % & / ( ) = ? » {bksp}',
            '{tab} Q W E R T Y U I O P * \u0300',
            '{lock} A S D F G H J K L Ç ª \u0302 {enter}',
            '{shiftactivated} > Z X C V B N M ; : _ {shiftactivated}',
            '{space}'
        ],
        alt: [
            '  @ £ § €  { [ ] }   {bksp}',
            '{tab}            ',
            '{lock}             {enter}',
            '{shiftactivated}            {shiftactivated}',
            '{space}'
        ]
    };
    private keyboard: Keyboard;
    private child: HTMLElement;
    private isKeyboardNumeric: boolean = false;
    private wasClickInside: boolean = false;
    private random: string;
    private static enterKeyCode: number = 13;

    @Input() public label: string = '';
    @Input() public inputType: string = 'text';
    @Input() public keyboardType: string = 'text';
    @Input() public value: string = '';
    @Input() public inputCss?: string;
    @Input() public labelCss?: string;
    @Input() public inputIcon?: string;
    @Input() public inputBorder?: string;
    @Input() public isMoney?: boolean = false;
    @Input() public inputDivCSS?: string = '';
    @Input() public inputPlaceholder?: string = '';
    @Input() public isObservation: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() public maxlength: number;
    @Input() public mask: string = '';
    @Input() public decimalMarker: string = '';
    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() public dblClick: EventEmitter<string> = new EventEmitter<string>();
    @Output() public enterClick: EventEmitter<string> = new EventEmitter<string>();


    public constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) {
        this.random = this.makeid();
    }

    public ngOnInit(): void {
        this.isKeyboardNumeric = this.keyboardType === 'number';
        this.document.addEventListener('click', () => this.documentClick());
        this.elementRef.nativeElement.addEventListener('click', () => this.componentClick());
    }

    public documentClick(): void {
        if (!this.wasClickInside) {
            if (this.keyboard) {
                this.keyboard.destroy();
                this.keyboard = null;
            }
            if (this.child) {
                this.renderer.removeChild(this.elementRef?.nativeElement, this.child);
                this.child = null;
            }
        }
        this.wasClickInside = false;
    }

    public componentClick(): void {
        if (!this.child && !this.keyboard) {
            this.child = document.createElement('div');
            this.child.className = this.random;
            this.child.style.setProperty("position", "absolute");
            this.child.style.setProperty("bottom", "0");
            this.child.style.setProperty("left", '0');
            this.child.style.setProperty("float", "right");
            this.child.style.setProperty("z-index", "5");
            this.renderer.appendChild(this.elementRef.nativeElement, this.child);
            this.setKeyboard(this.setLayout());
        }
        this.wasClickInside = true;
    }

    private setLayout(): any {
        return this.isKeyboardNumeric ? this.NUMERIC_LAYOUT : this.PORTUGUESE_LAYOUT;
    }

    private setKeyboard(layout: any) {
        this.keyboard = new Keyboard("." + this.random, {
            onChange: input => this.onChange(input),
            onKeyPress: button => this.onKeyPress(button),
            layout,
            display: this.DISPLAY
        });
        this.keyboard.setInput(this.value);
    }

    public onKeyPress(button): void {
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button.includes('{') && button.includes('}')) {
            this.handleLayoutChange(button);
        }
        if (button.includes('{enter}')) {
            this.enterclick(PrimaryInputKeyboardComponent.enterKeyCode);
        }
    }

    public handleLayoutChange(button): void {
        const currentLayout = this.keyboard.options.layoutName;
        let layoutName;
        switch (button) {
            case '{shift}':
            case '{shiftactivated}':
            case '{default}':
                layoutName = currentLayout === 'default' ? 'shift' : 'default';
                break;
            case '{alt}':
            case '{altright}':
                layoutName = currentLayout === 'alt' ? 'default' : 'alt';
                break;
            default:
                break;
        }
        if (layoutName) {
            this.keyboard.setOptions({
                layoutName
            });
        }
    }

    public onChange = (input: string) => {
        this.value = input;
        this.valueChange.emit(this.value);
    }

    public onInputChange = (event: any) => {
        this.value = event.target.value;
        try {
            this.keyboard.setInput(this.value);
            this.valueChange.emit(this.value);
        } catch (e) {
            this.valueChange.emit(this.value);
        }
}

    public onMoneyInputChange = (event: any) => {
        const dotChar = '.';
        const commaChar = ',';
        if (event.target.value.includes(dotChar)) {
            if (event.target.value.includes(commaChar)) {
                event.target.value = event.target.value.split(dotChar).join('');
            } else {
                event.target.value = event.target.value.replace(dotChar, commaChar);
            }
        }
        this.value = event.target.value;
        try {
            this.keyboard.setInput(this.value);
            this.valueChange.emit(this.value);
        } catch (e) {
            this.valueChange.emit(this.value);
        }
    }

    public onTextAreaChange = (event: any) => {
        this.value = event;
        this.keyboard.setInput(this.value);
        this.valueChange.emit(this.value);
    }

    public ngOnDestroy(): void {
        if (this.keyboard) {
            this.keyboard.destroy();
        }
    }

    private makeid(): string {
        const result = [];
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
    }

    dblclick() {
        this.dblClick.emit();
    }

    focus() {
        this.componentClick();
        this.documentClick();
    }

    // virtual keyboard for money input
    focusMoney() {
        this.moneyComponentClick();
        this.documentClick();
    }

    public moneyComponentClick(): void {
        if (!this.child && !this.keyboard) {
            this.child = document.createElement('div');
            this.child.className = this.random;
            this.child.style.setProperty("position", "absolute");
            this.child.style.setProperty("bottom", "0");
            this.child.style.setProperty("left", '0');
            this.child.style.setProperty("float", "right");
            this.child.style.setProperty("z-index", "5");
            this.renderer.appendChild(this.elementRef.nativeElement, this.child);
            this.setMoneyKeyboard(this.setLayout());
        }
        this.wasClickInside = true;
    }

    private setMoneyKeyboard(layout: any) {
        this.keyboard = new Keyboard("." + this.random, {
            onChange: input => this.onMoneyChange(input),
            onKeyPress: button => this.onKeyPress(button),
            layout,
            display: this.DISPLAY
        });
        this.keyboard.setInput(this.value);
    }

    public onMoneyChange = (input: string) => {
        const dotChar = '.';
        const commaChar = ',';
        if (input.includes(dotChar)) {
            if (input.includes(commaChar)) {
                input = input.split(dotChar).join('');
            } else {
                input = input.replace(dotChar, commaChar);
            }
        } else if (input.includes(commaChar)) {
            // mask has no effect in virtual keyboard so we need to process input "manually"
            if (input.indexOf(commaChar) !== input.lastIndexOf(commaChar)) {
                input = input.substring(0, input.lastIndexOf(commaChar));
            }
            if (input.lastIndexOf(commaChar) !== input.length - 1) {
                const splittedInput = input.split(commaChar);
                if (splittedInput.length === 2) {
                    splittedInput[1] = splittedInput[1].substring(0, 2);
                }
                input = splittedInput[0] + commaChar + splittedInput[1];
            }
        }
        this.value = input;
        this.valueChange.emit(this.value);
        this.keyboard.setInput(this.value);
    }

    enterclick(keyCode: number) {
        if (keyCode === PrimaryInputKeyboardComponent.enterKeyCode) {
            this.enterClick.emit();
        }
    }

}
