import { EnvironmentUtil } from './../../../../../environments/environment-util';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { ValidateUtils } from 'src/app/shared/utils/validate-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ShiftService } from 'src/app/core/services/shift.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { WebsocketMessage, WebsocketMessageType } from 'src/app/shared/models/websocket-message';
import { CardDetailsMessage } from '../../../../shared/models/card-details-message';
import NavigationManagerService, { TicketingRoutes } from '../../../../core/services/navigation-manager.service';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { CardEnum } from '../../../../shared/enum/card.enum';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { HttpStatusCode } from 'src/app/shared/enum/http-status-code';
import { SpinService } from '../../../../core/services/spin.service';
import { SupportDetailService } from '../../../../core/services/support-detail.service';
import { ShoppingCartService } from '../../../../core/services/shopping-cart.service';
import { HandlerComponent, SocketClientService } from '../../../../core/services/websocket-client.service';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit, HandlerComponent {

    notifier = new Subject();
    navigation: Navigation;
    private readonly shiftId: string;
    private isLookingForCard = true;
    public buttonsDisabled = true;
    public isShoppingCartFull = false;
    public canReadCard = false;
    public canConsultOrEditFines = false;

    constructor(
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private alertService: AlertService,
        private cd: ChangeDetectorRef,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private spinService: SpinService,
        private supportDetailsService: SupportDetailService,
        private shoppingCartService: ShoppingCartService,
        private socketClient: SocketClientService,
        private authorizationService: AuthorizationService
    ) {
        this.socketClient.setHandlerComponent(this);
        this.shiftId = this.shiftService.getShiftID();

        if (this.shiftService.isLoggedIn() && !this.shiftId) {
            this.navigationManager.go(TicketingRoutes.SHIFTLESS);
        }

        if (this.navigationManager.hasNavigationMessage()) {
            if (this.navigationManager.getNavigationMessageType() === 'success') {
                this.alertService.success(this.navigationManager.getNavigationMessage(), false, false);
            } else {
                this.alertService.info(this.navigationManager.getNavigationMessage(), false, false);
            }
        }
    }

    ngOnDestroy(): void {
        this.isLookingForCard = false;
        this.notifier.next();
        this.notifier.complete();
        this.storageManager.session.remove(CommonEnum.errorMessageScanCard);
    }

    async ngOnInit() {
        this.canReadCard = this.authorizationService.hasOneOf([AuthorityName.TICKETS_SELL, AuthorityName.BALANCE_TRANSFER,
        AuthorityName.ISSUE_GUIDE_WITH_READ, AuthorityName.ISSUE_GUIDE_WITHOUT_READ, AuthorityName.FAMILY_PASS,
        AuthorityName.CARD_HISTORY, AuthorityName.ANNUL_SALE, AuthorityName.CHARGE_AUTHORIZATIONS]);
        this.canConsultOrEditFines = this.authorizationService.hasOneOf([AuthorityName.FINE_READ_PAYMENT, AuthorityName.FINE_PRE_REGISTER]);

        if (!this.canReadCard) {
            this.alertService.info(CommonEnum.msgHasNotReadingPermissions, false, true, 0);
        }
        this.isQaEnvironment();
        this.resetSessionStorageVariables();

        if (this.socketClient.isConnected) {
            await this.scanForCardInBackground();
        }

        this.shiftService.clean();

        if (!ValidateUtils.isOkValueString(String(this.shiftId))) {
            await this.navigationManager.go(TicketingRoutes.LOGIN, {
                messageError: CommonEnum.msgUnauthorized,
            });
        }
    }

    resetSessionStorageVariables() {
        // clear last card read info
        this.storageManager.session.remove(CardEnum.FULL_CARD_DETAILS);
        this.storageManager.session.remove(CardEnum.CARD_DETECTION_BINARY);
        // clear payment info
        this.storageManager.session.remove(PaymentEnum.PaymentType);
        this.storageManager.session.remove(PaymentEnum.ShoppingCart);
        // clear load card & shopping cart info/action
        this.storageManager.session.remove(ReadingLoadingEnum.ShoppingCartRequest);
        this.storageManager.session.remove(ReadingLoadingEnum.LoadContractRequest);
        this.storageManager.session.remove(ReadingLoadingEnum.Action);
        this.storageManager.session.remove(ReadingLoadingEnum.ReloadContractRequest);
        // clear replacement guide info
        this.storageManager.session.remove(ReadingLoadingEnum.CreateReplGuideRequest);
        // clear trade contract info
        this.storageManager.session.remove(ReadingLoadingEnum.SwitchRequest);
        // clear nullify contract request
        this.storageManager.session.remove(ReadingLoadingEnum.NullifyContractRequest);
        // clear consume authorization request
        this.storageManager.session.remove(ReadingLoadingEnum.ConsumeAuthorizationRequest);
        this.storageManager.session.remove(ReadingLoadingEnum.FromReadingEmitGuide);
        // clear home loading info
        this.storageManager.session.remove(CommonEnum.loadingActive);
        // card enum
        this.storageManager.session.remove(CardEnum.NEW_FULL_CARD_DETAILS);
        this.storageManager.session.remove(CardEnum.CONTRACT_TO_DELETE);
        this.storageManager.session.remove(CardEnum.LAST_EVENT_MESSAGE);
        this.storageManager.session.remove(CardEnum.CARD_READ);
    }

    async ngAfterViewInit(): Promise<void> {
        if (this.navigationManager.hasNavigationMessage()) {
            this.alertService.success(this.navigationManager.getNavigationMessage());
            this.cd.detectChanges();
        }

        await this.shoppingCartService.list(this.shiftId);
        this.isShoppingCartFull = this.storageManager.session.get(CommonEnum.shoppingCartFull) as boolean;
        this.alertService.removeNotification(CommonEnum.shoppingCartFull);
        if (this.isShoppingCartFull) {
            const shoppingCartFullNotification = this.alertService.info(CommonEnum.msgShoppingCartFull, true, false, 0);
            shoppingCartFullNotification.messageId = CommonEnum.shoppingCartFull;
        }
    }

    async customerInfo() {
        await this.navigationManager.go(TicketingRoutes.CUSTOMER_INFO);
    }

    async readCard(scanCounter: number = 1) {
        if (this.isLookingForCard) {
            await this.peripheralService.startCardScan().toPromise().then(async (response) => {
                if (response.success) {
                    // Set card binary
                    this.storageManager.session.set(CardEnum.CARD_DETECTION_BINARY, response.data);
                } else {
                    scanCounter++;
                    this.spinService.setSpinValue(false);
                    this.storageManager.session.set(CommonEnum.loadingActive, 'false');
                    if (response.statusCode === HttpStatusCode.NO_CONTENT) {
                        this.alertService.error(CommonEnum.msgSupportDamagedError);
                    }
                    await this.scanForCardInBackground(scanCounter);
                }
            });
        }
    }

    async scanForCardInBackground(scanCounter: number = 1) {
        if (this.isLookingForCard && this.canReadCard) {
            await this.peripheralService.detectCard()
                .then(async (response) => {
                    if (response.success) {
                        this.spinService.setSpinValue(true);
                        this.storageManager.session.set(CommonEnum.loadingActive, 'true');
                        this.storageManager.session.set(CommonEnum.errorMessageScanCard, '');
                        await this.readCard(scanCounter);
                    } else {
                        if (scanCounter === 1) {
                            this.alertService.info(response.message, false, true, 0);
                            scanCounter++;
                            this.buttonsDisabled = false;
                            this.storageManager.session.set(CommonEnum.errorMessageScanCard, '');
                        }
                        await new Promise(f => setTimeout(f, 1000));
                        this.storageManager.session.set(CommonEnum.errorMessageScanCard, '');
                        await this.scanForCardInBackground(scanCounter);
                    }
                })
                .catch(async (error: Error) => {
                    this.spinService.setSpinValue(false);
                    this.storageManager.session.set(CommonEnum.loadingActive, 'false');
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                            this.alertService.error('[' + scanCounter + '] ' + CommonEnum.msgCommunicationReaderError);
                            this.storageManager.session.set(CommonEnum.errorMessageScanCard,
                                CommonEnum.msgCommunicationReaderError ? CommonEnum.msgCommunicationReaderError : '');
                        }
                        scanCounter++;
                        this.buttonsDisabled = false;
                        await this.scanForCardInBackground(scanCounter);
                    }
                });
        }
    }

    async goToRequisitionLv() {
        await this.navigationManager.go(TicketingRoutes.LV_REQUISITION);
    }

    async handleMessage(message: WebsocketMessage) {
        console.log(`(HOME COMPONENT) WebsocketMessageType: ${message?.type}`);
        switch (message.type) {
            case WebsocketMessageType.ExchangeApdu:
                await this.peripheralService.sendCommandToCardReader(message?.content, message?.requestId).toPromise();
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (message.content) as CardDetailsMessage;
                this.storageManager.session.set(CardEnum.FULL_CARD_DETAILS, cardDetails);

                const readableDetails = await this.supportDetailsService.fetchActions().toPromise() as any;

                if (cardDetails.details.environment === undefined) {
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS,
                        { card: cardDetails, readableDetails: readableDetails.data }
                    );
                }
                break;
            default:
                break;
        }
    }

    async goToReplacementGuide() {
        await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_INITIAL);
    }

    async goToFines() {
        await this.navigationManager.go(TicketingRoutes.FINES);
    }

    async goToNonTicketingProducts() {
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS);
    }

    public isQaEnvironment(): boolean {
        return EnvironmentUtil.isQuality();
    }

    async onConnect() {
        this.spinService.setSpinValue(false);
        await this.scanForCardInBackground();
    }
}
