import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SpinService {

    private myVar$ = new BehaviorSubject<boolean>(null);
    public myVar: Observable<any> = this.myVar$.asObservable();

    setSpinValue(newValue: boolean) {
        this.myVar$.next(newValue);
    }
}
