import {SaleItemResumeShift} from './../../../operation/modules/sale-item-resume-shift';
import {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {TableColumn} from 'src/app/shared/models/table-column';
import {CurrentShiftService} from './service/current-shift.service';
import {AlertService} from 'src/app/core/services/alert.service';
import {TableHeaderDataConfig} from 'src/app/shared/models/table-header-data-config';
import {TotalSalesShiftResponse} from './model/total-sales-shift-response';
import {SalesShiftResponse} from './model/sales-shift-response';
import {ShiftService} from 'src/app/core/services/shift.service';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import {DatePipe} from '@angular/common';
import {ShiftInfo} from './model/shift-response';
import {PreviousShiftsService} from 'src/app/core/services/previous-shifts.service';
import {PrintShiftRequest} from 'src/app/shared/models/print-shift-request';
import { CommonEnum } from 'src/app/shared/enum/common.enum';

@Component({
    selector: 'app-shift-resume',
    templateUrl: './shift-resume.component.html',
    styleUrls: ['./shift-resume.component.less'],
})
export class ShiftResumeComponent implements OnInit {
    listResultColumnTS: TableColumn[] = [];
    listResultHeaderTS: TableHeaderData[] = [];

    listResultColumnS: TableColumn[] = [];
    listResultHeaderS: TableHeaderData[] = [];

    totalSalesShiftResponse: TotalSalesShiftResponse[] = [];
    salesShiftResponse: SalesShiftResponse[] = [];

    saleItemResumeShift: SaleItemResumeShift[] = [];

    peripheralId = '';

    shiftId = 0;
    resumeLoading = true;
    shiftBeginDate: string;
    shiftInfo: ShiftInfo;


    constructor(
        private router: Router,
        private currentShiftService: CurrentShiftService,
        private previousShiftService: PreviousShiftsService,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private datePipe: DatePipe
    ) {
        this.prepareTableSalesShiftResponseResult();
        this.prepareTableTotalSalesShiftResponseResult();

        this.subscribePeripheralIdResponse();
        this.subscribeTotalSalesShiftResponse();

        this.shiftId = Number(this.shiftService.getShiftID());
        this.getInfoFromShift(this.shiftId);
        this.getSaleShiftResume(this.shiftId);
    }

    async ngOnInit() {
        this.peripheralId = await this.peripheralService.getPeripheralId();
        this.subscribeDeviceId();
    }

    async getSaleShiftResume(shiftId: number) {
        await this.currentShiftService.getSaleShiftResume(this.shiftId).toPromise()
            .then((response) => {
                this.saleItemResumeShift = response.data;
            })
            .finally(() => this.resumeLoading = false);
    }

    async getInfoFromShift(shiftId: number) {
        (await this.currentShiftService.getInfoFromShift(shiftId)).toPromise()
        .then((info) => {
            this.shiftInfo = info.data;
        });
    }

    subscribeTotalSalesShiftResponse() {
        this.currentShiftService.totalSalesShiftResponse.subscribe(
            (data: TotalSalesShiftResponse[]) => {
                this.totalSalesShiftResponse = data;
                this.resumeLoading = false;
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                console.log('HTTP Error', err);
            }
        );
    }

    async subscribePeripheralIdResponse() {
        this.peripheralId = await this.peripheralService.getPeripheralId();
    }

    subscribeDeviceId() {
        this.currentShiftService.salesShiftResponse.subscribe(
            (data: SalesShiftResponse[]) => {
                this.salesShiftResponse = data;
                this.resumeLoading = false;
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                console.log('HTTP Error', err);
            }
        );
    }

    prepareTableSalesShiftResponseResult() {
        this.listResultColumnS = [
            { title: 'TÍTULOS', config: { colAlign: 'left', colWidth: '33%' } },
            { title: 'QTD.', config: { colAlign: 'center', colWidth: '33%' } },
            { title: 'VALOR', config: { colAlign: 'right', colWidth: '33%' } },
        ];

        this.listResultHeaderS = [
            {
                visible: true,
                name: 'description',
                config: { valAlign: 'left' } as TableHeaderDataConfig,
            } as TableHeaderData,
            {
                visible: true,
                name: 'quantity',
                config: { valAlign: 'center' } as TableHeaderDataConfig,
            } as TableHeaderData,
            {
                visible: true,
                name: 'amount',
                config: { valAlign: 'right', money: true } as TableHeaderDataConfig,
            } as TableHeaderData,
        ];
    }

    prepareTableTotalSalesShiftResponseResult() {
        this.listResultColumnTS = [
            { title: null, config: { colAlign: 'center', colWidth: '50%' } },
            { title: '', config: { colAlign: 'center', colWidth: '50%' } },
        ];

        this.listResultHeaderTS = [
            {
                visible: true,
                name: 'name',
                config: { valAlign: 'left' } as TableHeaderDataConfig,
            } as TableHeaderData,
            {
                visible: true,
                name: 'sum',
                config: { valAlign: 'right', money: true } as TableHeaderDataConfig,
            } as TableHeaderData,
        ];
    }

    toHomeScreen() {
        this.router.navigate(['home']);
    }

    printShiftResume() {
        const request = {
            id: this.shiftId,
            deviceId: this.peripheralId,
            includeMiniReceipt: false,
            includeReceiptCopy: false
        } as PrintShiftRequest;
        this.previousShiftService.printShift(request);
    }

    previousDocuments() {
        this.router.navigate([TicketingRoutes.DOCUMENTS_HISTORY]);
    }

    previousShift() {
        this.router.navigate([TicketingRoutes.SHIFT_PREVIOUS]);
    }

    getStartDate() {
        const formatStartDate = this.datePipe.transform(this.shiftInfo.startDate, 'dd-MM-yyyy HH:mm:ss');
        return formatStartDate;
    }
}
