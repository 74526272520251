import { ItemRequisitionType } from './../model/item-requisition-type';
import { ItemDeliveryType } from './../model/item-delivery-type';
import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequisitionService {

    listRequisitionType: Subject<ItemRequisitionType[]> = new Subject<ItemRequisitionType[]>();
    listDeliveryType: Subject<ItemDeliveryType[]> = new Subject<ItemDeliveryType[]>();

    constructor(private httpClient: HttpClient, private alertService: AlertService) { }

    public getRequisitionProfiles() {

        this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/profile`)
            .subscribe(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        if (data != null && data.content != null) {
                            const list: ItemRequisitionType[] = [];
                            for (const entry of data.content) {
                                list.push({ label: entry.description, value: entry.id } as ItemRequisitionType);
                            }
                            this.listRequisitionType.next(list);
                        }
                    } else {
                        this.alertService.error(res.message);
                    }
                },
                (e: any) => {
                    this.alertService.error(e.error.message);
                }
            );
    }

    public getDeliveryTypeByProfile(id: number) {

        let params = new HttpParams();

        params = params.set('profileId', `${id}`);

        this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/requisition-type/by-profile`, { params })
            .subscribe(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        if (data != null && data.content != null) {
                            const list: ItemDeliveryType[] = [];
                            for (const entry of data.content) {
                                list.push(
                                    {
                                        label: entry.delivery.shortDescription,
                                        value: entry.delivery.id,
                                        expectedTime: entry.delivery.expectedDaysToDelivery
                                    } as ItemDeliveryType);
                            }
                            this.listDeliveryType.next(list);
                        }
                    } else {
                        this.alertService.error(res.message);
                    }
                },
                (e: any) => {
                    this.alertService.error(e.error.message);
                }
            );
    }


}
