<div class="payment-multibanco-content">
  <h1 style="text-transform:uppercase;" class="title-blue m-b-20 m-t-15">Pagamentos</h1>


  <div nz-row class="m-b-20">
    <div nz-col nzSpan="12" [nzOffset]="0"  class="text-bold-left">
      TOTAL A PAGAR
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ shoppingCart.payment.total / 100 | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="12" [nzOffset]="0" class="text-normal-left">
      MODO DE PAGAMENTO
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-normal-right-light-blue">
      VALOR
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="18" [nzOffset]="0">
      <hr class="line-hr"/>
    </div>
  </div>

  <div nz-row class="div-multibanco">
    <div nz-col nzSpan="12" [nzOffset]="0" class="text-bold-left">
      Multibanco
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ shoppingCart.payment.amountTpa / 100 | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="18" [nzOffset]="0">
      <hr class="dashed-hr"/>
    </div>
  </div>

  <div nz-row class="m-v-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      Montante em Falta
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ shoppingCart.payment.pendingAmount / 100 | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row class="m-b-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      Total Recebido
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ shoppingCart.payment.amountTpa / 100 | number: ".2-2":"pt" }} €
    </div>
  </div>

  <div nz-row class="m-b-10">
    <div nz-col nzSpan="9" [nzOffset]="3" class="text-normal-right-dark-blue toUpperCase">
      A devolver ao Cliente
    </div>
    <div nz-col nzSpan="4" [nzOffset]="1" class="text-bold-right">
      {{ shoppingCart.payment.returnAmount / 100 | number: ".2-2":"pt" }} €
    </div>
  </div>


</div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="12" style="text-align: left;">
      <app-primary-white-button style="border-radius: 6px" (click)="onPreviousClick()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>

    <div nz-col nzSpan="12" style="text-align: right; padding-right: 48px">
      <app-primary-normal-button
        [critical] = "true"
        (click)="onNextClick()"
        buttonCSS="button-seguinte">
        <span class="btn-icon icon-seguinte icon-middle float-right"></span>
        <span class="font-size-17">Seguinte</span>
      </app-primary-normal-button>
    </div>
  </div>
</div>
