import { HttpStatusCode } from 'src/app/shared/enum/http-status-code';
import { StorageManagerService } from './../../../../core/services/storage-manager.service';
import { ShoppingCartService } from './../../../../core/services/shopping-cart.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { Navigation, Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { WebsocketMessage, WebsocketMessageType } from 'src/app/shared/models/websocket-message';
import { CardRead } from 'src/app/shared/models/card-read';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import NavigationManagerService, { TicketingRoutes } from '../../../../core/services/navigation-manager.service';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { SpinService } from '../../../../core/services/spin.service';
import { HandlerComponent, SocketClientService } from '../../../../core/services/websocket-client.service';

@Component({
    selector: 'app-reading-loading-lv',
    templateUrl: './reading-loading-lv-transfer.component.html',
    styleUrls: ['./reading-loading-lv-transfer.component.less']
})
export class ReadingLoadingLvTransferComponent implements OnInit, OnDestroy, HandlerComponent {

    // Page Titles
    bigTitle: string;
    smallTitle: string;
    description: string;

    // Button title
    actionButtonTitle: string;

    navigation: Navigation;
    private action: string;
    private cartRequest: ShoppingCartItemRequest;
    private titleRequest: LoadTitleRequest;

    cardRead: CardRead;
    isCancelDisabled = false;

    public showCancelButton = true;

    constructor(
        private spinService: SpinService,
        private router: Router,
        private peripheralService: PeripheralService,
        private alertService: AlertService,
        private loadTitleService: LoadTitleService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private shoppingCartService: ShoppingCartService,
        private socketClient: SocketClientService
    ) {
        this.socketClient.setHandlerComponent(this);
        this.navigation = this.router.getCurrentNavigation();
    }

    ngOnInit(): void {
        // Websocket is shared by all components, but if somehow is not connected, lets wait for it to connect
        if (!this.socketClient.isConnected) {
            // Loading Screen set true until websocket connects (prevent user click on emit button too quickly)
            this.spinService.setSpinValue(true);
        }
        this.getPageTitles();
    }

    private getPageTitles() {
        this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
        switch (this.action) {
            case ReadingLoadingEnum.TransferContractRead:
                this.bigTitle = 'Leitura de suporte';
                this.description = 'Por favor coloque o suporte destino no leitor';
                this.smallTitle = 'Transferência de Titulo';
                this.actionButtonTitle = 'Transferir título';
                this.showCancelButton = true;
                break;
            case ReadingLoadingEnum.TransferContract:
                this.bigTitle = 'Escrita no suporte';
                this.description = 'Por favor coloque o suporte destino no leitor';
                this.smallTitle = 'Transferência de Titulo';
                this.actionButtonTitle = 'Transferir título';
                this.showCancelButton = true;
                break;
            case ReadingLoadingEnum.DeleteContractRead:
                this.bigTitle = 'Leitura no suporte';
                this.description = 'Por favor coloque o suporte origem no leitor';
                this.smallTitle = 'Eliminar Titulo do Cartão inicial';
                this.actionButtonTitle = 'Limpar suporte';
                this.showCancelButton = false;
                break;
            case ReadingLoadingEnum.DeleteContract:
                this.bigTitle = 'Escrita no suporte';
                this.description = 'Por favor coloque o suporte origem no leitor';
                this.smallTitle = 'Eliminar Titulo do Cartão inicial';
                this.actionButtonTitle = 'Limpar suporte';
                this.showCancelButton = false;
                break;
        }
    }

    ngOnDestroy() {
        this.spinService.setSpinValue(false);
    }

    async actionButtonClick() {
        this.isCancelDisabled = true;
        this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
        await this.peripheralService.detectCard()
            .then(async (response) => {
                if (response.success) {
                    switch (this.action) {
                        case ReadingLoadingEnum.TransferContractRead:
                            this.spinService.setSpinValue(true);
                            await this.peripheralService.startCardScan().toPromise()
                                .then(cardScanResponse => {
                                    this.storageManager.session.set(CardEnum.CARD_DETECTION_BINARY, cardScanResponse.data);
                                }).catch(error => {
                                    console.log('Error: ', error);
                                    this.spinService.setSpinValue(false);
                                    this.isCancelDisabled = false;
                                    this.alertService.error(CommonEnum.msgReadCardError);
                                });
                            break;
                        case ReadingLoadingEnum.DeleteContractRead:
                            this.spinService.setSpinValue(true);
                            await this.peripheralService.startCardScan().toPromise()
                                .catch(error => {
                                    console.log('Error: ', error);
                                    this.spinService.setSpinValue(false);
                                    this.isCancelDisabled = false;
                                    this.alertService.error(CommonEnum.msgReadCardError);
                                });
                            break;
                        default:
                            this.alertService.error(CommonEnum.msgUnknownActionError + this.action);
                            this.isCancelDisabled = false;
                    }
                }
            })
            .catch((error: any) => {
                const errorMessage = error.status === HttpStatusCode.NOT_FOUND ||
                    error.status === HttpStatusCode.INTERNAL_SERVER_ERROR
                    ? CommonEnum.msgDetectCardError
                    : CommonEnum.msgWriteCardError + error.message;

                this.alertService.error(errorMessage);
            });
    }

    async handleMessage(messageBody: WebsocketMessage) {
        // Update page titles
        this.getPageTitles();
        // Handle message
        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId)
                    .toPromise();
                if (!response.success) {
                    this.isCancelDisabled = false;
                    this.alertService.error(response.detail);
                }
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (messageBody.content) as CardDetailsMessage;
                if (cardDetails.details.environment === undefined) {
                    this.spinService.setSpinValue(false);
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
                    switch (this.action) {
                        case ReadingLoadingEnum.DeleteContractRead:
                            const previousCardBinary = this.storageManager.session.get(CardEnum.PREVIOUS_CARD_BINARY) as CardDecodeResponse;
                            // cardDetails: Cartão que possui o contrato a ser deletado (cartão origem da transferência)
                            // previousCardBinary: Cartão para o qual o contrato foi transferido (cartão destino da transferência)
                            // se as informacoes forem diferentes, aceita o delete
                            // caso contrario da uma mensagem por 5 segundos
                            // e depois direciona para a mesma página até que o operador mude o cartao
                            if (cardDetails.low_part === previousCardBinary.lowPart &&
                                cardDetails.high_part === previousCardBinary.highPart
                            ) {
                                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.DeleteContractRead);
                                await this.deleteContract();
                            } else {
                                this.alertService.error(CommonEnum.msgOriginalCardRequest);
                                this.spinService.setSpinValue(false);
                            }
                            break;
                        case ReadingLoadingEnum.TransferContractRead:
                            const oldCard = this.storageManager.session.get<CardDetailsMessage>(
                                CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
                            const newCard = cardDetails;

                            if (oldCard.low_part === newCard.low_part) {
                                this.spinService.setSpinValue(false);
                                this.isCancelDisabled = false;
                                this.alertService.error(CommonEnum.msgSameCardRead, true);
                                break;
                            } else if (CardUtils.IsVV(oldCard.details.environment.card_data_model) &&
                                !CardUtils.IsVV(newCard.details.environment.card_data_model)
                            ) {
                                this.spinService.setSpinValue(false);
                                this.isCancelDisabled = false;
                                this.alertService.error(CommonEnum.msgVVCardError);
                                break;
                            } else if (CardUtils.IsLV(oldCard.details.environment.card_data_model) &&
                                !CardUtils.IsLV(newCard.details.environment.card_data_model)
                            ) {
                                this.spinService.setSpinValue(false);
                                this.isCancelDisabled = false;
                                this.alertService.error(CommonEnum.msgLVCardError);
                                break;
                            }
                            this.storageManager.session.set(CardEnum.NEW_FULL_CARD_DETAILS, newCard);
                            this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.TransferContractRead);

                            await this.transferContract();

                            break;
                        case ReadingLoadingEnum.CheckIfCleanCardContractWasSucessful:
                            const contractLoadRequest = this.storageManager.session
                                .get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                            const contracts = cardDetails.details.contracts;
                            let nullSuccessful = true;
                            const titleLoadedInCard = contracts.some((contract) =>
                                contract.ticket_code === contractLoadRequest.tickCode
                                && contract.operator_code === contractLoadRequest.tickOperCode);

                            if (titleLoadedInCard) {
                                nullSuccessful = false;
                                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.DeleteContractRead);
                                this.alertService.error(CommonEnum.msgCleaningCardError);
                            }
                            if (nullSuccessful) {
                                this.storageManager.session.remove(CardEnum.PREVIOUS_CARD_BINARY);
                                this.storageManager.session.remove(CardEnum.NEW_FULL_CARD_DETAILS);
                                this.storageManager.session.remove(CardEnum.CONTRACT_TO_DELETE);
                                this.storageManager.session.remove(CardEnum.CARD_DETECTION_BINARY);

                                await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                            }
                            this.spinService.setSpinValue(false);
                            break;
                        default:
                            this.alertService.error(CommonEnum.msgUnknownActionError + this.action);
                            break;
                    }
                }
                break;
        }
    }

    async transferContract() {
        await this.peripheralService.detectCard()
            .then(async (response) => {
                if (response.success) {
                    this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                    this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                    const fullCardDetails = this.storageManager.session.get<CardDetailsMessage>(
                        CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
                    const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
                    if (this.cartRequest != null && this.cartRequest.cardSerialNumber != null) {
                        this.cartRequest.cardSerialNumber = cardBinary.lowPart;
                    }
                    const cardContracts = fullCardDetails.details.contracts;
                    let contractSlot = 0;
                    let contractToDelete = 1;
                    let transferContract;
                    cardContracts.forEach((contract) => {
                        if (contract.ticket_code === this.titleRequest.tickCode) {
                            transferContract = contract;
                            transferContract.id = this.titleRequest.id;
                            contractToDelete += contractSlot;
                        }
                        contractSlot++;
                    });
                    this.storageManager.session.set(CardEnum.CONTRACT_TO_DELETE, contractToDelete.toString());
                    try {
                        await this.loadTitleService.transferTitle(transferContract, this.cartRequest, cardBinary, this.titleRequest);
                    } catch (error) {
                        console.log(`TRANSFER CONTRACT ERROR: ---------------- ${error}`);
                        const message = error.error.message.replace(/[""]+/g, '');
                        this.spinService.setSpinValue(false);
                        this.isCancelDisabled = false;
                        if (message.includes('Unable to find')) {
                            this.alertService.error(CommonEnum.msgTransferError);
                        } else {
                            this.alertService.error(message);
                        }
                        throw new Error(message);
                    }
                    this.spinService.setSpinValue(false);
                    this.isCancelDisabled = false;
                    this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.DeleteContractRead);
                    this.getPageTitles();
                }
            });
    }

    async deleteContract() {
        await this.peripheralService.detectCard()
            .then(async (response) => {
                if (response.success) {
                    const previousCardBinary = this.storageManager.session.get(CardEnum.PREVIOUS_CARD_BINARY) as CardDecodeResponse;
                    const contractToDelete = this.storageManager.session.get(CardEnum.CONTRACT_TO_DELETE) as number;

                    const nullifyResponse = await this.shoppingCartService.null(contractToDelete, previousCardBinary);

                    if (nullifyResponse) {
                        // if annulled then we need to check if:
                        // title was really annuled or if title is still in the card
                        // otherwise show error message and stay in this page
                        await this.peripheralService.detectCard()
                            .then(async (responseDetect) => {
                                if (responseDetect.success) {
                                    this.storageManager.session
                                        .set(ReadingLoadingEnum.Action, ReadingLoadingEnum.CheckIfCleanCardContractWasSucessful);
                                    await this.peripheralService.startCardScan().toPromise();
                                } else {
                                    this.spinService.setSpinValue(false);
                                    this.alertService.info(responseDetect.message);
                                }
                            })
                            .catch((error: any) => {
                                this.spinService.setSpinValue(false);
                                this.alertService.error(CommonEnum.msgDetectCardError);
                            });
                    } else {
                        this.spinService.setSpinValue(false);
                        this.alertService.error(CommonEnum.msgCleaningCardError);
                    }
                }
            }).catch((error: any) => {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgTransferDeleteError);
            });
    }

    navigateBack() {
        history.back();
    }

    onConnect(): void {
        // Disable loading after websocket connected and ready (prevent error of user click too quickly on transfer button)
        this.spinService.setSpinValue(false);
    }
}
