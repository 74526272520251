import { TariffService } from 'src/app/core/services/tariff.service';
import { Component, OnInit } from '@angular/core';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableColumn } from 'src/app/shared/models/table-column';
import { NavigationExtras, Router } from '@angular/router';
import { FilterCustInfoRequest } from 'src/app/shared/models/filter-cust-info-request';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.less'],
})
export class CustomerInfoComponent implements OnInit {

  public static GUIDE = 3;
  public static MUSEUM_TICKETS = 5;
  public static PRELOAD = 6;
  public static CCI = 7;
  public static TRANSPORT_UNITS = 8;
  public static FINE = 9;
  public static FAMILY = 12;
  selectedTypeProd = 1;
  listTypeProd: object[] = [];

  selectedCardProfile = null;
  listCardProfile: object[] = [];

  listResultColumn: TableColumn[] = [];
  listResultHeader: TableHeaderData[] = [];
  listResult: object[] = [];

  constructor(
    private router: Router,
    private tariffService: TariffService
  ) {
  }

  ngOnInit(): void {
    this.loadProdType();
    this.loadListCardProfile(1);
    this.prepareTableResult();
    this.loadListResult();
    window.scrollTo(0, 0);
  }

  onTypeProdChanged(value: number) {
    this.selectedCardProfile = null;
    this.selectedTypeProd = value;
    this.loadListCardProfile(value);
    this.loadListResult();
  }

  onCardProfileChanged(value: number) {
    this.selectedCardProfile = value;
    this.loadListResult();
  }

  homeScreen() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['home'], navigationExtras);
  }

  previousScreen() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    };

    this.router.navigate(['tariff-pdf'], navigationExtras);
  }

  resetSearch(): void {
    // TODO Rest of the select and radio does not work
    this.selectedTypeProd = null;
    this.selectedCardProfile = null;
    this.loadListResult();
  }

  async loadProdType() {
    const response: BasicDataResponse = await this.tariffService.getListProdType().toPromise();
    response.data.content.forEach(element => {
      if (!(element.titleGroupId == CustomerInfoComponent.GUIDE || element.titleGroupId == CustomerInfoComponent.MUSEUM_TICKETS
        || element.titleGroupId == CustomerInfoComponent.PRELOAD || element.titleGroupId == CustomerInfoComponent.CCI
        || element.titleGroupId == CustomerInfoComponent.FINE)) {
        this.listTypeProd.push({
          value: element.titleGroupId,
          label: element.titleGroupDescription
        })
      }
    }
    );
  }

  async loadListCardProfile(value: number) {
    const response: BasicDataResponse = await this.tariffService.getListCardProfile(value).toPromise();
    var cardProfileList = [];
    response.data.content.forEach(element => {
      cardProfileList.push({
        value: element.id,
        label: element.description
      })
    });
    this.listCardProfile = cardProfileList;
  }

  prepareTableResult() {
    this.listResultColumn = [
      { title: 'PRODUTOS', config: { colWidth: '60%' }},
      { title: 'VALOR UNITÁRIO' },
      { title: 'IVA' },
      { title: '' },
    ];

    this.listResultHeader = [
      {
        visible: false,
        name: 'id',
        config: null,
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'description',
        config: null,
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'price',
        config: { valAlign: 'left', money: true },
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'iva',
        config: { valAlign: 'left', type: 'PERCENT' },
        button: null,
      } as TableHeaderData,
      {
        visible: true,
        name: 'operador',
        config: null,
        button: null,
      } as TableHeaderData,
    ];
  }

    async loadListResult() {
        const request: FilterCustInfoRequest = {
            titleGroupIds: this.selectedTypeProd === null ? null : [this.selectedTypeProd],
            cardProfileIds: this.selectedCardProfile === null ? null : [this.selectedCardProfile],
            all: false,
            pageSize: 1000
        };
        var resultList = [];
        if (this.selectedTypeProd == CustomerInfoComponent.FAMILY) {
            const responseFamily: BasicDataResponse = await this.tariffService.getTitleByTitleGroup(this.selectedTypeProd).toPromise();
            responseFamily.data.forEach(element => {
                resultList.push({
                    id: element.id,
                    description: element.description,
                    price: element.price.value,
                    iva: element.vat
                })
            });
            this.listResult = resultList;
        } else {
            const response: BasicDataResponse = await this.tariffService.filter(request).toPromise();
            response.data.content.forEach(element => {
                resultList.push({
                    id: element.id,
                    description: element.description,
                    price: element.price.value,
                    iva: element.vat
                })
            });
            this.listResult = resultList;
            if(request.titleGroupIds.some(value => value == CustomerInfoComponent.TRANSPORT_UNITS)) {
                this.listResult = resultList.sort((a,b) => (a.id > b.id) ? 1 : -1);
            }
        }
    }
}
