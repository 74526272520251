import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { DisabledTimeFn } from 'ng-zorro-antd';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.less']
})
export class DateTimePickerComponent implements OnInit {


  @Input() inputCss?: string;
  @Input() data;
  @Input() futureDateOnly = false;
  @Input() allowAllDates = false;
  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange(event) {
    this.valueChange.emit(event);
  }

  disabledDate = (current: Date): boolean => {
    const today = new Date();
    // Can select days before today and today
    if (this.allowAllDates) {
      return false;
    } else if (this.futureDateOnly) {
      return differenceInCalendarDays(current, today) < 0;
    }
    return differenceInCalendarDays(current, today) > 0;
  }

  disabledTime: DisabledTimeFn = () => ({
    nzDisabledHours: () => this.timeRange(true, false, false),
    nzDisabledMinutes: () => this.timeRange(false, true, false),
    nzDisabledSeconds: () => this.timeRange(false, false, true)
  })

  private timeRange(hours: boolean, minutes: boolean, seconds: boolean): number[] {
    const today = new Date();
    let startTime = 0;
    let maxTime = 60;
    if (hours) {
      startTime = today.getHours() + 1;
      maxTime = 24;
    } else if (minutes) {
      startTime = today.getMinutes() + 1;
    } else if (seconds) {
      startTime = today.getSeconds() + 1;
    }
    const result: Array<number> = [];
    if (this.allowAllDates) {
      return result;
    } else if (this.futureDateOnly) {
      for (let i = 0; i < maxTime; i++) {
        result.push(i);
      }
      return result;
    }
    for (let i = startTime; i < maxTime; i++) {
      result.push(i);
    }
    return result;
  }

}
