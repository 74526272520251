import { ConsumeAuthorizationRequest } from './../../../../../shared/models/consume-authorization-request';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import { Component, OnInit } from '@angular/core';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { ShiftService } from 'src/app/core/services/shift.service';
import { ShoppingCart } from 'src/app/modules/operation/modules/shopping-cart';
import { Navigation, Router } from '@angular/router';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { Load } from '../models/load';
import { TitleDetailsService } from 'src/app/core/services/title-details.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardEnum } from '../../../../../shared/enum/card.enum';
import { CardDetailsMessage } from '../../../../../shared/models/card-details-message';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { ValidityDatesRequest } from 'src/app/shared/models/validity-dates-request';
import { TariffService } from 'src/app/core/services/tariff.service';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { TitleDTO } from '../../../../../shared/models/title-dto';
import { AlertService } from 'src/app/core/services/alert.service';
import { TitleGroupEnum } from '../../../../../shared/enum/title-group.enum';
import { FreeTitleEnum } from 'src/app/shared/enum/free-title.enum';
import { SelectItem } from 'src/app/shared/utils/http/http-commons-interfaces';
import { OriginalTitlePrice, RechargeTitleValue } from 'src/app/modules/operation/modules/recharge-title-value';
import { CommercialOfferService } from 'src/app/core/services/commercial-offer.service';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { SpinService } from 'src/app/core/services/spin.service';

enum LoadType {
    LOAD,
    EXCHANGE,
    AUTHORIZATION,
    TRANSFER,
    LOAD_COMPOSED_TITLE
}

@Component({
    selector: 'app-title-details',
    templateUrl: './title-details.component.html',
    styleUrls: ['./title-details.component.less'],
})
export class TitleDetailsComponent implements OnInit {

    private static DEFAULT_QUANTITY = '1';

    load: Load;

    navigation: Navigation;

    loadType: LoadType;

    shoppingCart: ShoppingCart = {} as ShoppingCart;
    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];

    data: CardReadTitle[] = [];
    tableData: CardReadTitle[] = [];

    newValidityStart: Date;
    newValidityEnd: Date;
    offset: string;

    validityTitleId: number;

    peripheralId: string;
    machineNumber: string;

    discount: number;
    titleDescription: string;
    buttonLabel: string;

    supportVVProduct: TitleDTO;

    card: CardDetailsMessage = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

    checkboxVV = false;
    public dropListRechargeTitleValues: Array<SelectItem> = [];
    public rechargeTitleValueList: Array<RechargeTitleValue> = [];
    public selectedRechargeTitleValue: RechargeTitleValue;
    public dropdownLabelMessage = 'Meses';
    public showDropdown = false;
    private originalPrice: number;
    private originalTitlePrices: OriginalTitlePrice[] = [];

    constructor(
        private router: Router,
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private titleDetailsService: TitleDetailsService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private tariffService: TariffService,
        private alertService: AlertService,
        private commercialOfferService: CommercialOfferService,
        private spinService: SpinService
    ) {
        this.peripheralService.getPeripheralId().then((id) => {
            this.peripheralId = id;
        });

        this.peripheralService.getMachineNumber().then((machine) => {
            this.machineNumber = machine;
        });

        const navExtras = this.router.getCurrentNavigation()?.extras?.state;
        if (!navExtras) {
            this.homeScreen();
        }

        if (navExtras?.load) {
            this.load = navExtras.load;
        }

        if (navExtras?.newTitle) {
            this.showDropdown = true;
            this.data.push(navExtras.newTitle);
            if (navExtras?.titleForExchange) {
                this.data.push(navExtras.titleForExchange);
                this.loadExchangeData();
            } else if (this.data[0].titles && this.data[0].titles.length > 0) {
                this.loadComposedTitleData();
            } else {
                this.loadTitleData();
            }
        } else if (navExtras?.authTitle) {
            this.data.push(navExtras.authTitle);
            this.loadAuthorizationData();
        } else if (navExtras?.transferTitle) {
            this.data.push(navExtras.transferTitle);
            this.loadTransferData();
        }

        if (navExtras?.titleForExchange) {
            this.titleDescription = this.data[1].description + ' - ' + this.data[0].description;
            this.data[1].expiringDateBalance = navExtras.titleForExchange.amount;
            this.buttonLabel = 'Trocar';
        } else {
            if (navExtras?.authTitle || navExtras?.newTitle) {
                this.titleDescription = this.data[0].description;
                this.buttonLabel = 'Carregar';
                if (this.data[0].expiringDateBalance === ',0 €') {
                    delete this.data[0].expiringDateBalance;
                }
                if (!navExtras?.authTitle) {
                    // default amount for titles such as the EMEL Pass
                    for (let i = 0; i < this.data[0].titles.length; i++) {
                        this.data[0].titles[i].expiringDateBalance = TitleDetailsComponent.DEFAULT_QUANTITY;
                        const title = {
                            price: this.data[0].titles[i].price,
                            title: this.data[0].titles[i].id
                        }
                        this.originalTitlePrices.push(title);
                    }
                }
            } else if (navExtras?.transferTitle) {
                const transferTitle = navExtras?.transferTitle;
                if (transferTitle.isSlidingTitle || transferTitle.isFixedMonth) {
                    this.data[0].expiringDateBalance = navExtras?.transferTitle.loadAmount;
                }
                this.listTitleColumn.push({ title: 'SALDO/QUANTIDADE', config: { colAlign: 'left', colWidth: '40%' } });
                this.listTitleHeader.push();
                this.buttonLabel = 'Transferir';
                this.alertService.info('Por favor, coloque o suporte de destino para o qual pretende efetuar a transferência');
            }
        }

        // default quantity for normal titles
        if (this.data[0].expiringDateBalance == '0' || !this.data[0].expiringDateBalance) {
            this.data[0].expiringDateBalance = TitleDetailsComponent.DEFAULT_QUANTITY;
        }
        this.originalPrice = this.data[0].price;
        if (this.isSlidingTitle()) {
            this.dropdownLabelMessage = 'Dias';
        }
    }

    async ngOnInit() {
        await this.getValidityDates();
        this.prepareTable();
        this.supportVVProduct = await this.tariffService.getSupportVVProduct().toPromise()
            .then(basicDataResponse => basicDataResponse.data);
        if (this.showDropdown) {
            this.getRechargeValuesByTitleId();
        }
    }

    async shoppingLoad() {
        let shoppingCartRequest: ShoppingCartItemRequest;

        let loadQuantity = this.selectedRechargeTitleValue != null ?
            this.selectedRechargeTitleValue.quantity :
            1;

        if (this.loadType !== LoadType.TRANSFER) {
            shoppingCartRequest = {
                shiftId: Number(this.shiftService.getShiftID()),
                titleId: this.data[0].id,
                description: this.data[0].description,
                quantity: loadQuantity,
                price: this.data[0].price,
                transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
                annulId: null,
                cardNumber: String(this.card.details.environment.issuing_number),
                loadId: null,
                requisitionId: null,
                authId: null,
                replacementId: null,
                voucherId: 0,
                cardSerialNumber: this.card.low_part,
                isAnnul: false,
                discount: this.data[0].discount != null ? this.data[0].discount : null
            };
        }
        if (this.checkboxVV) {
            shoppingCartRequest = {
                shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
                titleId: this.supportVVProduct.id,
                description: this.supportVVProduct.description,
                quantity: 1,
                price: this.supportVVProduct.price['value'],
                transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
                annulId: null,
                // VV does not have cardNumber
                cardNumber: null,
                loadId: null,
                requisitionId: null,
                authId: null,
                replacementId: null,
                voucherId: 0,
                cardSerialNumber: this.card.low_part,
                isAnnul: false,
                discount: null,
            };
        }
        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);

        if (this.loadType === LoadType.TRANSFER) {
            loadQuantity = Number(this.data[0].expiringDateBalance);
        }

        const loadTitleRequest: LoadTitleRequest = {
            peripheralId: this.peripheralId,
            serialNumber: this.load.cardRead.serialNumber,
            cardNumber: CardUtils.IsVV(this.card.details.environment.card_data_model)
                ? this.load.cardRead.serialNumber
                : this.getLVNumber(),
            tickCode: this.data[0].tickCode,
            tickOperCode: this.data[0].tickOperCode,
            id: this.data[0].id,
            voucherNumber: '',
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
            amount: 1,
            ticketQuantity: loadQuantity,
            addVVToCart: this.checkboxVV
        };

        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);

        switch (this.loadType) {
            case LoadType.TRANSFER:
                const currentCardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
                this.storageManager.session.set(CardEnum.PREVIOUS_CARD_BINARY, currentCardBinary);
                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.TransferContractRead);
                await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV_TRANSFER, shoppingCartRequest);
                break;
            case LoadType.AUTHORIZATION:
                const consumeAuthorizationRequest: ConsumeAuthorizationRequest = {
                    cardNumber: this.load.cardRead.number,
                    loadAuthorizationId: Number(this.data[0].idAuthorization),
                    machine: this.machineNumber,
                    saleId: 0,
                    shiftId: Number(this.shiftService.getShiftID()),
                    ownerId: Number(this.shiftService.getUsername()),
                    serialNumber: this.load.cardRead.serialNumber,
                    tickCode: this.load.tickCode,
                    tickOperCode: this.load.tickOperCode,
                    titleId: Number(this.data[0].id),
                    value: 0
                };

                // Update shopping cart request with AuthId
                shoppingCartRequest.authId = Number(this.data[0].idAuthorization);
                this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);

                this.storageManager.session.set(ReadingLoadingEnum.ConsumeAuthorizationRequest, consumeAuthorizationRequest);
                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadAuthorization);
                await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, shoppingCartRequest);
                break;
            case LoadType.LOAD_COMPOSED_TITLE:
                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadComposedTitle);
                await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, shoppingCartRequest);
                break;
            default:
                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.LoadContract);
                await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV, shoppingCartRequest);
                break;
        }
    }

    async changeTitleService() {
        // TODO--------new exchange workflow request-----------
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const binary = this.storageManager.session.get<CardDecodeResponse>(CardEnum.CARD_DETECTION_BINARY);

        const currentTitle = this.data[1];
        const newTitle = this.data[0];

        const loadQuantity = this.selectedRechargeTitleValue != null ?
            this.selectedRechargeTitleValue.quantity :
            1;

        const request = {
            titleId: newTitle.id,
            cancelTitleId: currentTitle.id,
            amount: 0,
            quantity: loadQuantity,
            peripheralId: this.peripheralId,
            transaction: LoadTitleTransactionType.TRANSACTION_VEND_CARR,
            cardFullDetails: cardDetails,
            binary,
            shiftId: Number(this.shiftService.getShiftID()),
            discount: newTitle.discount != null ? newTitle.discount : null
        };

        this.storageManager.session.set(ReadingLoadingEnum.SwitchRequest, request);
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.SwitchContract);

        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV);
    }

    private loadTransferData() {
        this.loadType = LoadType.TRANSFER;
        this.tableData = this.data;
        this.validityTitleId = this.data[0].id;
    }

    private loadAuthorizationData() {
        this.loadType = LoadType.AUTHORIZATION;
        this.tableData = this.data;
        this.validityTitleId = this.data[0].id;
    }

    private loadTitleData() {
        this.loadType = LoadType.LOAD;
        if (this.data[0]?.price === 0 && this.data[0]?.titlePrice != null) {
            this.data[0].price = this.data[0].titlePrice;
        }
        this.tableData = this.data;
        this.validityTitleId = this.data[0].id;
    }

    private loadComposedTitleData() {
        this.loadType = LoadType.LOAD_COMPOSED_TITLE;
        this.tableData = this.data[0].titles;
        // TODO FIX THIS... Como verificar qual a data de validade do titulo quando varios titulos?
        const titleToLoad = this.data[0].titles.find((i) => i.toLoad);
        this.validityTitleId = titleToLoad.id;
    }

    private loadExchangeData() {
        this.loadType = LoadType.EXCHANGE;
        const infoFromFirstTitle = this.data[0] as any;
        this.data[0].price = infoFromFirstTitle.price.value;
        this.tableData = this.data;
        this.validityTitleId = this.data[0].id;
    }

    async getValidityDates() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        const request: ValidityDatesRequest = {
            titleId: this.validityTitleId,
            cardFullDetails: cardDetails,
        };
        const response = await this.titleDetailsService.getValidityDates(request) as any;
        this.newValidityStart = response?.startValidity;
        this.newValidityEnd = response?.endValidity;
        this.offset = DateUtils.getTimezone();
    }

    public onCheckVV(event: any): void {
        this.checkboxVV = event;
        // If new card checkbox selected, add card to table
        if (!this.tableData.some(element => element.description === this.supportVVProduct.description)) {
            this.tableData = [...this.tableData, TitleDetailsComponent.mapTitleDTOToCardReadableTitle(this.supportVVProduct)];
        } else {
            this.tableData = this.data.filter(cardReadTitle => cardReadTitle.description !== this.supportVVProduct.description);
        }
    }

    /* Utils */
    public isVV(): boolean {
        return CardUtils.IsVV(this.card.details.environment.card_data_model) && this.data[0].price === 0;
    }

    public isExchange(): boolean {
        return this.loadType === LoadType.EXCHANGE;
    }

    public isValidDate(): boolean {
        // validation to see if label with validity dates appears
        // single tickets in VV's --> false --> label not shown
        return this.newValidityStart != null && this.newValidityEnd != null &&
            !CardUtils.IsVV(this.card.details.environment.card_data_model);
    }

    private getLVNumber() {
        return this.card.details.environment.app_issuer.toString()
            .padStart(3, '0') + '0' + this.card.details.environment.issuing_number.toString().padStart(8, '0');
    }

    private static mapTitleDTOToCardReadableTitle(titleDTO: TitleDTO): CardReadTitle {
        return {
            id: titleDTO.id,
            description: titleDTO.description,
            price: titleDTO.price['value'],
            tickCode: titleDTO.tickCode,
            tickOperCode: titleDTO.tickOperCode,
            titleGroup: titleDTO.titleGroup
        } as CardReadTitle;
    }

    /* TABLE */
    prepareTable() {
        if (this.data[0]?.discount && this.data[0]?.discount > 0) {
            this.tableWithDiscount();
        } else {
            this.defaultTable();
        }
    }

    private tableWithDiscount() {
        this.listTitleColumn = [
            { title: 'TÍTULO', config: { colAlign: 'left', colWidth: '35%' } },
            { title: 'PREÇO', config: { colAlign: 'right', colWidth: '12,5%' } },
            { title: 'DESCONTO', config: { colAlign: 'right', colWidth: '12,5%' } },
            { title: 'SALDO/QUANTIDADE', config: { colAlign: 'right', colWidth: '20%' } },
            { title: 'VALOR', config: { colAlign: 'right', colWidth: '20%' } },
        ];

        this.listTitleHeader = [
            {
                visible: false,
                name: 'id',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'description',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'right', money: true },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'discount',
                config: { valAlign: 'right', percent: true },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'expiringDateBalance',
                config: { valAlign: 'center' },
                button: null
            } as TableHeaderData,
            {
                visible: true,
                name: 'priceWithDiscount',
                config: { valAlign: 'right', money: true },
                button: null,
            } as TableHeaderData
        ];
    }

    private defaultTable() {
        this.listTitleColumn = [
            { title: 'TÍTULO', config: { colAlign: 'left', colWidth: '50%' } },
            { title: 'SALDO/QUANTIDADE', config: { colAlign: 'center', colWidth: '30%' } },
            { title: 'VALOR', config: { colAlign: 'right', colWidth: '50%' } },
        ];

        this.listTitleHeader = [
            {
                visible: false,
                name: 'id',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'description',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true, name: 'expiringDateBalance',
                config: { valAlign: 'center' },
                button: null
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'right', money: true },
                button: null,
            } as TableHeaderData,
        ];
    }

    private async getRechargeValuesByTitleId(): Promise<void> {
        this.spinService.setSpinValue(true);
        await this.commercialOfferService.getRechargeValuesByTitleId(this.validityTitleId)
            .then((response: BasicResponseDTO<Array<RechargeTitleValue>>) => {
                if (response?.success && response?.data) {
                    this.rechargeTitleValueList = response.data;
                    // by default the first element shoud be selected
                    this.selectedRechargeTitleValue = this.rechargeTitleValueList[0];
                    this.dropListRechargeTitleValues = response.data
                        .map((rechargeTitleValue: RechargeTitleValue) => {
                            return {
                                value: rechargeTitleValue.value,
                                label: String(rechargeTitleValue.value)
                            };
                        });
                    this.handleDropdownSelect(this.dropListRechargeTitleValues[0]?.value);
                }
            })
            .catch((error) => {
                this.spinService.setSpinValue(false);
                this.alertService.error(error.error.message);
            });
        this.spinService.setSpinValue(false);
    }

    public handleDropdownSelect(value: any): void {
        this.selectedRechargeTitleValue = this.rechargeTitleValueList
            .find(rechargeTitleValue => rechargeTitleValue.value === value);
        this.data[0].price = this.originalPrice * this.selectedRechargeTitleValue.quantity;
        this.data[0].expiringDateBalance = this.selectedRechargeTitleValue.quantity.toString();
        for (let i = 0; i < this.data[0].titles.length; i++) {
            this.data[0].titles[i].expiringDateBalance = this.selectedRechargeTitleValue.quantity.toString();
            this.data[0].titles[i].price = this.originalTitlePrices[i].price * this.selectedRechargeTitleValue.quantity;
        }
        const startDate = new Date(this.newValidityStart);
        // subtract 1 to the value because the first one counts
        // example: value: 30 days | start-> 19/12/2022 | end-> 17/01/2023
        // day 19 counts so we just need to add 29 days
        // same thing for months -> example: value: 2 months  | start-> 01/12/2022 | end-> 31/01/2023
        // month of december counts so we just need to add 1 month
        // sliding titles
        this.newValidityEnd = new Date(
            startDate.setDate(startDate.getDate() + this.selectedRechargeTitleValue.value - 1));
        if (!this.isSlidingTitle()) {
            // monthly titles
            const originalStartDate = new Date(this.newValidityStart);
            // despite our need to substract 1 as stated in the comment above
            // for months we use the direct value of selectedRechargeTitleValue
            // because Date function "getMonth()" starts counting months from 0 (January)
            this.newValidityEnd = DateUtils.addMonths(
                originalStartDate, this.selectedRechargeTitleValue.value);
        }
    }

    private isSlidingTitle(): boolean {
        return TitleGroupEnum.DIVERSE_TICKETS === this.load.titleGroup;
    }

    /* Routing Methods */
    async homeScreen() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public nextClick(): void {
        this.data.length === 1 ? this.shoppingLoad() : this.changeTitleService();
    }

    async previousClick() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        switch (this.loadType) {
            case LoadType.EXCHANGE:
                await this.navigationManager.go(TicketingRoutes.TITLE_SELECTION, {
                    titleDetails: this.data[1],
                    load: this.load,
                });
                break;
            case LoadType.AUTHORIZATION:
                await this.navigationManager.go(TicketingRoutes.HOME);
                break;
            case LoadType.LOAD:
            case LoadType.LOAD_COMPOSED_TITLE:
            case LoadType.TRANSFER:
                await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
                break;
            default:
                break;
        }
    }

}
