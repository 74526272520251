export interface RequestCreateEvent {
    eventTypeId?: number;
    machineCode?: string;
    resolved?: boolean;
    controllerId?: string;
}

export enum EventTypeEnum {
    LOGOUT = 1,
    LOGIN = 2,
    PERIPHERAL_ERROR = 3,
    PRINTER_ERROR = 4,
    READING_ERROR = 5,
    CONTACTLESS_ERROR = 6,
    WRITING_ERROR = 7
}
