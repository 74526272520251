import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { FineService } from 'src/app/core/services/fines.service';
import { ShoppingCartItemRequest } from '../../../../shared/models/shopping-cart-item-request';
import { LoadTitleTransactionType } from '../../../../shared/enum/load-title-transaction-type.enum';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { ShoppingCartService } from '../../../../core/services/shopping-cart.service';
import { VatValidationRequest } from '../../../../shared/models/vat-validation-request';
import { SaleService } from '../../../../core/services/sale.service';
import { FineFiltersMaxLengthEnum } from 'src/app/shared/enum/fine.enum';
import { Fine, FineRegisterRequest } from 'src/app/shared/models/fine';
import { FineValue } from '../../modules/fine';

@Component({
    selector: 'fine-register-page',
    templateUrl: './fine-register.component.html',
    styleUrls: ['./fine-register.component.less']
})
export class FineRegisterComponent implements OnInit {

    public paymentValueOptions: FineValue[] = [];

    public finedPersonFiscalNumber: string;
    public finedPersonName: string;
    public noticeNumber: number;
    public finePrice: number;
    public fineDate: Date;
    public isVatInternational: boolean;
    private titleId: number;

    public noticeNumberMaxLength = FineFiltersMaxLengthEnum.NOTICE_NUMBER_MAX_LENGTH;
    public nameMaxLength = FineFiltersMaxLengthEnum.NAME_MAX_LENGTH;
    public fiscalNumberMaxLength = FineFiltersMaxLengthEnum.FISCAL_NUMBER_MAX_LENGTH;

    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private fineService: FineService,
        private shoppingService: ShoppingCartService,
        private storageManagerService: StorageManagerService,
        private saleService: SaleService

    ) { }

    async ngOnInit(): Promise<void> {
        this.paymentValueOptions = (await this.fineService.fineValuesInfo()).data;
    }

    public checkIfVatValid(vat: string) {
        const vatNumberSize = 9;
        const vatLength = vat.toString().length;

        if (vatLength === vatNumberSize) {
            const request: VatValidationRequest = {
                vat
            };
            return this.saleService.isVatValid(request);
        }

        return vatLength === 0;
    }

    public async registerFine() {
        this.spinService.setSpinValue(true);
        if (!this.isVatInternational) {
            const isVatValid = await this.checkIfVatValid(this.finedPersonFiscalNumber);
            if (!isVatValid) {
                this.alertService.error(CommonEnum.msgInvalidNifError);
                this.spinService.setSpinValue(false);
                return;
            }
        }
        try {
            const isNoticeNumberAvailableResponse = await this.fineService.isNoticeNumberAvailable(this.noticeNumber);
            if (isNoticeNumberAvailableResponse && isNoticeNumberAvailableResponse.success && !isNoticeNumberAvailableResponse.data) {
                this.alertService.error(CommonEnum.msgNoticeNumberAlreadyExistError);
                this.noticeNumber = null;
                this.spinService.setSpinValue(false);
                return;
            }
        } catch (error) {
            this.alertService.error(error.error.message);
            this.noticeNumber = null;
            this.spinService.setSpinValue(false);
            return;
        }

        try {
            // check if notice number is already in shopping cart
            const isNoticeNumberInShoppingCart = await this.isNoticeNumberInShoppingCart();
            if (isNoticeNumberInShoppingCart) {
                this.alertService.error(CommonEnum.msgNoticeNumberAlreadyExistInShoppingCartError);
                this.noticeNumber = null;
                this.spinService.setSpinValue(false);
                return;
            }
        } catch (error) {
            this.alertService.error(error.error.message);
            this.noticeNumber = null;
            this.spinService.setSpinValue(false);
            return;
        }

        try {
            const fineRegisterRequest = {
                paidValue: this.finePrice,
                noticeNumber: this.noticeNumber,
                finedPersonFiscalNumber: this.finedPersonFiscalNumber,
                finedPersonName: this.finedPersonName,
                date: this.fineDate,
                preRegister: true
            } as FineRegisterRequest;
            const savedFine = await this.fineService.saveFine(fineRegisterRequest);
            if (savedFine && savedFine.success) {
                const request = savedFine.data;
                request.titleId = this.titleId;
                const shoppingCartRequest = this.buildShoppingCartItemRequest(request);
                await this.shoppingService.insert(shoppingCartRequest)
                    .then(() => {
                        this.spinService.setSpinValue(false);
                        this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                    });
            }
        } catch (error) {
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    private async isNoticeNumberInShoppingCart(): Promise<boolean> {
        this.spinService.setSpinValue(true);
        let isNoticeNumberInShoppingCart = false;
        const shiftId = Number(this.storageManagerService.session.get(CommonEnum.shiftID));
        try {
            const response = await this.shoppingService.getCartByShiftId(shiftId);
            if (response.success && response?.data != null && response?.data.content.length > 0) {
                const shoppingCartItems = response.data?.content[0]?.items;
                const fineIdList = [];
                Object.keys(shoppingCartItems).forEach(key => {
                    if (shoppingCartItems[key].fineId != null) {
                        fineIdList.push(shoppingCartItems[key].fineId);
                    }
                });
                if (fineIdList.length > 0) {
                    const fineResponse = await this.fineService.findFinesByIds(fineIdList);
                    if (fineResponse.success && fineResponse?.data != null) {
                        Object.keys(fineResponse.data).forEach(key => {
                            if (this.noticeNumber === fineResponse.data[key].noticeNumber) {
                                isNoticeNumberInShoppingCart = true;
                            }
                        });
                    }
                }
            }
            this.spinService.setSpinValue(false);
        } catch (error) {
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
        return isNoticeNumberInShoppingCart;
    }

    private buildShoppingCartItemRequest(fine: Fine): ShoppingCartItemRequest {
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            titleId: fine?.titleId,
            description: `Auto ${fine.noticeNumber}€`,
            quantity: 1,
            price: fine?.fineValue,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
            fineId: fine.id,
            annulId: null,
            cardNumber: null,
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: null,
            cardSerialNumber: null,
            isAnnul: false
        } as ShoppingCartItemRequest;
    }

    public onNoticeNumberChange(value: any): void {
        this.noticeNumber = Number(value);
        if (value === '') {
            this.noticeNumber = null;
        }
    }

    public onFinedPersonFiscalNumberChange(value: string): void {
        this.finedPersonFiscalNumber = value;
    }

    public onFinedPersonNameChange(value: string): void {
        this.finedPersonName = value;
    }

    public onDateChange(value: Date): void {
        this.fineDate = value;
    }

    public onPaymentValueChange(value: number): void {
        this.finePrice = value;
        this.titleId = this.paymentValueOptions.find(payment => payment.value === value).titleId;
    }

    public checkNextDisableState(): boolean {
        return !(!!this.finePrice && !!this.finedPersonName && !!this.noticeNumber && !!this.finedPersonFiscalNumber);
    }

    public previousView(): void {
        this.navigationManager.go(TicketingRoutes.FINES);
    }

}
