<input
  nz-input
  [ngClass]="inputCSS"
  [type]="type"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="onValueChange($event)"
  [style.width]="width"
  [style.height]="height"
  [style.text-align]="textAlign"
  [disabled]="isDisabled"
  [ngStyle]="inputStyle"
  [style.color]="color"
/>
