import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CommonEnum} from 'src/app/shared/enum/common.enum';
import { EnvironmentUtil } from 'src/environments/environment-util';
import {StorageManagerService} from './storage-manager.service';
import {AlertService} from '../services/alert.service';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import {BasicResponseDTO} from "../../shared/models/basic-response-dto";
import {CardDecodeResponse} from "../../shared/models/card-decode-response";

interface DeviceIdResponse {
  value: string;
}

interface MachineConfigResponse {
  machineNumber: string;
  storeName: string;
  address: string;
}

@Injectable({
  providedIn: 'root'
})
export class PeripheralService {

  constructor(private httpClient: HttpClient,
              private storageManager: StorageManagerService,
              private alertService: AlertService) {

  }

  public hasId(): boolean {
    return this.storageManager.session.get(CommonEnum.peripheralId) != null;
  }

  public async getMachineNumber(): Promise<string> {

      // fetch machine config from local peripheral api
      const response = await this.httpClient
        .get<MachineConfigResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/peripheral/config`).toPromise();

      return response.machineNumber;
  }

  public async getAddress(): Promise<string> {

    // fetch machine config from local peripheral api
    const response = await this.httpClient
      .get<MachineConfigResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/peripheral/config`).toPromise();

    return response.address;
  }

  public async getStoreName(): Promise<string> {
    // fetch machine config because it has all the information needed
    const response = await this.httpClient
      .get<MachineConfigResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/peripheral/config`).toPromise();
    return response.storeName;
}

public getInfoFromMachine() {
   return this.httpClient.get<MachineConfigResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/peripheral/config`).toPromise();
}

  public async getPeripheralId(): Promise<string> {

    if (this.hasId()) {
      // fetch id from local storage
      return this.storageManager.session.get<string>(CommonEnum.peripheralId);

    } else {

      // fetch id from local peripheral api
      const response = await this.httpClient
        .get<DeviceIdResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/peripheral/id`).toPromise();

      if (response) {
        this.storageManager.session.set(CommonEnum.peripheralId, response.value);
      }

      return response.value;
    }
  }

  public startCardScan(): Observable<BasicDataResponse> {
    return this.httpClient.get<BasicDataResponse>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/card/read`);
  }

  public async detectCard(): Promise<BasicResponseDTO<CardDecodeResponse>> {
    return await this.httpClient.get<BasicResponseDTO<CardDecodeResponse>>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/card/detect`).toPromise();
  }

  public sendCommandToCardReader(cardInfo: any, requestId: string): Observable<BasicDataResponse> {
    const message = {
      requestId,
      channelAction: cardInfo?.channelAction,
      command: cardInfo?.command
    };

    return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/card/exchange-apdu`, message);
  }

    public async sendToPrinter(content: any): Promise<string> {
        try {
            const response = await this.httpClient
                .post<BasicResponseDTO<any>>(`${EnvironmentUtil.getEnv().peripheralApiUrl}api/printer/print`, content).toPromise();
            if (response.success) {
                if (CommonEnum.msgPrintDocumentSuccess === response.message) {
                    this.alertService.success(response.message, true);
                }
                return response.message;
            } else {
                return null;
            }
        } catch (e) {
            this.alertService.error(e.error.message, true);
            return null;
        }
    }
}
