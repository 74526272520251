import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AlertService} from './alert.service';
import {IsSerialNumberOnBlackListResponse} from '../../shared/models/is-serial-number-on-black-list-response';
import {CommonEnum} from '../../shared/enum/common.enum';
import {EnvironmentUtil} from '../../../environments/environment-util';

@Injectable({
  providedIn: 'root'
})
export class BlackListService {

  constructor(private httpClient: HttpClient, private alertService: AlertService) {
  }

  public async isSerialNumberOnBlackList(cardSerialNumber: string): Promise<boolean> {

    const params = new HttpParams().set('cardSerialNumber', cardSerialNumber);

    const response = await this.httpClient
      .get<IsSerialNumberOnBlackListResponse>(
        `${EnvironmentUtil.getEnv().apiIntApiUrl}black-list/serv/black-list-service/is-in-black-list`, {params}
      ).toPromise();

    if (response && response.success && response.data.cardSerialNumber === cardSerialNumber) {
      return response.data.isInBlackList;
    } else {
      this.alertService.info(CommonEnum.msgIsOnBlackListError);
      return false;
    }
  }
}




