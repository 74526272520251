<div class="detail-content">
    <div nz-row style="height: 100%;">
        <div nz-col [nzSpan]="8">
                <div *ngIf="isVV; else lvTitle" nz-row>
                    <h1 class="lisboa" id="one-line">
                        {{ this.isReplacementGuide ? REPLACEMENT_GUIDE_VV : NORMAL_VV }}
                        {{ cardRead.serialNumber }}
                    </h1>
                </div>
                <ng-template ng-template #lvTitle>
                    <h1 class="lisboa" id="one-line">
                        Lisboa VIVA/navegante personalizado Nº {{ getLVNumber() }}
                    </h1>
                </ng-template>
            <div *ngIf="!isVV; else vvProperties">
                <div nz-row>
                    <div nz-col>
                        <label>Nº de Série:&nbsp;</label>
                    </div>
                    <div nz-col class="value">
                        <label class="label">{{ cardRead.serialNumber }}</label>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col>
                        <label>Validade:&nbsp;</label>
                    </div>

                    <div nz-col class="value" *ngIf="this.seeIfCardIsExpired(); else stillValid">
                        <label class="label-expired">
                            {{ cardRead.expiringDate | date: "dd/MM/yyyy" }}
                            &nbsp;(Expirado)
                        </label>
                    </div>

                    <ng-template #stillValid>
                        <div nz-col class="value">
                            <label class="label">
                                {{ cardRead.expiringDate | date: "dd/MM/yyyy" }}
                            </label>
                        </div>
                    </ng-template>

                </div>
                <div nz-row class="holderHeight">
                    <div nz-col>
                        <label>Titular:&nbsp;</label>
                    </div>
                    <div nz-col class="value">
                        <label class="label">{{ cardRead.holder }}</label>
                    </div>
                </div>
                <div nz-row class="lastLoadHeight">
                    <div nz-col>
                        <label>Perfil:&nbsp;</label>
                    </div>
                    <div nz-col>
                        <label class="longLabelLast">{{ this.profileInfo }}</label>
                    </div>
                </div>
            </div>
            <ng-template #vvProperties>
                <div style="top:10px; position:relative;">
                    <div nz-row>
                        <div nz-col>
                            <label>Estado:&nbsp;</label>
                        </div>
                        <div nz-col class="value">
                            <label class="label">{{ stateVV }}</label>
                        </div>
                    </div>
                    <div nz-row *ngIf="this.isVVInit">
                        <div nz-col>
                            <label>Validade:&nbsp;</label>
                        </div>
                        <div nz-col class="value" *ngIf="this.seeIfCardIsExpired(); else vvStillValid">
                            <label class="label-expired">
                                {{ cardRead.expiringDate | date: "dd/MM/yyyy" }}
                                &nbsp;(Expirado)
                            </label>
                        </div>
                        <ng-template #vvStillValid>
                            <div nz-col class="value">
                                <label class="label">
                                    {{ cardRead.expiringDate | date: "dd/MM/yyyy" }}
                                </label>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
            </div>

        <div nz-col [nzSpan]="12">
            <app-action-support [card]="cardRead" [isVV]="isVV" [isVVInitialized]="isVVInit"
                [isReplacementGuide]="replacementGuideHasTitles" [isSerialNumberOnBlackList]="this.isSerialNumberOnBlackList"
                [isShoppingCartFull]="isShoppingCartFull"
                (onAlert)="onAlertMessage($event)"></app-action-support>
        </div>
    </div>
    <div nz-col [nzSpan]="24" style="position:relative" [ngStyle]="{'top': isVV? '10px' : '0px'}">
        <div nz-row>
            <div nz-col>
                <label>Último Carregamento:&nbsp;</label>
            </div>
            <div nz-col *ngIf="isLastLoadEventVisible()">
                <label class="longLabelLast">{{ lastEventMessage.titleDescription }} {{ lastEventMessage.dateTime | date:'dd-MM-yyyy' }} às {{ lastEventMessage.dateTime | date:'HH:mm' }} na {{ lastEventMessage.operator }}</label>
            </div>
        </div>
        <div nz-row>
            <div nz-col>
                <label>Última Validação:&nbsp;</label>
            </div>
            <div nz-col *ngIf="isLastValidationEventVisible()">
                <label class="longLabelLast">
                    {{ lastEventMessage.eventDescription }} {{ lastEventMessage.titleDescription }} {{ lastEventMessage.dateTime | date:'dd-MM-yyyy' }} às {{ lastEventMessage.dateTime | date:'HH:mm' }} na {{ lastEventMessage.operator }}
                </label>
            </div>
        </div>
        <div nz-row>
            <div nz-col>
                <span [appBlackListAlertMessage]="this.isSerialNumberOnBlackList"></span>
            </div>
        </div>
        <div *ngIf="!isVV;" nz-row>
            <div nz-col>
                <span [appUnassignedLVAlertMessage]="this.isLVCardUnassigned"></span>
            </div>
        </div>
    </div>
    <div nz-row class="div-table-title" style="position:relative" [ngStyle]="{'top': isVV? '20px' : '0px'}">
        <div class="div-table-result" nz-col [nzSpan]="24">
            <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader"
                [listOfData]="cardRead.titles" showPagination="false" pageSize="5"
                (callButtonClick)="onButtonClickTable($event)">
            </app-primary-table>
        </div>
    </div>
    <div nz-row class="load" style="position:relative" [ngStyle]="{'top': isVV? '20px' : '0px'}">
        <span [appHideIfUnauthorised]="'TICKETS_SELL'" [appHideIfChildProfile]="this.cardRead.profileIds"
            [appGenericHide]="this.isSerialNumberOnBlackList">
            <div nz-row>
            </div>
            <br>
            <div *ngIf="this.seeIfCardIsExpired(); else showContent"></div>
            <ng-template #showContent>
                <div nz-row>
                    <h3 class="NewLoadText">Novo Carregamento</h3>
                </div>
                <div>
                    <app-primary-list-button text="left" [data]="listNewLoad" [colNum]="6"
                        (callButtonClick)="newLoadSelection($event)"></app-primary-list-button>
                </div>
            </ng-template>
        </span>
    </div>
</div>
<nz-modal [(nzVisible)]="isModalVisible" [nzFooter]=null (nzOnCancel)="closeModal()">
    <div>
      <h3>Selecione o título pretendido</h3>
    </div>
    <div *ngFor="let title of composedTitleList" nz-col nz-span="2" class="composed-titles">
        <app-primary-normal-button
            [disabled]="title.disabled"
            (click)="selectComposedTitle(composedTitleList, title.id)">
            {{ title.description }}
        </app-primary-normal-button>
    </div>
  </nz-modal>
