import { Injectable } from '@angular/core';
import { Item, InfoTitleWithCurrency } from './../model/item';
import { AlertService } from 'src/app/core/services/alert.service';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { RequisitionLv } from '../../modules/requisition-lv';
import { Router } from '@angular/router';
import { RequisitionDTO, RequisitionTypeDTO } from '../model/requisition';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';

@Injectable({
    providedIn: 'root'
})
export class RequisitionDetailsService {

    error = 'Ocorreu um erro.';

    listDeliveryLocals: Subject<Item[]> = new Subject<Item[]>();

    infoTitle: Subject<InfoTitleWithCurrency> = new Subject<InfoTitleWithCurrency>();

    constructor(
        private httpClient: HttpClient,
        private alertService: AlertService
    ) { }

    public getDeliveryLocals(): void {
        this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/local`)
            .subscribe(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        if (data != null && data.content != null) {
                            const list: Item[] = [];
                            for (const entry of data.content) {
                                list.push({
                                    label: entry.description,
                                    value: entry.id
                                } as Item);
                            }
                            this.listDeliveryLocals.next(list);
                        }
                    } else {
                        this.alertService.error(res.message);
                    }
                },
                (e: any) => {
                    console.log(e);
                    this.alertService.error(e.error.message);
                }
            );
    }


    public requisitionRegister(requisition: RequisitionLv): Observable<BasicResponseDTO<RequisitionDTO>> {
        const body = {
            requisitionType: requisition.requisitionType,
            local: requisition.selectedDeliveryPlace.value,
            deliveryDate: requisition.expectedTArrivalDate,
            vatNumber: requisition.vatNumber,
            observations: requisition.observations
        };
        return this.httpClient
            .post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/requisition`, body);
    }

    public getRequisitionTypeByProfileIdAndDeliveryId(
            profileId: number,
            deliveryId: number): Promise<BasicResponseDTO<RequisitionTypeDTO>> {
        let params = new HttpParams();
        params = params.set('profileId', `${profileId}`);
        params = params.set('deliveryId', `${deliveryId}`);
        return this.httpClient
            .get<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/requisition-type/by-profile-delivery`, { params })
            .toPromise();
    }

    public getPriceByDelAndReq(profileId: number, deliveryId: number) {

        let params = new HttpParams();

        params = params.set('profileId', `${profileId}`);
        params = params.set('deliveryId', `${deliveryId}`);

        this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}lv-requisition/api/requisition-type/get-price`, { params })
            .subscribe(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        if (data != null) {
                            this.infoTitle.next(data);
                        }
                    } else {
                        this.alertService.error(res.message);
                    }

                },
                (e: any) => {
                    this.alertService.error(e.error.message);
                }
            );
    }

}
