<div class="detailContent">
  <div nz-row>
    <h1 class="caption">{{ load.titleDescription }}</h1>
  </div>
  <div nz-row>
    <h2 class="description">Detalhes do voucher a comprar</h2>
  </div>

  <div nz-row class="m-t-20">
    <h2>Validade</h2>
  </div>
  <div nz-row>
    <h3 class="mensal">Válido de {{ load.expiringDateStart | date: 'dd/MM/yyyy' }} até
      {{ load.expiringDateFinish | date: 'dd/MM/yyyy' }}</h3>
  </div>
  <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader" [listOfData]="data"
    showPagination="false" pageSize="5" showTotal="true" total="{{
        load.total
      }}">
  </app-primary-table>
  <div class="divTableTitle">
    <div nz-row class="voucher-cancel-row footer-buttons">
      <div nz-col nzSpan="12" class=" align-left">
        <app-primary-white-button
            nzType="primary"
            nzDanger (click)="onPreviousClick()">
            <span class="btn-icon icon-anterior icon-middle float-left "></span>
            Voltar
        </app-primary-white-button>
        <app-primary-cancel-button
            class="star-button"
            (click)="onHomeClick()">
            Cancelar
        </app-primary-cancel-button>
      </div>
      <div nz-col nzSpan="12" style="text-align: right">
        <app-primary-normal-button
          class="mr-55"
          [critical] = "true"
          [disabled]="disabled"
          nzType="primary"
          nzDanger
          (click)="scanCard()"
          buttonCSS="button-load">
          Comprar
          <span class="btn-icon icon-seguinte icon-middle float-right"></span>
        </app-primary-normal-button>
      </div>
    </div>

  </div>
</div>
