export enum TitleGroupEnum {
    TICKETS = 1,
    DIVERSE_TICKETS = 2,
    GUIDES = 3,
    CARDS = 4,
    MUSEUM_TICKETS = 5,
    PRE_LOADED = 6,
    CCI = 7,
    TRANSPORT_UNITS = 8,
    FINE = 9,
    MUNICIPAL_NAVIGATOR = 10,
    METROPOLITAN_NAVIGATOR = 11,
    FAMILY_NAVIGATOR = 12,
    ANTIGO_COMBATENTE_MUNICIPAL = 13,
    ANTIGO_COMBATENTE_METROPOLITANO = 14
}
