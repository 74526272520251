<div style="padding: 0px 0px">
  <div>
    <h1 class="info">Informação ao Cliente</h1>
  </div>

  <div nz-col [nzSpan]="24">
    <div class="m-b-10">
      <label class="label-products">Tipo de Produto</label><br />
      <app-primary-radio-group
        [selected]="selectedTypeProd"
        [listOptions]="listTypeProd"
        (onChanged)="onTypeProdChanged($event)"
      ></app-primary-radio-group>
    </div>
    <div class="m-b-10">
      <label *ngIf="listCardProfile.length > 0">Perfil</label><br />
      <app-primary-radio-group
        [selected]="selectedCardProfile"
        [listOptions]="listCardProfile"
        (onChanged)="onCardProfileChanged($event)"
      ></app-primary-radio-group>
    </div>
  </div>

  <div>
    <div id="div-table-result" nz-col [nzSpan]="24">
      <app-primary-table
        [listOfColumn]="listResultColumn"
        [headerDatas]="listResultHeader"
        [listOfData]="listResult"
        pageSize="4"
      >
      </app-primary-table>
    </div>
  </div>
</div>
  <div class="overlap">
    <app-primary-white-button (click)="homeScreen()">
      <span class="btn-icon icon-anterior icon-middle float-left"></span>
      Início
    </app-primary-white-button>
    <app-primary-white-button (click)="previousScreen()" class="margin-left-10">
      <span class="btn-icon icon-pdf icon-middle float-left"></span>
      PDF
    </app-primary-white-button
    >
  </div>

