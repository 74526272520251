import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { RequestCreateEvent } from 'src/app/modules/shift/pages/shift-resume/model/event.interface';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor(private httpClient: HttpClient) {
    }

    public async createEvent(eventTypeId: number, machineCode: string, resolved: boolean, controllerId: string): Promise<any> {
        const createEventRequest: RequestCreateEvent = {
            eventTypeId,
            machineCode,
            resolved,
            controllerId
        };
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}events/api/events/`, createEventRequest).toPromise();
    }
}

