import {HttpStatusCode} from 'src/app/shared/enum/http-status-code';
import {StorageManagerService} from '../../../../core/services/storage-manager.service';
import {ShoppingCartService} from '../../../../core/services/shopping-cart.service';
import {ConsumeAuthorizationRequest} from '../../../../shared/models/consume-authorization-request';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import {Navigation, Router} from '@angular/router';
import {AlertService} from 'src/app/core/services/alert.service';
import {LoadTitleService} from 'src/app/core/services/load-title.service';
import {ReadingLoadingEnum} from 'src/app/shared/enum/reading-loading.enum';
import {ShoppingCartItemRequest} from 'src/app/shared/models/shopping-cart-item-request';
import {LoadTitleRequest} from 'src/app/shared/models/load-title-request';
import {CardDecodeResponse} from 'src/app/shared/models/card-decode-response';
import {WebsocketMessage, WebsocketMessageType} from 'src/app/shared/models/websocket-message';
import {AuthorizationLoadService} from 'src/app/core/services/authorization-load.service';
import {Load} from 'src/app/modules/load/pages/lisboa-viva/models/load';
import {CardRead} from 'src/app/shared/models/card-read';
import {CardContract, CardDetailsMessage, CardEvent} from 'src/app/shared/models/card-details-message';
import {Subject, Subscription} from 'rxjs';
import {SupportDetailService} from 'src/app/core/services/support-detail.service';
import {CardReadTitle} from 'src/app/shared/models/card-read-title';
import {CardEnum} from 'src/app/shared/enum/card.enum';
import NavigationManagerService, {TicketingRoutes} from '../../../../core/services/navigation-manager.service';
import {CardUtils} from 'src/app/shared/utils/card-utils';
import {CommonEnum} from 'src/app/shared/enum/common.enum';
import {VoucherLoadService} from '../voucher-load/service/voucher-load.service';
import {ShoppingCartProduct} from '../../modules/shopping-cart-product';
import {LoadTitleTransactionType} from 'src/app/shared/enum/load-title-transaction-type.enum';
import {SpinService} from '../../../../core/services/spin.service';
import {TariffService} from 'src/app/core/services/tariff.service';
import {PreviousContractResponse} from '../../../../shared/models/previous-contract-response';
import {CancelShoppingCartItemRequest} from '../../../../shared/models/cancel-shoppingcart-item-request';
import {HandlerComponent, SocketClientService} from '../../../../core/services/websocket-client.service';
import { SaleClient } from '../../modules/sale-client';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { SaleVatAssociation } from '../../modules/sale-vat-association';
import { Sale } from '../../modules/sale';
import { ShiftService } from 'src/app/core/services/shift.service';
import { SaleService } from 'src/app/core/services/sale.service';
import { RegisterSaleErrorTypeEnum } from 'src/app/shared/enum/register-sale-error.enum';

@Component({
    selector: 'app-reading-loading-lv',
    templateUrl: './reading-loading-lv.component.html',
    styleUrls: ['./reading-loading-lv.component.less']
})
export class ReadingLoadingLvComponent implements OnInit, OnDestroy, AfterViewInit, HandlerComponent {

    private static AUTH_LOAD_USED = 2;
    private navigation: Navigation;
    private action: string;
    private cartRequest: ShoppingCartItemRequest;
    private consumeAuthorizationRequest: ConsumeAuthorizationRequest;
    private titleRequest: LoadTitleRequest;
    private switchRequest: any;

    bigTitle: string;
    smallTitle: string;
    description: string;

    callLoadTitle: Subscription;
    callAnnulTitle: any;
    annulAuthorizationTitle: any;
    cardLoadChange: any;
    callTitleExchange: any;
    cardRead: CardRead;
    titleToAnnul: ShoppingCartProduct;
    subProductToAnnul: ShoppingCartProduct;
    itemId: number;
    titleValue: number;
    fromShoppingCart: boolean;
    reloadContract: PreviousContractResponse;
    wait = true;

    // SCAN LOGIC VARIABLES
    private MAX_SUCCESSIVE_SCAN_TRIES = 5; // MAX NUMBER OF WRITE CARD SUCCESSIVE TRIES IN CASE OF ERROR
    private SEC_BETWEEN_SCANS = 2; // TIME BETWEEN EACH SCAN IN SECONDS
    private isScanActive = true;
    private scanCounter = 0;
    private scanErrorCounter = 0;
    private mustReceivePayment: boolean;
    private alertText = '';

    private currentCardBinary: CardDecodeResponse;

    public showCancelButton = true;

    constructor(
        private spinService: SpinService,
        private service: VoucherLoadService,
        private router: Router,
        private peripheralService: PeripheralService,
        private alertService: AlertService,
        private loadTitleService: LoadTitleService,
        private authorizationLoadService: AuthorizationLoadService,
        private supportDetailService: SupportDetailService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private shoppingCartService: ShoppingCartService,
        private tariffService: TariffService,
        private socketClient: SocketClientService,
        private shiftService: ShiftService,
        private saleService: SaleService
    ) {
        this.socketClient.setHandlerComponent(this);
        this.navigation = this.router.getCurrentNavigation();
        if (this.navigation?.extras?.state?.titleToAnnul) {
            this.titleToAnnul = this.navigation.extras.state.titleToAnnul;
        }

        if (this.navigation?.extras?.state?.itemId) {
            this.itemId = this.navigation.extras.state.itemId;
        }

        if (this.navigation?.extras?.state?.fromShoppingCart) {
            this.fromShoppingCart = this.navigation.extras.state.fromShoppingCart;
        }

        if (this.navigation?.extras?.state?.subProductToAnnul) {
            this.subProductToAnnul = this.navigation.extras.state.subProductToAnnul;
        }
    }

    async ngOnInit() {
        this.action = this.storageManager.session.get(ReadingLoadingEnum.Action);
        switch (this.action) {
            case ReadingLoadingEnum.LoadFamilyTicket:
                this.bigTitle = 'Leitura de suporte';
                this.smallTitle = 'Consumir Passe Família';
                this.description = 'Por favor coloque o suporte no leitor';
                this.alertText = 'Carregamento título família efetuado com sucesso';
                break;
            case ReadingLoadingEnum.AuthorizationCharge:
                this.bigTitle = 'Leitura de suporte';
                this.smallTitle = 'A verificar autorizações associadas ao suporte';
                this.description = 'Por favor coloque o suporte no leitor';
                this.alertText = 'Carregamento autorização efetuado com sucesso';
                break;
            case ReadingLoadingEnum.NullifyContract:
                this.bigTitle = 'Leitura de suporte';
                this.smallTitle = 'Anulação de título';
                this.description = 'Por favor coloque o suporte no leitor';
                break;
            case ReadingLoadingEnum.NullifyContractShopping:
                this.bigTitle = 'Anulação de título';
                this.smallTitle = 'Anulação de título';
                this.description = 'Por favor coloque o suporte no leitor';
                break;
            case ReadingLoadingEnum.DeleteContractRead:
                this.bigTitle = 'Leitura no suporte';
                this.smallTitle = 'Eliminar Titulo do Cartão inicial';
                this.description = 'Por favor coloque o suporte inicial no leitor';
                break;
            case ReadingLoadingEnum.DeleteContract:
                this.bigTitle = 'Escrita no suporte';
                this.smallTitle = 'Eliminar Titulo do Cartão inicial';
                this.description = 'Por favor coloque o suporte inicial no leitor';
                break;
            default:
                this.bigTitle = 'Leitura de suporte';
                this.smallTitle = 'Carregamento de titulo';
                this.description = 'Por favor coloque o suporte no leitor';
                break;
        }

        this.loadTitleService.callAnnulTitle = new Subject<any>();
        this.loadTitleService.annulAuthorizationTitle = new Subject<any>();
        this.supportDetailService.cardLoadChange = new Subject<any>();
        this.loadTitleService.callTitleExchange = new Subject<any>();

        if (this.socketClient.isConnected) {
            await this.backgroundScan();
        }
    }

    ngAfterViewInit(): void {
        this.spinService.setSpinValue(true);
    }

    async handleMessage(messageBody: WebsocketMessage) {
        console.log(messageBody.type);
        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId)
                    .toPromise();
                if (!response.success) {
                    this.spinService.setSpinValue(false);
                    this.wait = false;
                    this.alertService.error(response.detail);
                }
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (messageBody.content) as CardDetailsMessage;
                if (ReadingLoadingEnum.LoadAuthorization === this.action) {
                    this.storageManager.session.set(CardEnum.CARD_FULL_DETAILS_AFTER_LOAD_AUTH, cardDetails);
                    break;
                }
                if (cardDetails.details.environment === undefined) {
                    this.spinService.setSpinValue(false);
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    switch (this.action) {
                        case ReadingLoadingEnum.DeleteContractRead:
                            const previousCardBinary = this.storageManager.session.get(CardEnum.PREVIOUS_CARD_BINARY) as CardDecodeResponse;
                            // cardDetails: Cartão que possui o contrato a ser deletado (cartão origem da transferência)
                            // previousCardBinary: Cartão para o qual o contrato foi transferido (cartão destino da transferência)
                            // se as informacoes forem diferentes, aceita o delete
                            // caso contrario da uma mensagem por 5 segundos e depois direciona para a mesma página até que o operador mude o cartao
                            if (cardDetails.low_part === previousCardBinary.lowPart && cardDetails.high_part === previousCardBinary.highPart) {
                                this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.DeleteContract);
                                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.navigationManager.go(TicketingRoutes.READING_LOADING_LV));
                            } else {
                                this.alertService.error(CommonEnum.msgOriginalCardRequest);
                                setTimeout(() => this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.navigationManager.go(TicketingRoutes.READING_LOADING_LV)), 5000);
                            }
                            break;
                        case ReadingLoadingEnum.NullifyContractShopping:
                            this.cardLoadChange = this.supportDetailService.cardLoadChange.subscribe(
                                (data) => {
                                    if (data != null && data.titles.length > 0) {
                                        this.cardRead = data;
                                        this.cardRead.titles = [...data.titles];
                                        const titleToAnnul = this.cardRead.titles
                                            .find((i) => i.tickCode === this.titleToAnnul.tickCode &&
                                                i.tickOperCode === this.titleToAnnul.tickOperCode) as CardReadTitle;
                                        if (titleToAnnul !== undefined) {
                                            const load: Load = {
                                                cardRead: this.cardRead,
                                                tickOperCode: titleToAnnul.tickOperCode,
                                                tickCode: titleToAnnul.tickCode,
                                                titleId: titleToAnnul.id,
                                                titleDescription: titleToAnnul.description,
                                                titleGroup: titleToAnnul.titleGroup,
                                            } as Load;

                                            this.navigationManager.go(TicketingRoutes.ANNUL_TITLE,
                                                {
                                                    load,
                                                    title: titleToAnnul,
                                                    itemId: this.itemId,
                                                    titleValue: this.titleToAnnul.price / this.titleToAnnul.quantity,
                                                    fromShoppingCart: this.fromShoppingCart,
                                                    subProductToAnnul: this.subProductToAnnul,
                                                });
                                        } else {
                                            this.spinService.setSpinValue(false);
                                            this.wait = false;
                                            this.alertService.error(CommonEnum.msgAnnulTitleNotInCardError);
                                        }
                                    }
                                },
                                (err) => {
                                    this.spinService.setSpinValue(false);
                                    this.wait = false;
                                    this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                                }
                            );
                            await this.supportDetailService.loadCard(cardDetails);
                            break;
                        default:
                            this.spinService.setSpinValue(false);
                            this.alertService.error(CommonEnum.msgUnknownActionError + this.action);
                            break;
                    }
                }
                break;
        }
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async componentLogic() {
        const cardFullDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        switch (this.action) {
            case ReadingLoadingEnum.LoadFamilyTicket:
                this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                if(ReadingLoadingLvComponent.isSameCardAsSupportDetailsRead(cardFullDetails.low_part, this.currentCardBinary.lowPart)) {
                    await this.showSameCardAlertAndGoHome();
                    break;
                }
                const responseLoadFamilyTicket =
                    await this.service.loadTicket(this.titleRequest.voucherNumber, this.titleRequest.id, this.titleRequest.amount, this.cartRequest)
                        .toPromise();
                if (!responseLoadFamilyTicket.success) {
                    this.wait = false;
                    throw new Error(CommonEnum.msgFamilyTicketError);
                } else {
                    await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                }
                break;
            case ReadingLoadingEnum.LoadAuthorization:
                const carrTransactionType = LoadTitleTransactionType.TRANSACTION_CARR;

                this.consumeAuthorizationRequest =
                    this.storageManager.session.get(ReadingLoadingEnum.ConsumeAuthorizationRequest) as ConsumeAuthorizationRequest;
                this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                this.mustReceivePayment = true;
                if(ReadingLoadingLvComponent.isSameCardAsSupportDetailsRead(cardFullDetails.low_part, this.currentCardBinary.lowPart)) {
                    await this.showSameCardAlertAndGoHome();
                    break;
                }
                const consumeResponse = await this.authorizationLoadService.consumeAuthorization(this.consumeAuthorizationRequest);

                if (consumeResponse && consumeResponse.success) {
                    try {
                        await this.loadContract(true, this.mustReceivePayment, carrTransactionType);
                    } catch (error) {
                        this.wait = true;
                        this.spinService.setSpinValue(true);
                        const requestCancelConsumeAuthorization = {
                            cardSerialNumber: this.consumeAuthorizationRequest.serialNumber,
                        };
                        await this.authorizationLoadService.annulAuthorization(requestCancelConsumeAuthorization);
                        this.spinService.setSpinValue(false);
                        this.wait = false;
                        throw new Error(error.message);
                    }
                    break;
                } else {
                    this.alertService.error(CommonEnum.msgConsumeAuthorizationError);
                }
                break;
            case ReadingLoadingEnum.LoadContract:
                this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                if(ReadingLoadingLvComponent.isSameCardAsSupportDetailsRead(cardFullDetails.low_part, this.currentCardBinary.lowPart)) {
                    await this.showSameCardAlertAndGoHome();
                    break;
                }
                await this.loadContract(false, false).then(
                    async (res) => {
                        if (!res.success) {
                            const message = JSON.parse(res.message);
                            const response = JSON.parse(message.response);
                            this.alertService.error(response.error.message);
                        } else {
                            if (CardUtils.IsVV(cardFullDetails.details.environment.card_data_model) &&
                                !CardUtils.IsInitialized(cardFullDetails.details.environment.card_data_model)) {
                                const data = JSON.parse(res.data);
                                const shoppingId = data.id;
                                await this.addVVCardProductShoppingCart(cardFullDetails.low_part, shoppingId);
                            }
                            await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                        }
                    });
                break;
            case ReadingLoadingEnum.LoadComposedTitle:
                this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.LoadContractRequest) as LoadTitleRequest;
                if(ReadingLoadingLvComponent.isSameCardAsSupportDetailsRead(cardFullDetails.low_part, this.currentCardBinary.lowPart)) {
                    await this.showSameCardAlertAndGoHome();
                    break;
                }
                const cardDetailsLoadComposedTitle = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
                this.titleRequest.cardNumber = cardDetailsLoadComposedTitle.details.environment.issuing_number.toString();

                await this.loadComposedTitle(true, false);
                break;
            case ReadingLoadingEnum.ReloadPrevContract:
                this.reloadContract = this.storageManager.session.get(ReadingLoadingEnum.ReloadContractRequest) as PreviousContractResponse;
                await this.callReplaceWithPrevContract(this.reloadContract.slot, this.reloadContract.contract, this.reloadContract.event);
                break;
            case ReadingLoadingEnum.DeleteContractRead:
                await this.peripheralService.startCardScan().toPromise();
                break;
            case ReadingLoadingEnum.DeleteContract:
                await this.deleteContract();
                break;
            case ReadingLoadingEnum.SwitchContract:
                this.switchRequest = this.storageManager.session.get(ReadingLoadingEnum.SwitchRequest);
                this.cancelAndLoadContract();
                break;
            case ReadingLoadingEnum.NullifyContract:
                this.cartRequest = this.storageManager.session.get(ReadingLoadingEnum.ShoppingCartRequest) as ShoppingCartItemRequest;
                this.titleRequest = this.storageManager.session.get(ReadingLoadingEnum.NullifyContractRequest) as LoadTitleRequest;
                const requestAnnulAuthorization = this.storageManager.session.get(ReadingLoadingEnum.CancelConsumeAuthorization);

                try {
                    const request = {
                        serialNumber: this.titleRequest.serialNumber,
                        titleId: this.titleRequest.id
                    };
                    const response = await this.authorizationLoadService.getLastConsumedAuthorization(request);
                    const loadAuthId = response.data.loadAuthorizationId;
                    const lastLoadResponse = await this.loadTitleService
                        .getLastLoadByTitleIdAndCardSerialNumber(this.titleRequest.id, this.cartRequest.cardSerialNumber);
                    const loadId = lastLoadResponse.data.id;

                    let lastLoadWasAuth = false;
                    const responseSaleItemAuth = await this.saleService
                        .findSaleItemAuthByAuthLoadId(loadId);
                    if (responseSaleItemAuth.success && responseSaleItemAuth.data != null) {
                        lastLoadWasAuth = true;
                    }

                    if (lastLoadWasAuth) {
                        const responseAnnulAuthorization = await this.authorizationLoadService
                            .annulAuthorization(requestAnnulAuthorization);
                        if (responseAnnulAuthorization && responseAnnulAuthorization.success) {
                            this.alertText = responseAnnulAuthorization.message;
                            await this.annulAuthorizationContract(loadAuthId, loadId);
                        } else {
                            await this.annulContract();
                        }

                    }

                } catch (e) {
                    await this.annulContract();
                }
                break;
            case ReadingLoadingEnum.NullifyContractShopping:
                const cardBinaryNull = await this.peripheralService.startCardScan().toPromise();
                this.storageManager.session.set(CardEnum.CARD_DETECTION_BINARY, cardBinaryNull);
                break;
            default:
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgUnknownActionError + this.action);
                break;
        }
    }

    async onConnect() {
        await this.backgroundScan();
    }

    async backgroundScan() {
        if (!this.isScanActive) {
            return;
        }
        try {
            // SCAN CARD
            this.scanCounter++;
            await this.peripheralService.detectCard()
                .then(async (response) => {
                    if (response.success) {
                        this.currentCardBinary = response.data;
                        this.isScanActive = false;
                        await this.componentLogic();
                    } else {
                        if (this.scanCounter === 1) {
                            this.alertService.info(response.message, false, true, 0);
                        }
                        await this.backgroundScan();
                    }
                })
                .catch((error: any) => {
                    this.goToHomeIfMaxTries();
                    const errorMessage = error.status === HttpStatusCode.NOT_FOUND ||
                        error.status === HttpStatusCode.INTERNAL_SERVER_ERROR
                        ? CommonEnum.msgDetectCardError
                        : CommonEnum.msgWriteCardError + error.message;

                    if (this.scanCounter <= this.MAX_SUCCESSIVE_SCAN_TRIES) {
                        if (errorMessage.includes(CommonEnum.errorTicketReloadChange)) {
                            this.alertService.error(errorMessage, true);
                            this.isScanActive = false;
                            this.navigationManager.go(TicketingRoutes.HOME);
                            this.spinService.setSpinValue(false);
                            this.wait = false;
                        } else {
                            this.alertService.error('[' + this.scanErrorCounter + '/' + this.MAX_SUCCESSIVE_SCAN_TRIES + '] ' + errorMessage, true);
                        }
                    }
                });
        } catch (e) {
            this.goToHomeIfMaxTries();

            if (this.scanCounter <= this.MAX_SUCCESSIVE_SCAN_TRIES) {
                this.alertService.error('[' + this.scanErrorCounter + '/' + this.MAX_SUCCESSIVE_SCAN_TRIES + '] ' + CommonEnum.msgWriteCardError + e);
            }
        }
        await this.delay(this.SEC_BETWEEN_SCANS * 1000);
        await this.backgroundScan();
    }

    cancelAndLoadContract() {
        this.callTitleExchange = this.loadTitleService.callTitleExchange.subscribe(
            async (data: any) => {
                await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
            },
            (err) => {
                this.spinService.setSpinValue(false);
                this.wait = false;
                this.alertService.error(CommonEnum.msgWriteCardAndSCError);
            }
        );
        this.loadTitleService.titleExchange(this.switchRequest);
    }

    async loadContract(redirectSC: boolean = true, mustReturnPayment: boolean = false, transactionType: number = LoadTitleTransactionType.TRANSACTION_VEND_CARR) {
        if (this.currentCardBinary) {
            try {
                const response = await this.loadTitleService
                    .loadTitle(this.titleRequest, this.cartRequest, this.currentCardBinary, mustReturnPayment, transactionType).toPromise();
                if (ReadingLoadingEnum.LoadAuthorization === this.action) {
                    // if we loaded auth then we need to check if: title is in the card or if title is in the card but not valid
                    // otherwise annul auth
                    await this.peripheralService.startCardScan().toPromise().then(async (readResponse) => {
                        if (readResponse.success) {
                            const cardFullDetails = this.storageManager.session.get(
                                CardEnum.CARD_FULL_DETAILS_AFTER_LOAD_AUTH) as CardDetailsMessage;
                            // decode card full details
                            const decodedResponse = await this.loadTitleService.decodeCardFullDetails(cardFullDetails) as any;
                            this.storageManager.session.remove(CardEnum.CARD_FULL_DETAILS_AFTER_LOAD_AUTH);
                            if (decodedResponse && decodedResponse.success) {
                                const decodedCardDetails = decodedResponse.data;
                                const authTitleLoadedInCard = decodedCardDetails.titles.find((contract) =>
                                    contract.ticket_code === this.titleRequest.tickCode
                                        && contract.operator_code === this.titleRequest.tickOperCode);
                                if (authTitleLoadedInCard == null ||
                                    (authTitleLoadedInCard != null && !authTitleLoadedInCard.stillValid)) {
                                    // auth title is not in the card or title is in the card but is not valid
                                    // in both cases we need to annul auth
                                    const requestCancelConsumeAuthorization = {
                                        cardSerialNumber: this.consumeAuthorizationRequest.serialNumber,
                                    };
                                    await this.authorizationLoadService.annulAuthorization(requestCancelConsumeAuthorization);
                                }
                            }
                        }
                    });
                }
                if (response && response.success) {
                    if (redirectSC) {
                        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                    }
                }
                return response;
            } catch (e) {
                this.spinService.setSpinValue(false);
                this.wait = false;
                throw new Error(e.error.message);
            }
        } else {
            this.spinService.setSpinValue(false);
            this.wait = false;
            throw new Error(CommonEnum.msgBinaryNullError);
        }
    }

    async loadComposedTitle(redirectSC: boolean = true, mustReturnPayment: boolean = false) {

        this.wait = true;
        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;

        const response = await this.loadTitleService
            .loadComposedTitle(this.titleRequest, this.cartRequest, cardBinary, mustReturnPayment).toPromise();

        if (response && response.success) {
            if (redirectSC) {
                this.wait = true;
                await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
            }
        } else {
            this.spinService.setSpinValue(false);
            this.wait = false;
            this.alertService.error(CommonEnum.msgWriteCardAndSCError);
        }
        return this.wait;
    }

    async callReplaceWithPrevContract(cardSlot: number, cardContract: CardContract, event: CardEvent) {
        const identifier = this.storageManager.session.get(CommonEnum.peripheralId);
        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;

        const response = await this.loadTitleService.rollbackContract(identifier, cardSlot, cardContract, cardBinary, event);

        if (response && response.success) {
            await this.navigationManager.go(TicketingRoutes.HOME);
        } else {
            this.alertService.error(CommonEnum.msgWriteCardError + response.message);
        }
    }

    async deleteContract() {
        const previousCardBinary = this.storageManager.session.get(CardEnum.PREVIOUS_CARD_BINARY) as CardDecodeResponse;
        const contractToDelete = this.storageManager.session.get(CardEnum.CONTRACT_TO_DELETE) as number;
        await this.shoppingCartService.null(contractToDelete, previousCardBinary);

        this.storageManager.session.remove(CardEnum.PREVIOUS_CARD_BINARY);
        this.storageManager.session.remove(CardEnum.NEW_FULL_CARD_DETAILS);
        this.storageManager.session.remove(CardEnum.CONTRACT_TO_DELETE);
        this.storageManager.session.remove(CardEnum.CARD_DETECTION_BINARY);

        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
    }

    async annulContract() {
        this.callAnnulTitle = this.loadTitleService.callAnnulTitle.subscribe(
            async (res: any) => {
                if (this.subProductToAnnul) {
                    const req: CancelShoppingCartItemRequest = {
                        shoppingCartId: await this.shoppingCartService.getCartId(this.cartRequest.shiftId),
                        shiftId: this.cartRequest.shiftId,
                        titleId: this.subProductToAnnul.id
                    };
                    await this.shoppingCartService.cancelItem(req);
                }
                const cardFullDetails = this.storageManager.session
                .get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
                try {
                    if (CardUtils.IsVV(cardFullDetails.details.environment.card_data_model)) {
                        const data = JSON.parse(res.data);
                        const shoppingId = data.id;
                        const loadId = data.items.find(item => item.tickCode === this.titleRequest.tickCode &&
                            item.tickOperCode === this.titleRequest.tickOperCode).loadId;
                        if (loadId !== undefined) {
                            const result = await this.loadTitleService.didLoadInitializedVVCard(loadId, cardFullDetails);
                            const loadInitializedVVCard = result.data;
                            if (loadInitializedVVCard && CardUtils.IsInitialized(cardFullDetails.details.environment.card_data_model)) {
                                await this.addAnnuledVVCardProductShoppingCart(cardFullDetails.low_part, shoppingId);
                                await this.shoppingCartService.reset(cardBinary);
                            }
                        } else {
                            this.alertService.error(CommonEnum.msgAnnulTitleLoadNotFoundError);
                            await this.navigationManager.go(TicketingRoutes.HOME);
                        }
                    }
                    await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                } catch (error) {
                    this.spinService.setSpinValue(false);
                    this.wait = false;
                }
            },
            async (err) => {
                this.spinService.setSpinValue(false);
                this.wait = false;
                this.alertService.error(CommonEnum.msgAnnulTitleAndSCError);
            }
        );

        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;

        this.loadTitleService.annulTitle(this.titleRequest, this.cartRequest, cardBinary, false, this.fromShoppingCart);
    }

    async annulAuthorizationContract(authId: number, loadId: number): Promise<void> {
        this.annulAuthorizationTitle = this.loadTitleService.annulAuthorizationTitle.subscribe(
            async (response: any) => {
                if (response && response.data) {
                    const shoppingCartRequest = this.prepareCancelAuthorizationShoppingCartItemRequest(authId, loadId);
                    // adicionar ao carrinho através de um fluxo especial
                    await this.shoppingCartService.cancelItem(shoppingCartRequest);
                    const cartId = await this.getShoppingCart(this.cartRequest.shiftId, shoppingCartRequest.authId);
                    await this.registerSale(cartId);
                    this.alertService.info('Título anulado com sucesso.', true);
                }

            },
            (err) => {
                this.spinService.setSpinValue(false);
                this.wait = false;
                this.alertService.error(CommonEnum.msgAnnulTitleError);
            }
        );

        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;

        this.loadTitleService.annulTitle(this.titleRequest, this.cartRequest, cardBinary, true);
    }

    private prepareCancelAuthorizationShoppingCartItemRequest(authId: number, loadId: number): CancelShoppingCartItemRequest {
            return {
                shiftId: this.cartRequest.shiftId,
                shoppingCartId: null,
                itemId: null,
                titleId: this.titleRequest.id,
                loadId,
                authId,
                transactionTypeId: LoadTitleTransactionType.TRANSACTION_ANUL_CARR,
                cardNumber: this.cartRequest.cardNumber,
                cardSerialNumber: this.cartRequest.cardSerialNumber
            } as CancelShoppingCartItemRequest;
    }

    private async getShoppingCart(shiftId: number, authId: number): Promise<number> {
        try {
            const response = await this.shoppingCartService
                        .getCartByShiftIdAndAuthId(shiftId, authId);
            if (response && response.success) {
                return response.data.id;
            }
        } catch (e) {
            this.spinService.setSpinValue(false);
            this.alertService.error(e.error.message);
        }
    }

    private async registerSale(cartId: number) {
        const sale = {
            deviceId: this.titleRequest.peripheralId,
            cartId,
            client: {} as SaleClient,
            payments: [
                {
                    paymentMethodId: EnvironmentUtil.getEnv().moneyMethodId, // Money
                    value: 0
                }
            ],
            vatAssociation: {
                associate: false,
                predefined: false
            } as SaleVatAssociation,
            vatInternational: false,
            receiptQuantity: 0
        } as Sale;

        const printDocumentRequest = {};
        const response = await this.saleService.register(sale, printDocumentRequest) as any;
        if (!response.success) {
            this.alertService.error(response.content, true);
            if (RegisterSaleErrorTypeEnum.PRINT === response?.error) {
                this.shoppingCartService.resetShoppingCartHeader();
                await this.navigationManager.go(TicketingRoutes.HOME);
            }
            await this.shoppingCartService.list(this.cartRequest.shiftId.toString());
        }
        this.alertService.success(CommonEnum.msgSaleSucess);
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async addVVCardProductShoppingCart(cardSerialNumber: number, shoppingCartId: number) {
        const tariffBasicResponse = await this.tariffService.getSupportVVProduct().toPromise();
        const vvCardTitle = tariffBasicResponse.data;
        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
            titleId: vvCardTitle.id,
            description: vvCardTitle.description,
            quantity: 1,
            price: vvCardTitle.price.value,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
            annulId: null,
            cardNumber: null,
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardSerialNumber,
            isAnnul: false
        };
        await this.shoppingCartService.insert(shoppingCartRequest);
    }

    private async addAnnuledVVCardProductShoppingCart(cardSerialNumber: number, shoppingCartId: number): Promise<void> {
        const tariffBasicResponse = await this.tariffService.getSupportVVProduct().toPromise();
        const vvCardTitle = tariffBasicResponse.data;
        const cancelShoppingCartRequest: CancelShoppingCartItemRequest = {
            shiftId: this.cartRequest.shiftId,
            shoppingCartId,
            titleId: vvCardTitle.id,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_ANUL_VEND,
            cardSerialNumber
        };
        await this.shoppingCartService.cancelItem(cancelShoppingCartRequest);
    }

    private async showSameCardAlertAndGoHome() {
        await this.alertService.error(CommonEnum.msgDifferentCardRead, true);
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    private static isSameCardAsSupportDetailsRead(supportDetailsSerialNumber: number, currentReadSerialNumber: number): boolean {
        return supportDetailsSerialNumber != currentReadSerialNumber;
    }

    async navigateBack() {
        if (this.action == ReadingLoadingEnum.LoadAuthorization) {
            const recordAuth = await this.authorizationLoadService.findAuthorizationById(this.consumeAuthorizationRequest.loadAuthorizationId);
            if (ReadingLoadingLvComponent.AUTH_LOAD_USED == recordAuth.data?.status?.id){
                const requestCancelConsumeAuthorization = {
                    cardSerialNumber: this.consumeAuthorizationRequest.serialNumber,
                };
                await this.authorizationLoadService.annulAuthorization(requestCancelConsumeAuthorization);
            }
        }
        history.back();
    }

    private goToHomeIfMaxTries(): void {
        this.wait = false;
        // REDIRECT TO HOME WHEN REACHED MAX NUMBER OF RETRIES
        if (this.scanErrorCounter < this.MAX_SUCCESSIVE_SCAN_TRIES) {
            // REACTIVATE SCAN WHILE MAX_SUCCESSIVE_SCAN_TRIES NOT REACHED
            this.scanErrorCounter++;
            this.isScanActive = true;
        } else {
            this.isScanActive = false;
            this.navigationManager.go(TicketingRoutes.HOME);
            this.spinService.setSpinValue(false);
            this.wait = false;
            this.alertService.error(CommonEnum.msgReachedMaxSuccessiveScanTriesError);
        }
    }

    ngOnDestroy() {
        this.spinService.setSpinValue(false);
        this.isScanActive = false;
        this.callLoadTitle?.unsubscribe();
        this.callAnnulTitle?.unsubscribe();
        this.annulAuthorizationTitle?.unsubscribe();
        this.cardLoadChange?.unsubscribe();
        this.callTitleExchange?.unsubscribe();
    }
}
