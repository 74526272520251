import { DetailsVoucherCancel } from './../../../../../operation/pages/voucher-cancel/model/details-voucher-cancel';
import { Injectable } from '@angular/core';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { HttpClient} from '@angular/common/http';
import {AlertService} from 'src/app/core/services/alert.service';
import { Subject, Observable } from 'rxjs';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';


@Injectable({
  providedIn: 'root'
})
export class FamilyPassService {

  error = 'Ocorreu um erro ao recuperar os dados.';
  data: Subject<DetailsVoucherCancel[]> = new Subject<DetailsVoucherCancel[]>();
  constructor(private httpClient: HttpClient, private alertService: AlertService) { }


  public getVouchersBySerialNumber(cardSerialNumber: number) {
    const body = { cardSerialNumber };
    return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}carris-system-integration/api/familypass/valid-vouchers`, body).toPromise()
    .then((response: BasicDataResponse) => {
        if (response && response.success) {
            return response.data;
        }
    });;
}

}
