import { ShiftService } from 'src/app/core/services/shift.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpinService } from './core/services/spin.service';
import { SuspendShiftRequest } from './shared/models/suspend-shift-request';
import { CommonEnum } from './shared/enum/common.enum';
import { AppComponentService } from './app.component.service';
import { disableRefresh, enableRefresh } from './shared/utils/disable-refresh';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {

    private INACTIVITY_TIME = 870; // 14 minutes and 30 seconds
    private SHIFT_SUSPENSION_TIMEOUT_WARNING = 30; // 30 seconds
    private PING_INTERVAL = 15; // 15 seconds

    private lastPing?: Date = null;
    private toSuspendShift = false;

    public isSpinning: boolean;
    public destroyer$: Subject<void> = new Subject();
    public isShiftSuspensionModalVisible = false;
    public shiftSuspensionCountdown: number;


    public constructor(
        private spinService: SpinService,
        private idle: Idle,
        private keepalive: Keepalive,
        private navigationManagerService: NavigationManagerService,
        private storageManager: StorageManagerService,
        private shiftService: ShiftService,
        private appComponentService: AppComponentService,
        private routerPage: Router
    ) {

        this.idle.setIdle(this.INACTIVITY_TIME);
        this.idle.setTimeout(this.SHIFT_SUSPENSION_TIMEOUT_WARNING);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.inactivityDetected();
        this.timeOut();
        this.timeOutWarning();
        // sets the ping interval to 15 seconds
        this.keepalive.interval(this.PING_INTERVAL);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.isShiftActive();
    }

    private inactivityDetected(): void {
        this.idle.onIdleStart.subscribe(() => {
            this.idle.clearInterrupts();
            this.isShiftSuspensionModalVisible = true;
        });
    }

    private timeOut(): void {
        this.idle.onTimeout.subscribe(() => {
            this.isShiftSuspensionModalVisible = false;
            this.suspendShift();
        });
    }

    private suspendShift(): void {
        this.toSuspendShift = true;
        const request: SuspendShiftRequest = {
            shiftId: Number(this.storageManager.session.get<string>(CommonEnum.shiftID))
        };
        this.shiftService.suspendShiftChange.subscribe(
            async () => {
                await this.shiftService.doLogout();
                await this.navigationManagerService.go(TicketingRoutes.LOGIN);
            }
        );
        this.shiftService.suspend(request);
    }

    private timeOutWarning(): void {
        this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            this.shiftSuspensionCountdown = countdown;
        });
    }

    private isShiftActive(): void {
        this.appComponentService.getIsShiftActive().subscribe(isShiftActive => {
            if (isShiftActive) {
                this.startCheckingInactivity();
            } else {
                this.idle.stop();
            }
        });
    }

    private startCheckingInactivity(): void {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
    }

    public ngOnInit(): void {
        this.spinService.myVar.pipe(takeUntil(this.destroyer$)).subscribe(myVar => this.isSpinning = myVar);
        this.spinService.setSpinValue(false);
    }

    public ngAfterContentChecked(): void {
        this.routerPage.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                if (event.url !== '/home') {
                    disableRefresh();
                } else {
                    enableRefresh();
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyer$.next();
        this.destroyer$.complete();
    }

    public modalHandleOk(): void {
        this.idle.stop();
        this.startCheckingInactivity();
        this.isShiftSuspensionModalVisible = false;
    }

}
