import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-input',
  templateUrl: './primary-input.component.html',
  styleUrls: ['./primary-input.component.less',
              '../../../modules/operation/pages/invoice/invoice.component.less'],
})
export class PrimaryInputComponent implements OnInit {
  @Input() width = '';
  @Input() height = '';
  @Input() value?: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input() textAlign = '';
  @Input() type = '';
  @Input() isDisabled: boolean;
  @Input() color = '';
  @Input() inputStyle = {};
  @Input() public inputCSS: string = 'inputRequest';

  constructor() {
  }

  ngOnInit(): void {
    this.onFocus();
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }

  onFocus() {
    window.setTimeout(() => {
      document.getElementById('inputRequest').focus();
    }, 0);
  }
}
