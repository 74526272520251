import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { ApplicationType } from 'src/app/shared/enum/application-type.enum';
import { CampaignRequest, CampaignTicketingFilterResponseDTO, TariffType } from 'src/app/modules/operation/pages/non-ticketing-products/model/commons-non-ticketing-product';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { StorageManagerService } from './storage-manager.service';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';

@Injectable({
    providedIn: 'root',
})
export class CommercialOfferService {

    constructor(
        private httpClient: HttpClient,
        private storageManager: StorageManagerService
    ) {}

    public async getRechargeValuesByTitleId(titleId: number) {
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        return await this.httpClient.post(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}commercial-offer/api/product/ticketing/recharge-product/get-values/${titleId}/${ApplicationType.TICKETING}`, cardFullDetails)
            .toPromise();
    }

    public async getTariffTypes(): Promise<BasicResponseDTO<Array<TariffType>>> {
        return await this.httpClient.get<BasicResponseDTO<Array<TariffType>>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}commercial-offer/api/product/non-ticketing/tariff-type`)
            .toPromise();
    }

    public async getCampaigns(campaignRequest: CampaignRequest): Promise<BasicResponseDTO<Array<CampaignTicketingFilterResponseDTO>>> {
        let params = new HttpParams();
        
        params = params.set('productId', `${campaignRequest.productId}`);
        params = params.set('date', `${campaignRequest.date}`);
        params = params.set('localId', `${campaignRequest.localId}`);

        return await this.httpClient.get<BasicResponseDTO<Array<CampaignTicketingFilterResponseDTO>>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}commercial-offer/api/campaign/ticketing-filter`, { params })
            .toPromise();
    }

}
