import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { CreditEntityService } from 'src/app/core/services/credit-entity.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { SaleService } from 'src/app/core/services/sale.service';
import { ShiftService } from 'src/app/core/services/shift.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { SpinService } from 'src/app/core/services/spin.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { Load } from 'src/app/modules/load/pages/lisboa-viva/models/load';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { RegisterSaleErrorTypeEnum } from 'src/app/shared/enum/register-sale-error.enum';
import { TitleGroupEnum } from 'src/app/shared/enum/title-group.enum';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardRead } from 'src/app/shared/models/card-read';
import { SelectItem } from 'src/app/shared/utils/http/http-commons-interfaces';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { Sale } from '../../modules/sale';
import { SaleClient } from '../../modules/sale-client';
import { SaleVatAssociation } from '../../modules/sale-vat-association';
import { ShoppingCart } from '../../modules/shopping-cart';
import { CustomerDetailsDTO } from '../replacement-guide/model/create-repl-guide-request';
import { CreditEntity } from './interfaces/payment-credit.interfaces';

@Component({
    selector: 'app-payment-credit',
    templateUrl: './payment-credit.component.html',
    styleUrls: ['./payment-credit.component.less']
})
export class PaymentCreditComponent implements OnInit {

    public total = 0;
    public totalRemaining = 0;
    public totalReturn = 0;

    private creditEntityList: CreditEntity[] = [];
    public selectedCreditEntity: CreditEntity;
    public dropListCreditEntities: Array<SelectItem> = [];
    private payments: Array<any> = [];

    public shoppingCart: ShoppingCart = { payment: {} } as ShoppingCart;
    private sale: Sale = {
        client: {} as SaleClient,
        receiptQuantity: 0,
        vatAssociation: {} as SaleVatAssociation,
    } as Sale;
    public isModalVisible = false;
    public isReplacementGuide = false;
    public navigationMessage: string;

    constructor(
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private creditEntityService: CreditEntityService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private peripheralService: PeripheralService,
        private spinService: SpinService,
        private saleService: SaleService,
        private shiftService: ShiftService
    ) {

        this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
        this.total = this.shoppingCart.totalToPay / 100;
        this.totalRemaining = this.shoppingCart.payment.pendingAmount / 100;
        this.totalReturn = this.shoppingCart.payment.returnAmount / 100;

        this.getCreditEntityList();

        this.shoppingCartService.shoppingCartChange.subscribe(
            (data) => {
                if (data != null && data.showHeader) {
                    this.total = data.totalToPay / 100;
                }
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            }
        );
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
    }

    private async getCreditEntityList(): Promise<void> {
        const entitiesResponse = this.creditEntityService.getAllEnabledCreditEntities();
        entitiesResponse.then((response: BasicDataResponse) => {
            if (response?.success && response?.data) {
                this.creditEntityList = response.data;
                this.dropListCreditEntities = response.data.map((entity: CreditEntity) => {
                    return {
                        value: entity.id,
                        label: entity.name
                    };
                });
            }
        });
    }

    public async goToHome(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public async onPreviousClick(): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
    }

    public async onNextClick(): Promise<void> {
        this.shoppingCart.payment.amountCredit = this.shoppingCart.totalToPay;
        this.shoppingCart.creditEntityId = this.selectedCreditEntity.id;
        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        await this.prepareInvoice();
        await this.register();
    }

    private buildPayments(): void {
        if (this.shoppingCart.payment.amountCredit >= 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().creditMethodId, // Credit
                    value: this.shoppingCart.payment.amountCredit
                }
            );
        }
    }

    public async register(): Promise<void> {
        this.spinService.setSpinValue(true);
        const getStoreInfo = await this.peripheralService.getInfoFromMachine();
        const getOpNumber = await this.shiftService.getShiftInfo(Number(this.shiftService.getShiftID())).toPromise() as any;
        const customerDetails = this.storageManager.session.get(ReadingLoadingEnum.CustomersDetails) as CustomerDetailsDTO;
        this.sale.storeName = getStoreInfo.storeName;
        this.sale.storeAddress = getStoreInfo.address;
        this.sale.storeTicketingNumber = getStoreInfo.machineNumber;
        this.sale.operatorNumber = getOpNumber.data.ownerId;
        this.sale.customerGuideInfoName = customerDetails?.name;
        this.sale.customerGuideInfoPhoneNumber = customerDetails?.phoneNumber;
        this.sale.customerGuideInfoDocumentType = customerDetails?.customerDocumentType;
        this.sale.customerGuideInfoDocumentNumber = customerDetails?.customerDocumentNumber;
        this.sale.customerGuideInfoNotes = customerDetails?.notes;
        const printDocumentRequest = {
            duplicate: false,
            additionalCopies: this.sale.receiptQuantity,
            deviceId: this.sale.deviceId,
            operatorNumber: getOpNumber.data.ownerId,
            customerNameGuide: customerDetails?.name,
            customerPhoneNumberGuide: customerDetails?.phoneNumber,
            customerDocumentTypeGuide: customerDetails?.customerDocumentType,
            customerDocumentNumberGuide: customerDetails?.customerDocumentNumber,
            customerNotesGuide: customerDetails?.notes
        };
        const response = await this.saleService.register(this.sale, printDocumentRequest) as any;
        if (response?.success) {
            this.navigationMessage = this.hasFamilyPassVend() && !this.isReplacementGuide ?
                CommonEnum.msgAcquiredFamilyPass :
                CommonEnum.msgSaleSucess;
            if (this.hasFamilyPassVend() && !this.isReplacementGuide) {
                this.isModalVisible = true;
            } else {
                await this.navigationManager.go(TicketingRoutes.HOME, {
                    message: this.navigationMessage,
                    messageType: 'success'
                });
            }
        } else {
            this.alertService.error(response.content, true);
            if (RegisterSaleErrorTypeEnum.PRINT === response?.error) {
                this.shoppingCartService.resetShoppingCartHeader();
                await this.navigationManager.go(TicketingRoutes.HOME);
            }
        }
        this.spinService.setSpinValue(false);
    }

    public isNextDisabled(): boolean {
        return this.selectedCreditEntity?.id == null;
    }

    public handleDropdownSelect(value: any): void {
        this.selectedCreditEntity = this.creditEntityList.find((entity) => entity.id === value);
    }

    private async prepareInvoice(): Promise<void> {
        const peripheralId = await this.peripheralService.getPeripheralId();
        this.buildPayments();
        this.sale = {
            deviceId: peripheralId,
            cartId: this.shoppingCart.cartId,
            client: {} as SaleClient,
            payments: this.payments,
            vatAssociation: {
                associate: false,
                predefined: false
            } as SaleVatAssociation,
            vatInternational: false,
            receiptQuantity: 0,
            couponCode: null,
            siitEntityId: null,
            creditEntityId: this.selectedCreditEntity.id,
            prePaymentEntityId: null
        } as Sale;
    }

    private hasFamilyPassVend(): boolean {
        return this.shoppingCart?.products?.some((product: any) => TitleGroupEnum.FAMILY_NAVIGATOR === product.titleGroup &&
            LoadTitleTransactionType.TRANSACTION_VEND === product.transactionTypeId);
    }

    public onModalClickNo(): void {
        this.isModalVisible = false;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.HOME, {
            message: this.navigationMessage,
            messageType: 'success'
        }), 100);
    }

    public onModalClickYes(): void {
        this.isModalVisible = false;
        const load: Load = {
            cardRead: this.storageManager.session.get<CardRead>(CardEnum.CARD_READ) as CardRead,
            titleId: 0,
        } as Load;
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.FAMILY_PASS, {
            load,
            card: cardDetails,
            hasFamilyTicketLoaded: true,
        }), 100);
    }

}
