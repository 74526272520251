import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '../../core/services/alert.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {StorageManagerService} from '../../core/services/storage-manager.service';
import NavigationManagerService, {TicketingRoutes} from '../../core/services/navigation-manager.service';
import {PeripheralService} from '../../core/services/peripheral.service';
import {WebsocketMessage, WebsocketMessageType} from '../../shared/models/websocket-message';
import {APIVivaResponse} from '../../shared/models/api-viva-response';
import {ResetResponse} from '../../shared/models/reset-response';
import {CardDecodeResponse} from '../../shared/models/card-decode-response';
import {EnvironmentUtil} from 'src/environments/environment-util';
import {HandlerComponent, SocketClientService} from "../../core/services/websocket-client.service";

@Component({
  selector: 'app-reset-card',
  templateUrl: './reset-card.component.html',
  styleUrls: ['./reset-card.component.less']
})
export class ResetCardComponent implements OnInit, OnDestroy, HandlerComponent {

  ready: boolean;
  private cardBinay: CardDecodeResponse;

  constructor(private route: ActivatedRoute,
              private alertService: AlertService,
              private httpClient: HttpClient,
              private storageManager: StorageManagerService,
              private navigationManager: NavigationManagerService,
              private peripheralService: PeripheralService,
              private socketClient: SocketClientService) {
    this.socketClient.setHandlerComponent(this);
    this.ready = false;
  }

  name = '';

  async ngOnInit() {
  }

  ngOnDestroy() {
  }

  async handleMessage(messageBody: WebsocketMessage) {

    switch (messageBody.type) {
      case WebsocketMessageType.ExchangeApdu:
        const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId).toPromise();
        if (!response.success) {
          this.alertService.error(response.detail);
        }
        break;
      default:
        console.error('Unknown WebsocketMessageType - ' + messageBody.type);
        break;
    }
  }

  async onConnect(): Promise<void> {
    const response = await this.peripheralService.detectCard();
    this.cardBinay = response.data;
    this.ready = true;
    this.alertService.info('Ready to delete reset LV');
  }

  async reset() {
    const request = this.cardBinay as any;

    this.httpClient.post(`${EnvironmentUtil.getEnv().apiVivaUrl}api/card/reset`, request)
      .subscribe(async (response: APIVivaResponse<ResetResponse>) => {
        console.log(response);
        if (response.success) {
          await this.navigationManager.go(TicketingRoutes.HOME);
        } else {
          this.alertService.error(response.description);
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.alertService.error(error.error as string);
      });
  }

}
