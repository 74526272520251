import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {NavigationExtras, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ShiftService} from '../../services/shift.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './header-shopping-cart.component.html',
  styleUrls: ['./header-shopping-cart.component.less']
})
export class HeaderShoppingCartComponent implements OnInit {

  @Input() total = 0;
  @Input() showList = 0;

  listResultColumn: TableColumn[] = [];
  listResultHeader: TableHeaderData[] = [];
  listResult: any[] = [];

  shoppingCartChange: Subject<any> = new Subject<any>();

  constructor(private router: Router,
              private shiftService: ShiftService,
              private shoppingCartService: ShoppingCartService) {

      this.shoppingCartService.shoppingCartChange.subscribe(data => {
        if (data != null && data.listResult != null) {
          this.listResult = data.listResult;
        }
        this.shoppingCartChange.next(data);
      });
  }

  ngOnInit(): void {
    this.prepareTableResult();
    this.loadListResult(this.shiftService.getShiftID());
  }

  prepareTableResult() {
    this.listResultColumn = [
      { title: 'Descrição', config: { colAlign: 'left', colWidth: '70%' } },
      { title: 'Quant.', config: { colAlign: 'right', colWidth: '10%' } },
      { title: 'Valor', config: { colAlign: 'right', colWidth: '20%' } }
    ];

    this.listResultHeader = [
      { visible: false, name: 'id', button: null} as TableHeaderData,
      { visible: true, name: 'description', button: null } as TableHeaderData,
      { visible: true, name: 'quantity' , config: { valAlign: 'right', money: false }, button: null } as TableHeaderData,
      { visible: true, name: 'price' , config: { valAlign: 'right', money: true }, button: null } as TableHeaderData
    ];
  }

  loadListResult(shiftID: string) {
    this.shoppingCartService.list(shiftID);
  }

  openShoppingCart() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };

    this.router.navigate(['shopping-cart'], navigationExtras);
  }
}
