<div class="margin-left-20 margin-right-20">
    <div nz-row class="margin-bottom-20">
        <div nz-col nzSpan="12">
            <h1 class="shift">
                Turno
            </h1>
            <h2 class="previousShift">
                Turnos Suspensos
            </h2>
        </div>
    </div>
    <div class="test">
        <div nz-row class="group">
            <div class="div-table-result" nz-col [nzSpan]="24">
                <app-primary-table
                    [listOfColumn]="tableColumns"
                    [headerDatas]="tableHeaders"
                    [listOfData]="suspendedShiftsList"
                    [showPagination]="true"
                    [isSingleSelection]="true"
                    (onPageChanged)="onPageChanged($event)"
                    [dataTotal]="dataTotal"
                    [frontPagination]="false"
                    [pageSize]="DEFAULT_PAGE_SIZE"
                    (callButtonClick)="isShiftClosable($event)"
                ></app-primary-table>
            </div>
        </div>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="start">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button (click)="goBack()">
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
    </div>
</div>

<app-alert-modal
    [(isModalVisible)]="isCloseShiftModalVisible"
    (onCloseModal)="handleCancelCloseShiftModal()"
    (onClickCancel)="handleCancelCloseShiftModal()"
    (onClickConfirm)="closeShift()"
    [isConfirmLoading]="isCloseShiftModalOkLoading"
    [confirmText]="'Sim'"
    [title]="'Fecho de Turno'"
>
    <h3 class="modal-sub-text data-label">
        Deseja fechar o Turno?
    </h3>
    <div class="search-row">
        <div class="flex-column">
            <label class="label">Data de Início</label>
            <p class="data-label">{{ shiftInfo?.startDate | date:'dd-MM-yyyy HH:mm:ss' }}</p>
        </div>
        <div class="flex-column">
            <label class="label">Local</label>
            <p class="data-label">{{ shiftInfo?.shiftLocation }}</p>
        </div>
        <div class="flex-column">
            <label class="label">Operador</label>
            <p class="data-label">{{ shiftInfo?.ownerId }}</p>
        </div>

        <div class="flex-column">
            <label class="label">Nº Turno</label>
            <p class="data-label">{{ shiftInfo?.id }}</p>
        </div>
    </div>

</app-alert-modal>
