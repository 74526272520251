export class DateUtils {

    static getTimezone(): string {
        var date = new Date();
        return (date.getTimezoneOffset() / 60).toString();
    }

    static setTimeTo0(date: Date) {
        date.setHours(0,0,0,0);
        return date;
    }

    static setTimeTo235959(date: Date) {
        date.setHours(23, 59, 59, 999);
        return date;
      }
      

    public static addMonths(date: Date, months: number) {
        date.setMonth(date.getMonth() + months);
        date.setDate(0);
        return date;
    }

}
