import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-primary-white-button',
  templateUrl: './primary-white-button.component.html',
  styleUrls: ['./primary-white-button.component.less',
              '../../../modules/operation/pages/shopping-cart/shopping-cart.component.less',
              '../../../modules/operation/pages/payment-money/payment-money.component.less',
              '../../../modules/operation/pages/invoice/invoice.component.less',
              '../../../modules/operation/pages/tariff-pdf/tariff-pdf.component.less'],
})
export class PrimaryWhiteButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter<any>();
  @Input() disabled;
  @Input() width = '';
  @Input() height = '';
  @Input() color = '';
  @Input() color2 = '';
  @Input() buttonCSS = 'button-primary-label';

  @Input() critical: boolean = false;

  onClickButton(event) {
    if(this.critical){
      this.disabled = true;
    }
    this.onClick.emit(event);
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

}
