export class TitleUtils {

    /**
     * Transform legacy price into normal price. ex 500 into 5,00 €
     * @param price String
     * @return formatted price with currency symbol
     */
    static getFormattedMoney(price: string): string {
        // needed to inicialize with this specific string because there are cases that use this specific string in verifications
        let formattedMoney = ',0 €';
        if (price === '0') {
            formattedMoney = price.substring(0, price.length / 2) + ',' + price.substring(price.length / 2) + ' €';
        } else {
            if (price.length === 1) {
                // special case when there's only one number, because number X divided by 100 is always 0,0X
                // so to ensure that the next substring works fine, we need to add one extra number (zero)
                price = price.padStart(price.length + 1, '0');
            }
            formattedMoney = price.substring(0, price.length - 2) + ',' + price.substring(price.length - 2) + ' €';
            if (formattedMoney.startsWith(',')) {
                formattedMoney = formattedMoney.padStart(formattedMoney.length + 1, '0');
            }
        }
        return formattedMoney;
    }

    /**
     * Transform price into price with discount
     * @param listTitles any[]
     * @param titleId title id
     * @return price with discount
     */

    static getPriceWithDiscount(listTitles: any[], titleId: any) {
        return listTitles.find((i) => i.id === titleId).price - listTitles.find((i) => i.id === titleId).discountValue;
    }
    
    static getDiscountValue(titlePrice: number, discountValue: number): number {
        const discountPrice = titlePrice * (discountValue / 100.0);
        return titlePrice - discountPrice;
    }


}
