export interface TitleDetail {
    titleId: number;
    expiringDateStart: string;
    expiringDateFinish: string;
    titleDescription: string;
    total: number;
  }

export enum ValidityType {
  SLIDING = "D",
  MENSAL = "M"
}
