import { AlertService } from 'src/app/core/services/alert.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, from, EMPTY } from 'rxjs';
import { Guid } from '../../configs/guid';
import { ShiftService } from '../services/shift.service';
import { JwtUtils } from '../../shared/utils/jwt-utils';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private alertService: AlertService,
    private shiftService: ShiftService,
    private navigationManagerService: NavigationManagerService,
    private storageManagerService: StorageManagerService
  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.handleAccess(httpRequest, next));
  }

  private async refreshToken() {

    const resRefreshToken = await this.shiftService.refreshToken().toPromise();

    if (resRefreshToken && resRefreshToken.success) {

      const authToken = resRefreshToken?.data?.token;
      const refreshToken = resRefreshToken?.data?.refreshToken;

      this.storageManagerService.session.set(CommonEnum.refreshToken, refreshToken);
      this.storageManagerService.session.set(CommonEnum.accessToken, authToken);

      const expriresIn = new JwtUtils().getExpiresIn();

      this.storageManagerService.session.set(CommonEnum.expiresIn, expriresIn.toString());

    } else {
      throw new Error();
    }
  }

  private async handleAccess(httpRequest: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    const TransactionId = Guid.newGuid();

    httpRequest = httpRequest.clone({
      setHeaders: {
        'RequestId': TransactionId,
        'Accept-Language': 'PT'
      }
    });

    const currentTime = new Date().getTime();
    const tokenEndDate = new JwtUtils().getExpireDate().getTime();

    if (this.shiftService.isLoggedIn() && tokenEndDate <= currentTime && !this.isRefreshing) {

      try {

        this.isRefreshing = true;
        await this.refreshToken();
        this.isRefreshing = false;

      } catch (e) {

        await this.doLogout();
        this.alertService.error(`Sessão do utilizador ${this.shiftService.getUsername()} expirou`);
        this.isRefreshing = false;
        return EMPTY.toPromise();

      }

    }

    if (this.shiftService.isLoggedIn() && !this.shiftService.isSuspend()) {

      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${new JwtUtils().getRawToken()}`
        }
      });

    }

    return next.handle(httpRequest).toPromise();

  }

  private async doLogout() {

    this.storageManagerService.session.remove(CommonEnum.accessToken);
    this.storageManagerService.session.remove(CommonEnum.expiresIn);
    this.storageManagerService.session.remove(CommonEnum.refreshToken);

    this.storageManagerService.session.set(CommonEnum.shiftStatus, CommonEnum.shiftStatusSuspend);

    this.shiftService.clearHeader();
    await this.navigationManagerService.go(TicketingRoutes.LOGIN);

  }
}
