import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-primary-large-button',
  templateUrl: './primary-large-button.component.html',
  styleUrls: ['./primary-large-button.component.less',
              '../../../modules/load/pages/lisboa-viva/family-pass/family-pass.component.less'],

})
export class PrimaryLargeButtonComponent implements OnInit {
  @Input() color = '';
  @Input() disabled;
  @Input() border = '';
  @Output() onClick = new EventEmitter<any>();

  @Input() critical: boolean = false;

  onClickButton(event) {
    if(this.critical){
      this.disabled = true;
    }
    this.onClick.emit(event);
  }

  constructor() {}

  ngOnInit(): void {}
}
