import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CardRead } from 'src/app/shared/models/card-read';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from '../../shared/enum/card.enum';
import { StorageManagerService } from './storage-manager.service';
import { AuthorizationLoadService } from 'src/app/core/services/authorization-load.service';
import { TickCode } from 'src/app/shared/enum/tick-code.enum';
import { TickOperCode } from 'src/app/shared/enum/tick-oper-code.enum';
import { TariffService } from './tariff.service';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CardUtils } from 'src/app/shared/utils/card-utils';
import { TitleUtils } from 'src/app/shared/utils/title-utils';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { RechargeTitleValue } from 'src/app/modules/operation/modules/recharge-title-value';

@Injectable({
    providedIn: 'root'
})
export class SupportDetailService {

    error = 'Ocorreu um erro ao recuperar os dados.';
    cardLoadChange: Subject<any> = new Subject<any>();
    callPrintCardDetails: Subject<any> = new Subject<any>();

    private UNKNOW_TITLE_ID = -1;
    private today = new Date();

    constructor(private httpClient: HttpClient,
                private storageManager: StorageManagerService,
                private authorizationLoadService: AuthorizationLoadService,
                private tariffService: TariffService,
                private datePipe: DatePipe
    ) {
    }

    async getListNewLoad() {
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        return await this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/new-load-list`, cardFullDetails)
            .toPromise();
    }
    
    public async getFamilyPassLoadList() {
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        return await this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/family-pass-load-list`, cardFullDetails)
            .toPromise();
    }

    isTicket(tickCode: number, tickOperCode: number): boolean {
        return TickOperCode.TICKET_TICK_OPER_CODE === tickOperCode &&
            (TickCode.CAML_TICK_CODE === tickCode || TickCode.MLTT_TICK_CODE === tickCode ||
                TickCode.MLCP_TICK_CODE === tickCode || TickCode.TRIPCAML_TICK_CODE === tickCode);
    }

    async loadCard(data: CardDetailsMessage) {

        // Save Current CardDetails in SessionStorage
        this.storageManager.session.set(CardEnum.FULL_CARD_DETAILS, data);

        const cardRead: CardRead = {
            number: '' + data.details.environment.issuing_number,
            serialNumber: data.low_part.toString(),
            issueDate: null,
            expiringDate: null,
            holder: data.details.profile.owner,
            profileIds: [
                data.details.profile.profile_one_code,
                data.details.profile.profile_two_code,
                data.details.profile.profile_three_code,
                data.details.profile.profile_four_code
            ],
            profileDescription: '',
            titles: []
        };

        const actionsResponse = await this.fetchActions().toPromise() as any;
        cardRead.slotsFull = actionsResponse.data.environment.slotsFull;
        cardRead.expiringDate = actionsResponse.data.environment.expiryDate;

        actionsResponse.data.profiles.forEach(profile => {
          cardRead.profileDescription += profile.name;
        });

        actionsResponse.data.titles.forEach(titleDetails => {

            const contractNum = data.details.contracts.findIndex(contract => contract.ticket_code === titleDetails.ticketCode &&
                contract.ticket_code === titleDetails.ticketCode);

            let titleMoney = data.details.contracts[contractNum].counter.value.toString();

            let formattedMoney = TitleUtils.getFormattedMoney(titleMoney);

            const contractTicketCode: number = data.details.contracts[contractNum].ticket_code;
            const contractOperatorCode: number = data.details.contracts[contractNum].operator_code;

            // if card is ticket no format needed
            if (this.isTicket(contractTicketCode, contractOperatorCode) ||
                data.details.contracts[contractNum].counter.counter_type === 0) {
                formattedMoney = titleMoney;
            } else if (CardUtils.IsZapping(contractTicketCode, contractOperatorCode, titleDetails.id) &&
                data.details.contracts[contractNum].counter.value === 0) {
                // title is zapping and has value 0
                titleMoney = titleMoney.padStart(titleMoney.length + 1, '0');
                formattedMoney = TitleUtils.getFormattedMoney(titleMoney);
            }

            let expiringDateFinish = titleDetails?.validity?.endValidity;
            let expiringDateStart = titleDetails?.validity?.startValidity;
            if (this.UNKNOW_TITLE_ID === titleDetails.id) {
                expiringDateFinish = null;
                expiringDateStart = null;
            }

            const isShoppingCartFull = this.storageManager.session.get(CommonEnum.shoppingCartFull) as boolean;

            cardRead.titles.push({
                id: titleDetails.id,
                description: titleDetails.description,
                expiringDateBalance: formattedMoney,
                tickCode: titleDetails.ticketCode,
                tickOperCode: titleDetails.operatorCode,
                canCancel: isShoppingCartFull ? false : titleDetails.cancelable,
                canExchange: isShoppingCartFull ? false : titleDetails.exchangeable,
                canLoad: isShoppingCartFull ? false : titleDetails.loadable,
                canRenew: titleDetails.loadable,
                canTransfer: isShoppingCartFull ? false : titleDetails.transferable,
                isSingleTicket: titleDetails.singleTicket,
                isSlidingTitle: titleDetails.slidingTitle,
                isFixedMonth: titleDetails.fixedMonth,
                isStillValid: titleDetails.stillValid,
                emitGuide: true,
                expiringDateFinish,
                expiringDateStart,
                price: titleDetails.units,
                titleGroup: titleDetails.titleGroupId,
                titlePrice: titleDetails.price,
                titles: [],
                discount: titleDetails.discount,
                loadAmount: titleDetails.loadAmount
            });

        });
        this.cardLoadChange.next(cardRead);
    }

    public fetchActions() {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card`, cardDetails);
    }

    public getLastEventMessages(): Observable<BasicDataResponse> {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        return this.httpClient.post<any>(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/card/last-event`, cardDetails);
    }


    printCardDetails(request: any) {
        console.log('call printCardDetails');

        this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}document/api/print/print-card-details`, request)
            .subscribe(
            (res: BasicDataResponse) => {
                if (res && res.success) {
                    this.callPrintCardDetails.next(res.success);
                } else {
                    this.callPrintCardDetails.error(res.message);
                }
            },
            (e: any) => {
                console.log(e);
                try {
                    this.error = HttpUtils.getErroMessage(e);
                    console.log(this.error);
                    this.callPrintCardDetails.error(this.error);
                } catch (e) {
                    console.log(e);
                }
            });
    }


    async hasLoadAuthorization(cardNumber: number, serialNumber: number): Promise<boolean> {

        try {
            const response = await this.authorizationLoadService
                .getAuthorizations({ cardNumber, serialNumber }).toPromise();

            if (response && response.data) {
                return (response.data.titles.length > 0);
            }

        } catch (e) {
            return false;
        }
        return false;
    }

}
