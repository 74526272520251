import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimaryListButtonData } from '../../models/primary-list-button-data';
import { PrimaryButtonSellData } from '../../models/primary-button-sell-data';

@Component({
  selector: 'app-primary-list-button-non-ticketing',
  templateUrl: './primary-list-button-non-ticketing.component.html',
  styleUrls: ['./primary-list-button-non-ticketing.component.less'],
})
export class PrimaryListButtonNonTicketingComponent implements OnInit {
  @Input() buttonSize = 'normal';
  @Input() colNum = 2;
  @Input() text = '';
  @Input() data: PrimaryButtonSellData[] = [];
  @Input() disabled;

  @Output() callButtonClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

  onButtonClick(event) {
    this.callButtonClick.emit(event);
  }
}
