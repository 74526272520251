import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';

@Component({
  selector: 'app-selection',
  templateUrl: './requisition.component.html',
  styleUrls: ['./requisition.component.less']
})
export class RequisitionComponent implements OnInit {

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
  }

  initialScreen() {
    this.router.navigate(['home']);
  }

  newReq() {
    this.router.navigate(['requisition-lv/new']);
  }
  reqHistory() {
    this.router.navigate([TicketingRoutes.LV_REQUISITION_HISTORY]);
  }
}
