import { CardDetailsMessage } from './../../../../../shared/models/card-details-message';
import { LoadTitleTransactionType } from './../../../../../shared/enum/load-title-transaction-type.enum';
import { ShiftService } from 'src/app/core/services/shift.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { RecoverReplacementGuideService } from 'src/app/core/services/recover-replacement-guide.service';
import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { TableHeaderDataType, TableHeaderDataConfig } from 'src/app/shared/models/table-header-data-config';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { IssueReplacementGuideService } from 'src/app/core/services/issue-replacement-guide.service';
import { ReplacementGuideDTO } from '../model/create-repl-guide-request';
import { NotificationDuration } from "../../../../../shared/enum/notification-duration.enum";
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { LoadTitleService } from 'src/app/core/services/load-title.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';

@Component({
    selector: 'app-deliver-replacement-guide-details',
    templateUrl: './deliver-replacement-guide-details.component.html',
    styleUrls: ['./deliver-replacement-guide-details.component.less']
})
export class DeliverReplacementGuideDetailsComponent implements OnInit {

    serialNumber: number;
    isChecked = false;
    replacementGuideDTO: ReplacementGuideDTO = {} as ReplacementGuideDTO;
    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    dataTable = [];
    transfLoadId: number;

    constructor(
        private shiftService: ShiftService,
        private peripheralService: PeripheralService,
        private storageManager: StorageManagerService,
        private recoverReplacementGuideService: RecoverReplacementGuideService,
        private tariffService: TariffService,
        private navigationManager: NavigationManagerService,
        private alertService: AlertService,
        private loadTitleService: LoadTitleService
    ) {
        if (navigationManager.getNavigation()?.extras?.state?.serialNumber) {
            this.serialNumber = Number(navigationManager.getNavigation()?.extras?.state?.serialNumber);
            this.prepareTable();
            this.loadReplacementGuideDetails();
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
        }
    }

    ngOnInit(): void {
        this.alertService.info('Por favor coloque o suporte Lisboa VIVA/navegante personalizado no leitor.',
            false, true, NotificationDuration.INFINITE_DURATION);
    }

    checkboxEventHandler(e: any) {
        this.isChecked = e;
    }

    async cancelClick() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    prepareTable() {
        this.listTitleColumn = [
            { title: 'Título', config: { colAlign: 'left', colWidth: '50%' } },
            { title: 'Validade', config: { colAlign: 'center', colWidth: '50%' } },
        ];
        this.listTitleHeader = [
            { visible: true, name: 'titleDescription', config: null, button: null, } as TableHeaderData,
            {
                visible: true, name: 'validityEnd',
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy', valAlign: 'center' } as TableHeaderDataConfig,
                button: null
            } as TableHeaderData
        ];
    }

    async loadReplacementGuideDetails() {

        this.dataTable = [];

        try {
            const response = await this.recoverReplacementGuideService
                .isCardActiveReplacementGuide(this.serialNumber.toString()).toPromise();

            if (response?.success && response?.data != null) {
                const infoTitleByIDResponse = await this.tariffService.fetchTitleById(response.data.title.titleId).toPromise();

                if (infoTitleByIDResponse?.success && infoTitleByIDResponse?.data != null) {
                    const loadTitleResponse = await this.loadTitleService.findLatest(this.serialNumber.toString()).toPromise();
                    if (loadTitleResponse?.success && loadTitleResponse?.data != null) {
                        this.transfLoadId = loadTitleResponse.data.id;
                        this.replacementGuideDTO = response.data;
                        this.replacementGuideDTO.loadAmount = loadTitleResponse.data.amount;
                        this.dataTable = [
                            {
                                titleDescription: infoTitleByIDResponse.data.description,
                                validityEnd: this.replacementGuideDTO.title.endDate
                            }
                        ];
                    }

                }
            }
        } catch (e) {
            this.alertService.error('A venda do Guia de Substituição com nº de série ' +
                this.serialNumber + ' não foi encontrada.');
            console.log('HTTP Error', e);
        }
    }

    async onNextClick() {

        const peripheralId = await this.peripheralService.getPeripheralId();
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const titleResponse = await this.tariffService.fetchTitleById(this.replacementGuideDTO.title.titleId).toPromise();

        const loadAmount = this.replacementGuideDTO.loadAmount != null ?
            this.replacementGuideDTO.loadAmount :
            1;
        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.replacementGuideDTO.title.titleId,
            description: titleResponse.data.description,
            quantity: loadAmount,
            price: 0,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_TRANSF,
            annulId: null,
            cardNumber: String(cardFullDetails.details.environment.issuing_number),
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: this.replacementGuideDTO?.id,
            voucherId: 0,
            cardSerialNumber: cardFullDetails.low_part,
            isAnnul: false
        };

        const loadTitleRequest: LoadTitleRequest = {
            peripheralId,
            serialNumber: this.replacementGuideDTO?.newCard?.serialNumber?.toString(),
            cardNumber: this.replacementGuideDTO?.newCard?.cardNumber?.toString(),
            tickCode: Number(titleResponse.data.tickCode),
            tickOperCode: Number(titleResponse.data.tickOperCode),
            id: this.replacementGuideDTO.title.titleId,
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID)),
            transfLoadId: this.transfLoadId,
        } as LoadTitleRequest;

        this.replacementGuideDTO.warranty = this.isChecked;
        const currentCardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
        const currentCardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDecodeResponse;
        this.storageManager.session.set(CardEnum.PREVIOUS_CARD_BINARY, currentCardBinary);
        this.storageManager.session.set(CardEnum.PREVIOUS_FULL_CARD_DETAILS, currentCardFullDetails);
        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
        this.storageManager.session.set(ReadingLoadingEnum.CreateReplGuideRequest, this.replacementGuideDTO);
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.RecoverGuide);
        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV_GUIDE_RECOVER);
    }

    public hasWarranty(): boolean {
        return this.replacementGuideDTO.warranty;
    }

}
