<button
  class="button-primary-label"
  nz-button
  nzBlock
  nzType="primary"
  [disabled]="disabled"
  (click)="onClickButton($event)"
  [ngClass]="buttonCSS"
>
  <h3 class="h3"><ng-content></ng-content></h3>
</button>
