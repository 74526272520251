import { CommonEnum } from '../app/shared/enum/common.enum';

export class EnvironmentUtil {
  // Local Peripheral API url
  public static peripheralApiUrl = 'http://localhost:6505/';

  public static getEnv(): EnvironmentPath {
    if (this.isDev() || this.enabledDevAPI()) {
      return {
        userIntApiUrl: 'https://api-bilhetica-dev.alx.carris.pt/',
        apiIntApiUrl: 'https://api-bilhetica-dev.alx.carris.pt/',
        carrisSystemApiUrl: 'https://api-bilhetica-dev.alx.carris.pt/carris-system-api/',
        apiVivaUrl: 'https://api-bilhetica-dev.alx.carris.pt/viva/',
        peripheralApiUrl: this.peripheralApiUrl,
        prePaymentMethodId: 5,
        creditMethodId: 4,
        otherMethodId: 3,
        tpaMethodId: 2,
        moneyMethodId: 1
      };
    }

    if (this.isQuality()) {
      return {
        userIntApiUrl: 'https://api-bilhetica-qa.alx.carris.pt/',
        apiIntApiUrl: 'https://api-bilhetica-qa.alx.carris.pt/',
        carrisSystemApiUrl: 'https://api-bilhetica-qa.alx.carris.pt/carris-system-api/',
        apiVivaUrl: 'https://api-bilhetica-qa.alx.carris.pt/viva/',
        peripheralApiUrl: this.peripheralApiUrl,
        prePaymentMethodId: 5,
        creditMethodId: 4,
        otherMethodId: 3,
        tpaMethodId: 2,
        moneyMethodId: 1
      };
    }

    if (this.isPreProd()) {
        return {
            userIntApiUrl: 'https://api-bilhetica-preprod.alx.carris.pt/',
            apiIntApiUrl: 'https://api-bilhetica-preprod.alx.carris.pt/',
            carrisSystemApiUrl: 'https://api-bilhetica-preprod.alx.carris.pt/carris-system-api/',
            apiVivaUrl: 'https://api-bilhetica-preprod.alx.carris.pt/viva/',
            peripheralApiUrl: this.peripheralApiUrl,
            prePaymentMethodId: 5,
            creditMethodId: 4,
            otherMethodId: 3,
            tpaMethodId: 2,
            moneyMethodId: 1
        };
    }

    if (this.isProd()) {
      return {
        userIntApiUrl: 'https://api-bilhetica.carris.pt/',
        apiIntApiUrl: 'https://api-bilhetica.carris.pt/',
        carrisSystemApiUrl: 'https://api-bilhetica.carris.pt/carris-system-api/',
        apiVivaUrl: 'https://api-bilhetica.carris.pt/viva/',
        peripheralApiUrl: this.peripheralApiUrl,
        prePaymentMethodId: 5,
        creditMethodId: 4,
        otherMethodId: 3,
        tpaMethodId: 2,
        moneyMethodId: 1
      };
    }

    // Local Env
    return {
      userIntApiUrl: 'http://localhost:8093/',
      apiIntApiUrl: 'http://localhost:8080/',
      carrisSystemApiUrl: 'https://api-bilhetica-dev.alx.carris.pt/carris-system-api/',
      apiVivaUrl: 'https://api.dev-carris-cloud.com/',
      peripheralApiUrl: this.peripheralApiUrl,
      prePaymentMethodId: 5,
      creditMethodId: 4,
      otherMethodId: 3,
      tpaMethodId: 2,
      moneyMethodId: 1
    };
  }

  // Enable Dev API in your Local Storage
  static enabledDevAPI() {
    const enabledDevAPI = sessionStorage.getItem(CommonEnum.enabledDevAPI);
    if (enabledDevAPI === null || enabledDevAPI === undefined) {
      sessionStorage.setItem(CommonEnum.enabledDevAPI, 'false');
    }

    return sessionStorage.getItem(CommonEnum.enabledDevAPI) === 'true';
  }

  static isDev() {
    return window.location.hostname === 'bilheteira-dev.alx.carris.pt';
  }

  static isQuality() {
    return window.location.hostname === 'bilheteira-qa.alx.carris.pt';
  }

  static isPreProd() {
      return window.location.hostname === 'bilheteira-preprod.alx.carris.pt';
  }

  static isProd() {
    return window.location.hostname === 'bilheteira.carris.pt';
  }

  static isLocal() {
    return window.location.hostname === 'localhost';
  }
}

export interface EnvironmentPath {
  userIntApiUrl: string;
  apiIntApiUrl: string;
  carrisSystemApiUrl: string;
  apiVivaUrl: string;
  peripheralApiUrl: string;
  prePaymentMethodId: number;
  creditMethodId: number;
  otherMethodId: number;
  tpaMethodId: number;
  moneyMethodId: number;
}
