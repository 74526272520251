import { Component, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ShoppingCart } from '../../modules/shopping-cart';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import {SiitEntity} from "../../modules/siit-entity";
import {SiitEntityService} from "../../../../core/services/siit-entity.service";
import {BasicDataResponse} from "../../../../shared/models/basic-data-response";
import { CommonEnum } from 'src/app/shared/enum/common.enum';

export interface DropdownItem {
    value: number;
    label: string;
}

@Component({
    selector: 'app-other',
    templateUrl: './payment-siit.component.html',
    styleUrls: ['./payment-siit.component.less'],
})
export class PaymentSiitComponent implements OnInit {
    public static BLANK = "";
    btnNextDisable = true;
    payAll: boolean = false;
    navigation: Navigation;
    paymentForm: FormGroup;
    listResult: any[] = [];
    total = 0;
    totalOther: number = 0.00;
    totalPayed = 0;
    totalRemaining = 0;
    totalReturn = 0;
    couponCode: string = "";

    siitEntities: SiitEntity[] = [];
    selectedSiitEntity: SiitEntity;
    dropdownItems: DropdownItem[] = [];

    shoppingCart: ShoppingCart = { payment: {} } as ShoppingCart;

    moneyText = "";

    constructor(
        private formBuilder: FormBuilder,
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private siitEntityService: SiitEntityService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService) {

        this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
        this.total = this.shoppingCart.totalToPay / 100;
        this.totalRemaining = this.total;

        this.getEntities();

        this.shoppingCartService.shoppingCartChange.subscribe(
            (data) => {
                if (data != null && data.showHeader) {
                    this.listResult = data.listResult;
                    this.total = data.totalToPay / 100;
                }
            },
            (err) => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            }
        );
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);

        this.paymentForm = this.formBuilder.group({
            money: new FormControl('', {
                validators: [],
            }),
        });
    }

    async goToHome() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async onPreviousClick() {
        await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
    }

    async onNextClick() {
        this.shoppingCart.payment.amountOther = Math.round(this.totalPayed * 100);
        this.shoppingCart.payment.amountMoney = this.totalReturn;
        // client has to receive change
        if (this.totalReturn > 0) {
            // money is negative because is the change that client has to receive
            this.shoppingCart.payment.amountMoney = Math.round(-this.totalReturn * 100);
        }
        this.shoppingCart.couponCode = this.couponCode;
        this.shoppingCart.siitEntityId = this.selectedSiitEntity.id;
        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        await this.navigationManager.go(TicketingRoutes.INVOICE);
    }

    dblclick() {
        this.paymentOtherChange(this.total.toString())
    }

    public paymentOtherChange(event: string): void {
        // it's necessary to replace comma for dot because visually it's necessary to be a comma
        // but we need a dot so total can be recognized as a number
        this.totalOther = Number(event.replace(',', '.'));
        const totalToPay = this.total;

        if (this.totalOther >= totalToPay) {
            this.totalRemaining = 0;
            this.totalReturn = this.totalOther - totalToPay;
            this.totalPayed = totalToPay;
            this.payAll = true;
        } else {
            this.totalReturn = 0;
            this.totalRemaining = totalToPay - this.totalOther;
            this.payAll = false;
        }
    }
    couponCodeOnChange(event: any) {
        this.couponCode = event;
    }

    public checkIfBothFieldHaveValue() {
        if (this.couponCode != PaymentSiitComponent.BLANK && this.totalOther && this.selectedSiitEntity && this.payAll) {
            this.btnNextDisable = false;
        } else {
            this.btnNextDisable = true;
        }
        return this.btnNextDisable;
    }

    private async getEntities() {
        const entitiesResponse = this.siitEntityService.fetchAllEnabledSiitEntities();
        entitiesResponse.then((response: BasicDataResponse) => {
            if(response?.success && response?.data) {
                this.siitEntities = response.data;
                this.dropdownItems = response.data.map((entity: SiitEntity) => {
                    return {
                        value: entity.id,
                        label: entity.name
                    }
                });
            }
        })
    }

    public handleDropdownSelect(value): void {
        this.selectedSiitEntity = this.siitEntities.find((entity) => entity.id === value);
    }
}
