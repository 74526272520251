import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResetCardComponent} from './maintenance/reset-card/reset-card.component';
import {DeleteContractComponent} from './maintenance/delete-contract/delete-contract.component';
import {EnvironmentUtil} from '../environments/environment-util';

function getRoutes(): Routes {
    let rout: Routes = [];
    if (EnvironmentUtil.isDev() || EnvironmentUtil.enabledDevAPI() || EnvironmentUtil.isQuality()) {
        rout = [
            {path: 'reset', component: ResetCardComponent},
            {path: 'null/:slot', component: DeleteContractComponent},
            {path: 'null', component: DeleteContractComponent},
        ];
    }
    return rout;
}

const routes: Routes = getRoutes();

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
