<div nz-row class="row-requisition-detail">
    <h1 class="req">Requisições de Lisboa VIVA/navegante personalizado</h1>
</div>

<div nz-row class="row-requisition-detail">
    <h2 class="card">Introduzir dados do cartão a emitir</h2>
</div>

<div nz-row class="align-left row-requisition-detail">
    <div class="m-t-20">
        <app-primary-input-keyboard
            label="Nº DE CONTRIBUINTE"
            labelCss="labels"
            inputCss="input-requistion-number"
            inputPlaceholder="Digite o nº de contribuinte"
            keyboardType="number"
            [mask]="'0*'"
            [maxlength]="25"
            [value]="requisition.vatNumber"
            (valueChange)="onVatNumberChange($event)">
        </app-primary-input-keyboard>
    </div>
    <div nz-col [nzSpan]="5" class="vat-international">
        <app-primary-checkbox
            [(isChecked)]="isVatInternational"
            label="NIF Internacional"
            labelCss="label-border"
        >
        </app-primary-checkbox>
    </div>
</div>

<div nz-row class="align-left row-requisition-detail m-t-20">
    <div nz-col nzSpan="6">
        <span class="labels">LOCAL DE ENTREGA</span>
    </div>
    <div nz-col nzSpan="6">
        <span class="labels">DATA ESTIMADA DE ENTREGA</span>
    </div>
</div>

<div nz-row class="align-left row-requisition-detail">
    <div nz-col nzSpan="6">
        <app-primary-select
            [listOptions]="listDeliveryPlace"
            [dropdownMatchSelectWidth]="false"
            (selectedChange)="onChangeDropdownEvent($event)">
        </app-primary-select>
    </div>
    <div nz-col nzSpan="18" class="m-t-10">
        <span class="delivery-date">{{ deliveryDate | date: "dd/MM/yyyy" }} ({{
            requisition.deliveryType.expectedTime
            }}
            dias úteis)</span>
    </div>
</div>

<div nz-row class="align-left row-requisition-detail">
    <div class="m-t-20">
        <app-primary-input-keyboard
            label="OBSERVAÇÕES"
            labelCss="labels"
            inputCss="input-observations"
            isObservation="true"
            [maxlength]="60"
            (valueChange)="onObservationChange($event)">
        </app-primary-input-keyboard>
    </div>
</div>

<div nz-row class="row-requisition-detail m-t-20">
    <div nz-col [nzSpan]="12">
        <p class="p">{{ infoTitle.description }}</p>
    </div>
    <div nz-col [nzSpan]="3">
        <p class="p">{{ infoTitle.price?.value / 100 | number: ".2-2":"pt" }} € </p>
    </div>
</div>

<div class="yellow-line"></div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col nzSpan="12" class="align-left">
            <app-primary-white-button
                (click)="previousScreen()">
                <span class="btn-icon icon-anterior icon-middle float-left blue"></span>
                Voltar
            </app-primary-white-button>
            <app-primary-cancel-button
                (click)="homeScreen()"
                class="m-l-10"
                buttonCSS="button-cancel">
                Cancelar
            </app-primary-cancel-button>
        </div>
        <div nz-col nzSpan="12" style="padding-right: 48px" class="align-right">
            <app-primary-normal-button
                width="180px"
                [disabled]="requisition.vatNumber && requisition.selectedDeliveryPlace ? null : true"
                (click)="onRegisterClick()">
                REGISTAR
            </app-primary-normal-button>
        </div>
    </div>
</div>
