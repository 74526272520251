import { Injectable } from '@angular/core';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { HttpClient } from '@angular/common/http';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';

@Injectable({
    providedIn: 'root',
})
export class TicketingNetworkService {

    constructor(
        private httpClient: HttpClient
    ) {}

    public async getTicketingInfo(machineCode: string): Promise<BasicResponseDTO<number>> {
        return await this.httpClient.get<BasicResponseDTO<number>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}ticketing-network/api/ticketing-network/operational-unit/get-local-id/${machineCode}`)
            .toPromise();
    }



}
