/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  private readonly _persistentStorage: PersistentStorage;
  private readonly _sessionStorage: SessionStorage;

  constructor() {
    this._persistentStorage = new PersistentStorage();
    this._sessionStorage = new SessionStorage();
  }

  get persistent(): IStorage {
    return this._persistentStorage;
  }

  get session(): IStorage {
    return this._sessionStorage;
  }

}

export interface IStorage {
  get<T>(key: string): T | string | undefined | null;
  has(key: string): boolean;
  set(key: string, value: string | object);
  remove(key: string);
}

class PersistentStorage implements IStorage {

  get<T>(key: string): T | string | undefined | null {

    const item = localStorage.getItem(key);

    if (item === null || typeof item === 'undefined') {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  has(key: string): boolean {
    return localStorage.getItem(key) != null;
  }

  set(key: string, value: string | object) {
    if (value === null || typeof value === 'undefined') {
      return;
    }
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}

class SessionStorage implements IStorage {

  get<T>(key: string): T | string | undefined | null {

    const item = sessionStorage.getItem(key);

    if (item === null || typeof item === 'undefined') {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  has(key: string): boolean {
    return sessionStorage.getItem(key) != null;
  }

  set(key: string, value: string | object) {
    if (value === null || typeof value === 'undefined') {
      return;
    }
    if (typeof value === 'string') {
      sessionStorage.setItem(key, value);
    } else if (typeof value === 'object') {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }
}
