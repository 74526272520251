<nz-table
  #listTable
  class="table"
  nzSize="small"
  [nzLoading]="loading"
  [nzPageSize]="pageSize"
  [nzData]="listOfData"
  [nzShowPagination]="showPagination"
  (nzPageIndexChange)="onPageIndexChanged($event)"
  [nzFrontPagination]="frontPagination"
  [nzTotal]="dataTotal"
  [nzPageIndex]="pageIndex"
>
  <thead>
    <tr>
      <th
        [ngClass]="tableHeadCSS"
        *ngFor="let column of listOfColumn"
        [nzWidth]="column.config != null ? column.config.colWidth : '30%'"
        [nzAlign]="column.config != null ? column.config.colAlign : 'left'"
      >
        {{ column.title }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of listTable.data; let i=index">
      <td
        [ngClass]="tableRowCSS"
        *ngFor="let cols of headerDatas"
        [hidden]="!cols.visible"
        [nzAlign]="cols.config != null ? cols.config.valAlign : 'left'"
      >
        <ng-template [ngIf]="cols.checkbox == null" [ngIfElse]="checkbox">
          <ng-template [ngIf]="cols.button == null" [ngIfElse]="button">
            <span [ngSwitch]="findType(cols?.config)">
              <span *ngSwitchCase="'MONEY'">
                <span *ngIf="isExc && i == 1">
                  -
                </span>
                {{
                  data[cols.name] / 100 | number: ".2-2":"pt"
                }} €
              </span>
              <span *ngSwitchCase="'DATE'">
                <div *ngIf="isSupportDetail();else isOk">
                  <div *ngIf="isExpired(i);then isDateExpired; else isOk">

                  </div>
                </div>
              </span>

            <ng-template #isDateExpired>
              <div *ngIf="isExpired(i); else isOk">
                <td class="expired">
          {{
            data[cols.name] | date: cols?.config?.format || "dd/MM/yyyy"
          }}
              </td>
              </div>
            </ng-template>

            <ng-template #isOk>
              <div *ngIf="isExpired(i)==false; else isDateExpired">
              {{
                data[cols.name] | date: cols?.config?.format || "dd/MM/yyyy"
              }}
            </div>
          </ng-template>


              <span *ngSwitchCase="'PERCENT'">
                {{
                  data[cols.name] + "%"
                }}
              </span>

              <span *ngSwitchCase="'BOOLEAN'">
                  {{
                    data[cols.name] ? cols.booleanTrueValueLabel : cols.booleanFalseValueLabel
                  }}
              </span>

               <span *ngSwitchDefault>
                <div *ngIf="isSupportDetail(); else hideColor">
                <div *ngIf="isExpired(i);then showGreyColor; else hideColor">
                </div>
                </div>
              </span>
            </span>

            <ng-template #showGreyColor>
              <div *ngIf="isExpired(i); else hideColor">
              <div *ngIf="data[cols.name] !== ',0 €'">
                <td class="expired">
                {{ data[cols.name] }}
                </td>
              </div>
            </div>
            </ng-template>

            <ng-template #hideColor>

                <div *ngIf="data[cols.name] !== ',0 €'">
                    {{ data[cols.name] }}
                </div>
            </ng-template>

          </ng-template>

          <ng-template #button>
            <!--BTN HIDDEN -->
            <ng-template
              [ngIf]="
                data.canLoad == false && cols.button.titleButton == 'Trocar'
              "
              [ngIfElse]="normalBtn"
            ></ng-template>

            <ng-template #normalBtn>
              <app-primary-table-button
              [buttonCSS]="cols.button.buttonCSS"
              [buttonText]="cols.button.buttonText"
              [textCSS]="cols.button.textCSS"
              [buttonIcon]="cols.button.iconType"
              (onClick)="onButtonClick($event, cols.button.btnAction, data)"
              [value]="data.id"
              *ngIf="isVisible(cols.button, data)">
              </app-primary-table-button>
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #checkbox>
          <app-primary-checkbox
            [label]="cols.checkbox.label"
            [isDisabled]="data[cols.checkbox.isDisabled] || (isSingleSelection && selected && !isSelectedId(data.id))"
            [isChecked]="data[cols.checkbox.isChecked]"
            (isCheckedChange)="changeCheckbox($event, data.id)"
          ></app-primary-checkbox>
        </ng-template>
        <ng-template #checkbox>

          <app-primary-checkbox
            [label]="cols.checkbox.label"
            [isChecked]="data.emitGuide"
            [isDisabled]="!data.actions.replacementGuide"
            (isCheckedChange)="changeCheckbox($event, data.id)"
          ></app-primary-checkbox>
          <!-- </ng-template> -->
        </ng-template>
      </td>
    </tr>
  </tbody>
<tfoot class="tfoot">
    <tr [hidden]="!showTotal">
        <td ngClass="table-space" nzAlign="right" [attr.colspan]="12">
        </td>
        <td class="total-sett" ngClass="table-total" [attr.colspan]="spanTotal" nzAlign="left">
            TOTAL
        </td>
        <td ngClass="table-total" nzAlign="right" class="sub-total-sett">
            <h3 [hidden]="!showQt">{{ totalQt }}</h3>
        </td>
        <td ngClass="table-total" nzAlign="right" class="showTotal">
            {{ total / 100 | number: ".2-2":"pt" }} &nbsp;€
        </td>
    </tr>
</tfoot>
</nz-table>
