import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { AuthorityName } from 'src/app/shared/models/authority-name';

@Directive({
  selector: '[appDisableIfUnauthorised]'
})
export class DisableIfUnauthorisedDirective implements OnInit {

  @Input('appDisableIfUnauthorised') permission: AuthorityName;

  constructor(private el: ElementRef,
              private authorizationService: AuthorizationService) { }

  ngOnInit() {
    if (!this.authorizationService.hasPermission(this.permission)) {
      // this.el.nativeElement.disabled = true;
    }
  }
}
