<textarea
  [style.width]="maxWidth"
  [style.height]="maxHeight"
  nz-input
  [maxlength]="maxLength"
  [required]="required"
  [ngClass]="textAreaCSS"
  [(ngModel)]="value"
  [placeholder]="placeholder"
  (ngModelChange)="onValueChange($event)"
></textarea>
