
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { Observable } from 'rxjs';
import { TitleDTO } from 'src/app/shared/models/title-dto';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';


@Injectable({
    providedIn: 'root'
})
export class AuthorizationLoadService {

    constructor(private httpClient: HttpClient) { }

    getAuthorizations(request: any): Observable<any> {

        let params = new HttpParams();

        if (request.serialNumber) {
            params = params.set('serialNumber', request.serialNumber);
        }
        if (request.cardNumber) {
            params = params.set('cardNumber', request.cardNumber);
        }

        return this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/search`, { params });
    }

    async consumeAuthorization(request: any): Promise<any> {
        return await this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/consume`, request).toPromise() as BasicResponseDTO<any>;
    }

    cancelConsumeAuthorization(request: any): Observable<any> {
        return this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/cancel`, request);
    }

    async annulAuthorization(request: any): Promise<any> {
        return await this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/annul`, request).toPromise() as BasicResponseDTO<any>;
    }

    canAnnulAuthorization(request: any) {
        return this.httpClient
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/can-annul`, request);
    }

    public getLastConsumedAuthorization(request: any): Promise<any> {
        let params = new HttpParams();
        if (request.serialNumber) {
            params = params.set('serialNumber', request.serialNumber);
        }
        if (request.titleId) {
            params = params.set('titleId', request.titleId);
        }
        return this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/lastest-consumed-serialnumber-titleid`,
                { params })
            .toPromise();
    }

    public async findAuthorizationById(id: number): Promise<any> {
        return this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}entity-management/api/load-authorization/${id}`).toPromise();
    }

}
