
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Component, OnInit } from '@angular/core';
import { ShiftService } from 'src/app/core/services/shift.service';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataType, TableHeaderDataConfig } from 'src/app/shared/models/table-header-data-config';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { ReplacementGuideDTO } from '../model/create-repl-guide-request';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { TitleDTO } from 'src/app/shared/models/title-dto';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardUtils } from 'src/app/shared/utils/card-utils';

@Component({
    selector: 'app-recovery-details',
    templateUrl: './recovery-details.component.html',
    styleUrls: ['./recovery-details.component.less'],
})

export class RecoveryDetailsComponent implements OnInit {

    replacementGuideDTO: ReplacementGuideDTO = {} as ReplacementGuideDTO;
    title: TitleDTO = {} as TitleDTO;
    isChecked = false;
    serialNumber: number;

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    dataTable = [];

    constructor(
        private shiftService: ShiftService,
        private alertService: AlertService,
        private peripheralService: PeripheralService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService
    ) {
        this.prepareTableResult();

        if (this.navigationManager?.getNavigation()?.extras?.state?.serialNumber) {
            this.serialNumber = this.navigationManager.getNavigation().extras.state.serialNumber;
        }

        if (this.navigationManager?.getNavigation()?.extras?.state?.guide
            && this.navigationManager?.getNavigation()?.extras?.state?.title) {

            this.replacementGuideDTO = this.navigationManager?.getNavigation().extras.state.guide;
            this.title = this.navigationManager?.getNavigation().extras.state.title;

            // TODO REPLACEMENT GUIDE REFACTOR
            this.dataTable.push({
                serialNumber: this.replacementGuideDTO.newCard.serialNumber,
                titleDescription: this.title.description,
                titleId: this.replacementGuideDTO.title.titleId,
                validityEnd: this.replacementGuideDTO.title.endDate,
            });

        } else {
            this.onPreviousClick();
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }

    async ngOnInit() {

    }

    async cancelClick() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async onPreviousClick() {
        await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_RECOVERY_REPLACEMENT_GUIDE, { serialNumber: this.serialNumber });
    }

    checkboxEventHandler(e: any) {
        this.isChecked = e;
    }

    prepareTableResult() {
        this.listTitleColumn = [
            { title: 'Título', config: { colAlign: 'left', colWidth: '50%' } },
            { title: 'Validade', config: { colAlign: 'center', colWidth: '50%' } },
        ];
        this.listTitleHeader = [
            { visible: true, name: 'titleDescription', config: null, button: null } as TableHeaderData,
            {
                visible: true, name: 'validityEnd',
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy', valAlign: 'center' } as TableHeaderDataConfig,
                button: null
            } as TableHeaderData,
        ];
    }

    public async loadCard(): Promise<void> {
        const peripheralId = await this.peripheralService.getPeripheralId();
        const cardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.replacementGuideDTO.title.titleId,
            description: this.title.description,
            quantity: 1,
            price: 0,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_TRANSF,
            annulId: null,
            cardNumber: String(cardFullDetails?.details?.environment?.issuing_number),
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardSerialNumber: cardFullDetails?.low_part,
            isAnnul: false
        };

        const loadTitleRequest: LoadTitleRequest = {
            peripheralId,
            serialNumber: this.replacementGuideDTO.newCard.serialNumber.toString(),
            tickCode: Number(this.title.tickCode),
            tickOperCode: Number(this.title.tickOperCode),
            id: this.replacementGuideDTO.title.titleId,
            shiftId: Number(this.storageManager.session.get(CommonEnum.shiftID))
        };
        const isVV = CardUtils.IsVV(cardFullDetails?.details?.environment?.card_data_model);
        this.replacementGuideDTO.warranty = this.isChecked;
        this.replacementGuideDTO.oldCard.serialNumber = this.replacementGuideDTO.newCard.serialNumber;
        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
        this.storageManager.session.set(ReadingLoadingEnum.CreateReplGuideRequest, this.replacementGuideDTO);
        this.storageManager.session.set(ReadingLoadingEnum.FromReadingEmitGuide, isVV ? 'true' : 'false');
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.EmitGuide);
        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV_GUIDE_EMIT);
    }

}
