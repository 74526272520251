import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppComponentService {

    private isShiftActive = new Subject<boolean>();

    public constructor() {
        this.isShiftActive.next(false);
    }

    public setIsShiftActive(isShiftActive: boolean): void {
        this.isShiftActive.next(isShiftActive);
    }

    public getIsShiftActive(): Observable<boolean> {
        return this.isShiftActive.asObservable();
    }

}
