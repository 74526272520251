import {Client, over} from 'stompjs';
import * as SockJS from 'sockjs-client';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {filter, first} from 'rxjs/operators';
import {WebsocketMessage, WebsocketMessageType} from '../../shared/models/websocket-message';
import {PeripheralService} from './peripheral.service';
import {CommonEnum} from '../../shared/enum/common.enum';
import {AlertService} from './alert.service';
import {PrintUtils} from '../../shared/utils/print-utils';

const reconnectionDelay = 6000;
const address = EnvironmentUtil.getEnv().carrisSystemApiUrl + 'websocket';

export enum SocketClientState {
    ATTEMPTING, CONNECTED
}

export interface HandlerComponent {
    handleMessage(message: WebsocketMessage): void;
    onConnect(): void;
}

@Injectable({
    providedIn: 'root'
})

export class SocketClientService implements OnDestroy {

    /* EXPLANATION:
            Websocket print messages are handled in this service
            Every other websocket are handled in the respective component
                - In this case we need to add the class implement HandlerComponent
                so we can use handleMessage() and onConnect and we also migh need to use isConnected
                (because this websocket connection is shared=
     */

    private readonly client = over(new SockJS(address));
    private state: BehaviorSubject<SocketClientState>;
    private status: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private topic = '/app/session/';
    private component: HandlerComponent;
    private deviceId: string;
    private attemptNr = 0;
    isConnected = false;
    isClosedOnPurpose = false;
    timerId: any;

    // VAR for prints
    private printResponses: Array<string> = [];

    constructor(
        private peripheralService: PeripheralService,
        private alertService: AlertService
    ) {
        this.startConnection();
        this.client.ws.addEventListener('close', (event) => {
            if (!this.isClosedOnPurpose) {
                this.timerId = setInterval(async () => {
                    console.log('###CLOSE: ', event);
                    this.attemptNr = this.attemptNr + 1;
                    console.log(`Connection down (${address}), will attempt ${this.attemptNr} reconnection in ${reconnectionDelay}ms`);
                    await this.client.ws.close();
                    this.client.ws = new SockJS(address);
                    await this.startConnection();
                }, reconnectionDelay);
            }
        });
    }

    private async startConnection() {
        this.peripheralService.getPeripheralId().then((deviceId: string) => {
            this.deviceId = deviceId;
        });
        this.state = new BehaviorSubject<SocketClientState>(SocketClientState.ATTEMPTING);
        this.client.connect({}, async () => {
            clearInterval(this.timerId);
            this.component.onConnect();
            this.status.next(true);
            this.isConnected = true;
            this.state.next(SocketClientState.CONNECTED);
            this.client.subscribe(this.topic + this.deviceId, message => {
                this.handleMessage(JSON.parse(message.body));
            });
        });
    }

    async handleMessage(message: WebsocketMessage) {
        switch (message.type) {
            case WebsocketMessageType.PrintReceipt:
                console.log('(SocketClientService) Printing Documents');
                const response = await this.peripheralService.sendToPrinter(message?.content);
                if (response != null && CommonEnum.msgPrintDocumentSuccess !== response) {
                    this.printResponses.push(response);
                }
                this.buildPrintSuccessMessageIfLastDocument(message);
                break;
            default:
                this.component.handleMessage(message);
                break;

        }
    }

    private buildPrintSuccessMessageIfLastDocument(message: WebsocketMessage) {
        if (message?.content?.isLastDocument && this.printResponses.length > 0) {
            const printSuccessMessage = PrintUtils.buildPrintSuccessMessageIfLastDocument(this.printResponses);
            this.alertService.success(printSuccessMessage, true);
            this.printResponses = [];
        }
    }

    setHandlerComponent(component: HandlerComponent) {
        this.component = component;
    }

    private connectedClient(): Observable<Client> {
        return new Observable<Client>(observer => {
            this.state.pipe(filter(state => state === SocketClientState.CONNECTED)).subscribe(() => {
                observer.next(this.client);
            });
        });
    }

    private disconnectClient() {
        this.isClosedOnPurpose = true;
        this.connectedClient().pipe(first()).subscribe(async client => {
            this.isConnected = true;
            await client.disconnect(null);
        });
    }

    ngOnDestroy(): void {
        this.disconnectClient();
    }
}
