<div class="">
  <div nz-row>
    <h1 class="caption">Seleção de Título</h1>
  </div>
  <div nz-row class="m-t-20">
    <h2>Validade:</h2>
  </div>
  <div nz-row>
    <h3 class="mensal">Mensal</h3>
  </div>
  <div nz-row class="m-t-20">
    <h2>Selecione o título que pretende comprar:</h2>
    <app-primary-list-button buttonSize="medium" [data]="listProduct" [colNum]="6"
      (callButtonClick)="loadSelection($event)"></app-primary-list-button>
  </div>
  <div class="footer-buttons" nz-row>
    <app-primary-white-button (click)="onPreviousClick()">
      <span class="btn-icon icon-anterior icon-middle float-left "></span>
      Voltar
    </app-primary-white-button>
    <app-primary-cancel-button class="m-l-50" (click)="onHomeClick()">Cancelar</app-primary-cancel-button>
  </div>
</div>
