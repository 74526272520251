import { BasicResponseDTO } from './../../shared/models/basic-response-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { CreditEntity } from 'src/app/modules/operation/pages/payment-credit/interfaces/payment-credit.interfaces';

@Injectable({
    providedIn: 'root',
})
export class CreditEntityService {

    constructor(private httpClient: HttpClient) {
    }

    public async getAllEnabledCreditEntities(): Promise<BasicResponseDTO<Array<CreditEntity>>> {
        return await this.httpClient.get<BasicResponseDTO<Array<CreditEntity>>>(
            `${EnvironmentUtil.getEnv().apiIntApiUrl}sale/api/credit/entity/all/enabled`).toPromise();
    }

}
