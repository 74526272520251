<button
  nz-button
  nzBlock
  nzType="primary"
  [ngClass]="buttonCSS"
  (click)="onClickButton($event)"
  [style.width]="width"
  [style.height]="height"
  [style.background-color]="color"
  [style.color]="color2"
  [style.background]="back"
  [style.border-color]="borderColor"
  [disabled]="disabled"
  [nzLoading]="loading"
>
  <h3 class="h3"><ng-content></ng-content></h3>
</button>
