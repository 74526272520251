export enum CardEnum {
  FULL_CARD_DETAILS= 'CURRENT_CARD_FULL_DETAILS',
  CARD_DETECTION_BINARY= 'CURRENT_CARD_DETECTION_BINARY',
  CARD_DATA_MODEL= 'CURRENT_CARD_DATA_MODEL',
  CARD_READ= 'CARD_READ',
  NEW_FULL_CARD_DETAILS= 'NEW_CARD_FULL_DETAILS',
  PREVIOUS_CARD_BINARY= 'PREVIOUS_CARD_BINARY',
  PREVIOUS_FULL_CARD_DETAILS= 'PREVIOUS_FULL_CARD_DETAILS',
  CONTRACT_TO_DELETE= 'CONTRACT_TO_DELETE',
  LAST_EVENT_MESSAGE= 'LAST_EVENT_MESSAGE',
  CARD_BINARY_DETECT_VOUCHER = 'CARD_BINARY_DETECT_VOUCHER',
  CARD_FULL_DETAILS_AFTER_LOAD_AUTH = 'CARD_FULL_DETAILS_AFTER_LOAD_AUTH',
  CARD_FULL_DETAILS_AFTER_CLEAN_REPLACEMENT = 'CARD_FULL_DETAILS_AFTER_CLEAN_REPLACEMENT',
}
