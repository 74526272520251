import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideIfUnauthorisedDirective } from './hide-if-unauthorised.directive';
import { DisableIfUnauthorisedDirective } from './disable-if-unauthorised.directive';
import { HideIfNoContentDirective } from './hide-if-no-content.directive';
import { HideIfChildProfileDirective } from './hide-if-child-profile.directive';
import { GenericHideDirective } from './generic-hide.directive';
import { BlackListAlertMessageDirective } from './black-list-alert-message.directive';
import {UnassignedLVAlertMessageDirective} from "./unassigned-lv-alert-message.directive";

@NgModule({
  declarations: [
    HideIfUnauthorisedDirective,
    DisableIfUnauthorisedDirective,
    HideIfNoContentDirective,
    HideIfChildProfileDirective,
    GenericHideDirective,
    BlackListAlertMessageDirective,
    UnassignedLVAlertMessageDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HideIfUnauthorisedDirective,
    DisableIfUnauthorisedDirective,
    HideIfNoContentDirective,
    HideIfChildProfileDirective,
    GenericHideDirective,
    BlackListAlertMessageDirective,
    UnassignedLVAlertMessageDirective
  ]
})
export class DirectivesModule { }
