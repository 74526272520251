import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.less']
})
export class AlertModalComponent implements OnInit {

  @Output() onClickCancel = new EventEmitter<any>();
  @Output() onClickConfirm = new EventEmitter<any>();
  @Output() onCloseModal = new EventEmitter<any>();

  @Input() isConfirmLoading: string;

  @Input() isModalVisible: boolean;
  @Output() isModalVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() cancelText = 'Cancelar';
  @Input() confirmText = 'Cofirmar';
  @Input() title = 'Alerta';

  onClickNOK(event) {
    this.onClickCancel.emit(event);
  }

  onClickOK(event) {
    this.onClickConfirm.emit(event);
  }

  closeModal(event) {
    this.onCloseModal.emit(event);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
