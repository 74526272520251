import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { HttpCancelService } from 'src/app/core/services/http-cancel-service.service';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private routerPage: Router,
        private httpCancelService: HttpCancelService
    ) {
        this.router.events.subscribe(event => {
            // An event triggered at the end of the activation part of the Resolve phase of routing.
            if (!this.isSupportDetailsPage()) {
                if (event instanceof ActivationEnd) {
                    // Cancel pending calls
                    this.httpCancelService.cancelPendingRequests();
                }
            }
        });
    }

    intercept<T>(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
        // only cancel pending requests to the endpoint ('/api/operation/card/family-pass-load-list')
        // and ignore the remaining pending requests
        if (req.url.endsWith('/api/operation/card/family-pass-load-list')) {
            return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
        }
        return next.handle(req);
    }

    private isSupportDetailsPage(): boolean {
        return (this.routerPage.url === '/load/support-detail');
    }
}
