<br>
<br>
<div nz-row class="margin-bottom-10">
    <!-- HEADER -->
    <div nz-col nzSpan="24" style="height: 90px;">
        <h1 class="title">
            Produtos Não Bilhética
        </h1>
        <div nz-row>
            <h3 class="tariff">Selecione o produto a adicionar ao carrinho de compras</h3>
        </div>
        <br>
        <br>
        <div nz-row>
            <h3 class="category-name">{{category?.description}}</h3>
        </div>
    </div>
</div>
<br>
<br>
<div nz-row class="mgt-70">
    <div class="div-table-result" nz-col nzSpan="24">
        <app-primary-table
            [listOfColumn]="listTitleColumn"
            [headerDatas]="listTitleHeader"
            [listOfData]="listRecords"
            [showPagination]="true"
            [isSingleSelection]="true"
            (isCheckedChange)="checkBoxEvent($event)"
            (onPageChanged)="onPageChanged($event)"
            [dataTotal]="dataTotal"
            [frontPagination]="false"
            [pageSize]="defaultPageSize"
        >
        </app-primary-table>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button (click)="previousView()">
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
        <div class="load" nz-col [nzSpan]="4">
            <app-primary-normal-button (click)="nextView()" [disabled]="checkNextDisableState()">
                Seguinte
            </app-primary-normal-button>
        </div>
    </div>
</div>