<app-primary-title
    title="Aproxime o cartão do leitor"
    subTitle="para Carregamentos ou Anulações">
</app-primary-title>

<div *ngIf="!isQaEnvironment(); else showQAImage">
    <div nz-row class="m-b-60">
        <div nz-col [nzSpan]="24" id="cartoes-img" (click)="readCard()"></div>
    </div>
</div>

<ng-template #showQAImage>
    <div nz-row class="m-b-60">
        <div nz-col [nzSpan]="24" id="cartoes-qa" (click)="readCard()"></div>
    </div>
</ng-template>

<div nz-row class="button-group-grid">
    <div nz-col [nzSpan]="24">
        <div class="d-f home-buttons-div">
            <span [appHideIfUnauthorised]="'REQUISITIONS_NEW'" [appHideIfUnauthorised]="'REQUISITIONS_HISTORY'">
                <app-primary-button [disabled]="(buttonsDisabled || isShoppingCartFull) && canReadCard" class="buttons"
                    buttonCSS="home-generic-button" (click)="goToRequisitionLv()"
                >
                    <span class="btn-icon icon-requisicoes_cartao"></span>
                    Requisições LV/navegante
                </app-primary-button>
            </span>

            <span [appHideIfUnauthorised]="'ISSUE_GUIDE_WITHOUT_READ'">
                <app-primary-button [disabled]="(buttonsDisabled || isShoppingCartFull) && canReadCard" class="buttons"
                    buttonCSS="home-generic-button" (click)="goToReplacementGuide()">
                    <span class="btn-icon icon-sem_leitura"></span>
                    Sem<br>Leitura
                </app-primary-button>
            </span>

            <span *ngIf="canConsultOrEditFines">
                <app-primary-button [disabled]="(buttonsDisabled || isShoppingCartFull) && canReadCard" class="buttons"
                    buttonCSS="home-generic-button" (click)="goToFines()"
                >
                    <span class="btn-icon icon-restricao"></span>
                    Autos
                </app-primary-button>
            </span>

            <span [appHideIfUnauthorised]="'OTHER_PRODUCTS'">
                <app-primary-button [disabled]="isShoppingCartFull" class="buttons"
                    buttonCSS="home-generic-button" (click)="goToNonTicketingProducts()"
                >
                    <span class="btn-icon icon-produtos_n_bilhetica"></span>
                    Produtos não bilhética
                </app-primary-button>
            </span>

            <span>
                <app-primary-button buttonCSS="home-information-button" class="buttons" [disabled]="buttonsDisabled && canReadCard"
                    (click)="customerInfo()"
                >
                    <span class="btn-icon icon-info_cliente"></span>
                    Informação ao Cliente
                </app-primary-button>
            </span>

        </div>
    </div>
</div>
