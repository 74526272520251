<div class="invoice-content">
  <div>
    <h1 class="thick">Emissão de Fatura / Recibo</h1>
  </div>

  <div class="form-content">
    <div nz-row>
        <div>
            <app-primary-input-keyboard
                label="Número de Contribuinte (NIF)"
                labelCss="label-input"
                inputCss="vat-input"
                keyboardType="number"
                inputBorder="vat-input-border"
                [maxlength]="18"
                [mask]="'0*'"
                [value]="sale.client.vatNumber"
                (valueChange)="onVatValueChange($event)">
            </app-primary-input-keyboard>
        </div>
        <div nz-col [nzSpan]="2" class="div-dropdown">
            <app-dropdown-button
                labelTemplate="btn-icon icon-drop_down icon-dropdown"
                height="52px"
                buttonStyle="button-dropdown"
                [isDisabled]="isVatDropdownDisabled"
                [listOptions]="listNif"
                (onSelect)="onVATChanged($event)">
            </app-dropdown-button>
        </div>
        <div nz-col [nzSpan]="5" class="div-dropdown">
            <app-primary-checkbox
                [(isChecked)]="sale.vatInternational"
                label="NIF Internacional"
                labelCss="label-border"
                (isCheckedChange)="onVatInternationalChange($event)">
            </app-primary-checkbox>
        </div>
        <!-- dropdown  with all moloni countries, only visible when it's international vat number -->
        <div nz-col [nzSpan]="6" class="div-dropdown" *ngIf="sale.vatInternational">
            <app-dropdown-button
                [label]="selectedCountry ? selectedCountry.label : 'País'"
                labelTemplate="btn-icon icon-drop_down icon-dropdown"
                height="52px"
                buttonStyle="button-dropdown-country"
                [listOptions]="countryList"
                (onSelect)="onSelectCountry($event)">
            </app-dropdown-button>
        </div>
        <div *ngIf="isNotRequisitionOrEletronicProductOrFine" nz-col nz-col [nzSpan]="4" class="div-dropdown">
            <app-primary-checkbox
                [(isChecked)]="sale.vatAssociation.associate"
                label="Associar NIF"
                (isCheckedChange)="onVatNumberAssociatedChange($event)">
            </app-primary-checkbox>
        </div>
        <div *ngIf="isNotRequisitionOrEletronicProductOrFine" nz-col nz-col [nzSpan]="4" class="div-dropdown">
            <app-primary-checkbox
                [(isChecked)]="sale.vatAssociation.predefined"
                label="Predefinir NIF"
                (isCheckedChange)="onVatNumberPredefinedChange($event)">
            </app-primary-checkbox>
        </div>
    </div>
    <div nz-row>
      <div class="div-input">
          <span *ngIf="isVatNumberFilled(); else disableInputName">
            <app-primary-input-keyboard
                label="Nome"
                labelCss="label-input"
                inputCss="name-input"
                inputBorder="name-input-border"
                [maxlength]="30"
                [value]="sale.client.name"
                (valueChange)="onNameChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
          </span>
          <ng-template #disableInputName>
            <app-primary-input-keyboard
                label="Nome"
                labelCss="label-input"
                inputCss="name-input-disabled"
                inputBorder="name-input-border"
                [value]="sale.client.name"
                (valueChange)="onNameChange($event)"
                [isDisabled]="true"
            ></app-primary-input-keyboard>
          </ng-template>
      </div>
    </div>
    <div nz-row>
      <div  class="div-input">
        <span *ngIf="isVatNumberFilled(); else disableInputZipCodeFourDigit">
            <app-primary-input-keyboard
                keyboardType="number"
                inputType="text"
                label="Código-Postal"
                labelCss="label-input"
                inputCss="zipcode-input-4-digit"
                inputBorder="zipcode-input-border-4-digit"
                [mask]="'0*'"
                [maxlength]="'4'"
                [value]="sale.client.postalCode"
                (valueChange)="onZipCodeChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
        </span>
        <ng-template #disableInputZipCodeFourDigit>
            <app-primary-input-keyboard
                keyboardType="number"
                inputType="text"
                label="Código-Postal"
                labelCss="label-input"
                inputCss="zipcode-input-4-digit-disabled"
                inputBorder="zipcode-input-border-4-digit"
                [mask]="'0*'"
                [maxlength]="'4'"
                [value]="sale.client.postalCode"
                (valueChange)="onZipCodeChange($event)"
                [isDisabled]="true"
            ></app-primary-input-keyboard>
        </ng-template>
      </div>
      <div class="div-zipcode">
        <span *ngIf="isVatNumberFilled(); else disableInputZipCodeThreeDigit">
            <app-primary-input-keyboard
                keyboardType="number"
                inputType="text"
                inputCss="zipcode-input-3-digit"
                inputBorder="zipcode-input-border-3-digit"
                [mask]="'0*'"
                [maxlength]="'3'"
                [value]="sale.client.postalCity"
                (valueChange)="onCityCodeChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
        </span>
        <ng-template #disableInputZipCodeThreeDigit>
            <app-primary-input-keyboard
                keyboardType="number"
                inputType="text"
                inputCss="zipcode-input-3-digit-disabled"
                inputBorder="zipcode-input-border-3-digit"
                [mask]="'0*'"
                [maxlength]="'3'"
                [value]="sale.client.postalCity"
                (valueChange)="onCityCodeChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
        </ng-template>
      </div>
    </div>
    <div nz-row>
      <div class="div-input">
        <span *ngIf="isVatNumberFilled(); else disableInputAddress">
            <app-primary-input-keyboard
                label="Morada"
                labelCss="label-input"
                inputCss="name-input"
                inputBorder="name-input-border"
                [maxlength]="30"
                [value]="sale.client.address"
                (valueChange)="onAddressChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
        </span>
        <ng-template #disableInputAddress>
            <app-primary-input-keyboard
                label="Morada"
                labelCss="label-input"
                inputCss="name-input-disabled"
                inputBorder="name-input-border"
                [value]="sale.client.address"
                (valueChange)="onAddressChange($event)"
                [isDisabled]="!isVatNumberFilled()"
            ></app-primary-input-keyboard>
        </ng-template>
      </div>
    </div>
  <div nz-row>
    <span class="font-blue uppercase font-size-15 m-t-15 m-b-15">Cópias adicionais</span>
  </div>
  <div nz-row>
    <div nz-col>
      <app-primary-white-button buttonCSS="button-copy" (click)="onNumCopyClick('-')">
        -
      </app-primary-white-button>
    </div>
    <div nz-col class="input-copy">
      <app-primary-input [(value)]="sale.receiptQuantity" inputCSS="copy-number"></app-primary-input>
    </div>
    <div nz-col>
      <app-primary-white-button buttonCSS="button-copy" (click)="onNumCopyClick('+')">
        +
      </app-primary-white-button>
    </div>
  </div>

</div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col [nzSpan]="12" class="left">
      <div nz-row nzJustify="start">
        <div nz-col [nzSpan]="7" class="p-r-15">
          <app-primary-white-button (click)="previousView()">
            <span class="btn-icon icon-anterior icon-middle float-left "></span>
            Voltar
          </app-primary-white-button>
        </div>
        <div nz-col [nzSpan]="7" class="p-l-15">
          <app-primary-white-button (click)="onCleanClick()" buttonCSS="button-clear">
            <span class="btn-icon icon-apagar icon-middle float-left "></span>
            Limpar
          </app-primary-white-button>
        </div>

      </div>
    </div>
    <div nz-col [nzSpan]="12" style="padding-right: 48px" class="right">
      <app-primary-normal-button
        [disabled]="isConfirmDisabled || isRegisterDisabled()"
        (click)="register()"
        buttonCSS="button-finish ">
        <span class="btn-icon icon-sucesso icon-middle  m-t-10 vertical-align-middle"></span>
        <span class="button-finish-text">Concluir</span>
      </app-primary-normal-button>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isModalVisible" nzClosable=false nzMaskClosable=false>
    <div>
      <p class="modal-text">Aviso</p>
      <h3 class="modal-sub-text">{{this.navigationMessage}}</h3>
    </div>
    <ng-template [nzModalFooter]>
      <button nz-button nzType="default" (click)="onModalClickNo()" class="modal-nok-button">
        Não
      </button>
      <button nz-button nzType="primary" (click)="onModalClickYes()" class="modal-ok-button">
        Sim
      </button>
    </ng-template>
  </nz-modal>
