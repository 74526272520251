import { MoloniIntegrationService } from './../../../../core/services/moloni-integration.service';
import { CardRead } from './../../../../shared/models/card-read';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Sale } from '../../modules/sale';
import { SaleClient } from '../../modules/sale-client';
import { SaleService } from 'src/app/core/services/sale.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { SaleVatAssociation } from '../../modules/sale-vat-association';
import { Navigation } from '@angular/router';
import { ShoppingCart } from '../../modules/shopping-cart';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ShiftService } from 'src/app/core/services/shift.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { PaymentEnum } from 'src/app/shared/enum/payment.enum';
import { VatValidationRequest } from 'src/app/shared/models/vat-validation-request';
import { TitleGroupEnum } from 'src/app/shared/enum/title-group.enum';
import { EnvironmentUtil } from '../../../../../environments/environment-util';
import { SpinService } from '../../../../core/services/spin.service';
import { Load } from 'src/app/modules/load/pages/lisboa-viva/models/load';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { VatNumberAssociationDTO } from '../../modules/vat-number-association-dto';
import { VatNumberEnum } from 'src/app/shared/enum/vat-number-association';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { CustomerDetailsDTO } from '../replacement-guide/model/create-repl-guide-request';
import { RecoverReplacementGuideService } from 'src/app/core/services/recover-replacement-guide.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { RegisterSaleErrorTypeEnum } from '../../../../shared/enum/register-sale-error.enum';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { MoloniCountry } from '../../modules/moloni-country';
import { SelectItem } from 'src/app/shared/utils/http/http-commons-interfaces';
import { FineService } from 'src/app/core/services/fines.service';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.less']
})
export class InvoiceComponent implements OnInit {

    public static CART_PAID = 1;
    private PORTUGAL_COUNTRY_ID = 1;

    isModalVisible = false;
    navigation: Navigation;
    sale: Sale = {
        client: {} as SaleClient,
        receiptQuantity: 0,
        vatAssociation: {} as SaleVatAssociation,
    } as Sale;
    listNif: object[] = [];
    isVatDropdownDisabled = false;
    payments: any[] = [];
    cardNumber = '';
    cardSerialNumber: number;
    isConfirmDisabled: boolean;
    shoppingCart: ShoppingCart;
    familyPassTitleGroup = 12;
    subscriptions: Subscription[] = [];
    couponCode: string;
    siitEntityId: number;
    private creditEntityId: number;
    private prePaymentEntityId: number;
    navigationMessage: string;
    isReplacementGuide = false;
    public isNotRequisitionOrEletronicProductOrFine = false;

    private vatNumberAssociationList: Array<VatNumberAssociationDTO>;
    public countryList: Array<SelectItem> = [];
    public selectedCountry: SelectItem;

    constructor(
        private saleService: SaleService,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private shoppingCartService: ShoppingCartService,
        private recoverReplacementGuideService: RecoverReplacementGuideService,
        private peripheralService: PeripheralService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private spinService: SpinService,
        private tariffService: TariffService,
        private moloniIntegrationService: MoloniIntegrationService,
        private fineService: FineService
    ) {
        this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
        this.prepareInvoice(this.shoppingCart);
        if (this.shoppingCart?.products?.length > 0) {
            this.cardNumber = this.shoppingCart.products[0].cardNumber;
            this.cardSerialNumber = this.shoppingCart.products[0].cardSerialNumber;
            this.couponCode = this.shoppingCart.couponCode;
            this.siitEntityId = this.shoppingCart.siitEntityId;
            this.creditEntityId = this.shoppingCart.creditEntityId;
            this.prePaymentEntityId = this.shoppingCart.prePaymentEntityId;
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }
    }

    async ngOnInit(): Promise<void> {
        this.isConfirmDisabled = false;
        await this.getAllMoloniCountriesList();
        await this.loadListAssociatedClientInfo();
        await this.checkIfShoppingcartIsRegistered();
        await this.containsEletronicProduct();
    }

    private async getAllMoloniCountriesList(): Promise<void> {
        this.spinService.setSpinValue(true);
        try {
            const response = await this.moloniIntegrationService.getAllMoloniCountries();
            if (response.success && response.data !== null) {
                this.countryList = response.data.filter(country => this.PORTUGAL_COUNTRY_ID !== country.countryId)
                    .map(country => (
                        {
                            label: country.name,
                            value: country.countryId
                        }));
            }
        } catch (error) {
            this.alertService.error(error.error.message);
        }
        this.spinService.setSpinValue(false);
    }

    private async prepareInvoice(shoppingCart: ShoppingCart): Promise<void> {
        const peripheralId = await this.peripheralService.getPeripheralId();
        this.buildPayments(shoppingCart);
        this.sale = {
            deviceId: peripheralId,
            cartId: shoppingCart.cartId,
            client: {} as SaleClient,
            payments: this.payments,
            vatAssociation: {
                associate: false,
                predefined: false
            } as SaleVatAssociation,
            vatInternational: false,
            receiptQuantity: 0,
            couponCode: this.couponCode,
            siitEntityId: this.siitEntityId,
            creditEntityId: this.creditEntityId,
            prePaymentEntityId: this.prePaymentEntityId
        } as Sale;
    }

    private buildPayments(shoppingCart: ShoppingCart): void {
        if (shoppingCart.payment.amountMoney > 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().moneyMethodId, // Money
                    value: shoppingCart.payment.amountMoney
                }
            );
        }
        if (shoppingCart.payment.amountTpa > 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().tpaMethodId, // Tpa
                    value: shoppingCart.payment.amountTpa
                }
            );
        }
        if (shoppingCart.payment.amountOther > 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().otherMethodId, // Other
                    value: shoppingCart.payment.amountOther
                }
            );
        }
        if (shoppingCart.payment.amountCredit > 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().creditMethodId, // Credit
                    value: shoppingCart.payment.amountCredit
                }
            );
        }
        if (shoppingCart.payment.amountPrePayment > 0) {
            this.payments.push(
                {
                    paymentMethodId: EnvironmentUtil.getEnv().prePaymentMethodId, // Pre-Payment
                    value: shoppingCart.payment.amountPrePayment
                }
            );
        }
    }

    private hasFamilyPassVend(): boolean {
        return this.shoppingCart?.products?.some((product: any) => TitleGroupEnum.FAMILY_NAVIGATOR === product.titleGroup &&
            LoadTitleTransactionType.TRANSACTION_VEND === product.transactionTypeId);
    }

    private async isCardReplaceGuide(): Promise<boolean> {
        try {
            const response = await this.recoverReplacementGuideService.isCardActiveReplacementGuide(this.cardSerialNumber.toString())
                .toPromise();
            if (response?.success && response?.data != null) {
                this.isReplacementGuide = true;
            }
        } catch (e) {
            this.isReplacementGuide = false;
        }
        return this.isReplacementGuide;
    }

    private async loadListAssociatedClientInfo(): Promise<void> {
        this.spinService.setSpinValue(true);
        try {
            const response = await this.saleService.getVatNumberAssociationList(this.cardSerialNumber);
            if (response.success && response.data !== null) {
                this.isVatDropdownDisabled = false;
                this.vatNumberAssociationList = response.data;
                this.listNif = this.vatNumberAssociationList.map(it => (
                    {
                        label: it.vatNumber,
                        value: it.vatNumber
                    }));
                this.vatNumberAssociationList.forEach(clientAssociated => {
                    if (clientAssociated.predefined) {
                        this.buildSaleClient(clientAssociated);
                    }
                });
            } else {
                this.isVatDropdownDisabled = true;
            }
        } catch (error) {
            this.alertService.error(CommonEnum.msgCardNifsError);
            console.log(`(Invoice Component: getVatNumberAssociationList error: ${error.message}`);
        }
        this.spinService.setSpinValue(false);
    }

    private buildSaleClient(clientAssociated: VatNumberAssociationDTO): void {
        this.sale.client.vatNumber = Number(clientAssociated.vatNumber);
        this.sale.client.name = clientAssociated.name;
        this.sale.client.address = clientAssociated.address;
        this.sale.client.postalCode = clientAssociated.postalCode;
        this.sale.client.postalCity = clientAssociated.postalCity;
        this.sale.vatInternational = clientAssociated.vatInternational;
        this.sale.countryId = clientAssociated.countryId;
        this.selectedCountry = this.countryList.find(country => country.value === clientAssociated.countryId);
    }

    public isRegisterDisabled(): boolean {
        return this.sale.vatInternational && (this.sale?.countryId == null || this.sale?.client?.vatNumber == null);
    }

    public async register(): Promise<void> {
        this.spinService.setSpinValue(true);
        this.isConfirmDisabled = true;
        let isVatValid = true;
        if (!this.sale.vatInternational) {
            if (this.sale.client.postalCode != null && this.sale.client.postalCode?.length !== 4 ||
                this.sale.client.postalCity != null && this.sale.client.postalCity?.length !== 3) {
                this.alertService.error(CommonEnum.msgZipCodeFormatError);
                this.isConfirmDisabled = false;
                this.spinService.setSpinValue(false);
                return;
            }
        }
        if (VatNumberEnum.FINAL_CUSTOMER_VAT_NUMBER === this.sale.client.vatNumber && this.sale.vatAssociation.associate) {
            this.alertService.error(CommonEnum.msgFinalCustomerNifError);
            this.isConfirmDisabled = false;
            this.spinService.setSpinValue(false);
            return;
        }
        if (this.sale.client.vatNumber != null && !this.sale.vatInternational) {
            isVatValid = await this.checkIfVatValid(this.sale.client.vatNumber);
        }
        if (!isVatValid) {
            this.sale.client.vatNumber = null;
            this.alertService.error(CommonEnum.msgInvalidNifError);
            this.isConfirmDisabled = false;
        } else if (await this.checkIfCartHasFinesAndIfArePayed()) {
            this.isConfirmDisabled = false;
        } else {
            const getStoreInfo = await this.peripheralService.getInfoFromMachine();
            const getOpNumber = await this.shiftService.getShiftInfo(Number(this.shiftService.getShiftID())).toPromise() as any;
            const customerDetails = this.storageManager.session.get(ReadingLoadingEnum.CustomersDetails) as CustomerDetailsDTO;
            this.sale.storeName = getStoreInfo.storeName;
            this.sale.storeAddress = getStoreInfo.address;
            this.sale.storeTicketingNumber = getStoreInfo.machineNumber;
            this.sale.operatorNumber = getOpNumber.data.ownerId;
            this.sale.customerGuideInfoName = customerDetails?.name;
            this.sale.customerGuideInfoPhoneNumber = customerDetails?.phoneNumber;
            this.sale.customerGuideInfoDocumentType = customerDetails?.customerDocumentType;
            this.sale.customerGuideInfoDocumentNumber = customerDetails?.customerDocumentNumber;
            this.sale.customerGuideInfoNotes = customerDetails?.notes;
            const printDocumentRequest = {
                duplicate: false,
                additionalCopies: this.sale.receiptQuantity,
                deviceId: this.sale.deviceId,
                operatorNumber: getOpNumber.data.ownerId,
                customerNameGuide: customerDetails?.name,
                customerPhoneNumberGuide: customerDetails?.phoneNumber,
                customerDocumentTypeGuide: customerDetails?.customerDocumentType,
                customerDocumentNumberGuide: customerDetails?.customerDocumentNumber,
                customerNotesGuide: customerDetails?.notes
            };
            await this.isCardReplaceGuide();
            const response = await this.saleService.register(this.sale, printDocumentRequest) as any;
            if (response?.success) {
                this.navigationMessage = this.hasFamilyPassVend() && !this.isReplacementGuide ?
                    CommonEnum.msgAcquiredFamilyPass :
                    CommonEnum.msgSaleSucess;
                if (this.hasFamilyPassVend() && !this.isReplacementGuide) {
                    this.isModalVisible = true;
                } else {
                    await this.navigationManager.go(TicketingRoutes.HOME, {
                        message: this.navigationMessage,
                        messageType: 'success'
                    });
                }
            } else {
                if (CommonEnum.msgSaleTransactionAlreadyExists == response.content) {
                    this.isConfirmDisabled = true;
                    await this.shoppingCartService.disableShoppingCart(this.shoppingCart.cartId);
                    this.storageManager.session.remove(PaymentEnum.ShoppingCart);
                    this.shoppingCartService.resetShoppingCartHeader();
                    this.navigationManager.go(TicketingRoutes.HOME);
                }
                this.alertService.error(response.content, true);
                if (response?.error == RegisterSaleErrorTypeEnum.PRINT) {
                    this.shoppingCartService.resetShoppingCartHeader();
                    await this.navigationManager.go(TicketingRoutes.HOME);
                } else if (response?.error == RegisterSaleErrorTypeEnum.SALE) {
                    this.isConfirmDisabled = false;
                }
            }
        }
        this.spinService.setSpinValue(false);
    }

    private async checkIfCartHasFinesAndIfArePayed(): Promise<boolean> {
        const fineIdList = [];
        this.shoppingCart.products.forEach(product => {
            if (product.fineId != null) {
                fineIdList.push(product.fineId);
            }
        });
        try {
            let fineAlreadyPaidError = false;
            if (fineIdList.length > 0) {
                // check if fines are already paid in Carris API
                const finePaidErrorResponse = await this.fineService.checkIfFinesAlreadyPaidByFineIds(fineIdList);
                if (finePaidErrorResponse.success && finePaidErrorResponse?.data != null) {
                    finePaidErrorResponse.data.forEach(finePaidError => {
                        fineAlreadyPaidError = true;
                        this.alertService.error('Auto ' + finePaidError.noticeNumber +
                            ' já se encontra pago. Por favor retire-o do carrinho');
                    });
                }
            }
            return fineAlreadyPaidError;
        } catch (error) {
            this.alertService.error(error.error.message);
            this.spinService.setSpinValue(false);
            return;
        }
    }

    public checkIfVatValid(vat: number) {
        const vatNumberSize = 9;
        const vatLength = vat.toString().length;

        if (vatLength === vatNumberSize) {
            const request: VatValidationRequest = {
                vat: this.sale.client.vatNumber.toString()
            };
            const isVatValidRequest = this.saleService.isVatValid(request);
            return isVatValidRequest;
        }
        if (vatLength === 0) {
            return true;
        }
        return false;
    }

    public onVatValueChange(value: string): void {
        // it's necessary to convert to number because event valueChange in PrimaryInputKeyboardComponent emit strings
        this.sale.client.vatNumber = Number(value);
        if (value === '') {
            this.onCleanClick();
        }
        this.isConfirmDisabled = false;
    }

    public onVATChanged(value: string): void {
        this.isConfirmDisabled = false;
        this.vatNumberAssociationList.forEach(clientAssociated => {
            if (clientAssociated.vatNumber === value) {
                this.buildSaleClient(clientAssociated);
            }
        });
    }

    public onVatInternationalChange(value: boolean): void {
        if (!value) {
            this.sale.countryId = null;
            this.selectedCountry = null;
        }
    }

    public onSelectCountry(value: number): void {
        this.sale.countryId = value;
        this.selectedCountry = this.countryList.find(country => country.value === value);
    }

    public onVatNumberAssociatedChange(value: boolean): void {
        this.sale.vatAssociation.associate = value;
        if (this.sale.vatAssociation.predefined && value === false) {
            this.sale.vatAssociation.predefined = value;
        }
    }

    public onVatNumberPredefinedChange(value: boolean): void {
        this.sale.vatAssociation.predefined = value;
        this.sale.vatAssociation.associate = value;
    }

    public onNameChange(event: string): void {
        this.sale.client.name = event;
    }

    public onAddressChange(event: string): void {
        this.sale.client.address = event;
    }

    public onZipCodeChange(event: string): void {
        this.sale.client.postalCode = event;
    }

    public onCityCodeChange(event: string): void {
        this.sale.client.postalCity = event;
    }

    public isVatNumberFilled(): boolean {
        return this.sale.client.vatNumber != null;
    }

    onNumCopyClick(signal: string) {
        if (signal === '+') {
            this.sale.receiptQuantity = (this.sale.receiptQuantity || 0) + 1;
        } else {
            if (this.sale.receiptQuantity > 0) {
                this.sale.receiptQuantity = (this.sale.receiptQuantity || 0) - 1;
            }
        }
    }

    public onCleanClick(): void {
        this.sale.client = {} as SaleClient;
    }

    async previousView() {
        const paymentType = this.storageManager.session.get(PaymentEnum.PaymentType);

        switch (paymentType) {
            case PaymentEnum.PaymentMoney:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_MONEY);
                break;

            case PaymentEnum.PaymentMultibanco:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_MULTIBANCO);
                break;

            case PaymentEnum.PaymentSiit:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_SIIT);
                break;

            case PaymentEnum.PaymentMultiple:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_MULTIPLE);
                break;
            case PaymentEnum.PaymentCredit:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_CREDIT);
                break;
            case PaymentEnum.PaymentPrePayment:
                await this.navigationManager.go(TicketingRoutes.PAYMENT_PRE_PAYMENT);
                break;
        }
    }

    async containsEletronicProduct(): Promise<boolean> {
        for (let i = 0; i < this.shoppingCart.products.length; i++) {
            // TODO: verificação de produtos não eletrónicos
            const getTitleGroup = await this.tariffService.fetchTitleById(this.shoppingCart.products[i].titleId).toPromise() as any;
            if (getTitleGroup.data.titleGroupId !== TitleGroupEnum.CARDS &&
                getTitleGroup.data.titleGroupId !== TitleGroupEnum.FINE) {
                this.isNotRequisitionOrEletronicProductOrFine = true;
                return this.isNotRequisitionOrEletronicProductOrFine;
            }
        }
        return this.isNotRequisitionOrEletronicProductOrFine;
    }

    public onModalClickNo(): void {
        this.isModalVisible = false;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.HOME, {
            message: this.navigationMessage,
            messageType: 'success'
        }), 100);
    }

    public onModalClickYes(): void {
        this.isModalVisible = false;
        const load: Load = {
            cardRead: this.storageManager.session.get<CardRead>(CardEnum.CARD_READ) as CardRead,
            titleId: 0,
        } as Load;
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        setTimeout(() => this.navigationManager.go(TicketingRoutes.FAMILY_PASS, {
            load,
            card: cardDetails,
            hasFamilyTicketLoaded: true,
        }), 100);
    }

    private async checkIfShoppingcartIsRegistered(): Promise<void> {
        const cart = await this.shoppingCartService.getCartById(this.shoppingCart.cartId);
        if (!cart || InvoiceComponent.CART_PAID === cart.status) {
            this.isConfirmDisabled = true;
            await this.shoppingCartService.disableShoppingCart(this.shoppingCart.cartId);
            this.storageManager.session.remove(PaymentEnum.ShoppingCart);
            this.navigationManager.go(TicketingRoutes.HOME);
            const msgCartError = !cart ? CommonEnum.msgCartNotExistError : CommonEnum.msgCartAlreadyPaid;
            this.alertService.error(msgCartError);
        }
    }
}
