import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-radio-group',
  templateUrl: './primary-radio-group.component.html',
  styleUrls: ['./primary-radio-group.component.less'],
})
export class PrimaryRadioGroupComponent implements OnInit {
  @Input() selected = 0;
  @Input() listOptions: object[] = [];
  @Input() size: '';
  @Input() color: '';

  @Output() onChanged = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  callChange(event: string) {
    this.onChanged.emit(event);
  }
}
