import { NzModalModule } from 'ng-zorro-antd/modal';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './pages/home/home.component';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {SharedModule} from 'src/app/shared/shared.module';
import {LoginComponent} from './pages/login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {DirectivesModule} from '../directives/directives.module';
import { ShiftlessComponent } from './pages/shiftless/shiftless.component';

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    ShiftlessComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    NzGridModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    SharedModule,
    DirectivesModule,
    NzModalModule
  ]
})
export class HomeModule {
}
