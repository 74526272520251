import {Injectable} from '@angular/core';
import {DetailsVoucherCancel} from '../model/details-voucher-cancel';
import {Subject} from 'rxjs';
import {CommonEnum} from 'src/app/shared/enum/common.enum';
import {HttpUtils} from 'src/app/shared/utils/http-utils';
import {HttpClient} from '@angular/common/http';
import {EnvironmentUtil} from '../../../../../../environments/environment-util';
import {AlertService} from 'src/app/core/services/alert.service';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import {BasicResponseDTO} from "../../../../../shared/models/basic-response-dto";

@Injectable({
    providedIn: 'root'
})
export class VoucherCancelService {

    data: Subject<DetailsVoucherCancel[]> = new Subject<DetailsVoucherCancel[]>();

    error = 'Ocorreu um erro ao recuperar os dados.';
    success = 'Voucher anulado com sucesso.';

    constructor(private httpClient: HttpClient,
                private alertService: AlertService,
                private storageManager: StorageManagerService
    ) {

    }

    getDetailsVoucherCancel(cardSerialNumber: number) {

        this.httpClient
            .get(`${EnvironmentUtil.getEnv().apiIntApiUrl}familypass-service/api/familypass/voucher/${cardSerialNumber}/get-valid`)
            .subscribe(
                (res: any) => {
                    if (res && res.success) {
                        const data = res.data;
                        this.data.next(data);
                        // this.alertService.success(res.message);
                    } else {
                        this.alertService.error(res.message);
                    }
                },
                (e: any) => {
                    try {
                        if (HttpUtils.isBasicResponse(e)) {
                            this.error = HttpUtils.getErroMessage(e);
                        } else if (e?.error?.error?.message) {
                            this.error = e.error.error.message;
                        } else {
                            this.error = e.getMessage;
                        }
                        this.alertService.error(this.error);
                    } catch (e) {
                        console.log(e);
                    }
                }
            );
    }

    async cancelVoucher(cardSerialNumber: number): Promise<BasicResponseDTO<DetailsVoucherCancel[]>> {
        const body = {cardSerialNumber};
        return await this.httpClient
            .post<BasicResponseDTO<DetailsVoucherCancel[]>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}familypass-service/api/familypass/voucher/cancel`, body).toPromise();
    }

}
