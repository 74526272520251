import { SpinService } from './../../../../../../core/services/spin.service';
import { RequisitionDTO } from './model/requisition';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { RequisitionLv } from '../modules/requisition-lv';
import { RequisitionDetailsService } from './service/requisition-details.service';
import { Item, InfoTitleWithCurrency } from './model/item';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { VatValidationRequest } from 'src/app/shared/models/vat-validation-request';
import { SaleService } from 'src/app/core/services/sale.service';
@Component({
    selector: 'app-requisition-details',
    templateUrl: './requisition-details.component.html',
    styleUrls: ['./requisition-details.component.less'],
})
export class RequisitionDetailsComponent implements OnInit {

    private VAT_NUMBER_SIZE = 9;

    public requisition: RequisitionLv = {} as RequisitionLv;
    public listDeliveryPlace: Item[] = [];
    public deliveryDate: Date;
    public isVatInternational = false;

    infoTitle: InfoTitleWithCurrency = {} as InfoTitleWithCurrency;

    constructor(
        private router: Router,
        private alertService: AlertService,
        private requisitionDetailsService: RequisitionDetailsService,
        private storageManagerService: StorageManagerService,
        private shoppingCartService: ShoppingCartService,
        private navigationManager: NavigationManagerService,
        private saleService: SaleService,
        private spinService: SpinService
    ) {
        this.getNavigationExtras();
        this.subscriveDeliveryType();
        this.subscriveTotalPrice();

        requisitionDetailsService.getDeliveryLocals();
        this.setTotalPrice();
    }

    async ngOnInit(): Promise<void> {
        const requisitionTypeResponse = await this.requisitionDetailsService
            .getRequisitionTypeByProfileIdAndDeliveryId(this.requisition.titleSelected.value, this.requisition.deliveryType.value);
        if (requisitionTypeResponse && requisitionTypeResponse.success && requisitionTypeResponse.data) {
            this.requisition.requisitionType = requisitionTypeResponse.data.id;
            this.deliveryDate = requisitionTypeResponse.data?.delivery?.deliveryDate;
            this.requisition.expectedTArrivalDate = this.deliveryDate;
        }
    }

    private getNavigationExtras(): void {
        if (this.router?.getCurrentNavigation()?.extras?.state) {
            this.requisition = this.router.getCurrentNavigation().extras.state.requestLv;
        } else {
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            this.router.navigate(['home']);
        }
    }

    private subscriveDeliveryType(): void {
        this.requisitionDetailsService.listDeliveryLocals.subscribe(
            (data: Item[]) => {
                this.listDeliveryPlace = data;
            },
            err => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                console.log('HTTP Error', err);
            }
        );
    }

    public onChangeDropdownEvent(event: any): void {
        this.requisition.selectedDeliveryPlace = this.listDeliveryPlace.find(({ value }) => value === event);
    }

    private setTotalPrice(): void {
        this.requisitionDetailsService.getPriceByDelAndReq(
            Number(this.requisition.titleSelected.value),
            Number(this.requisition.deliveryType.value));
    }

    public homeScreen(): void {
        this.navigationManager.go(TicketingRoutes.HOME);
    }

    public previousScreen(): void {
        this.navigationManager.go(TicketingRoutes.LV_REQUISITION_NEW);
    }

    public async onRegisterClick(): Promise<void> {
        this.spinService.setSpinValue(true);
        let isVatValid = true;
        if (!this.isVatInternational) {
            isVatValid = await this.checkIfVatValid(this.requisition.vatNumber);
        }
        if (isVatValid) {
            this.registerRequisition().then(async () => {
                this.alertService.success('Requisição efetuada com sucesso');
                await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
            });
        } else {
            this.requisition.vatNumber = null;
            this.alertService.error(CommonEnum.msgInvalidNifError);
            this.spinService.setSpinValue(false);
        }
    }

    private async checkIfVatValid(vatNumber: string): Promise<boolean> {
        if (this.VAT_NUMBER_SIZE === vatNumber.length) {
            const request: VatValidationRequest = {
                vat: vatNumber
            };
            return await this.saleService.isVatValid(request);
        }
        return false;
    }

    private async registerRequisition() {
        if (this.requisition.observations == null) {
            this.requisition.observations = '';
        }
        const requisitionResponse = await this.requisitionDetailsService.requisitionRegister(this.requisition).toPromise();
        if (requisitionResponse.success) {
            const shoppingCartRequest = this.prepareRequisitionShoppingCartRequest(requisitionResponse.data);
            await this.shoppingCartService.insert(shoppingCartRequest);
        }
    }

    private subscriveTotalPrice(): void {
        this.requisitionDetailsService.infoTitle.subscribe(
            (data: InfoTitleWithCurrency) => {
                this.infoTitle = data;
            },
            err => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                console.log('HTTP Error', err);
            }
        );
    }

    public onVatNumberChange(event: any): void {
        this.requisition.vatNumber = event;
    }

    public onObservationChange(event: any): void {
        this.requisition.observations = event;
    }

    public prepareRequisitionShoppingCartRequest(requisition: RequisitionDTO): ShoppingCartItemRequest {
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            titleId: this.infoTitle.id.id,
            description: this.infoTitle.description,
            quantity: 1,
            price: this.infoTitle.price.value,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_VEND,
            annulId: null,
            cardNumber: null,
            loadId: null,
            requisitionId: requisition.id,
            authId: null,
            replacementId: null,
            voucherId: 0,
            cardSerialNumber: null,
            isAnnul: false
        } as ShoppingCartItemRequest;
    }

}
