import {Component, OnInit} from '@angular/core';
import {Navigation} from '@angular/router';
import {ShoppingCart} from '../../modules/shopping-cart';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import {PaymentEnum} from 'src/app/shared/enum/payment.enum';

@Component({
  selector: 'app-payment-multibanco',
  templateUrl: './payment-multibanco.component.html',
  styleUrls: ['./payment-multibanco.component.less'],
})
export class PaymentMultibancoComponent implements OnInit {

  navigation: Navigation;

  shoppingCart: ShoppingCart = { payment : {} } as ShoppingCart;

  total: number;

  constructor(
    private navigationManager: NavigationManagerService,
    private storageManager: StorageManagerService) {

    this.shoppingCart = this.storageManager.session.get<ShoppingCart>(PaymentEnum.ShoppingCart) as ShoppingCart;
    this.total = this.shoppingCart.totalToPay / 100;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  async goToHome() {
    await this.navigationManager.go(TicketingRoutes.HOME);
  }

  async onPreviousClick() {
    await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
  }

  async onNextClick() {
    this.shoppingCart.payment.amountTpa = this.shoppingCart.totalToPay;
    this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
    await this.navigationManager.go(TicketingRoutes.INVOICE);
  }
}
