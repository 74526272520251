import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-cancel-button',
  templateUrl: './primary-cancel-button.component.html',
  styleUrls: ['./primary-cancel-button.component.less',
              '../../../modules/operation/pages/requisition/lisboa-viva/requisition-details/requisition-details.component.less',
              '../../../../app/app.component.less'],
})
export class PrimaryCancelButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() buttonCSS: string = 'button';

  onClickButton(event) {
    this.onClick.emit(event);
  }

  constructor() {}

  ngOnInit(): void {}
}
