import { StorageManagerService } from './../../../../../core/services/storage-manager.service';
import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';

@Component({
  selector: 'app-initial-screen',
  templateUrl: './initial-screen.component.html',
  styleUrls: ['./initial-screen.component.less']
})
export class InitialScreenComponent implements OnInit {

  constructor(private router: Router,
    private storageManager: StorageManagerService) {}

  ngOnInit(): void {
  }

  recoverReplacementGuide() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };

    this.router.navigate(['replacement-guide/recover-replacement-guide'], navigationExtras);
  }

  issueReplacementGuide() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    this.storageManager.session.set(ReadingLoadingEnum.FromReadingEmitGuide, "false");
    this.router.navigate(['replacement-guide/issue-replacement-guide'], navigationExtras);
  }

  homeClick() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };

    this.router.navigate(['home'], navigationExtras);
  }

}
