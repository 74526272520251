import { ReplacementGuidesFilter, SearchOrigin } from './model/replacement-guides';
import { LoadDTO } from '../../../../../shared/models/load-dtos';
import { CardContract, CardDataModel, CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { TableHeaderDataButton } from 'src/app/shared/models/table-header-data-button';
import { TitleInfoTable } from './model/title-info-table';
import { Component, OnInit } from '@angular/core';
import { Navigation } from '@angular/router';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { IssueReplacementGuideService } from 'src/app/core/services/issue-replacement-guide.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import {
    ReplacementGuideDTO, ReplacementGuideCard,
    ReplacementGuideStatus, ReplacementGuideTitle
} from '../model/create-repl-guide-request';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CommonEnum } from '../../../../../shared/enum/common.enum';
import { SpinService } from 'src/app/core/services/spin.service';
import { TariffService } from 'src/app/core/services/tariff.service';

enum DropdownEnum {
    CARD_NUMBER = 'Nº de Cartão',
    SERIAL_NUMBER ='Nº de Série'
}

@Component({
    selector: 'app-issue-replacement-guide',
    templateUrl: './issue-replacement-guide.component.html',
    styleUrls: ['./issue-replacement-guide.component.less'],
})
export class IssueReplacementGuideComponent implements OnInit {

    dropdownList: object[] = [
        { label: DropdownEnum.SERIAL_NUMBER, value: DropdownEnum.SERIAL_NUMBER },
        { label: DropdownEnum.CARD_NUMBER, value: DropdownEnum.CARD_NUMBER },
    ];

    /**
     * For filter purposes
     * The method getCardTitles was confuse, now has two parts
     * getCardTitles when there is CardRead before navigation to this page.
     * getCardTitles with the data of page input
     */
    searchOrigin: SearchOrigin = {
        input: true,
        cardRead: false,
    };
    labelDropdown = DropdownEnum.SERIAL_NUMBER;

    // Serial Number that comes from navigation extras
    serialNumber: number;

    // Input from page
    input: number;

    activeReplacementStatusId: number;

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];

    // Valid Titles for ReplacementGuide (from Load-Service)
    titles: LoadDTO[] = [];

    titlesDataTable: TitleInfoTable[] = [];
    navigation: Navigation;

    cardDetails: CardDetailsMessage;
    hasCardReadData: boolean;

    constructor(
        private navigationManager: NavigationManagerService,
        private service: IssueReplacementGuideService,
        private alertService: AlertService,
        private storageManager: StorageManagerService,
        private spinService: SpinService,
        private tariffService: TariffService
    ) {
        this.navigation = this.navigationManager.getNavigation();
        if (this.navigationManager.getNavigation().extras?.state?.serialNumber) {
            this.serialNumber = this.navigationManager.getNavigation().extras.state.serialNumber;
            this.input = this.serialNumber;
        }
        this.hasCardReadData = this.storageManager.session.has(CardEnum.FULL_CARD_DETAILS);
        if (this.hasCardReadData) {
            this.cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        }

        this.setDefault();
        this.prepareTableResult();
    }

    async ngOnInit() {
        await this.fetchActiveStatus();

        // Replacement Guide with Card Read
        if (this.serialNumber !== undefined) {
            await this.getCardTitles(this.searchOrigin.cardRead);
        }
    }

    /**
     * Fetch valid titles for replacement guides
     * @param searchOrigin True if this method is called with search input data, False if is called with card read data
     */
    private async getCardTitles(searchOrigin: boolean): Promise<void> {
        const filter = {
            cardDataModel: String(CardDataModel.K_CARD_DATAMODEL_LISBOA_VIVA_V_0.valueOf())
        } as ReplacementGuidesFilter;


        // Search Origin is card read
        if (searchOrigin === this.searchOrigin.cardRead) {
            filter.cardSerialNumber = this.serialNumber;
        } else { // Search Origin is page input data

            if (this.labelDropdown === DropdownEnum.CARD_NUMBER) {
                filter.cardNumber = this.input;
            }

            if (this.labelDropdown === DropdownEnum.SERIAL_NUMBER) {
                filter.cardSerialNumber = this.input;
            }
        }

        if (this.hasCardInfo()) {
            const titlesRequest = this.cardDetails.details.contracts
                .filter((contract: CardContract) => contract.ticket_code !== 0 && contract.operator_code !== 0)
                .map((contract: CardContract) => {
                    return {
                        operator_code: contract.operator_code,
                        temporal_validity: contract.temporal_validity,
                        ticket_code: contract.ticket_code
                    };
                });

            filter.titles = titlesRequest;
        }

        await this.fetchReplacementGuides(filter);
    }

    // **************FILTER**************

    public onChangedInput(value: string): void {
        this.input = Number(value);
    }

    public onChangedDropdown(value: DropdownEnum): void {
        this.labelDropdown = value;
    }

    public async searchBy(numberValue: number): Promise<void> {
        /*
        the numberValue !== 0 case it's because when the input gets its
        first value and then cleared to search with an empty input,
        the search will be executed with a 0 instead of an "undefined"
        */
        if (numberValue !== undefined && numberValue !== 0) {

            this.input = numberValue;
            await this.getCardTitles(this.searchOrigin.input);

        } else {
            this.alertService.error(CommonEnum.msgFillFilterError);
        }

    }

    private async fetchReplacementGuides(filter: any): Promise<void> {
        this.spinService.setSpinValue(true);
        const msgIssueReplacementGuideTitlesNotFound = `Não foram encontrados títulos válidos para o ${this.labelDropdown} pesquisado`;
        try {
            const response = await this.service.fetchReplacementGuides(filter).toPromise() as any;
            if (response != null) {
                if (response.success) {
                    if (response.data.length !== 0) {
                        this.titles = response.data;
                    } else {
                        this.alertService.info(msgIssueReplacementGuideTitlesNotFound);
                        this.spinService.setSpinValue(false);
                    }
                }
            }
        } catch (e) {
            this.alertService.error(msgIssueReplacementGuideTitlesNotFound);
            this.spinService.setSpinValue(false);
        }
        const dataTable: TitleInfoTable[] = [];
        for (const title of this.titles) {
            const getTitleInfo = await this.tariffService.fetchTitleById(title.titleId).toPromise();
            dataTable.push({
                id: title.id,
                titleId: title.titleId,
                titleDescription: getTitleInfo.data.description,
                validityStart: title.currentContract.validityStart,
                validityEnd: title.currentContract.validityEnd,
                actions: { replacementGuide: true },
                // removed zapping: boolean
            } as any);
        }
        this.titlesDataTable = dataTable;
        this.spinService.setSpinValue(false);
    }

    // **************TABLE**************

    /**
     * Reset table results
     */
    private setDefault(): void {
        this.titles = [];
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: 'TÍTULO', config: { colAlign: 'left', colWidth: '45%' } },
            { title: 'VAL INÍCIO', config: { colAlign: 'left', colWidth: '20%' } },
            { title: 'VAL FIM', config: { colAlign: 'left', colWidth: '20%' } },
            { title: null, config: { colAlign: 'left', colWidth: '15%' } }
        ];

        this.listTitleHeader = [
            { visible: false, name: 'id' } as TableHeaderData,
            { visible: true, name: 'titleDescription' } as TableHeaderData,
            {
                visible: true,
                name: 'validityStart',
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy' },
            } as TableHeaderData,
            {
                visible: true,
                name: 'validityEnd',
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy' },
            } as TableHeaderData,
            {
                visible: true,
                button: {
                    titleButton: 'Emitir Guia',
                    btnAction: 'Emitir Guia',
                    buttonCSS: 'emit-button',
                    textCSS: 'emit-button-text',
                    buttonText: 'Emitir Guia',
                    checkVisibilityField: 'actions.replacementGuide'
                } as TableHeaderDataButton
            } as TableHeaderData,
        ];
    }

    /*
     * Table button action
     */
    public async changeEmitGuide(event): Promise<void> {
        const replacementGuideDTO: ReplacementGuideDTO = {} as ReplacementGuideDTO;
        const oldCard: ReplacementGuideCard = {} as ReplacementGuideCard;
        const status: ReplacementGuideStatus = {} as ReplacementGuideStatus;
        const title: ReplacementGuideTitle = {} as ReplacementGuideTitle;

        const titleSelected: LoadDTO = this.titles.find(e => e.id === event.value);
        const replacementGuideTitle = this.titlesDataTable
            .filter(replacementTitle => replacementTitle.titleId === titleSelected.titleId)[0];

        status.id = this.activeReplacementStatusId;
        if (this.labelDropdown == DropdownEnum.SERIAL_NUMBER.toString()) {
            oldCard.serialNumber = this.input;
        } else {
            oldCard.cardNumber = this.input;
        }
        title.titleId = titleSelected.titleId;
        title.startDate = new Date(replacementGuideTitle.validityStart);
        title.endDate = new Date(replacementGuideTitle.validityEnd);

        replacementGuideDTO.oldCard = oldCard;
        replacementGuideDTO.status = status;
        replacementGuideDTO.title = title;
        replacementGuideDTO.loadAmount = titleSelected.amount;

        await this.nextView(replacementGuideDTO, titleSelected.id);
    }

    public hasCardInfo(): boolean {
        return this.navigation.extras?.state && this.hasCardReadData;
    }

    private async fetchActiveStatus(): Promise<void> {
        try {
            const response = await this.service.getActiveReplacementStatus().toPromise();
            if (response?.success) {
                this.activeReplacementStatusId = response?.data?.id;
            } else {
                this.alertService.warning(response?.message);
            }
        } catch (e) {
            this.alertService.warning(CommonEnum.msgReplacementGuideCommonError);
        }
    }

    // **************NAVIGATION**************
    public async previousView(): Promise<void> {
        if (this.hasCardInfo()) {
            await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: this.cardDetails });
        } else {
            await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_INITIAL);
        }
    }

    private async nextView(replacementGuide: ReplacementGuideDTO, transfLoadId: number): Promise<void> {
        await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_SUBSTITUTION_GUIDE, {
            replacementGuide,
            transfLoadId
        });
    }

}
