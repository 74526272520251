import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-secondary-second-button',
  templateUrl: './secondary-second-button.component.html',
  styleUrls: ['./secondary-second-button.component.less'],
})
export class SecondarySecondButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() color = '';
  @Input() color2 = '';
  @Input() back = '';
  @Input() borderColor = '';
  @Input() fontSize = '14px';
  @Input() width = '';
  @Input() buttonSize = 'normal';
  @Input() value: string;

  onClickButton(event) {
    this.onClick.emit({ event, value: this.value });
  }

  constructor() {}

  ngOnInit(): void {}
}
