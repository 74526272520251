<div class="detailContent">
  <div>
    <h1 style="text-transform: uppercase">
      {{ titleDescription }}
    </h1>
  </div>

  <div nz-row class="group">
    <div
      style="text-align: left; color: #002b5a; font-size: 20px"
      nz-col
      [nzSpan]="24"
      class="validFrom">
      <label *ngIf="isValidDate()">Válido de <b>{{ newValidityStart | date: "dd/MM/yyyy" }}</b> até
        <b>{{ newValidityEnd | date: "dd/MM/yyyy" }} </b>
      </label>
    </div>
  </div>
  <div>
    <title-details-table
      [listOfColumn]="listTitleColumn"
      [headerDatas]="listTitleHeader"
      [listOfData]="tableData"
      [isExc]="isExchange()"
      tableRowCSS="table-row-no-line"
    >
    </title-details-table>
    <div class="m-b-20" *ngIf="isVV()">
        <app-primary-checkbox
            [isChecked] = "checkboxVV"
            (isCheckedChange)="onCheckVV($event)"
            label="Novo Suporte VIVA Viagem/navegante ocasional"
        ></app-primary-checkbox>
    </div>
  </div>
  <br />
  <br />
  <br />
  <div class="label-dropdown" *ngIf="showDropdown">
    <div nz-col nz-span="24">
      <label>Indique quantos {{dropdownLabelMessage}} pretende carregar</label>
    </div>
  </div>
    <div nzSpan="12" class="dropdown-div" *ngIf="showDropdown">
      <app-dropdown-button 
        [label]="selectedRechargeTitleValue ? selectedRechargeTitleValue.value : dropdownLabelMessage"
        labelTemplate="btn-icon icon-drop_down icon-dropdown-arrow m-l-a" 
        [listOptions]="dropListRechargeTitleValues"
        buttonStyle="dropdown" 
        (onSelect)="handleDropdownSelect($event)">
      </app-dropdown-button>
    </div>
  <div class="footer-buttons">
  <div  nz-row>
    <div  nz-col [nzSpan]="12" nzJustify="space-around" nzAlign="top" class="align-left">
      <app-primary-white-button
        (click)="previousClick()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
      <app-primary-cancel-button class="m-l-50" (click)="homeScreen()">Cancelar</app-primary-cancel-button>
    </div>
    <div nz-col [nzSpan]="12" class="align-right">
      <app-primary-normal-button
        [critical] = "true"
        (click)="nextClick()"
        buttonCSS="button-load">
        {{ buttonLabel }}
        <span class="btn-icon icon-seguinte icon-middle float-right"></span>
      </app-primary-normal-button>
    </div>
  </div>
</div>


</div>
