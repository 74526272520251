import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataCheckbox } from 'src/app/shared/models/table-header-data-checkbox';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { NonTicketingProductHistoryRecord, RecordsByFilterRequest } from './model/history-non-ticketing-product';
import { LoadTitleTransactionType } from 'src/app/shared/enum/load-title-transaction-type.enum';
import { CancelShoppingCartItemRequest } from 'src/app/shared/models/cancel-shoppingcart-item-request';
import { DatePipe } from '@angular/common';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { SaleService } from 'src/app/core/services/sale.service';

@Component({
    selector: 'app-non-ticketing-products-history',
    templateUrl: './non-ticketing-products-history.html',
    styleUrls: ['./non-ticketing-products-history.less']
})
export class NonTicketingProductsHistoryComponent implements OnInit {

    public listNonTicketingProducts = [];

    public isShoppingCartFull = false;
    public isNextDisabled = true;
    public selectedIds: Array<number> = [];
    public tariffSelected;
    public quantity = 0;

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    public listRecords: NonTicketingProductHistoryRecord[] = [];
    public dataTotal: number;
    public readonly defaultPageSize = 5;
    private sort: string[] = [];
    private selectedProducts: NonTicketingProductHistoryRecord[] = [];;
    public checkedItems: any[] = [];
    public documentNumber: string;
    public startDate: Date;
    public endDate: Date;
    
    public selected = false;
    private selectedId: number;

    public recordsByFilterRequest: RecordsByFilterRequest = {};


    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private saleService: SaleService,
        private shoppingService: ShoppingCartService,
        private storageManagerService: StorageManagerService,
        private datepipe: DatePipe
    ) { }

    async ngOnInit(): Promise<void> {
        this.spinService.setSpinValue(true);
        this.prepareTableResult();
        this.startDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 6);
        this.endDate = new Date();
        await this.fetchNonTicketingProducts();
        this.spinService.setSpinValue(false);
    }

    public async onPageChanged(value: any): Promise<void> {
        await this.fetchNonTicketingProducts(value - 1);
    }

    public async loadRecords(): Promise<void> {
        const actualDate = this.withoutTime(new Date());

        if (this.startDate == null || this.endDate == null) {
            this.alertService.error(CommonEnum.msgFillBothDatesError);
        } else if (actualDate < this.withoutTime(new Date(this.startDate))) {
            this.alertService.error(CommonEnum.msgInitialDateOverCurrentDateError);
        } else if (actualDate < this.withoutTime(new Date(this.endDate))) {
            this.alertService.error(CommonEnum.msgFinalDatePreviousInitialDateError);
        } else if (this.startDate > this.endDate) {
            this.alertService.error(CommonEnum.msgInitialDateOverFinalDate);
        } else {
            await this.fetchNonTicketingProducts();
        }
    }

    private withoutTime(value: Date): Date {
        const dateMidnight = new Date(value);
        dateMidnight.setHours(0, 0, 0, 0);
        return dateMidnight;
    }

    private async fetchNonTicketingProducts(pageNumber: number = 0) {
        this.spinService.setSpinValue(true);
        this.sort = [];
        this.sort.push('createdAt,desc');
        this.recordsByFilterRequest.endDate = this.endDate != null ? this.datepipe.transform(this.endDate, 'yyyy-MM-dd') : null,
        this.recordsByFilterRequest.startDate = this.startDate != null ? this.datepipe.transform(this.startDate, 'yyyy-MM-dd') : null,
        this.recordsByFilterRequest.size = this.defaultPageSize;
        this.recordsByFilterRequest.page = pageNumber;
        this.recordsByFilterRequest.sorters = this.sort;
        try {
            const response = await this.saleService.getListNewTicketingProducts(this.recordsByFilterRequest);
            if (response?.data != null) {
                this.listRecords = response.data?.content;
                this.dataTotal = response.data.totalElements;
                this.spinService.setSpinValue(false);
            }
            if (response.data.empty) {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgRequisitionNotFound);
            }
        } catch (error) {
            console.log('Error: ', error);
            this.listRecords = [];
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: null, config: { colAlign: 'left', colWidth: '5%' } },
            { title: 'ITEM', config: { colAlign: 'left', colWidth: '40%' } },
            { title: 'QUANTIDADE', config: { colAlign: 'left', colWidth: '15%' } },
            { title: 'Nº DOCUMENTO', config: { colAlign: 'left', colWidth: '20%' } },
            { title: 'DATA', config: { colAlign: 'left', colWidth: '10%' } },
            { title: 'PREÇO', config: { colAlign: 'left', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                checkbox: {isChecked: 'annul' } as TableHeaderDataCheckbox
            } as TableHeaderData,
            {
                visible: true,
                name: 'description',
                config: { valAlign: 'left'},
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'quantity',
                config: { valAlign: 'center'},
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'documentNumber',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'documentDate',
                config: { valAlign: 'right'},
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'right', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData
        ];
    }
    
    async previousView() {
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS);
    }

    public documentNumberOnChange(value: string): void {
        if (value) {
            // getting the first 2 characters because they are the initials
            const initials = value.substring(0, 2).toUpperCase();
            // remaining string is the document provider number
            const docNumber = value.substring(2);
            // then we need to concat both strings with space because this filter has an inflexible structure
            this.recordsByFilterRequest.docNumber = initials.concat(' ' + docNumber);
        } else {
            this.recordsByFilterRequest.docNumber = null;
        }
    }

    
    public onChangedStartDate(value: Date): void {
        this.startDate = value == null ? null : DateUtils.setTimeTo0(value);
    }

    public onChangedEndDate(value: Date): void {
        this.endDate = value == null ? null : DateUtils.setTimeTo235959(value);
    }


    public checkBoxEvent(event: any): void {
        if (event.value) {
            this.checkedItems.push(event.id);
            this.selected = true;
        } else {
            const index = this.checkedItems.indexOf(event.id);
            if (index !== -1) {
                this.checkedItems.splice(index, 1);
            }
        }
        if (this.checkedItems.length <= 0) {
            this.selected = false;
        }
        this.selectedProducts = this.listRecords.filter(item => this.checkedItems.includes(item.id));
    }

    public async annulNonTicketingProducts() {
        this.selected = false;
        const hasDisabledItem: boolean = this.selectedProducts.some(obj => obj.disabled);
        if (hasDisabledItem) {
            this.alertService.error('Possui um produto selecionado que não permite anulação');
        } else {
            let cancelPromises = this.selectedProducts.map(async (product) => {
                const shoppingCartRequest = this.prepareCancelNonTicketingProductShoppingCartItemRequest(product);
                return this.shoppingService.cancelItem(shoppingCartRequest);
            });
            
            await Promise.all(cancelPromises);
            await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);            
        }
    }

    private prepareCancelNonTicketingProductShoppingCartItemRequest(product: NonTicketingProductHistoryRecord): CancelShoppingCartItemRequest {
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            shoppingCartId: null,
            itemId: null,
            titleId: product.productId,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_ANUL_VEND,
            isNonTicketing: true,
            previousSaleItemNonTicketing: product.saleItemId,
            campaignId: product.campaignId ? product.campaignId : null
        } as CancelShoppingCartItemRequest;
    }

    public clearSearch(): void {
        this.documentNumber = null;
        this.startDate = null;
        this.endDate = null;
    }
}
