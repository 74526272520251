export enum CommonEnum {

  shiftID = 'SHIFT_ID',
  accessToken = 'ACCESS_TOKEN',
  refreshToken = 'REFRESH_TOKEN',
  enabledDevAPI = 'ENABLED_DEV_API',
  expiresIn = 'EXPIRES_IN',
  shiftStatus = 'SHIFT_STATUS',
  username = 'USERNAME',
  externalId = 'EXTERNAL_ID',
  shiftStartDate = 'SHIFT_START_DATE',
  peripheralId = 'PERIPHERAL_ID',
  errorMessageScanCard = 'ERROR_MESSAGE_SCAN_CARD',

  msgCredentialsError = 'O Nº de Utilizador ou a palavra-passe estão incorrectos',
  msgPeripheralNotConnectedError = 'Não foi possível ligar com o controlador de Periféricos',
  msgLoginShiftAlreadyOpenByAnotherUser = 'Já se encontra um turno aberto de outro utilizador',
  msgModalShiftResume = 'Retomar Turno',
  subMsgModalShiftResume = 'Pretende Retomar Turno?',
  msgModalShiftStart = 'Início de Turno',
  subMsgModalShiftStart = 'Pretende Iniciar Turno?',
  msgShiftStatusError = 'Não foi possível obter o estado do turno',
  msgShiftResume = 'Turno retomado com sucesso.',
  msgNewShift = 'Turno iniciado com sucesso',
  msgShiftOpen = 'Esta bilheteira encontra-se com um turno aberto',
  msgShiftCloseError = 'Não foram enviados os dados necessários para encerrar o turno',
  msgUnauthorized = 'O Utilizador não autorizado',
  msgShiftClose = 'Turno encerrado com sucesso',
  msgSaleSucess = 'Carrinho de compras pago com sucesso',
  msgAcquiredFamilyPass = 'Adquiriu um Voucher Família, verifique se pretende efetuar um carregamento',
  msgVVCardError = 'O cartão inserido não é um VV/Navegante ocasional',
  msgOriginalCardRequest = 'Por favor coloque o suporte original no leitor',
  msgVVInitializedCardError = 'A guia de substituição inserida já se encontra inicializada. ',
  msgLVCardError = 'O cartão inserido não é um LV/Navegante personalizado',
  msgWriteCardError = 'Não foi possível escrever no cartão: ',
  msgDetectCardError = 'Por favor, aproxime o cartão do leitor ',
  msgWriteCardAndSCError = 'Não foi possível carregar o cartão. Item não adicionado ao carrinho de compras',
  msgConsumeAuthorizationError = 'Não foi possível consumir a autorização de carregamento',
  msgGetInfoFromPreviousViewError = 'Não foi possível obter a informação necessária para carregar a página pretendida',
  msgCartPaymentSuccess = 'Carrinho de compras pago com sucesso',
  msgFamilyTicketError = 'Não foi possível consumir o Passe Família',
  msgFamilyTicketValidVouchersError = 'Não foi possível obter informações sobre o Passe Família para o presente cartão',
  msgCardWriteGenericError = 'Ocorreu um erro na escrita do cartão',
  msgReachedMaxSuccessiveScanTriesError = 'Foi atingido o número máximo de erros na leitura ',
  msgIsOnBlackListError = 'Não foi possível validar se o presente cartão se encontra na Lista Negra',
  msgHttpUtilsMissingDetail = 'Não foi possível aceder aos detalhes da mensagem',
  msgHttpUtilsMissingMessage = 'Não foi possível aceder à mensagem da resposta',
  msgHttpUtilsMissingResponse = 'Não foi possível aceder à resposta do erro',
  msgReplacementGuideCommonError = 'Não foi possível carregar os títulos válidos para emissão de guias',
  msgValueZappingNotSelected = 'Por favor insira o valor que pretende carregar',
  msgSubstitutionGuideVVAlert = 'Por favor coloque a guia de substituição no leitor',
  msgAlreadyReplacementGuide = 'O suporte utilizado já é uma guia de substituição registada em sistema',
  msgSameCardRead = 'Cartão destino deverá ser diferente daquele que originou a transferência',
  msgDifferentCardRead = 'O cartão com que tentou fazer a operação é diferente do cartão lido inicialmente',
  msgPrintMultipleDocumentsSuccess = 'Documentos impressos com sucesso',
  msgPrintDocumentSuccess = 'Documento impresso com sucesso',
  msgNoTitlesAvailableForExchange = 'Não existem títulos disponíveis para troca',
  msgCardContractDeleteRequest = 'Por favor coloque o suporte a eliminar contrato no leitor',
  msgFillBothDatesError = 'Por favor preencha ambas as datas',
  msgInitialDateOverCurrentDateError = 'Data inicial não pode ser posterior à data atual',
  msgFinalDatePreviousInitialDateError = 'Data final não pode ser anterior à data inicial',
  msgInitialDateOverFinalDate = 'Data inicial não pode ser posterior à data final',
  msgRequisitionNotFound = 'Requisição não encontrada',
  msgAnnulRequisitionError = 'Não foi possível anular a requisição',
  msgCardDifferentAnnulError = 'Cartão não corresponde ao título a ser anulado',
  msgSlotNotDetectedShoppingRemoval = 'Não foi possível detetar a slot do contrato que pretende remover do carrinho',
  msgLoadNotFound = 'Não foi possível obter a informação do carregamento',
  msgAnullShoppingItemAnnulError = 'Ocorreu um erro ao anular item do carrinho de compras',
  msgInfoDontRemoveCard = 'Não retire o suporte do leitor',
  msgInfoTitleError = 'Não foi possível obter informação relativa ao título selecionado',
  msgDocNotFoundForSpecificDates = 'Documentos não encontrados para o intervalo de datas selecionado',
  msgDocNotFoundError = 'Documentos não encontrados',
  msgShiftCloseWithShoppingItemsError = 'Não pode fechar o turno com itens no carrinho',
  msgPeripheralIdentificationError = 'Falha na identificação do controlador de periféricos',
  msgSupportDamagedError = 'O suporte encontra-se danificado',
  msgSAMFARMError = 'Erro de comunicação com a SAMFARM TML',
  msgCommunicationReaderError = 'Não foi possível comunicar com o leitor. Certifique-se que o Controlador de Periféricos está ligado',
  msgCardReadError = 'Não foi possível ler corretamente o cartão, por favor tente novamente',
  msgSelectedTitleNotFoundError = 'O título selecionado não foi encontrado',
  msgExtendTitleError = 'O título já se encontra carregado e não pode ser estendido',
  msgAnnulTitleNotInCardError = 'O título não está presente no cartão para anular',
  msgMaxValueAllowedError = 'O número de quantidade inserida é superior ao valor máximo permitido: 16777215',
  msgReturnZappingValuesError = 'Não foi possível devolver os valores de Zapping',
  msgCardNifsError = 'Não foi possível obter a lista de NIFs associados ao cartão',
  msgFinalCustomerNifError = 'Não é possível associar/predefinir NIF de consumidor final',
  msgInvalidNifError = 'Por favor introduza um NIF válido',
  msgZipCodeFormatError = 'O código postal, quando preenchido, deve ter o formato XXXX-YYY',
  msgUnknownActionError = 'Erro na ação: ',
  msgAnnulTitleLoadNotFoundError = 'O carregamento do título que pretende anular não foi encontrado',
  msgAnnulTitleAndSCError = 'Não foi possível anular o titulo. Item não adicionado ao carrinho de compras',
  msgAnnulTitleError = 'Não foi possível anular o titulo',
  msgFillFilterError = 'Por favor preencha o filtro',
  msgPrintReceiptError = 'Não foi possível imprimir o recibo',
  msgPrintSecondShiftReceiptError = 'Não foi possível imprimir a 2ª via do fecho de turno',
  msgPrintDocumentError = 'Não foi possível imprimir o documento',
  msgCardReadIsNotTheOriginal = 'O cartão lido não corresponde ao cartão original',
  msgCartNotExistError = 'Não foi possível obter o carrinho para esta venda',
  msgCartAlreadyPaid = 'O carrinho já se encontra pago',
  msgBinaryNullError = 'O binário do cartão encontra-se vazio. Por favor aguarde',
  msgUnexpectedAnnulError = 'Ocorreu um erro na anulação',
  msgUnexpectedExchangeError = 'Ocorreu um erro ao efetuar a troca',
  msgCleaningCardError = 'Ocorreu um erro na limpeza do cartão',
  msgReplacementEmitGuideError = 'Ocorreu um erro ao emitir guia. Por favor não remova o suporte do leitor',
  msgReadCardError = 'Ocorreu um erro. Leitura não foi bem sucedida',
  msgTransferError = 'Ocorreu um erro na transferência. Por favor não remova o suporte do leitor',
  msgTransferDeleteError = 'Ocorreu um erro ao limpar suporte. Por favor não remova o suporte do leitor',
  msgSelectedCategoryWithoutProducts = 'Categoria selecionada não possui produtos',
  msgProductAddedToCart = 'Produto Adicionado ao carrinho',
  msgOperationSucceded = 'Operação efetuada com sucesso',
  msgReportModal = 'Descrição Campanha',
  msgReportDefaultModal = 'De momento não possui nenhuma campanha selecionada',
  msgNoLocalIdFound = "Não foi encontrado o local da bilheteira",

  // Fines
  msgFinesNotFound = 'Não existem registos que correspondam aos valores da pesquisa',
  msgNoticeNumberAlreadyExistError = 'O Nº Auto introduzido já se encontra registado em sistema',
  msgNoticeNumberAlreadyExistInShoppingCartError = 'Nº Auto introduzido já se encontra no carrinho de compras',

  // Reading Card
  msgHasNotReadingPermissions = 'Não possui permissões de leitura na bilheteira',

  // composed titles
  msgComposedTitlesNotFound = 'Não foi possível obter os títulos compostos',

  shiftStatusActive = 'ACTIVE',
  shiftStatusSuspend = 'SUSPEND',
  shiftStatusFinished = 'FINISHED',
  msgSuspendedShiftsNotFound = 'Não existem turnos suspensos',
  msgShiftNotSuspended = 'Turno já não se encontra suspenso',

  identificationDocumentTypeCC = 'CC',
  identificationDocumentTypePassport = 'PASSPORT',
  identificationDocumentTypeBI = 'BI',

  loadingActive = 'LOADING_ACTIVE',
  shoppingCartFull = 'SHOPPING_CART_FULL',
  msgShoppingCartFull = 'Carrinho atingiu limite de produtos',
  msgTotalPaymentBiggerThanTotalShoppingCart = 'Total recebido excedeu total do carrinho',

  // sale
  msgSaleTransactionAlreadyExists = 'A transação já se encontra registada',

  // Load
  errorTicketReloadChange = 'O título previamente carregado não permite recarregamento',
  // load history
  msgPrintReceiptLoadHistoryWithoutDoc = 'Operação não possui documento associado',

  // requisitions
  msgPrintReceiptRequisitionWithoutDoc = 'Requisição não possui documento associado',
  
  // family pass notifications
  msgFamilyPassChecking = 'Avaliação agregado familiar em curso',
  msgFamilyPassValid = 'Suporte possui agregado familiar válido',
  msgFamilyPassInvalid = 'Suporte não possui agregado familiar válido',

  // Document info shift
  msgInfoBothDatesEmpty = 'Pesquisa limitada aos últimos 3 meses por omissão. Deverá especificar o intervalo de tempo pretendido'
}

