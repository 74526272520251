import { Injectable } from '@angular/core';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BasicResponseDTO } from 'src/app/shared/models/basic-response-dto';
import { Fine, FinePaidError, FineRegisterRequest } from 'src/app/shared/models/fine';
import { FineValue } from 'src/app/modules/operation/modules/fine';

@Injectable({
    providedIn: 'root'
})
export class FineService {

    constructor(
        private httpClient: HttpClient
    ) { }

    public getFinesByFilter(request: any): Observable<any> {
        let params = new HttpParams();

        if (request.date) {
            params = params.set('date', `${request.date}`);
        }
        if (request.fiscalNumber) {
            params = params.set('fiscalNumber', `${request.fiscalNumber}`);
        }
        if (request.finedPersonName) {
            params = params.set('finedPersonName', `${request.finedPersonName}`);
        }
        if (request.noticeNumber) {
            params = params.set('noticeNumber', `${request.noticeNumber}`);
        }
        if (request.pageElements) {
            params = params.set('pageElements', `${request.pageElements}`);
        }
        if (request.page) {
            params = params.set('page', `${request.page}`);
        }
        if (request.orderAsc) {
            params = params.set('orderAsc', `${request.orderAsc}`);
        }
        return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/not-arquived`, { params });
    }

    public preRegisterFine(request: any): Observable<any> {
        return this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/pre-register`, request);
    }

    public async isNoticeNumberAvailable(noticeNumber: number): Promise<BasicResponseDTO<boolean>> {
        return await this.httpClient.get<BasicResponseDTO<boolean>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/is-notice-number-available/${noticeNumber}`)
            .toPromise();
    }

    public async saveFine(fine: FineRegisterRequest): Promise<BasicResponseDTO<Fine>> {
        return await this.httpClient.post<BasicResponseDTO<Fine>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/save`, fine)
            .toPromise();
    }

    public async saveMultipleFines(fines: Array<FineRegisterRequest>): Promise<BasicResponseDTO<Array<Fine>>> {
        return await this.httpClient.post<BasicResponseDTO<Array<Fine>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/save/multiple-fines`, fines)
            .toPromise();
    }

    public async fineValuesInfo(): Promise<BasicResponseDTO<Array<FineValue>>> {
        return await this.httpClient.get<BasicResponseDTO<Array<FineValue>>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}tariff/serv/title/consult-fines-info`)
            .toPromise();
    }

    public async findFinesByIds(fineIds: Array<number>): Promise<BasicResponseDTO<Array<Fine>>> {
        let params = new HttpParams();

        if (fineIds) {
            params = params.set('fineIds', `${fineIds}`);
        }
        return await this.httpClient.get<BasicResponseDTO<Array<Fine>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/by-fine-ids`, { params })
            .toPromise();
    }

    public async checkIfFinesAlreadyPaidByFineIds(fineIds: Array<number>): Promise<BasicResponseDTO<Array<FinePaidError>>> {
        let params = new HttpParams();

        if (fineIds) {
            params = params.set('fineIds', `${fineIds}`);
        }
        return await this.httpClient.get<BasicResponseDTO<Array<FinePaidError>>>
            (`${EnvironmentUtil.getEnv().apiIntApiUrl}fines/api/fines/by-fine-ids/check-if-already-paid`, { params })
            .toPromise();
    }

}
