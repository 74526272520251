import {CardDataModel} from '../models/card-details-message';

export class CardUtils {

    static IsVV(cardDataModel: CardDataModel): boolean {
        return (cardDataModel === CardDataModel.K_CARD_DATAMODEL_UNKNOWN
            || cardDataModel === CardDataModel.K_CARD_DATAMODEL_SETE_COLINAS_V_0
            || cardDataModel === CardDataModel.K_CARD_DATAMODEL_SUBSTITUTION_GUIDE_V_0);
    }

    static IsLV(cardDataModel: CardDataModel): boolean {
        return (cardDataModel === CardDataModel.K_CARD_DATAMODEL_LISBOA_VIVA_V_0);
    }

    static IsInitialized(cardDataModel: CardDataModel): boolean {
        return !(CardDataModel.K_CARD_DATAMODEL_UNKNOWN === cardDataModel);
    }

    static IsZapping(tickCode: number, tickOperCode: number, titleId: number): boolean {
      return tickCode === 33592 && tickOperCode === 31  && titleId === 3201;
    }

    public static isProfileExpired(daysAfter1997: number): boolean {
        const profileExpiryDate = new Date(1997, 0, 1);
        profileExpiryDate.setDate(profileExpiryDate.getDate() + daysAfter1997);
        const now = new Date();
        return profileExpiryDate < now;
    }

}
