import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { ShiftService } from 'src/app/core/services/shift.service';
import { Component, OnInit } from '@angular/core';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { EnvironmentUtil } from 'src/environments/environment-util';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { AuthorityName } from 'src/app/shared/models/authority-name';
import { HandlerComponent, SocketClientService } from 'src/app/core/services/websocket-client.service';
import { WebsocketMessage } from 'src/app/shared/models/websocket-message';

@Component({
    selector: 'app-shiftless',
    templateUrl: './shiftless.component.html',
    styleUrls: ['./shiftless.component.less']
})
export class ShiftlessComponent  implements OnInit, HandlerComponent {

    showButtonExceptProd = false;
    public canConsultAndEditSuspendedShifts = false;
    public canConsultOrEditRequisitions = false;

    constructor(
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private shiftService: ShiftService,
        private authorizationService: AuthorizationService,
        private socketClient: SocketClientService,
    ) {
        if (!this.storageManager.session.get<string>(CommonEnum.accessToken)) {
            this.navigationManager.go(TicketingRoutes.LOGIN);
        }

        this.socketClient.setHandlerComponent(this);
    }

    handleMessage(message: WebsocketMessage): void {
    }

    onConnect(): void {
    }

    ngOnInit(): void {
        this.showButtonExceptProd = !EnvironmentUtil.isProd();
        this.canConsultAndEditSuspendedShifts = this.authorizationService.hasPermission(AuthorityName.SUSPENDED_SHIFTS_READ_WRITE);
        this.canConsultOrEditRequisitions = this.authorizationService.hasOneOf([AuthorityName.REQUISITIONS_HISTORY,
            AuthorityName.REQUISITIONS_NEW]);
    }

    public isQaEnvironment(): boolean {
        return EnvironmentUtil.isQuality();
    }

    public previousShifts() {
        this.navigationManager.go(TicketingRoutes.SHIFT_PREVIOUS);
    }

    public suspendedShifts() {
        this.navigationManager.go(TicketingRoutes.SHIFT_SUSPENDED);
    }

    public documentsHistory() {
        this.navigationManager.go(TicketingRoutes.DOCUMENTS_HISTORY);
    }

    public requisitionHistory() {
        this.navigationManager.go(TicketingRoutes.LV_REQUISITION_HISTORY);
    }

    public async logout() {
        await this.shiftService.doLogout();
        this.navigationManager.go(TicketingRoutes.LOGIN);
    }
}
