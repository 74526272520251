import {Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[appGenericHide]'
})
export class GenericHideDirective implements OnChanges {

  @Input('appGenericHide') isHidden = false;

  constructor(private el: ElementRef) {
  }

  ngOnChanges() {
    if (this?.isHidden) {
      this.el.nativeElement.style.display = 'none';
    } else {
      this.el.nativeElement.style.display = 'block';
    }
  }

}
