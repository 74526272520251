import {TableHeaderDataButton} from 'src/app/shared/models/table-header-data-button';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableColumn} from 'src/app/shared/models/table-column';
import {Navigation, Router} from '@angular/router';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {ShoppingCartService} from 'src/app/core/services/shopping-cart.service';
import {ShiftService} from 'src/app/core/services/shift.service';
import {AlertService} from 'src/app/core/services/alert.service';
import {ShoppingCart} from '../../modules/shopping-cart';
import {ShoppingCartProduct} from '../../modules/shopping-cart-product';
import {ReadingLoadingEnum} from 'src/app/shared/enum/reading-loading.enum';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {PaymentEnum} from 'src/app/shared/enum/payment.enum';
import {NzModalService} from 'ng-zorro-antd';
import {SaleService} from 'src/app/core/services/sale.service';
import {Sale} from '../../modules/sale';
import {SaleClient} from '../../modules/sale-client';
import {SaleVatAssociation} from '../../modules/sale-vat-association';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import {WebsocketMessage, WebsocketMessageType} from 'src/app/shared/models/websocket-message';
import {CardEnum} from 'src/app/shared/enum/card.enum';
import {CardUtils} from 'src/app/shared/utils/card-utils';
import {CardDataModel, CardDetailsMessage, NullContract} from 'src/app/shared/models/card-details-message';
import {CardDecodeResponse} from 'src/app/shared/models/card-decode-response';
import {LoadTitleService} from 'src/app/core/services/load-title.service';
import {Subscription} from 'rxjs';
import {ShoppingCartTable} from './interface/shopping-cart.interface';
import {EnvironmentUtil} from '../../../../../environments/environment-util';
import {SpinService} from 'src/app/core/services/spin.service';
import {CancelShoppingCartItemRequest} from '../../../../shared/models/cancel-shoppingcart-item-request';
import {FamilyPassService} from 'src/app/modules/load/pages/lisboa-viva/family-pass/service/family-pass.service';
import {PreviousContractResponse} from '../../../../shared/models/previous-contract-response';
import {LoadTitleTransactionType} from 'src/app/shared/enum/load-title-transaction-type.enum';
import {CustomerDetailsDTO} from '../replacement-guide/model/create-repl-guide-request';
import {HandlerComponent, SocketClientService} from '../../../../core/services/websocket-client.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { RegisterSaleErrorTypeEnum } from 'src/app/shared/enum/register-sale-error.enum';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.less'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy, HandlerComponent {
    private VV_CARD_SLOT = 1;
    private shoppingCartSubscription: Subscription;
    public shoppingCartTable: ShoppingCartTable[] = [];
    public productsTableHeader: TableHeaderData[] = [];
    public productsTableColumn: TableColumn[] = [];

    shoppingCart: ShoppingCart = {} as ShoppingCart;
    navigation: Navigation;
    sale: Sale;
    tabs: string[] = [];
    isVV: boolean;
    peripheralId = '';
    cardSerialNumberFromVoucher: string;

    // Used for special case when cart only contains annul itens
    // (it shows modal to confirm return modal and process register ssale)
    shoppingCartOnlyHasAnnuls: boolean = false;
    isContinueDisabled: boolean;
    showContinueButton: boolean = false;
    showPaymentButtons: boolean = false;
    showButtonExceptProd: boolean = false;
    isModalVisible: boolean = false;
    refundTotal: number;

    constructor(
        private router: Router,
        private shiftService: ShiftService,
        private shoppingCartService: ShoppingCartService,
        private alertService: AlertService,
        private storageManager: StorageManagerService,
        private navigationManager: NavigationManagerService,
        private modal: NzModalService,
        private saleService: SaleService,
        private peripheralService: PeripheralService,
        private loadTitleService: LoadTitleService,
        private spinService: SpinService,
        private familypassService: FamilyPassService,
        private socketClient: SocketClientService
    ) {
        this.socketClient.setHandlerComponent(this);
        this.spinService.setSpinValue(true);
        this.navigation = this.router.getCurrentNavigation();

        if (this.navigation?.extras?.state?.shoppingCart) {
            this.shoppingCart = this.navigation.extras.state.shoppingCart;
        }

        this.shoppingCartSubscription = this.shoppingCartService.shoppingCartChange.subscribe(
            (data) => {
                if (data != null && data.showHeader) {
                    this.setTableData(data.listResult);
                    this.shoppingCart.products = data.listResult.map((it) => ({
                        id: it.id,
                        description: it.description,
                        quantity: it.quantity,
                        price: it.price,
                        cardNumber: it.cardNumber,
                        cardSerialNumber: it.cardSerialNumber,
                        voucherId: it.voucherId ? it.voucherId : 0,
                        titleId: it.titleId,
                        nullable: it.nullable,
                        authId: it.authId,
                        titleGroup: it.titleGroupId,
                        loadId: it.loadId,
                        fineId: it.fineId,
                        tickCode: it.tickCode,
                        tickOperCode: it.tickOperCode,
                        transactionTypeId: it.transactionTypeId
                    }));
                    this.shoppingCart.total = data.totalValue;
                    this.shoppingCart.totalQt = data.totalQt;
                    this.shoppingCart.totalToPay = data.totalToPay;
                    this.shoppingCart.shiftId = Number(shiftService.getShiftID());
                    this.shoppingCart.cartId = data.cartId;

                    if (this.shoppingCart?.products?.length > 0) {
                        this.shoppingCartOnlyHasAnnuls = !this.shoppingCart?.products.some(shoppingCartItem => shoppingCartItem.quantity > 0);
                        // Transfers, Auths and Guides cost 0€ every other has price, including annuls
                        const shoppingCartIsVend = this.shoppingCart?.products.some(shoppingCartItem => shoppingCartItem.transactionTypeId == LoadTitleTransactionType.TRANSACTION_VEND ||
                            shoppingCartItem.transactionTypeId == LoadTitleTransactionType.TRANSACTION_VEND_CARR);
                        if (shoppingCartIsVend) {
                            this.showContinueButton = false;
                            this.showPaymentButtons = true;
                        } else {
                            const shoppingCartOnlyHasAnnuls = this.shoppingCart?.products.some(shoppingCartItem => shoppingCartItem.transactionTypeId == LoadTitleTransactionType.TRANSACTION_ANUL_VEND ||
                                shoppingCartItem.transactionTypeId == LoadTitleTransactionType.TRANSACTION_ANUL_VEND_CARR);
                            if (shoppingCartOnlyHasAnnuls) {
                                this.refundTotal = -this.shoppingCart.total;
                            }
                            this.showContinueButton = true;
                            this.showPaymentButtons = false;
                        }
                    }
                } else {
                    this.showContinueButton = false;
                    this.showPaymentButtons = false;
                }
            },
            () => {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
            }
        );
    }

    private setTableData(data: any[]) {
        this.shoppingCartTable = data.map((it) => ({
            id: it.id,
            description: it.description,
            cardNumber: it.cardNumber,
            cardSerialNumber: it.cardSerialNumber,
            quantity: it.quantity,
            price: it.price,
            nullable: it.nullable,
        }));
    }

    async ngOnInit() {
        await this.shoppingCartService.list(this.shiftService.getShiftID());
        this.peripheralId = await this.peripheralService.getPeripheralId();
        this.showButtonExceptProd = !EnvironmentUtil.isProd();

        this.prepareTabs();
        this.prepareTableResult();
        const card = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        this.isVV = CardUtils.IsVV(card?.details?.environment?.card_data_model);
        this.spinService.setSpinValue(false);
    }

    ngOnDestroy() {
        this.shoppingCartSubscription?.unsubscribe();
    }

    async onConnect() {
        this.spinService.setSpinValue(false)
    }

    async handleMessage(messageBody: WebsocketMessage) {

        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId).toPromise();
                if (!response.success) {
                    this.alertService.error(response.detail);
                }
                break;
        }
    }

    prepareTableResult() {
        this.productsTableColumn = [
            { title: 'DESCRIÇÃO', config: { colAlign: 'left', colWidth: '10%' } },
            { title: null, config: { colAlign: 'left', colWidth: '30%' } },
            { title: 'Nº SÉRIE', config: { colAlign: 'left', colWidth: '30%' } },
            { title: 'QTD.', config: { colAlign: 'right', colWidth: '10%' } },
            { title: 'VALOR', config: { colAlign: 'right', colWidth: '10%' } },
        ];

        this.productsTableHeader = [
            {
                visible: false,
                name: 'id',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                config: { valAlign: 'center', money: false },
                button: {
                    widthButton: '46px',
                    iconType: 'delete-icon icon-erro_fechar',
                    checkVisibilityField: 'nullable',
                    buttonCSS: 'delete-button'
                } as TableHeaderDataButton
            } as TableHeaderData,
            {
                visible: true,
                name: 'description',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'cardSerialNumber',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'quantity',
                config: { valAlign: 'right', money: false },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'right', money: true },
                button: null,
            } as TableHeaderData,
        ];
    }

    async loadListResult() {
        await this.shoppingCartService.list(this.shiftService.getShiftID());
    }

    prepareTabs() {
        this.tabs = ['Um modo de pagamento', 'Vários modos de pagamento'];
    }

    async otherOperClick() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    async goToPaymentMultibanco() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountMoney: 0,
            amountTpa: this.shoppingCart.totalToPay,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentMultibanco);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_MULTIBANCO);
    }

    async goToPaymentMoney() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountMoney: this.shoppingCart.totalToPay,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentMoney);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_MONEY);
    }

    async goToPaymentSiit() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountMoney: 0,
            amountTpa: 0,
            amountOther: this.shoppingCart.totalToPay,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentSiit);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_SIIT);
    }

    async goToPaymentCredit() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountMoney: 0,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: this.shoppingCart.totalToPay,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentCredit);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_CREDIT);
    }

    async goToPaymentPrePayment() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountMoney: 0,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: this.shoppingCart.totalToPay,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentPrePayment);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_PRE_PAYMENT);
    }


    async goToPaymentMultiple() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.totalToPay,
            amountTpa: 0,
            amountMoney: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentMultiple);
        await this.navigationManager.go(TicketingRoutes.PAYMENT_MULTIPLE);
    }

    async onCancelItem(event) {

        this.spinService.setSpinValue(true);

        const cancelRequest: CancelShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            shoppingCartId: this.shoppingCart.cartId,
            itemId: event.value
        };

        const titleToAnnul: ShoppingCartProduct = this.shoppingCart.products.find((x) => x.id === event.value);

        if (!titleToAnnul.loadId) {
            // VEND
            console.log('Going to anull vend ' + titleToAnnul.id);

            await this.shoppingCartService.cancelItem(cancelRequest);
            this.spinService.setSpinValue(false);
            await this.shoppingCartService.list(this.shiftService.getShiftID());
        } else {
            // VEND_CARR

            this.alertService.info(CommonEnum.msgInfoDontRemoveCard, false, true, 0);

            // compare detected card serial with titleToAnnul serial number.
            const detectResponse = await this.peripheralService.detectCard();
            if (!detectResponse.success || detectResponse.data.lowPart !== titleToAnnul.cardSerialNumber) {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgCardDifferentAnnulError);
                return;
            }

            // GET PREV CONTRACT FROM ANNUL LOAD TITLE
            const prevContract = await this.loadTitleService.getPrevContract(titleToAnnul.loadId);

            if (prevContract != null) {

                if (titleToAnnul.cardSerialNumber === Number(prevContract.cardSerialNumber) &&
                    detectResponse.data.lowPart === Number(prevContract.cardSerialNumber)) {
                    // replace with previous contract
                    await this.replaceWithPreviousContract(prevContract, titleToAnnul.loadId, cancelRequest);
                } else {
                    this.alertService.error(CommonEnum.msgCardDifferentAnnulError);
                    this.spinService.setSpinValue(false);
                }
            } else {
                // does not have a previous contract to replace

                (this.isVV)
                    ? await this.handleVVItemCancel(titleToAnnul.loadId, cancelRequest)
                    : await this.handleLVItemCancel(titleToAnnul.loadId, cancelRequest);
            }
        }
    }

    async hasNewProducts() {
        this.shoppingCart.payment = {
            total: this.shoppingCart.total,
            amountMoney: this.shoppingCart.total,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0,
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);
        this.storageManager.session.set(PaymentEnum.PaymentType, PaymentEnum.PaymentMoney);
        await this.navigationManager.go(TicketingRoutes.INVOICE);
    }

    async onContinueClick() {
        if (this.refundTotal != null) {
            this.isModalVisible = true;
        } else {
            await this.registerSale();
        }
    }

    onCloseModal($event) {
        this.isContinueDisabled = false;
        this.isModalVisible = false;
    }

    async handleConfirmModal($event) {
        this.spinService.setSpinValue(true);
        this.isModalVisible = false;
        this.shoppingCart.payment = {
            total: this.shoppingCart.total,
            amountMoney: this.shoppingCart.total,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: this.refundTotal
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);

        this.modal.closeAll();

        this.prepareCreditNote();
        const getStoreInfo = await this.peripheralService.getInfoFromMachine();
        const getOpNumber = await this.shiftService.getShiftInfo(Number(this.shiftService.getShiftID())).toPromise() as any;
        this.sale.storeName = getStoreInfo.storeName;
        this.sale.storeAddress = getStoreInfo.address;
        this.sale.storeTicketingNumber = getStoreInfo.machineNumber;
        this.sale.operatorNumber = getOpNumber.data.ownerId;
        const printDocumentRequest = {
            duplicate: false,
            additionalCopies: this.sale.receiptQuantity,
            deviceId: this.sale.deviceId,
            operatorNumber: getOpNumber.data.ownerId
        };
        const response = await this.saleService.register(this.sale, printDocumentRequest) as any;
        this.spinService.setSpinValue(false);
        if (!response.success) {
            return await this.processUnsucessfullRegisterResponse(response);
        }
        this.alertService.success('Carrinho de compras pago com sucesso');
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    prepareCreditNote() {
        this.sale = {
            deviceId: this.peripheralId,
            cartId: this.shoppingCart.cartId,
            client: {} as SaleClient,
            payments: [
                {
                    paymentMethodId: EnvironmentUtil.getEnv().moneyMethodId, // Money
                    value: this.shoppingCart.payment.amountMoney
                }
            ],
            vatAssociation: {
                associate: false,
                predefined: false
            } as SaleVatAssociation,
            vatInternational: false,
            receiptQuantity: 0
        } as Sale;
    }

    private getVoucherSerialNumber(cardSerialNumber: number): string {
        this.familypassService.getVouchersBySerialNumber(cardSerialNumber).then((request: any) => {
            if (request.voucher[0].card.serialNumber != null) {
                return this.cardSerialNumberFromVoucher = request.voucher[0].card.serialNumber.toString();
            }
        });
        return this.cardSerialNumberFromVoucher;
    }

    private async replaceWithPreviousContract(
        prevContract: PreviousContractResponse,
        loadId: number,
        cancelRequest: CancelShoppingCartItemRequest
    ) {
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.ReloadPrevContract);
        this.storageManager.session.set(ReadingLoadingEnum.ReloadContractRequest, prevContract);

        let detectResponse = await this.peripheralService.detectCard();
        if (!detectResponse.success) {
            this.alertService.error(detectResponse.message);
            this.spinService.setSpinValue(false);
            return;
        }
        await this.loadTitleService.deleteLoadTitle(loadId);

        detectResponse = await this.peripheralService.detectCard();
        if (!detectResponse.success) {
            this.alertService.error(detectResponse.message);
            this.spinService.setSpinValue(false);
            // revert load deleted
            await this.loadTitleService.enableLoad(loadId);
            return;
        }
        await this.shoppingCartService.cancelItem(cancelRequest);

        this.spinService.setSpinValue(false);
        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV);
    }

    private async handleVVItemCancel(loadId: number, cancelRequest: CancelShoppingCartItemRequest) {

        // fetch current load to get it previous contract base contract id
        let load = await this.loadTitleService.getLoadById(loadId);

        if (!load) {
            // revert load deleted
            await this.loadTitleService.enableLoad(loadId);
            load = await this.loadTitleService.getLoadById(loadId);
        }

        const detectResponse = await this.peripheralService.detectCard();
        if (!detectResponse.success) {
            this.alertService.error(detectResponse.message);
            this.spinService.setSpinValue(false);
            return;
        }


        await this.loadTitleService.deleteLoadTitle(loadId);

        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
        const replacedCardFullDetails = this.storageManager.session
            .get(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;

        // force another detect to make sure that we have a card to annul
        const response = await this.peripheralService.detectCard();
        if (!response.success) {
            this.alertService.error(response.message);
            this.spinService.setSpinValue(false);
            // revert load deleted
            await this.loadTitleService.enableLoad(loadId);
            return;
        }
        let result;
        // vv is not initialized so reset the card
        if (CardDataModel.K_CARD_DATAMODEL_UNKNOWN === replacedCardFullDetails.details.environment.card_data_model
            || NullContract.NULL_CONTRACT_ID === load.previousContract.baseContractId) {
            // reset card ( don't need to go reading-loading-lv with action )
            result = await this.shoppingCartService.reset(cardBinary);
        } else {
            // vv is initizalized so annul contract
            const currentCardBinary = response.data;
            result = await this.shoppingCartService.null(this.VV_CARD_SLOT, currentCardBinary);
        }

        this.spinService.setSpinValue(false);

        if (!result) {
            this.alertService.error(CommonEnum.msgAnullShoppingItemAnnulError);
            // revert load deleted
            await this.loadTitleService.enableLoad(loadId);
        } else {
            // remove item from SC
            await this.shoppingCartService.cancelItem(cancelRequest);
            await this.navigationManager.go(TicketingRoutes.HOME);
        }
    }

    private async handleLVItemCancel(loadId: number, cancelRequest: CancelShoppingCartItemRequest) {
        // replace contract with null contract (normal flow)
        // OR use null endpoint to remove that contract and then delete loadTitleId and remove from SC
        const cardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;

        // fetch current load to get it's contract slot
        let load = await this.loadTitleService.getLoadById(loadId);

        if (!load) {
            // revert load deleted
            await this.loadTitleService.enableLoad(loadId);
            load = await this.loadTitleService.getLoadById(loadId);
        }

        let detectResponse = await this.peripheralService.detectCard();
        if (!detectResponse.success) {
            this.alertService.error(detectResponse.message);
            this.spinService.setSpinValue(false);
            return;
        }
        await this.loadTitleService.deleteLoadTitle(load.id);

        // force another detect to make sure that we have a card to annul
        detectResponse = await this.peripheralService.detectCard();
        if (!detectResponse.success) {
            this.alertService.error(detectResponse.message);
            this.spinService.setSpinValue(false);
            // revert load deleted
            await this.loadTitleService.enableLoad(load.id);
            return;
        }
        // nullify slot ( don't need to go reading-loading-lv with action )
        const result = await this.shoppingCartService.null(load.cardSlot, cardBinary);

        this.spinService.setSpinValue(false);

        if (!result) {
            this.alertService.error(CommonEnum.msgAnullShoppingItemAnnulError);
            // revert load deleted
            await this.loadTitleService.enableLoad(load.id);
        } else {
            // remove item from SC
            await this.shoppingCartService.cancelItem(cancelRequest);
            await this.navigationManager.go(TicketingRoutes.HOME);
        }
    }

    private async registerSale() {
        this.spinService.setSpinValue(true);
        const customerDetails = this.storageManager.session.get(ReadingLoadingEnum.CustomersDetails) as CustomerDetailsDTO;

        this.shoppingCart.payment = {
            total: this.shoppingCart.total,
            amountMoney: this.shoppingCart.total,
            amountTpa: 0,
            amountOther: 0,
            amountCredit: 0,
            amountPrePayment: 0,
            pendingAmount: 0,
            returnAmount: 0
        };

        this.storageManager.session.set(PaymentEnum.ShoppingCart, this.shoppingCart);

        this.sale = {
            deviceId: this.peripheralId,
            cartId: this.shoppingCart.cartId,
            client: {} as SaleClient,
            payments: [
                {
                    paymentMethodId: EnvironmentUtil.getEnv().moneyMethodId, // Money
                    value: this.shoppingCart.payment.amountMoney
                }
            ],
            vatAssociation: {
                associate: false,
                predefined: false
            } as SaleVatAssociation,
            vatInternational: false,
            receiptQuantity: 0,
            customerGuideInfoName: customerDetails?.name,
            customerGuideInfoPhoneNumber: customerDetails?.phoneNumber,
            customerGuideInfoDocumentType: customerDetails?.customerDocumentType,
            customerGuideInfoDocumentNumber: customerDetails?.customerDocumentNumber,
            customerGuideInfoNotes: customerDetails?.notes
        } as Sale;

        const getStoreInfo = await this.peripheralService.getInfoFromMachine();
        const getOpNumber = await this.shiftService.getShiftInfo(Number(this.shiftService.getShiftID())).toPromise() as any;
        this.sale.storeName = getStoreInfo.storeName;
        this.sale.storeAddress = getStoreInfo.address;
        this.sale.storeTicketingNumber = getStoreInfo.machineNumber;
        this.sale.operatorNumber = getOpNumber.data.ownerId;
        const printDocumentRequest = {
            duplicate: false,
            additionalCopies: this.sale.receiptQuantity,
            deviceId: this.sale.deviceId,
            operatorNumber: getOpNumber.data.ownerId,
            customerNameGuide: customerDetails?.name,
            customerPhoneNumberGuide: customerDetails?.phoneNumber,
            customerDocumentTypeGuide: customerDetails?.customerDocumentType,
            customerDocumentNumberGuide: customerDetails?.customerDocumentNumber,
            customerNotesGuide: customerDetails?.notes
        };
        const response = await this.saleService.register(this.sale, printDocumentRequest) as any;
        this.spinService.setSpinValue(false);
        if (!response.success) {
            return await this.processUnsucessfullRegisterResponse(response);
        }
        this.alertService.success(CommonEnum.msgSaleSucess);
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    private async processUnsucessfullRegisterResponse(response: any) {
        this.alertService.error(response.content, true);
        if (RegisterSaleErrorTypeEnum.PRINT === response?.error) {
            this.shoppingCartService.resetShoppingCartHeader();
            await this.navigationManager.go(TicketingRoutes.HOME);
        }
        await this.shoppingCartService.list(this.shiftService.getShiftID());
        return;
    }

}
