import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { DocumentsFilteredRequest } from 'src/app/shared/models/documents-filtered-request';
import { SaleDocument, SaleDocumentResponse } from 'src/app/shared/models/document-response';
import { TableHeaderDataButton } from 'src/app/shared/models/table-header-data-button';
import { CommonEnum } from '../../../../shared/enum/common.enum';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { PageableResponse } from 'src/app/shared/models/pageable-response';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { SpinService } from 'src/app/core/services/spin.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.less'],
})
export class DocumentsComponent implements OnInit {
    dropdownList: object[] = [
        {
            label: 'Pesquise por Documento',
            value: 'Nº de Documento',
        },
        {
            label: 'Pesquise por Cartão',
            value: 'Nº de Cartão',
        },
    ];

    private docTypeDropdown = 'FS';
    public labelDropdown = 'Nº de Documento';
    public placeholder = 'Pesquise por Número';
    public input: string;
    public startDate = new Date();
    public endDate = new Date();

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    private documentsResponse: SaleDocumentResponse;
    public pageableResponse: PageableResponse;
    public listDocuments: SaleDocument[] = [];

    public dataTotal: number;
    public pageIndex: number;
    public isSpinning: boolean;
    public destroyer$: Subject<void> = new Subject();

    constructor(
        private documentsService: DocumentsService,
        private alertService: AlertService,
        private location: Location,
        public datepipe: DatePipe,
        private spinService: SpinService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.spinService.myVar.pipe(takeUntil(this.destroyer$)).subscribe(myVar => this.isSpinning = myVar);
        this.endDate.setHours(23, 59, 0);
        this.startDate.setDate(this.endDate.getDate() - 1);
        this.startDate.setHours(0, 0, 0);
        this.prepareTableResult();
        await this.loadDocuments();
        this.spinService.setSpinValue(false);
    }

    public onChangedDropdown(value: string): void {
        this.labelDropdown = value;
    }

    public onChangedInput(value: string): void {
        this.input = value;
    }

    public onChangedStartDate(value: Date): void {
        this.startDate = value == null ? null : DateUtils.setTimeTo0(value);
    }

    public onChangedEndDate(value: Date): void {
        this.endDate = value == null ? null : DateUtils.setTimeTo235959(value);
    }

    public async onIndexChange(value: any): Promise<void> {
        this.spinService.setSpinValue(true);
        this.pageIndex = value;
        this.alertService.clear();
        this.documentsService.callLoadDocuments.subscribe(
            (response: BasicDataResponse) => {
                this.documentsResponse = response.data as SaleDocumentResponse;
                if (!this.documentsResponse) {
                    this.alertService.error(CommonEnum.msgDocNotFoundError);
                    this.spinService.setSpinValue(false);
                }
                this.pageableResponse = this.documentsResponse?.pageable ? this.documentsResponse?.pageable : {} as PageableResponse;
                this.dataTotal = response?.data?.totalElements;
                this.listDocuments = this.documentsResponse?.content;
                this.spinService.setSpinValue(false);
            },
            (err) => {
                this.spinService.setSpinValue(false);
                console.log('HTTP Error', err);
                if (this.labelDropdown === 'Nº de Documento' || this.labelDropdown === 'Nº de Cartão') {
                    this.alertService.error(CommonEnum.msgDocNotFoundForSpecificDates);
                    this.spinService.setSpinValue(false);
                }
            }
        );
        let request: DocumentsFilteredRequest;
        request = {
            providerDocumentNumber: this.labelDropdown === 'Nº de Documento' ? this.input : null,
            documentType: this.docTypeDropdown,
            cardNumberOrSerialNumber: this.labelDropdown === 'Nº de Cartão' ? this.input : null,
            endDate: this.endDate != null ? this.datepipe.transform(this.endDate, 'yyyy-MM-dd') : null,
            startDate: this.startDate != null ? this.datepipe.transform(this.startDate, 'yyyy-MM-dd') : null,
            pageSize: 5,
            page: value - 1,
            sorters: []
        };
        await this.documentsService.getDocuments(request);
        this.spinService.setSpinValue(false);
    }

    public async loadDocuments(): Promise<void> {
        this.alertService.clear();
        this.spinService.setSpinValue(true);
        if (this.verifyDates(this.startDate, this.endDate)) {
            this.documentsService.callLoadDocuments.subscribe(
                (response: BasicDataResponse) => {
                    this.alertService.clear();
                    this.documentsResponse = response?.data as SaleDocumentResponse;
                    if (!this.documentsResponse) {
                        this.alertService.error(CommonEnum.msgDocNotFoundError);
                        this.spinService.setSpinValue(false);
                    }
                    this.pageableResponse = this.documentsResponse?.pageable ? this.documentsResponse?.pageable : {} as PageableResponse;
                    this.dataTotal = response?.data?.totalElements;
                    this.listDocuments = this.documentsResponse?.content;
                    this.spinService.setSpinValue(false);
                },
                (err) => {
                    console.log('HTTP Error', err);
                    if (this.labelDropdown === 'Nº de Documento' || this.labelDropdown === 'Nº de Cartão') {
                        this.alertService.error(CommonEnum.msgDocNotFoundError);
                        this.spinService.setSpinValue(false);
                    }
                }
            );
            const afterDate: Date = new Date();
            let request: DocumentsFilteredRequest;
            if (this.endDate) {
                afterDate.setDate(this.endDate?.getDate());
            }
            request = {
                providerDocumentNumber: this.labelDropdown === 'Nº de Documento' ? this.input : null,
                documentType: this.docTypeDropdown,
                cardNumberOrSerialNumber: this.labelDropdown === 'Nº de Cartão' ? this.input : null,
                endDate: this.endDate != null ? this.datepipe.transform(this.endDate, 'yyyy-MM-dd') : null,
                startDate: this.startDate != null ? this.datepipe.transform(this.startDate, 'yyyy-MM-dd') : null,
                pageSize: 5,
                page: 0,
                sorters: []
            };
            await this.documentsService.getDocuments(request);
            this.pageIndex = 1;
        }
    }

    private verifyDates(startDateVerify: Date, endDateVerify: Date): boolean {
        if (!startDateVerify && !endDateVerify) {
            this.endDate = new Date();
            this.endDate.setHours(23, 59, 0);
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - 90);
            this.startDate.setHours(0, 0, 0);
            this.alertService.info(CommonEnum.msgInfoBothDatesEmpty);
        }
        if (this.startDate != null && this.endDate == null || this.startDate == null && this.endDate != null) {
            this.alertService.error(CommonEnum.msgFillBothDatesError);
            this.spinService.setSpinValue(false);
            return false;
        } else if (this.datepipe.transform(startDateVerify, 'yyyy-MM-dd HH:mm:ss') > this.datepipe.transform(endDateVerify, 'yyyy-MM-dd HH:mm:ss')) {
            this.alertService.error(CommonEnum.msgInitialDateOverFinalDate);
            this.spinService.setSpinValue(false);
            return false;
        } 
        return true;
    }
    


    public clearSearch(): void {
        this.input = null;
        this.startDate = null;
        this.endDate = null;
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: 'Nº Documento', config: { colAlign: 'left', colWidth: '20%' } },
            { title: 'Data', config: { colAlign: 'left', colWidth: '23%' } },
            { title: 'Item', config: { colAlign: 'left', colWidth: '25%' } },
            { title: 'Quantidade', config: { colAlign: 'center', colWidth: '9%' } },
            { title: 'Valor', config: { colAlign: 'center', colWidth: '15%' } },
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                name: 'providerDocumentNumber',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'saleDate',
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'itemDescription',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'quantity',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'saleValue',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: null,
                config: { valAlign: 'center', money: false },
                button: {
                    titleButton: 'Emitir 2ª Via',
                    iconType: 'icon-imprimir icon-print',
                    buttonCSS: 'button-print'
                } as TableHeaderDataButton,
            } as TableHeaderData,
        ];
    }

    public previousView(): void {
        this.location.back();
    }

    public async onButtonClickPrintReceipt(event): Promise<void> {
        const deviceId = sessionStorage.getItem(CommonEnum.peripheralId);
        await this.documentsService.printReceipt({ id: event.value, deviceId, data: event.data });
    }

    public ngOnDestroy(): void {
        this.destroyer$.next();
        this.destroyer$.complete();
    }

}
