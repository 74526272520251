import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.less',
              '../../../modules/home/pages/home/home.component.less',
              '../../../modules/operation/pages/shopping-cart/shopping-cart.component.less'],

})
export class PrimaryButtonComponent implements OnInit {
  @Input() color = '';
  @Input() disabled;
  @Input() border = '';
  @Input() buttonCSS = 'button-primary-label';
  @Output() onClick = new EventEmitter<any>();

  @Input() critical: boolean = false;

  onClickButton(event) {
    if(this.critical){
      this.disabled = true;
    }
    this.onClick.emit(event);
  }

  constructor() {}

  ngOnInit(): void {}
}
