import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderDataConfig } from 'src/app/shared/models/table-header-data-config';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { NavigationExtras, Router } from '@angular/router';
import { TableColumnConfig } from 'src/app/shared/models/table-column-config';
import { Load } from '../models/load';
import { CardReadTitle } from 'src/app/shared/models/card-read-title';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { TitleDTO } from 'src/app/shared/models/title-dto';

@Component({
    selector: 'app-change-title',
    templateUrl: './change-title.component.html',
    styleUrls: ['./change-title.component.less']
})
export class ChangeTitleComponent implements OnInit {

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];

    public data: CardReadTitle[] = [];
    private load: Load;

    constructor(
        private router: Router,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService
    ) {
        if (!this.router.getCurrentNavigation()?.extras?.state) {
            this.homeScreen();
        }
        if (this.router.getCurrentNavigation().extras.state?.titleToExchange) {
            const titleToExchange = this.router.getCurrentNavigation().extras.state?.titleToExchange;
            titleToExchange.titlePrice = titleToExchange.titlePrice * titleToExchange.loadAmount;
            this.data.push(titleToExchange);
        }
        if (this.router.getCurrentNavigation()?.extras?.state?.load?.cardRead) {
            this.load = this.router.getCurrentNavigation().extras.state.load;
        }
        this.prepareTableResult();
    }

    ngOnInit(): void { }

    // Table
    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: 'Descrição', config: { colAlign: 'left', colWidth: '40%' } as TableColumnConfig } as TableColumn,
            { title: null, config: { colAlign: 'right', colWidth: '10%' } as TableColumnConfig } as TableColumn,
            { title: null, config: { colAlign: 'right', colWidth: '10%' } as TableColumnConfig } as TableColumn,
            { title: null, config: { colAlign: 'right', colWidth: '10%' } as TableColumnConfig } as TableColumn,
            { title: null, config: { colAlign: 'right', colWidth: '10%' } as TableColumnConfig } as TableColumn,
            { title: 'Valor', config: { colAlign: 'right', colWidth: '20%' } as TableColumnConfig } as TableColumn,
        ];
        this.listTitleHeader = [
            { visible: true, name: 'description' } as TableHeaderData,
            { visible: true, name: null } as TableHeaderData,
            { visible: true, name: null } as TableHeaderData,
            { visible: true, name: null } as TableHeaderData,
            { visible: true, name: null } as TableHeaderData,
            { visible: true, name: 'titlePrice', config: { valAlign: 'right', money: true } as TableHeaderDataConfig } as TableHeaderData,
        ];
    }

    // Navigation
    public async previousView(): Promise<void> {
        const cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, { card: cardDetails });
    }

    private homeScreen(): void {
        this.router.navigate(['home']);
    }

    public nextView(): void {
        const title = this.data[0];
        const titleToExchange: TitleDTO = {
            id: title.id,
            description: title.description,
            tickOperCode: title.tickOperCode,
            tickCode: title.tickCode,
            price: title.titlePrice,
            amount: title.loadAmount,
            discount: title.discount
        } as TitleDTO;
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true,
            state:
            {
                titleDetails: titleToExchange,
                load: this.load
            }
        };
        this.router.navigate([TicketingRoutes.TITLE_SELECTION], navigationExtras);
    }

}
