import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-normal-button',
  templateUrl: './primary-normal-button.component.html',
  styleUrls: ['./primary-normal-button.component.less',
              '../../../modules/home/pages/login/login.component.less',
              '../../../modules/load/pages/zapping/zapping.component.less',
              '../../../modules/operation/pages/payment-money/payment-money.component.less',
              '../../../modules/operation/pages/invoice/invoice.component.less'],
})
export class PrimaryNormalButtonComponent implements OnInit {
  @Input() color = '';
  @Input() color2 = '';
  @Input() back = '';
  @Input() borderColor = '';
  @Input() fontSize = '14px';
  @Input() width = '';
  @Input() height = '';
  @Input() buttonCSS = 'normal';
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Output() onClick = new EventEmitter();

  @Input() critical: boolean = false;

  onClickButton(event) {
    if(this.critical){
      this.disabled = true;
    }
    this.onClick.emit({ event, value: this.value });
  }

  constructor() {}

  ngOnInit(): void {}
}
