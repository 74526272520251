<label [ngClass]="labelCss">{{label}}</label>
<div nz-row [ngClass]="inputBorder">
  <div nz-col nzSpan="4" *ngIf="inputIcon">
    <span [ngClass]="inputIcon"></span>
  </div>
  <div nz-col nzSpan="20" [ngClass]="inputDivCSS">
    <app-primary-text-area *ngIf="isObservation"
      [(value)]="value"
      [maxLength]="maxlength"
      (valueChange)="onTextAreaChange($event)"
      [textAreaCSS]="inputCss"
      (focus)="focus()">
    </app-primary-text-area>
    <input *ngIf="isMoney"
      [type]="inputType"
      [(ngModel)]="value"
      [ngClass]="inputCss"
      (input)="onMoneyInputChange($event)"
      [mask]="'0*,00'"
      [patterns]="customPattern"
      [decimalMarker]="decimalMarker"
      [placeholder]="inputPlaceholder"
      (dblclick)="dblclick()"
      (focus)="focusMoney()"
    >

    <input *ngIf="!isMoney && !isObservation"
    [type]="inputType"
    [(ngModel)]="value"
    [ngClass]="inputCss"
    (input)="onInputChange($event)"
    [placeholder]="inputPlaceholder"
    [maxlength]="maxlength"
    [mask]="mask"
    (focus)="focus()"
    (keypress)="enterclick($event.keyCode)"
    [disabled]="isDisabled"
    >
    <span *ngIf="isMoney" >€</span>
  </div>
</div>
