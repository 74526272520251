import { LoadTitleTransactionType } from './../../../../../shared/enum/load-title-transaction-type.enum';
import { TariffService } from 'src/app/core/services/tariff.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ShiftService } from 'src/app/core/services/shift.service';
import { Component, OnInit } from '@angular/core';
import { CustomerDetailsDTO, ListOfDocuments, ReplacementGuideDTO } from '../model/create-repl-guide-request';
import { LoadTitleRequest } from 'src/app/shared/models/load-title-request';
import { ShoppingCartItemRequest } from 'src/app/shared/models/shopping-cart-item-request';
import { ReadingLoadingEnum } from 'src/app/shared/enum/reading-loading.enum';
import { NotificationDuration } from '../../../../../shared/enum/notification-duration.enum';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { SaleService } from 'src/app/core/services/sale.service';
import { TitleDTO } from 'src/app/shared/models/title-dto';
import { CardRead } from 'src/app/shared/models/card-read';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { CCValidation } from '../../../modules/cc-validation';


const INVALID_IDENTIFICATION_DOCUMENT_MSG = 'Nº Doc. Identificação inválido';

@Component({
    selector: 'app-substitution-guide',
    templateUrl: './substitution-guide.component.html',
    styleUrls: ['./substitution-guide.component.less'],
})
export class SubstitutionGuideComponent implements OnInit {

    listDocs: ListOfDocuments[] = [
        { label: 'BI', value: 'BI' },
        { label: 'CC', value: 'CC' },
        { label: 'Passaporte', value: 'PASSPORT' },
    ];

    public labelDropdownDocumentNumber = 'Nº Doc. Identificação';
    public placeholderDocument = 'Digite o número';
    disabled = true;

    customerDetailsDTO: CustomerDetailsDTO = {} as CustomerDetailsDTO;
    replacementGuideDTO: ReplacementGuideDTO = {} as ReplacementGuideDTO;
    title: TitleDTO = {} as TitleDTO;
    isChecked = true;
    transfLoadId: number;

    isIdentificationDocumentValid = true;

    constructor(
        private peripheralService: PeripheralService,
        private alertService: AlertService,
        private shiftService: ShiftService,
        private saleService: SaleService,
        private navigationManager: NavigationManagerService,
        private storageManager: StorageManagerService,
        private tariffService: TariffService
    ) {
        if (this.navigationManager.getNavigation()?.extras?.state?.replacementGuide) {
            this.replacementGuideDTO = this.navigationManager.getNavigation().extras.state.replacementGuide;

            if (this.navigationManager.getNavigation()?.extras?.state?.transfLoadId) {
                this.transfLoadId = this.navigationManager.getNavigation().extras.state.transfLoadId;
            }
        } else {
            // this.homeScreen();
        }
    }

    async ngOnInit(): Promise<void> {
        await this.loadTitle();
        this.alertService.info(CommonEnum.msgSubstitutionGuideVVAlert, false, true, NotificationDuration.INFINITE_DURATION);

    }

    onChangeNameInputEvent(value: string) {
        this.customerDetailsDTO.name = value;
    }
    onChangePhoneNumberInputEvent(value: string) {
        this.customerDetailsDTO.phoneNumber = value;
    }

    onChangeDocumentType(event: string) {
        if (event) {
            this.customerDetailsDTO.customerDocumentType = this.listDocs.find((doc) => doc.value === event).label;
        } else {
            this.customerDetailsDTO.customerDocumentType = null;
        }
    }

    onChangeDocumentNumberInputEvent(value: string) {
        this.customerDetailsDTO.customerDocumentNumber = value;
    }

    onChangeObsInputEvent(value: string) {
        this.customerDetailsDTO.notes = value;
    }

    onCheckCaution(value: boolean) {
        this.isChecked = value;
    }

    checkIfCanGo() {
        let numberFilled = false;
        if (this.customerDetailsDTO.customerDocumentNumber) {
            numberFilled = true;
        }
        return (this.customerDetailsDTO.customerDocumentType == null || !numberFilled);
    }

    async homeScreen() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }

    public async previousClick() {
        const serialNumber = this.replacementGuideDTO.oldCard.serialNumber;
        await this.navigationManager.go(TicketingRoutes.REPLACEMENT_GUIDE_ISSUE_REPLACEMENT_GUIDE, { serialNumber });
    }

    verifyIdentificationDocumentCC(docNumber: string) {
        const request: CCValidation = {
            cc: docNumber
        };
        const isVatValidRequest = this.saleService.verifyIdentificationDocumentCC(request);
        return isVatValidRequest;
    }

    async conclusionClick() {
        if (this.customerDetailsDTO.customerDocumentType === CommonEnum.identificationDocumentTypeCC) {
            const response = await this.verifyIdentificationDocumentCC(this.customerDetailsDTO.customerDocumentNumber)
                .toPromise() as BasicDataResponse;
            this.isIdentificationDocumentValid = response.data as boolean;
        } else {
            this.isIdentificationDocumentValid = true;
        }

        if (!this.isIdentificationDocumentValid) {
            this.alertService.error(INVALID_IDENTIFICATION_DOCUMENT_MSG);
            return;
        }

        const loadAmount = this.replacementGuideDTO.loadAmount != null ?
            this.replacementGuideDTO.loadAmount :
            1;

        const peripheralId = await this.peripheralService.getPeripheralId();
        const cardRead = this.storageManager.session.get<CardRead>(CardEnum.CARD_READ) as CardRead;
        const shoppingCartRequest: ShoppingCartItemRequest = {
            shiftId: Number(this.shiftService.getShiftID()),
            titleId: this.replacementGuideDTO.title.titleId,
            description: this.title.description,
            quantity: loadAmount,
            price: 0,
            transactionTypeId: LoadTitleTransactionType.TRANSACTION_TRANSF,
            annulId: null,
            loadId: null,
            requisitionId: null,
            authId: null,
            replacementId: null,
            voucherId: 0,
            isAnnul: false,
            cardSerialNumber: null,
            cardNumber: null,
        };

        const loadTitleRequest: LoadTitleRequest = {
            peripheralId,
            tickCode: Number(this.title.tickCode),
            tickOperCode: Number(this.title.tickOperCode),
            id: this.replacementGuideDTO.title.titleId,
            shiftId: Number(this.shiftService.getShiftID()),
            transfLoadId: this.transfLoadId,
            serialNumber: null
        };

        if (cardRead) {
            shoppingCartRequest.cardSerialNumber = Number(cardRead.serialNumber);
            shoppingCartRequest.cardNumber = cardRead.number;
            loadTitleRequest.serialNumber = cardRead.serialNumber;
        } else {
            if (this.replacementGuideDTO.oldCard.cardNumber) {
                shoppingCartRequest.cardNumber = this.replacementGuideDTO.oldCard.cardNumber.toString();
            } else if (this.replacementGuideDTO.oldCard.serialNumber) {
                shoppingCartRequest.cardSerialNumber = this.replacementGuideDTO.oldCard.serialNumber;
                loadTitleRequest.serialNumber = this.replacementGuideDTO.oldCard.serialNumber.toString();
            }
        }

        this.replacementGuideDTO.warranty = this.isChecked;
        const currentCardBinary = this.storageManager.session.get(CardEnum.CARD_DETECTION_BINARY) as CardDecodeResponse;
        const currentCardFullDetails = this.storageManager.session.get(CardEnum.FULL_CARD_DETAILS) as CardDecodeResponse;
        this.storageManager.session.set(CardEnum.PREVIOUS_CARD_BINARY, currentCardBinary);
        this.storageManager.session.set(CardEnum.PREVIOUS_FULL_CARD_DETAILS, currentCardFullDetails);
        this.storageManager.session.set(ReadingLoadingEnum.ShoppingCartRequest, shoppingCartRequest);
        this.storageManager.session.set(ReadingLoadingEnum.LoadContractRequest, loadTitleRequest);
        this.storageManager.session.set(ReadingLoadingEnum.CreateReplGuideRequest, this.replacementGuideDTO);
        this.storageManager.session.set(ReadingLoadingEnum.Action, ReadingLoadingEnum.EmitGuide);
        this.storageManager.session.set(ReadingLoadingEnum.CustomersDetails, this.customerDetailsDTO);
        if (this.storageManager.session.get(ReadingLoadingEnum.FromReadingEmitGuide) == null) {
            this.storageManager.session.set(ReadingLoadingEnum.FromReadingEmitGuide, 'true');
        }

        await this.navigationManager.go(TicketingRoutes.READING_LOADING_LV_GUIDE_EMIT);
    }

    private async loadTitle() {
        const tariffBasicResponse = await this.tariffService.fetchTitleById(this.replacementGuideDTO.title.titleId).toPromise();

        if (tariffBasicResponse?.data != null) {
            this.title = tariffBasicResponse.data;
        }
    }
}
