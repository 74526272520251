<div class="invoice-content">
  <div nz-row nzJustify="left" class="m-t-5">
    <h1 class="reading">Detalhe da Emissão da Guia de Substituição</h1>
  </div>
  <div nz-row nzJustify="left">
    <h2 class="issue">
      Detalhes para emissão de guia de substituição lisboa VIVA/navegante personalizado nº {{replacementGuideDTO.oldCard.serialNumber}}
    </h2>
  </div>

  <div class="form-content m-t-20">
    <div nz-row>
      <div  class="inputs">
        <app-primary-input-keyboard
        label="Nome Cliente"
        labelCss="label-input"
        inputCss="name-input"
        inputBorder="name-input-border"
        [maxlength]="30"
        (valueChange)="onChangeNameInputEvent($event)"
        class="keyboard-class"
        ></app-primary-input-keyboard>
      </div>

      <div class="inputs contacts">
        <app-primary-input-keyboard
          label="Contacto"
          labelCss="label-input"
          inputCss="contact-input"
          keyboardType="number"
          inputBorder="contact-input-border"
          [maxlength]="10"
          (valueChange)="onChangePhoneNumberInputEvent($event)"
          class="keyboard-class">
        </app-primary-input-keyboard>
      </div>
    </div>

    <div nz-row class="div-input-document m-t-20">
      <div class="document-top">
        <label class="id label-document">Doc. Identificação</label>
        <app-primary-select
          [listOptions]="listDocs"
          (selectedChange)="onChangeDocumentType($event)"
          width="90%"
          border-top-right-radiu="0px !important"
          border-bottom-left-radius=" 0px !important"
        ></app-primary-select>
      </div>
      <div class="doc-number">
        <app-primary-input-keyboard
          class="class-keyboard-input"
          [label]="labelDropdownDocumentNumber"
          labelCss="label-input-document-number"
          [inputPlaceholder]="placeholderDocument"
          (valueChange)="onChangeDocumentNumberInputEvent($event)"
          inputCss="input-issue-replacement-document"
          keyboardType="number"
          height="52px"
          text-align="left">
        </app-primary-input-keyboard>
    </div>
  </div>

    <div class="div-input m-t-20">
        <div class="inputs" class="align-left">
          <app-primary-input-keyboard
          label="Observações"
          isObservation="true"
          labelCss="label-input"
          inputCss="input-obs"
          [maxlength]="60"
          (valueChange)="onChangeObsInputEvent($event)"
        ></app-primary-input-keyboard>
        </div>
      </div>
    </div>

    <div class="div-input">
      <div nz-row class="m-t-20">
          <label>CAUÇÃO</label>
      </div>
      <div nz-row class="m-t-22">
          <app-primary-checkbox  isChecked = "true" (isCheckedChange)="onCheckCaution($event)" label="SEM CAUÇÃO"></app-primary-checkbox>
      </div>
    </div>

  </div>

<div class="footer-buttons">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="9">
      <div nz-row nzJustify="start">
        <app-primary-white-button style="margin-right: 10px" (click)="previousClick()">
          <span class="btn-icon icon-anterior icon-middle float-left "></span>
          Voltar
        </app-primary-white-button>
        <app-primary-cancel-button (click)="homeScreen()">
          Cancelar
        </app-primary-cancel-button>
      </div>
    </div>

    <div nz-col nzSpan="4" (click)="conclusionClick()">
        <div nz-row style="padding-right: 48px" nzJustify="end">
          <app-primary-normal-button
          [disabled]="checkIfCanGo()"
          >Emitir Guia
        </app-primary-normal-button>
        </div>
    </div>
  </div>
</div>
