import {Subscription} from 'rxjs';
import {VoucherCancelService} from './service/voucher-cancel.service';
import {DetailsVoucherCancel} from './model/details-voucher-cancel';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderDataConfig} from 'src/app/shared/models/table-header-data-config';
import {AlertService} from 'src/app/core/services/alert.service';
import {Load} from 'src/app/modules/load/pages/lisboa-viva/models/load';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import {SpinService} from '../../../../core/services/spin.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { CancelFamilyShoppingCartItemRequest} from 'src/app/shared/models/cancel-shoppingcart-item-request';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import { TariffService } from 'src/app/core/services/tariff.service';
import { TitleDTO } from 'src/app/shared/models/title-dto';
import { CardDecodeResponse } from 'src/app/shared/models/card-decode-response';
import { CardEnum } from 'src/app/shared/enum/card.enum';
import { HttpStatusCode } from 'src/app/shared/enum/http-status-code';
import { VoucherDetailsComponent } from '../voucher-details/voucher-details.component';
import { HandlerComponent, SocketClientService } from 'src/app/core/services/websocket-client.service';
import { WebsocketMessage, WebsocketMessageType } from 'src/app/shared/models/websocket-message';

@Component({
    selector: 'app-voucher-cancel',
    templateUrl: './voucher-cancel.component.html',
    styleUrls: ['./voucher-cancel.component.less']
})
export class VoucherCancelComponent implements OnInit, OnDestroy, HandlerComponent {

    subscription1$: Subscription;
    subscriptions: Subscription[] = [];

    listResultColumn: TableColumn[] = [];
    listResultHeader: TableHeaderData[] = [];
    private success = 'Voucher anulado com sucesso.';
    load: Load;
    card: CardDetailsMessage;
    data: DetailsVoucherCancel[] = [];

    constructor(
        private navigationManager: NavigationManagerService,
        private service: VoucherCancelService,
        private alertService: AlertService,
        private spinService: SpinService,
        private shoppingCartService: ShoppingCartService,
        private storageManagerService: StorageManagerService,
        private tariffService: TariffService,
        private storageManager: StorageManagerService,
        private peripheralService: PeripheralService,
        private socketClient: SocketClientService
    ) {
        this.socketClient.setHandlerComponent(this);
        if (this.navigationManager.getNavigation()?.extras?.state?.load && this.navigationManager.getNavigation()?.extras?.state?.card
        ) {
            this.load = this.navigationManager.getNavigation().extras.state.load;
            this.card = this.navigationManager.getNavigation().extras.state.card;
        } else {
            this.navigationManager.go(TicketingRoutes.HOME);
            this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
        }

        this.prepareTableTotalSalesShiftResponseResult();

        this.subscribeData();

        this.service.getDetailsVoucherCancel(Number(this.load.cardRead.serialNumber));
    }

    ngOnInit(): void {
    }

    onConnect(): void {
        this.spinService.setSpinValue(false);
     }

    prepareTableTotalSalesShiftResponseResult() {
        this.listResultColumn = [
            {title: 'Descrição', config: {colAlign: 'left', colWidth: '50%'}},
            {title: 'Valor', config: {colAlign: 'right', colWidth: '50%'}}
        ];

        this.listResultHeader = [
            {visible: true, name: 'description', config: {valAlign: 'left'} as TableHeaderDataConfig} as TableHeaderData,
            {visible: true, name: 'price', config: {valAlign: 'right', money: true} as TableHeaderDataConfig} as TableHeaderData,
        ];
    }

    total(data: DetailsVoucherCancel[]) {
        let total = 0;
        if (data !== null && data !== undefined) {
            for (const voucher of data) {
                total = total + voucher.price;
            }
        }
        return total;
    }

    refundTotal(data: DetailsVoucherCancel[]) {
        let total = 0;
        if (data !== null && data !== undefined && data[0] != null) {
            total = data[0].price;
        }
        return total;
    }

    subscribeData() {
        this.subscription1$ = this.service.data.subscribe(
            async (data: DetailsVoucherCancel[]) => {
                this.data = [];
                if (data && data.length > 0) {
                    const voucher = data.slice(-1)[0];
                    const title = await this.tariffService.getInfoTitleByTicketCodes(
                        voucher.ticketCode,
                        voucher.ticketOperatorCode
                    ).toPromise();
                    voucher.description = title.data.description;
                    voucher.price = title.data.price.value;
                    this.data = [voucher, ...this.data];
                }
            },
            err => {
                this.alertService.error(CommonEnum.msgGetInfoFromPreviousViewError);
                console.log('HTTP Error', err);
            }
        );
        this.subscriptions.push(this.subscription1$);
    }

    async onClickCancel() {
        await this.navigationManager.go(TicketingRoutes.FAMILY_PASS, {load: this.load, card: this.card});
    }

    private async cancelVoucher() {
        this.spinService.setSpinValue(true);
        const currentCardBinary = this.storageManager.session.get(CardEnum.CARD_BINARY_DETECT_VOUCHER) as CardDecodeResponse;
        if (!VoucherDetailsComponent.isSameCardAsSupportDetailsRead(Number(this.load.cardRead.serialNumber), currentCardBinary.lowPart)) {
            this.alertService.error(CommonEnum.msgCardReadIsNotTheOriginal, true);
            this.data.length == null;
            this.spinService.setSpinValue(false);
        } else {
            await this.service.cancelVoucher(Number(this.load.cardRead.serialNumber)).then(async response => {
                if (response && response.success) {
                    const familyTitle = (await this.tariffService.getInfoTitleByTicketCodes(
                        this.data[0].ticketCode,
                        this.data[0].ticketOperatorCode
                    ).toPromise() as BasicDataResponse)?.data;

                    const voucherIdList = response.data.map((voucher) => voucher.id);

                    const shoppingRequest = this.prepareCancelVoucherShoppingItemRequest(familyTitle, voucherIdList);

                    await this.shoppingCartService.cancelFamilyItem(shoppingRequest);
                    this.spinService.setSpinValue(false);
                    this.alertService.success(this.success);
                    await this.navigationManager.go(TicketingRoutes.SHOPPING_CART);
                }
            }, async err => {
                this.spinService.setSpinValue(false);
                await this.navigationManager.go(TicketingRoutes.HOME);
                this.alertService.error(err?.error?.message);
            });
        }
    }

    private prepareCancelVoucherShoppingItemRequest(familyTitle: TitleDTO, voucherCodeList: number[]): CancelFamilyShoppingCartItemRequest {
        return {
            shiftId: Number(this.storageManagerService.session.get(CommonEnum.shiftID)),
            cardNumber: this.card.details.environment.issuing_number,
            cardSerialNumber: this.card.low_part,
            shoppingCartId: null,
            itemId: null,
            titleId: familyTitle.id,
            description: familyTitle.description,
            quantity: -1,
            voucherIds: voucherCodeList,
        } as CancelFamilyShoppingCartItemRequest;
    }

    async scanCard() {
        await this.peripheralService.detectCard()
            .then(async (response) => {
                if (response.success) {
                    const cardBinary = response.data as CardDecodeResponse;
                    this.storageManager.session.set(CardEnum.CARD_BINARY_DETECT_VOUCHER, cardBinary);
                    this.spinService.setSpinValue(true);
                    await this.peripheralService.startCardScan().toPromise()
                        .then(async (read) => {
                            if (!read.success) {
                                this.spinService.setSpinValue(false);
                            }
                        })
                        .catch(error => {
                            this.spinService.setSpinValue(false);
                            this.alertService.error(error);
                        });
                }
            })
            .catch((error: any) => {
                const errorMessage = error.status === HttpStatusCode.NOT_FOUND ||
                    error.status === HttpStatusCode.INTERNAL_SERVER_ERROR
                    ? CommonEnum.msgDetectCardError
                    : CommonEnum.msgWriteCardError + error.message;
                this.alertService.error(errorMessage);
                this.spinService.setSpinValue(false);
            });
    }

    async handleMessage(messageBody: WebsocketMessage) {
        // Handle message
        switch (messageBody.type) {
            case WebsocketMessageType.ExchangeApdu:
                const response = await this.peripheralService.sendCommandToCardReader(messageBody?.content, messageBody?.requestId)
                    .toPromise();
                if (!response.success) {
                    this.alertService.error(response.detail);
                }
                break;
            case WebsocketMessageType.CardDetails:
                const cardDetails = (messageBody.content) as CardDetailsMessage;
                if (cardDetails.details.environment === undefined) {
                    this.spinService.setSpinValue(false);
                    this.alertService.error(CommonEnum.msgSAMFARMError);
                } else {
                    this.spinService.setSpinValue(true);
                    await this.cancelVoucher();
                }
                break;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.storageManager.session.remove(CardEnum.CARD_BINARY_DETECT_VOUCHER);
    }

}
