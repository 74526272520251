
<div class="margin-left-20 margin-right-20">
  <div nz-row class="margin-bottom-20">
    <div nz-col nzSpan="12">
      <h1 class="shift">Turno</h1>
      <h2 class="previousShift">Turnos Anteriores</h2>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="8">
      <h1 class="doc">Nº operador</h1>
    </div>
    <div nz-col nzSpan="5">
      <h3 class="date margin-left-30">Data De</h3>
    </div>
    <div nz-col nzSpan="4">
      <h3 class="to">Até</h3>
    </div>
  </div>

  <div class="test">
    <div nz-row class="margin-bottom-30">
      <div nz-col [nzSpan]="5" class="align-left">
        <app-dropdown-button
          [label]="labelDropdown"
          labelTemplate="btn-icon icon-drop_down icon-dropdown-arrow"
          [listOptions]="dropdownList"
          buttonStyle="dropdown"
          (onSelect)="onChangedDropdown($event)"
        ></app-dropdown-button>
      </div>
      <div>
        <app-primary-input-keyboard
        *ngIf="labelDropdown === 'Nº de Operador'"
        [inputPlaceholder]="placeholder"
        [value]="ownerId"
        (valueChange)="onChangeOwnerId($event)"
        inputCss="input-search-filter"
        ></app-primary-input-keyboard>
        <app-primary-input-keyboard
          *ngIf="labelDropdown === 'Nº de Turno'"
          type="number"
          [inputPlaceholder]="placeholder"
          [value]="shiftId"
          (valueChange)="onChangedInput($event)"
          inputCss="input-search-filter"
          ></app-primary-input-keyboard>
      </div>
      <div nz-col [nzSpan]="5" class="align-left">
        <span class="margin-left-30">
          <app-date-picker
            [data]="startDate"
            (valueChange)="onChangedStartDate($event)"
            height="50px"
        ></app-date-picker>
        </span>
      </div>
      <div nz-col [nzSpan]="4" class="align-left">
        <app-date-picker
          [data]="endDate"
          (valueChange)="onChangedEndDate($event)"
          height="50px"
        ></app-date-picker>
      </div>
      <div nz-col [nzSpan]="5" nzJustify="end" class="align-left">
        <app-primary-normal-button
          borderColor=" #707070"
          back="white"
          width="55px"
          (click)="clearSearch()"
          class="margin-left-20">
         <span class="btn-icon icon-apagar icon-delete"></span>
        </app-primary-normal-button>
        <app-primary-normal-button
          class="m-l-10"
          width="55px"
          height="52px"
          [disabled]="isSearchDisabled"
          (click)="loadShifts()"
          ><span class="btn-icon icon-pesquisar icon-search"></span>
        </app-primary-normal-button>
      </div>
    </div>

    <div nz-row class="group">
      <div class="div-table-result" nz-col [nzSpan]="24">
        <app-primary-table
          [listOfColumn]="listTitleColumn"
          [headerDatas]="listTitleHeader"
          [listOfData]="listShifts"
          [showPagination]="true"
          [isSingleSelection]="true"
          (callButtonClick)="printShift($event)"
          (isCheckedChange)="checkBoxEvent($event)"
          (onPageChanged)="onIndexChange($event)"
          [dataTotal]="dataTotal"
          [frontPagination]="false"
          [pageSize]="defaultPageSize"
        >
        </app-primary-table>
      </div>
    </div>
  </div>

</div>

<div class="footer-buttons">
  <div nz-row nzJustify="start">
    <div nz-col [nzSpan]="4">
      <app-primary-white-button (click)="previousView()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>
  </div>
</div>
