export enum AuthorityName {
    SUSPENDED_SHIFTS_READ_WRITE = 'SUSPENDED_SHIFTS_READ_WRITE',
    TICKETS_SELL = 'TICKETS_SELL',
    BALANCE_TRANSFER = 'BALANCE_TRANSFER',
    ISSUE_GUIDE_WITH_READ = 'ISSUE_GUIDE_WITH_READ',
    ISSUE_GUIDE_WITHOUT_READ = 'ISSUE_GUIDE_WITHOUT_READ',
    FAMILY_PASS = 'FAMILY_PASS',
    CARD_HISTORY = 'CARD_HISTORY',
    ANNUL_SALE = 'ANNUL_SALE',
    CHARGE_AUTHORIZATIONS = 'CHARGE_AUTHORIZATIONS',
    REQUISITIONS_NEW = 'REQUISITIONS_NEW',
    REQUISITIONS_HISTORY = 'REQUISITIONS_HISTORY',
    FINE_READ_PAYMENT = 'FINE_READ_PAYMENT',
    FINE_PRE_REGISTER = 'FINE_PRE_REGISTER',
    OTHER_PRODUCTS = 'OTHER_PRODUCTS'
}

