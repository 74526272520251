import { CommonEnum } from '../enum/common.enum';

export class PrintUtils {

    public static buildPrintSuccessMessageIfLastDocument(printResponses: Array<string>): string {
        if (printResponses.length === 1) {
            return printResponses[0];
        } else if (printResponses.length > 1) {
            return CommonEnum.msgPrintMultipleDocumentsSuccess;
        }
    }

}
