<div>
    <h1>Devolução de guia de substituição Nº {{serialNumber}}</h1>
    <h4>Por favor confirme os detalhes do título para devolução</h4>

    <div class="m-t-50" *ngIf="!hasWarranty()">
        <h3>Devolver o valor do suporte VIVA Viagem/navegante ocasional ao cliente</h3>
        <app-primary-checkbox (isCheckedChange)="checkboxEventHandler($event)" label="Devolver Caução">
        </app-primary-checkbox>
    </div>

    <div class="m-t-50">
        <app-primary-table [listOfColumn]="listTitleColumn" [headerDatas]="listTitleHeader" [listOfData]="dataTable"
            showPagination="false" pageSize="1" showTotal="true" spanTotal=0 total=" {{isChecked ? -50 : 0 }}">
        </app-primary-table>
    </div>

    <div nz-row class="footer-buttons">
        <div nz-col [nzSpan]="12" class="text-align-left">
            <app-primary-cancel-button (click)="cancelClick()">Cancelar</app-primary-cancel-button>
        </div>
        <div nz-col [nzSpan]="12" class="text-align-right p-r-50">
            <app-primary-normal-button (click)="onNextClick()" width="180px">
                Seguinte
            </app-primary-normal-button>
        </div>
    </div>
</div>
