import {CustomerDetailsDTO, ReplacementGuideDTO, ReplacementGuideStatus} from './../../modules/operation/pages/replacement-guide/model/create-repl-guide-request';
import {LoadTitleRequest} from '../../shared/models/load-title-request';
import {ShoppingCartItemRequest} from '../../shared/models/shopping-cart-item-request';
import {CardDecodeResponse} from '../../shared/models/card-decode-response';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TitleInfo} from 'src/app/modules/operation/pages/replacement-guide/issue-replacement-guide/model/title-info';
import {Observable, Subject} from 'rxjs';
import {EnvironmentUtil} from '../../../environments/environment-util';
import {CardDetailsMessage} from '../../shared/models/card-details-message';
import {CardEnum} from '../../shared/enum/card.enum';
import {StorageManagerService} from './storage-manager.service';
import {BasicResponseDTO} from '../../shared/models/basic-response-dto';
import {CommonEnum} from "../../shared/enum/common.enum";

@Injectable({
  providedIn: 'root'
})
export class IssueReplacementGuideService {

  error = 'Ocorreu um erro ao recuperar os dados.';

  titleList: Subject<TitleInfo[]> = new Subject<TitleInfo[]>();

  constructor(
    private http: HttpClient,
    private storageManagerService: StorageManagerService
  ) {
  }

  fetchReplacementGuides(filter: any) {
    return this.http.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/replacement-guides`, filter);
  }

  getActiveReplacementStatus(): Observable<BasicResponseDTO<ReplacementGuideStatus>> {
    return this.http.get<BasicResponseDTO<ReplacementGuideStatus>>(`${EnvironmentUtil.getEnv().apiIntApiUrl}replacement/api/status/active`);
  }

    public callInsertReplacementWorkflow(
        replacementGuideRequest: ReplacementGuideDTO,
        customerDetailsDTO: CustomerDetailsDTO,
        titleRequest: LoadTitleRequest,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        mustReturnPayment: boolean = true
    ): Observable<any> {

        titleRequest.validityStart = replacementGuideRequest.title.startDate, // Optional for transfer
            titleRequest.validityEnd = replacementGuideRequest.title.endDate // Optional for transfer

        const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);

        const loadCardRequestWrapper = {
            cartItem: shoppingCartRequest, // para sale-service
            loadItem: titleRequest,        // para load-title-service
            binary: cardBinary,            // para peripheral-service
            cardDetails,                   // para load-title-service
            mustReturnPayment
        };

        const createReplacementWorkflowRequest = {
            replacementDTO: replacementGuideRequest,
            customerDetailsDTO,
            loadCardRequestWrapper,
            amount: replacementGuideRequest.loadAmount,
            peripheralId
        };

        return this.http.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/replacement/create`,
            createReplacementWorkflowRequest);
    }

    public callReturnReplacementWorkflow(
        replacementGuideRequest: ReplacementGuideDTO,
        titleRequest: LoadTitleRequest,
        shoppingCartRequest: ShoppingCartItemRequest,
        cardBinary: CardDecodeResponse,
        mustReturnPayment: boolean = true
    ): Observable<any> {

        titleRequest.validityStart = replacementGuideRequest.title.startDate, // Optional for transfer
            titleRequest.validityEnd = replacementGuideRequest.title.endDate // Optional for transfer

        let cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);

        const loadCardRequestWrapper = {
            cartItem: shoppingCartRequest, // para sale-service
            loadItem: titleRequest,        // para load-title-service
            binary: cardBinary,            // para peripheral-service
            cardDetails,                    // para load-title-service
            mustReturnPayment
        };

        const createReplacementWorkflowRequest = {
            replacementDTO: replacementGuideRequest,
            loadCardRequestWrapper,
            amount: replacementGuideRequest.loadAmount,
            peripheralId
        };

        return this.http
            .post(`${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/replacement/return`, createReplacementWorkflowRequest);
    }

    public async deleteReplacementGuide(replacementId: any) {
        return await this.http.delete(`${EnvironmentUtil.getEnv().apiIntApiUrl}replacement/api/replacement/${replacementId}`, replacementId).toPromise();
    }

    public async findReplacemnetGuideByNewCardSerialNumber(newCardSerialNumber: number) {
        return await this.http.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}replacement/api/replacement/new-card-serial-number/${newCardSerialNumber}`).toPromise() as any;

    }


}

