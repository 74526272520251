import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { NonTicketingProduct, TariffType } from './model/commons-non-ticketing-product';
import { CommercialOfferService } from 'src/app/core/services/commercial-offer.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { Category } from '../non-ticketing-products-select/model/commons-non-ticketing-product';

@Component({
    selector: 'app-non-ticketing-products',
    templateUrl: './non-ticketing-products.html',
    styleUrls: ['./non-ticketing-products.less']
})
export class NonTicketingProductsComponent implements OnInit {

    public listTariffType: TariffType[] = [];
    public request: NonTicketingProduct = {} as NonTicketingProduct;
    public listCategories = [];

    public isShoppingCartFull = false;
    public isNextDisabled = true;
    public selectedIds: Array<number> = [];
    private finalPrice: number;
    public tariffSelected;
    public quantity = 0;


    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private storageManagerService: StorageManagerService,
        private tariffService: TariffService,
        private commercialService: CommercialOfferService,
        private shoppingService: ShoppingCartService
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.spinService.setSpinValue(true);
        await this.getNonTicketingProductsCategories();
        this.spinService.setSpinValue(false);
        /*this.canConsultFines = this.authorizationService.hasPermission(AuthorityName.FINE_READ_PAYMENT);
        this.canPreRegisterFines = this.authorizationService.hasPermission(AuthorityName.FINE_PRE_REGISTER);
        if (this.canConsultFines) {
            await this.getFines();
        }*/

    }

    async getNonTicketingProductsCategories(): Promise<void> {
        const res: any = await this.tariffService.getListCategories();
        if (res && res.success && res.data) {
            res.data.forEach((product: { id: any; description: any; }) => {
                this.listCategories.push({
                    id: product.id,
                    name: product.description,
                    disabled: false
                })
            });
        }
    }

    async newCategorySelection(event: { value: any }) {
        const selectedCategory= this.listCategories.find(i => i.id === event.value) as any;
        //TODO fazer chamada ao tariff e ver se existem produtos com esta tarifa no tarifario atual
        if (selectedCategory === undefined || selectedCategory === null) {
            this.alertService.error(CommonEnum.msgSelectedCategoryWithoutProducts);
            return;
        }
        const category = {
            description: selectedCategory.name,
            id: selectedCategory.id 
        } as Category;
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS_SELECT, {category});
    }

    async previousView() {
        await this.navigationManager.go(TicketingRoutes.HOME);
    }
    
    async historyView() {
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS_HISTORY);
    }

}
