<button
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu1"
  nzPlacement="bottomCenter"
  [ngClass]="buttonStyle"
  [style.width]="width"
  [style.height]="height"
  [nzDisabled]="isDisabled"
>
  <ng-container *ngIf="label">
    {{ label }}
  </ng-container>
    <span *ngIf="labelTemplate" [class]="labelTemplate"></span>
</button>
<nz-dropdown-menu #menu1="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      *ngFor="let option of listOptions"
      (click)="callSelect(option.value)"
    >
      {{ option.label }}
    </li>
  </ul>
</nz-dropdown-menu>
