<div>
    <nz-modal [(nzVisible)]="isFineModalActive" nzClosable="true" (nzOnCancel)="handleCloseModal()" [nzFooter]=null [nzWidth]=700>
        <div nz-row>
            <div nz-col nzSpan="24">
                <div>
                    <h1>Notas</h1>
                </div>
                <div>
                    <p>{{ modalFine?.notes }}</p>
                </div>
            </div>
        </div>
        <div class="yellow-border-top" nz-row nzJustify="center">
            <div nz-col>
                <div>
                    <h3>A Pagamento</h3>
                </div>
                <div>
                    <p>{{ modalFine?.canPay ? 'Sim' : 'Não' }}</p>
                </div>
            </div>
            <div nz-col>
                <div>
                    <h3>Motivo</h3>
                </div>
                <div>
                    <p>{{ modalFine?.canPayReason }}</p>
                </div>
            </div>
        </div>
    </nz-modal>
    <div nz-row class="margin-bottom-10">
        <!-- HEADER -->
        <div nz-col nzSpan="12">
            <h1 class="title">
                Autos
            </h1>
        </div>
        <div nz-col nzSpan="12" *ngIf="canPreRegisterFines">
            <app-primary-normal-button
                width="180px"
                (click)="registerFineView()"
            >
               Adicionar auto
            </app-primary-normal-button>
        </div>
    </div>

    <div class="search-row margin-bottom-10">
        <div class="flex-column">
            <h3 class="align-left">
                Data
            </h3>
            <app-date-picker
                [data]="fineDate"
                height="50px"
                width="150px"
                (valueChange)="onChangedDate($event)"
                class="m-l-5"
        ></app-date-picker>
        </div>
        <div class="flex-column">
            <h3 class="align-left">
                Nº Auto
            </h3>
            <app-primary-input-keyboard
                    [value]="noticeNumber"
                    (valueChange)="onNoticeNumberChange($event)"
                    [maxlength]="noticeNumberMaxLength"
                    [mask]="'0*'"
                    inputPlaceholder="Digite o número"
                    inputCss="input-search-number"
            ></app-primary-input-keyboard>
        </div>
        <div class="flex-column">
            <h3 class="align-left">
                Nome
            </h3>
            <app-primary-input-keyboard
                    [value]="finedPersonName"
                    (valueChange)="onFinedPersonNameChange($event)"
                    [maxlength]="nameMaxLength"
                    inputPlaceholder="Digite o nome"
                    inputCss="input-search-number"
            ></app-primary-input-keyboard>
        </div>
        <div class="search-buttons" *ngIf="canConsultFines">
            <app-primary-normal-button
                    borderColor=" #707070"
                    back="white"
                    width="55px"
                    (click)="clearSearch()"
                    class="margin-left-20"
            >
                <span class="btn-icon icon-apagar delete-icon"></span>
            </app-primary-normal-button>

            <app-primary-normal-button
                    id="buttonSearch"
                    style="margin-left: 19px; top: 214px; left: 762px; width: 55px; height: 52px; opacity: 1; vertical-align: top;"
                    width="55px"
                    (click)="searchFines()"
            >
                <span nzType="search" class="btn-icon icon-pesquisar search-icon"></span>
            </app-primary-normal-button>
        </div>
    </div>
    <div nz-row class="search-row margin-bottom-20">
        <div class="flex-column">
            <h3 class="align-left">
                NIF
            </h3>
            <app-primary-input-keyboard
                    [value]="finedPersonFiscalNumber"
                    (valueChange)="onFinedPersonFiscalNumberChange($event)"
                    [maxlength]="fiscalNumberMaxLength"
                    inputPlaceholder="Digite o NIF"
                    inputCss="input-search-number"
            ></app-primary-input-keyboard>
        </div>
        <div class="flex-column align-mid">
            <app-primary-checkbox
            [(isChecked)]="isVatInternational"
            label="NIF Internacional"
            >
            </app-primary-checkbox>
        </div>
    </div>

    <div nz-row>
        <div class="div-table-result" nz-col nzSpan="24">
            <app-primary-table
                    [listOfColumn]="listColumn"
                    [headerDatas]="listHeader"
                    [listOfData]="listFineData"
                    [showPagination]="true"
                    (isCheckedChange)="checkBoxEvent($event)"
                    (onPageChanged)="getFines($event)"
                    [dataTotal]="listTotal"
                    [frontPagination]="false"
                    [pageSize]="defaultPageSize.toString()"
                    (callButtonClick)="openFineNoteModal($event)"
            >
            </app-primary-table>
        </div>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button
                    (click)="previousView()"
            >
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
        <div class="load" nz-col [nzSpan]="4">
            <app-primary-normal-button (click)="addToCart()" [disabled]="isNextDisabled">
                Seguinte
            </app-primary-normal-button>
        </div>
    </div>
</div>
