import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FamilyPassVoucher} from 'src/app/shared/models/family-pass-voucher';
import {EnvironmentUtil} from '../../../environments/environment-util';

@Injectable({
    providedIn: 'root',
})
export class FamilyPassVoucherService {

    constructor(private httpClient: HttpClient) {
    }

    async insert(request: FamilyPassVoucher): Promise<any> {
        try {
            return await this.httpClient.post(
                `${EnvironmentUtil.getEnv().apiIntApiUrl}orchestrator/orchestrator/familyPass/voucher`,
                request
            ).toPromise();
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}
