<br>
<br>
<div nz-row class="margin-bottom-10">
    <!-- HEADER -->
    <div nz-col nzSpan="24" style="height: 90px;">
        <h1 class="title">
            Produtos Não Bilhética
        </h1>
        <div nz-row>
            <h3 class="tariff">Histórico de vendas</h3>
        </div>
        <div nz-col nzSpan="6" class="buttons" style="
        width: 160px;
        height: 80px;
        float: right;
        bottom: 90px;">
            <span>
                <app-primary-rectangle-button-annul class="m-r-20" (click)="annulNonTicketingProducts()"
                    [disabled]="!selected" width="300px">
                    Anular vendas selecionadas
                </app-primary-rectangle-button-annul>
            </span>
        </div>
    </div>
</div>

<div nz-row>
    <div nz-col nzSpan="5">
        <h1 class="doc">
            Nº Documento
        </h1>
    </div>
    <div nz-col nzSpan="4">
        <h1 class="date margin-left-10">
            Data De
        </h1>
    </div>
    <div nz-col nzSpan="4">
        <h1 class="date margin-left-10">
            Até
        </h1>
    </div>
</div>

<div nz-row class="margin-bottom-30 margin-bottom-10">
    <div nzSpan="4" class="align-left">
        <app-primary-input-keyboard 
        inputCss="input-search-number" 
        inputPlaceholder="Pesquisar Nº Documento"
        keyboardType="number" 
        [mask]="'SS 0*'"
        [maxlength]="80" 
        [value]="documentNumber"
        (valueChange)="documentNumberOnChange($event)">
        </app-primary-input-keyboard>
    </div>

    <div nzSpan="4" class="align-left m-l-10">
        <app-date-picker height="50px" [data]="startDate" (valueChange)="onChangedStartDate($event)">
        </app-date-picker>
    </div>

    <div nzSpan="4" class="align-left m-l-15">
        <app-date-picker height="50px" [data]="endDate" (valueChange)="onChangedEndDate($event)">
        </app-date-picker>
    </div>

    <div nz-col nzSpan="7" nzJustify="end" class="align-left">
        <app-primary-normal-button borderColor=" #707070" back="white" width="55px" (click)="clearSearch()"
            class="margin-left-20">
            <span class="btn-icon icon-apagar delete-icon"></span>
        </app-primary-normal-button>

        <app-primary-normal-button id="buttonSearch"
            style="margin-left: 19px; top: 214px; left: 762px; width: 55px; height: 52px; opacity: 1; vertical-align: top;"
            width="55px" (click)="loadRecords()">
            <span nzType="search" class="btn-icon icon-pesquisar search-icon"></span>
        </app-primary-normal-button>
    </div>
</div>

<div nz-row class="mgt-70">
    <div class="div-table-result" nz-col nzSpan="24">
        <app-primary-table 
            [listOfColumn]="listTitleColumn"
            [headerDatas]="listTitleHeader" 
            [listOfData]="listRecords"
            [showPagination]="true" 
            [isSingleSelection]="false" 
            (isCheckedChange)="checkBoxEvent($event)"
            (onPageChanged)="onPageChanged($event)" 
            [dataTotal]="dataTotal" 
            [frontPagination]="false"
            [pageSize]="defaultPageSize">
        </app-primary-table>
    </div>
</div>

<div class="footer-buttons">
    <div nz-row nzJustify="space-between">
        <div nz-col [nzSpan]="4">
            <app-primary-white-button (click)="previousView()">
                <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>
    </div>
</div>