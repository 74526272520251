import NavigationManagerService, {TicketingRoutes} from 'src/app/core/services/navigation-manager.service';
import {Component, OnInit} from '@angular/core';
import {CardRead} from 'src/app/shared/models/card-read';
import {Navigation, Router} from '@angular/router';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {CardHistoryData} from 'src/app/shared/models/card-history-data';
import {CardHistoryRequest} from 'src/app/shared/models/card-history-request';
import {CardHistoryService} from 'src/app/core/services/card-history.service';
import {AlertService} from 'src/app/core/services/alert.service';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import {CardDetailsMessage} from 'src/app/shared/models/card-details-message';
import {CardEnum} from 'src/app/shared/enum/card.enum';
import {CardUtils} from 'src/app/shared/utils/card-utils';
import {DatePipe} from '@angular/common';
import {TableHeaderDataButton} from '../../../../../shared/models/table-header-data-button';
import {DocumentsService} from '../../../../../core/services/documents.service';
import {CommonEnum} from '../../../../../shared/enum/common.enum';
import {BasicDataResponse} from '../../../../../shared/models/basic-data-response';
import { SpinService } from 'src/app/core/services/spin.service';

@Component({
    selector: 'app-card-history',
    templateUrl: './card-history.component.html',
    styleUrls: ['./card-history.component.less']
})
export class CardHistoryComponent implements OnInit {

    navigation: Navigation = this.router.getCurrentNavigation();
    cardRead: CardRead = {titles: []} as CardRead;
    cardDetails: CardDetailsMessage;
    cardSerialNumber: string;
    isVV = false;

    startDate: Date;
    endDate: Date;

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    listHistoryData: CardHistoryData[] = [];

    dataTotal: number;
    readonly defaultPageSize = 5;
    sort: string[] = [];

    constructor(private router: Router,
                private cardHistoryService: CardHistoryService,
                private alertService: AlertService,
                private storageManager: StorageManagerService,
                private navigationManager: NavigationManagerService,
                private documentService: DocumentsService,
                public datepipe: DatePipe,
                public spinService: SpinService) {

    }

    ngOnInit(): void {
        if (this.navigation?.extras?.state?.cardRead) {
            this.cardRead = this.navigation.extras.state.cardRead;
            this.cardDetails = this.storageManager.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
            this.cardSerialNumber = this.cardDetails.low_part.toString();
            this.isVV = CardUtils.IsVV(this.cardDetails.details.environment.card_data_model);
        }

        this.initDates();

        this.prepareTableResult();

        this.loadCardHistory();
    }

    onChangedStartDate(value: Date) {
        this.startDate = value;
    }

    onChangedEndDate(value: Date) {
        this.endDate = value;
    }

    prepareTableResult() {
        this.listTitleColumn = [
            {title: 'Data', config: {colAlign: 'left', colWidth: '15%'}},
            {title: 'Tipo de Operação', config: {colAlign: 'left', colWidth: '20%'}},
            {title: 'Equipamento', config: {colAlign: 'left', colWidth: '15%'}},
            {title: 'Operador', config: {colAlign: 'left', colWidth: '15%'}},
            {title: 'Título', config: {colAlign: 'left', colWidth: '15%'}},
            {title: 'Valor/Validade', config: {colAlign: 'left', colWidth: '20%'}},
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                name: 'createdAt',
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'operationDescription',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'equipmentDescription',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'operatorUserName',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'titleDescription',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'validityValue',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: null,
                config: { valAlign: 'center', money: false },
                button: {
                    titleButton: 'Emitir 2ª Via',
                    iconType: 'icon-imprimir icon-print',
                    buttonCSS: 'button-print'
                } as TableHeaderDataButton,
            } as TableHeaderData,
        ];
    }

    public printDocument({data}: {data: CardHistoryData}): void {
        const historyData = this.listHistoryData.find(it => it.loadId === data.loadId &&
            it.operationType === data.operationType);
        if (historyData != null && !historyData.hasDocumentAssociated) {
            // this means that the selected operation donesn't have a document associated
            // so we don't need to try to print a non existing document
            this.alertService.error(CommonEnum.msgPrintReceiptLoadHistoryWithoutDoc);
            return;
        }
        const deviceId = sessionStorage.getItem(CommonEnum.peripheralId);
        this.documentService.printLoadDocument(data.loadId, {deviceId}, data?.operationType).then(
            (response: BasicDataResponse) => {
                if (!response.data) {
                    this.alertService.error(CommonEnum.msgPrintDocumentError);
                }
            }).catch((error) => {
                this.alertService.error(CommonEnum.msgPrintDocumentError + ': ' + error.error.message);
            });
    }

    async loadCardHistory(pageNumber: number = 0) {
        if (this.validDates()) {
            this.alertService.clear();
            this.sort = [];
            this.sort.push('createdAt,desc');
            let request: CardHistoryRequest;
            request = {
                cardSerialNumber: this.cardSerialNumber,
                endDate: this.endDate != null ? this.datepipe.transform(this.endDate, 'yyyy-MM-dd') : null,
                startDate: this.startDate != null ? this.datepipe.transform(this.startDate, 'yyyy-MM-dd') : null,
                pageSize: this.defaultPageSize,
                page: pageNumber,
                sorters: this.sort
            };
            try {
                this.spinService.setSpinValue(true);
                const response = await this.cardHistoryService.getHistory(request).toPromise();
                this.spinService.setSpinValue(false);
                if (response?.data?.totalCount !== 0) {
                    this.listHistoryData = response.data.content;
                    this.dataTotal = response.data.totalElements;
                } else {
                    this.spinService.setSpinValue(false);
                    this.alertService.error('Operações em suporte Nº '
                    + this.getCardNumberOrSerialNumber() + ' não encontradas para o período selecionado');
                }
            } catch (error) {
                this.spinService.setSpinValue(false);
                console.log('HTTP Error', error);
                this.alertService.error('Histórico do cartão Nº ' + this.cardRead.number + ' não encontrado no período escolhido');
            }

        }
    }

    clearSearch() {
        this.startDate = null;
        this.endDate = null;
    }

    async onPageChanged(value: any) {
        await this.loadCardHistory(value - 1);
    }

    async previousView() {
        if (this.hasCardInfo()) {
            await this.navigationManager.go(TicketingRoutes.SUPPORT_DETAILS, {card: this.cardDetails});
        } else {
            await this.navigationManager.go(TicketingRoutes.HOME);
        }
    }

    hasCardInfo(): boolean {
        return this.navigation.extras?.state ? true : false;
    }

    private initDates() {
        this.startDate = new Date();
        this.endDate = new Date();
        this.endDate.setHours(23, 59, 0);
        this.startDate.setDate(this.endDate.getDate() - 30);
        this.startDate.setHours(0, 0, 0);
    }

    private validDates(): boolean {
        const actualDate = this.withoutTime(new Date());
        if (this.endDate == null || this.startDate == null) {
            this.alertService.error(CommonEnum.msgFillBothDatesError);
            return false;
        } else if (actualDate < this.withoutTime(new Date(this.startDate))) {
            this.alertService.error(CommonEnum.msgInitialDateOverCurrentDateError);
            return false;
        } else if (actualDate < this.withoutTime(new Date(this.endDate))) {
            this.alertService.error(CommonEnum.msgFinalDatePreviousInitialDateError);
            return false;
        } else if (this.startDate > this.endDate) {
            this.alertService.error(CommonEnum.msgInitialDateOverFinalDate);
            return false;
        }

        return true;
    }

    private withoutTime(value: Date) {
        const dateMidnight = new Date(value);
        dateMidnight.setHours(0, 0, 0, 0);
        return dateMidnight;
    }

    getCardNumberOrSerialNumber(): string {
        return this.isVV ? this.cardSerialNumber : this.cardDetails.details.environment.app_issuer.toString().padStart(3, '0')
            + ' 0'
            + this.cardRead.number.toString().padStart(8, '0');
    }

}
