import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { ShiftService } from 'src/app/core/services/shift.service';
import { SpinService } from 'src/app/core/services/spin.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { CloseShiftRequest } from 'src/app/shared/models/close-shift-request';
import { PrintShiftRequest } from 'src/app/shared/models/print-shift-request';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { PaginationFilter } from 'src/app/shared/utils/http/http-commons-interfaces';
import { JwtUtils } from 'src/app/shared/utils/jwt-utils';
import { Columns, Headers, SuspendedShiftTable } from './interfaces/suspended-shifts-interfaces';

@Component({
    selector: 'app-suspended-shifts',
    templateUrl: './suspended-shifts.component.html',
    styleUrls: ['./suspended-shifts.component.less']
})
export class SuspendedShiftsComponent implements OnInit {

    private DEFAULT_PAGE_NUMBER = 0;
    public readonly DEFAULT_PAGE_SIZE = 8;
    private DEFAULT_SORT = ['id,desc'];
    private paginationFilter: PaginationFilter = {};
    private deviceId: string;
    private machineNumber: string;
    private jwtUtils: JwtUtils;

    public tableColumns: Array<TableColumn> = Columns;
    public tableHeaders: Array<TableHeaderData> = Headers;
    public suspendedShiftsList: Array<SuspendedShiftTable> = [];
    public dataTotal: number;
    public isCloseShiftModalVisible: boolean = false;
    public isCloseShiftModalOkLoading: boolean = false;
    public shiftInfo: SuspendedShiftTable;

    constructor(
        private navigationManager: NavigationManagerService,
        private shiftService: ShiftService,
        private spinService: SpinService,
        private alertService: AlertService,
        private peripheralService: PeripheralService
    ) {
        const jwtUtils = new JwtUtils();
        this.jwtUtils = jwtUtils;
    }

    public async ngOnInit(): Promise<void> {
        this.peripheralService.getPeripheralId().then(
            (deviceId: any) => {
                this.deviceId = deviceId;
            });
        await this.getSuspendedShifts();
    }

    public async getSuspendedShifts(pageNumber: number = this.DEFAULT_PAGE_NUMBER): Promise<void> {
        this.spinService.setSpinValue(true);
        this.paginationFilter.size = this.DEFAULT_PAGE_SIZE;
        this.paginationFilter.sorters = this.DEFAULT_SORT;
        this.paginationFilter.page = pageNumber;
        try {
            const response = await this.shiftService.getAllSuspendedShifts(this.paginationFilter);
            if (response?.data != null) {
                this.suspendedShiftsList = response.data?.content;
                this.dataTotal = response.data.totalElements;
            }
            if (response.data.empty) {
                this.alertService.error(CommonEnum.msgSuspendedShiftsNotFound);
            }
            this.spinService.setSpinValue(false);
        } catch (error) {
            this.suspendedShiftsList = [] as Array<SuspendedShiftTable>;
            this.spinService.setSpinValue(false);
            this.alertService.error(error.message);
        }
    }

    public async onPageChanged(value: any): Promise<void> {
        await this.getSuspendedShifts(value - 1);
    }

    public async isShiftClosable(event: any): Promise<void> {
        try {
            const shiftResponse = await this.shiftService.getShiftInfo(event.value).toPromise() as any;
            const shiftState = shiftResponse.data.status;
            this.machineNumber = shiftResponse.data.machine;
            if (shiftState != null && CommonEnum.shiftStatusSuspend === shiftState) {
                // it means that shift is suspended
                this.isCloseShiftModalVisible = true;
                this.shiftInfo = this.suspendedShiftsList.find(shift => shift.id === event.value);
            } else {
                this.alertService.error(CommonEnum.msgShiftNotSuspended);
            }
        } catch (error) {
            this.alertService.error(error.error.message);
        }
    }

    public handleCancelCloseShiftModal(): void {
        this.isCloseShiftModalVisible = false;
    }

    public async closeShift(): Promise<void> {
        this.isCloseShiftModalOkLoading = true;

        if (!this.machineNumber) {
            this.alertService.error(CommonEnum.msgPeripheralIdentificationError);
            this.isCloseShiftModalOkLoading = false;
            this.handleCancelCloseShiftModal();
            return;
        }
        const request: CloseShiftRequest = {
            shiftId: this.shiftInfo.id,
            requesterId: Number(this.jwtUtils.getUserID()),
            deviceId: this.machineNumber,
            role: 'user'
        };
        const printRequest: PrintShiftRequest = {
            id: this.shiftInfo.id,
            deviceId: this.deviceId,
            includeMiniReceipt: true,
            includeReceiptCopy: true,
        };
        const response = await this.shiftService.closeShift(request, printRequest);
        if (response.success) {
            this.isCloseShiftModalOkLoading = false;
            this.isCloseShiftModalVisible = false;
            await this.getSuspendedShifts();
        } else {
            this.handleCancelCloseShiftModal();
            this.isCloseShiftModalOkLoading = false;
            this.alertService.error(
                `Ocorreu um erro. ${response.error ? response.error : CommonEnum.msgShiftCloseError}`
            );
        }
    }

    public goBack() {
        this.navigationManager.go(TicketingRoutes.SHIFTLESS);
    }

}
