<div>
    <div nz-row style="text-transform: uppercase;">
        <div nz-col nz-span="24">
            <h1>{{newTitle.description}}</h1>
        </div>
    </div>

    <div nz-row style="text-align: left; color: #002b5a; font-size: 20px; text-transform: uppercase;">
        <div nz-col nz-span="24">
            <label>Indique quantos bilhetes pretende comprar</label>
        </div>
    </div>

   <div nz-row class="quantity">
      <div nz-row>
        <div nz-col>
          <app-primary-white-button width="50px" height="52px" style="text-align: center;" (click)="onNumCopyClick('-')">
            -
          </app-primary-white-button>
        </div>
        <div>
            <app-primary-input-keyboard
                class="quantity-box"
                color="#003f8f"
                inputCss="quantity-input"
                inputBorder="quantity-input-border"
                keyboardType="number"
                [mask]="'00000000000'"
                [(value)]="ticketQuantity"
                (valueChange)="onQuantityInputChange($event)"
                >
            </app-primary-input-keyboard>
            </div>
        <div nz-col>
          <app-primary-white-button width="50px" height="52px" style="text-align: center; margin-left: -1px;" (click)="onNumCopyClick('+')">
            +
          </app-primary-white-button>
        </div>
      </div>
    </div>

    <div nz-row>
        <div nz-col nzSpan="24">
            <app-primary-table
                [listOfColumn]="listTitleColumn"
                [headerDatas]="listTitleHeader"
                [listOfData]="listOfData"
                showPagination="false"
                pageSize="5">
            </app-primary-table>
        </div>
    </div>

    <div nz-row class="m-t-150"  style="margin-top: 150px;">
        <div nz-col nzSpan="2">
            <app-primary-white-button (click)="previous()">
              <span class="btn-icon icon-anterior icon-middle float-left "></span>
                Voltar
            </app-primary-white-button>
        </div>

        <div nz-col nzSpan="2" nzOffset="2">
            <app-primary-cancel-button (click)="home()">
                Cancelar
            </app-primary-cancel-button>
        </div>

        <div nz-col nzSpan="2" nzOffset="14">
            <app-primary-normal-button
                width="159px"
                nzType="primary"
                nzDanger
                buttonCSS="button-load"
                (click)="load()">
                    Carregar
                    <span class="btn-icon icon-seguinte icon-middle float-right"></span>
            </app-primary-normal-button>
        </div>
    </div>
</div>

