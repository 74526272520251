<button
  [ngClass]="buttonCSS"
  nz-button
  nzBlock
  nzType="primary"
  [disabled]="disabled"
  [style.background-color]="color"
  [style.border]="border"
  (click)="onClickButton($event)"
>
  <h3 class="h3"><ng-content></ng-content></h3>
</button>
