import { TitleFilter } from '../../modules/load/pages/lisboa-viva/title-selection/model/TitleFilter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasicResponse } from 'src/app/shared/models/basic-response';
import { Subject } from 'rxjs';
import { HttpUtils } from 'src/app/shared/utils/http-utils';
import { EnvironmentUtil } from '../../../environments/environment-util';
import { TitleDTO, TitleDTOWithPriceCurrency } from 'src/app/shared/models/title-dto';
import { CardDetailsMessage } from 'src/app/shared/models/card-details-message';
import { CanTitleBeLoadedResponse } from 'src/app/shared/models/can-title-be-loaded-response';
import { BasicDataResponse } from 'src/app/shared/models/basic-data-response';
import ExchangeListRequest from "../../shared/models/exchange-list-request";

@Injectable({
    providedIn: 'root'
})
export class TitleSelectionService {

    error = 'Ocorreu um erro ao recuperar os dados.';
    titleLoadChange: Subject<TitleDTOWithPriceCurrency[]> = new Subject<TitleDTOWithPriceCurrency[]>();

    constructor(private httpClient: HttpClient) { }

    getListLoadTitleExchangeCase(titleToExchange: TitleDTO, fullCardDetails: CardDetailsMessage) {

        console.log(titleToExchange);

        const request: ExchangeListRequest = {
            currentTitleId: titleToExchange.id,
            cardFullDetails: fullCardDetails
        };

        this.httpClient.post(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/exchange/titles`, request)
            .subscribe((res: any) => {
                if (res) {
                    this.titleLoadChange.next(res.data);
                } else {
                    console.log('error on response');
                    this.titleLoadChange.error(res.message);
                }
            },
                (e: any) => {
                    console.log(e);
                    try {
                        this.error = HttpUtils.getErroMessage(e);
                        console.log(this.error);
                    } catch (e) {
                        console.log(e);
                    }
                });

    }

    public async canTitleBeLoadedInCard(titleInfo: number, cardDetails: CardDetailsMessage): Promise<boolean> {

        const request: any = {
            titleId: titleInfo,
            cardFullDetails: cardDetails,
            amount: 1
        };

        try {
            const response = await this.httpClient
                .post<CanTitleBeLoadedResponse>(
                    `${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/validate`, request
                ).toPromise();

            if (response && response.success) {
                return response.data;
            } else {
                return false;
            }
        } catch (e) {
            throw new Error(e.error.message);
        }
    }
}
