import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {ShiftService} from '../services/shift.service';
import {CommonEnum} from '../../shared/enum/common.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private shiftService: ShiftService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const url: string = state.url;

    return this.checkLogin(url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  checkLogin(url: string): boolean {

    if (!sessionStorage.getItem(CommonEnum.accessToken) || !this.shiftService.isLoggedIn()) {
      this.shiftService.redirectHomeUrl = url;
      const navigationExtras: NavigationExtras = {};

      this.router.navigate(['/login'], navigationExtras)
        .then(r => {
          console.log(r);
        }).catch(reason => {
        console.log(reason);
      }).finally(() => {
        console.log('finally');
      });
      return false;
    }

    return true;
  }

}
