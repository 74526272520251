import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShiftResumeComponent} from './pages/shift-resume/shift-resume.component';
import {AuthGuard} from 'src/app/core/guards/auth.guard';
import {DocumentsComponent} from './pages/documents/documents.component';
import {PreviousShiftsComponent} from './pages/previous-shifts/previous-shifts.component';
import {ShiftAdminComponent} from './pages/admin/shift-admin.component';
import { SuspendedShiftsComponent } from './pages/suspended-shifts/suspended-shifts.component';

const routes: Routes = [
  {
    path: 'shift/shift-resume',
    component: ShiftResumeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shift/documents',
    component: DocumentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shift/admin',
    component: ShiftAdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shift/previous-shifts',
    component: PreviousShiftsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shift/suspended-shifts',
    component: SuspendedShiftsComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShiftRoutingModule {
}
