<div class="margin-left-20 margin-right-20">
  <div>
    <h1 class="shift">Turno</h1>
    <h2 class="hist">Histórico de Documentos</h2>
  </div>

  <div>
    <div nz-row class="input">
      <div nz-col [nzSpan]="5" class="align-left">
        <app-dropdown-button
          [label]="labelDropdown"
          labelTemplate="btn-icon icon-drop_down icon-dropdown-arrow"
          [listOptions]="dropdownList"
          buttonStyle="dropdown"
          (onSelect)="onChangedDropdown($event)"
        ></app-dropdown-button>
      </div>
      <div>
        <app-primary-input-keyboard
        [inputPlaceholder]="placeholder"
        [value]="input"
        (valueChange)="onChangedInput($event)"
        inputCss="input-document-number-search">
        </app-primary-input-keyboard>
      </div>
      <div nz-col [nzSpan]="9" class="align-left start">
        <span>De</span><span class="red"></span>
        <app-date-picker
          [data]="startDate"
          height="40px"
          (valueChange)="onChangedStartDate($event)"
          class="m-l-5"
        ></app-date-picker>
        <span class="m-l-10">Até</span><span class="red"></span>
        <app-date-picker
          [data]="endDate"
          height="40px"
          (valueChange)="onChangedEndDate($event)"
          class="m-l-5"
        ></app-date-picker>
      </div>
      <div nz-row class="align-right">
        <app-primary-normal-button
          borderColor=" #707070"
          back="white"
          width="55px"
          height="40px"
          (click)="clearSearch()"
          class="m-l-5"
          >
          <span class="btn-icon icon-apagar icon-delete"></span>
        </app-primary-normal-button>
        <app-primary-normal-button
          class="m-l-10"
          width="55px"
          height="40px"
          (click)="loadDocuments()"
          >
          <span class="btn-icon icon-pesquisar icon-search"></span>
        </app-primary-normal-button>
      </div>
    </div>
    <br>
    <div nz-row class="group">
      <div class="margin-top-10 div-table-result" nz-col [nzSpan]="24">
        <app-primary-table
          [listOfColumn]="listTitleColumn"
          [headerDatas]="listTitleHeader"
          [listOfData]="listDocuments"
          pageSize="5"
          (callButtonClick)="onButtonClickPrintReceipt($event)"
          (onPageChanged)="onIndexChange($event)"
          [dataTotal]="dataTotal"
          [frontPagination] = "false"
          [pageIndex]="pageIndex"
        >
        </app-primary-table>
      </div>
    </div>
  </div>


</div>
<div class="footer-buttons">
  <div nz-row nzJustify="start">
    <div nz-col [nzSpan]="5">
      <app-primary-white-button (click)="previousView()">
        <span class="btn-icon icon-anterior icon-middle float-left "></span>
        Voltar
      </app-primary-white-button>
    </div>
  </div>
</div>
