import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EnvironmentUtil } from 'src/environments/environment-util';
@Injectable({
  providedIn: 'root'
})
export class CardHistoryService {

  constructor(private httpClient: HttpClient) { }

  getHistory(request: any) : Observable<any> {
    console.log('call getHistory');

    let params = new HttpParams();

    params = params.set('cardSerialNumber', `${request.cardSerialNumber}`);
    params = params.set('startDate', `${request.startDate}`);
    params = params.set('endDate', `${request.endDate}`);
    params = params.set('size' ,`${request.pageSize}`);
    params = params.set('page' ,`${request.page}`);
    params = params.set('sorters' ,`${request.sorters}`);

    return this.httpClient.get(`${EnvironmentUtil.getEnv().apiIntApiUrl}load-title/api/operation/load/history`, { params });

  }

}
