import { StorageManagerService } from './../../../core/services/storage-manager.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { PrimaryListButtonData } from '../../models/primary-list-button-data';
import { CardDataModel, CardDetailsMessage } from '../../models/card-details-message';
import { CardEnum } from '../../enum/card.enum';

@Component({
  selector: 'app-secondary-white-button',
  templateUrl: './secondary-white-button.component.html',
  styleUrls: ['./secondary-white-button.component.less'],
})
export class SecondaryWhiteButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() color = '';
  @Input() color2 = '';
  @Input() back = '';
  @Input() borderColor = '';
  @Input() fontSize = '14px';
  @Input() width = '';
  @Input() buttonSize = 'normal';
  @Input() value: PrimaryListButtonData;

  isVVInit: Boolean;
  onClickButton(event) {
    this.onClick.emit({ event, value: this.value.id });
  }

  constructor(
    private storageManagerService: StorageManagerService
  ) {}

  ngOnInit(): void {
    const cardDetails = this.storageManagerService.session.get<CardDetailsMessage>(CardEnum.FULL_CARD_DETAILS) as CardDetailsMessage;
    this.isVVInit = false;

    if (cardDetails?.details?.environment?.card_data_model === CardDataModel.K_CARD_DATAMODEL_SETE_COLINAS_V_0 ||
      cardDetails?.details?.environment?.card_data_model === CardDataModel.K_CARD_DATAMODEL_SUBSTITUTION_GUIDE_V_0) {
        this.isVVInit = true;
    } else if ( cardDetails?.details?.environment.card_data_model === CardDataModel.K_CARD_DATAMODEL_UNKNOWN) {
      this.isVVInit = false;
    }
  }

}
