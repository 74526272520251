import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-title-loading',
  templateUrl: './title-loading.component.html',
  styleUrls: ['./title-loading.component.less'],
})
export class TitleLoadingComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  homeScreen() {
    this.router.navigate(['home']);
  }
}
