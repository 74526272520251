import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-primary-table-button',
  templateUrl: './primary-table-button.component.html',
  styleUrls: ['./primary-table-button.component.less',
              '../../../modules/load/pages/lisboa-viva/support-detail/support-detail.component.less',
              '../../../modules/operation/pages/shopping-cart/shopping-cart.component.less',
              '../../../modules/shift/pages/suspended-shifts/suspended-shifts.component.less']
})
export class PrimaryTableButton {
  @Input() buttonCSS: string;
  @Input() buttonText : string;
  @Input() textCSS: string;
  @Input() buttonIcon: string;
  @Input() value: string;

  @Output() onClick = new EventEmitter();

  onClickButton(event: any) {
    this.onClick.emit({ event, value: this.value });
  }
}
