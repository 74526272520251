import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationDuration } from '../../shared/enum/notification-duration.enum';
import { NotificationTypes } from '../../shared/enum/notification-types.enum';
import { NotificationStylesClass } from '../../shared/enum/notification-styles.enum';
import { NzNotificationRef } from 'ng-zorro-antd/notification/typings';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private removeOnRouteChange: string[] = [];

    private activeNotifications: NzNotificationRef[] = [];

    constructor(private router: Router, private notificationService: NzNotificationService) {
        // clear alert messages on route change by message ids (removeOnRouteChange)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // clear notifications
                this.clear();
            }
        });
    }

    public success(
        message: string, keepAfterRouteChange = false, closeable = true, duration = NotificationDuration.SUCCESS_NOTIFICATION_DURATION
    ) {
        this.createNotification(
            NotificationTypes.SUCCESS, message, keepAfterRouteChange, closeable, duration, NotificationStylesClass.SUCCESS
        );
    }

    public error(
        message: string, keepAfterRouteChange = false, closeable = true, duration = NotificationDuration.ERROR_NOTIFICATION_DURATION
    ) {
        this.createNotification(NotificationTypes.ERROR, message, keepAfterRouteChange, closeable, duration, NotificationStylesClass.ERROR);
    }

    public info(
        message: string, keepAfterRouteChange = false, closeable = true, duration = NotificationDuration.INFO_NOTIFICATION_DURATION
    ) {
        return this.createNotification(
            NotificationTypes.INFO, message, keepAfterRouteChange, closeable, duration, NotificationStylesClass.INFO
        );
    }

    public warning(
        message: string, keepAfterRouteChange = false, closeable = true, duration = NotificationDuration.WARNING_NOTIFICATION_DURATION
    ) {
        this.createNotification(
            NotificationTypes.WARNING, message, keepAfterRouteChange, closeable, duration, NotificationStylesClass.WARNING
        );
    }

    clear() {
        this.removeOnRouteChange.forEach((notificationId) => {
            this.notificationService.remove(notificationId);
        });

        this.removeOnRouteChange.length = 0;
    }

    public removeNotification(messageId: string) {
        this.notificationService.remove(messageId);
    }

    public replaceNotification(message: string, keepAfterRouteChange: boolean, closeable: boolean, duration: number) {

        this.clear();

        const styleClass = this.getStyleClass(NotificationStylesClass.INFO, closeable);

        const notification = this.notificationService.create('blank', undefined, message, {
            nzDuration: duration,
            nzClass: styleClass,
            // nzKey: key
        });

        this.removeOnRouteChange.push(notification.messageId);
    }

    private createNotification(
        type: string, message: string, keepAfterRouteChange: boolean, closeable: boolean, duration: number, style: string
    ) {
        const styleClass = this.getStyleClass(style, closeable);

        const notification = this.notificationService.create('blank', undefined, message, {
            nzDuration: duration,
            nzClass: styleClass,
        });

        if (!keepAfterRouteChange) {
            this.removeOnRouteChange.push(notification.messageId);
        }

        return notification;
    }

    private getStyleClass(styleClass: string, closeable: boolean): string {
        return closeable ? styleClass : styleClass.concat(' ', NotificationStylesClass.HIDE_CLOSE_BUTTON);
    }

}
