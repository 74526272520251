import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-text-area',
  templateUrl: './primary-text-area.component.html',
  styleUrls: ['./primary-text-area.component.less',
              '../../../modules/operation/pages/requisition/lisboa-viva/requisition-details/requisition-details.component.less'],
})
export class PrimaryTextAreaComponent implements OnInit {
  @Input() value: string;
  @Input() placeholder = '';
  @Input() maxWidth = '';
  @Input() maxHeight = '';
  @Input() maxLength = '';
  @Input() required = false;
  @Input() textAreaCSS: string = 'textarea';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onValueChange(event) {
    this.valueChange.emit(event);
  }
}
