import { DatePipe } from '@angular/common';
import {CommonEnum} from '../../../../shared/enum/common.enum';
import {StorageManagerService} from 'src/app/core/services/storage-manager.service';
import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {TableColumn} from 'src/app/shared/models/table-column';
import {TableHeaderData} from 'src/app/shared/models/table-header-data';
import {PreviousShiftsResponse} from 'src/app/shared/models/previous-shifts-response';
import {PreviousShiftsService} from 'src/app/core/services/previous-shifts.service';
import {AlertService} from 'src/app/core/services/alert.service';
import {TableHeaderDataType} from 'src/app/shared/models/table-header-data-config';
import {TableHeaderDataButton} from 'src/app/shared/models/table-header-data-button';
import {PrintShiftRequest} from 'src/app/shared/models/print-shift-request';
import {PeripheralService} from 'src/app/core/services/peripheral.service';
import { SpinService } from 'src/app/core/services/spin.service';

@Component({
    selector: 'app-previous-shifts',
    templateUrl: './previous-shifts.component.html',
    styleUrls: ['./previous-shifts.component.less']
})
export class PreviousShiftsComponent implements OnInit  {

    private SHIFT_NUMBER_LABEL = 'Nº de Turno';
    private OPERATOR_NUMBER_LABEL = 'Nº de Operador';

    dropdownList: object[] = [{
        label: 'Nº de Operador',
        value: 'Nº de Operador'
    }, {
        label: 'Nº de Turno',
        value: 'Nº de Turno'
    }];

    public dataTotal: number;
    public readonly defaultPageSize = 5;
    private sort: Array<string> = [];

    fixedText: string;
    public labelDropdown = this.OPERATOR_NUMBER_LABEL;
    public placeholder = 'Digite o número';
    public shiftId: number;
    public startDate = new Date();
    public endDate = new Date();
    public ownerId: number;

    isSearchDisabled = false;

    selected = false;
    selectedId: number;

    pageIndex: number;

    listTitleColumn: TableColumn[] = [];
    listTitleHeader: TableHeaderData[] = [];
    listShifts: PreviousShiftsResponse[] = [];

    deviceId: string;

    constructor(
        private previousShiftsService: PreviousShiftsService,
        private alertService: AlertService,
        private location: Location,
        private router: Router,
        private peripheralService: PeripheralService,
        private storageManager: StorageManagerService,
        private spinService: SpinService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.endDate.setHours(23, 59, 0);
        this.startDate.setDate(this.endDate.getDate() - 1);
        this.startDate.setHours(0, 0, 0);
        this.shiftId = Number(this.storageManager.session.get(CommonEnum.shiftID));
        this.ownerId = Number(this.storageManager.persistent.get(CommonEnum.externalId));
        this.fixedText = '' + this.ownerId;
        this.prepareTableResult();
        this.peripheralService.getPeripheralId().then(
            (deviceId: any) => {
                this.deviceId = deviceId;
            });
    }

    public onChangedDropdown(value: string): void {
        this.labelDropdown = value;
    }

    public onChangedInput(value: number): void {
        this.shiftId = value;
    }

    public onChangeOwnerId(value: number): void {
        this.ownerId = value;
    }

    public onChangedStartDate(value: Date): void {
        this.startDate = value;
    }

    public onChangedEndDate(value: Date): void {
        this.endDate = value;
    }

    private withoutTime(value: Date): Date {
        const dateMidnight = new Date(value);
        dateMidnight.setHours(0, 0, 0, 0);
        return dateMidnight;
    }

    private verifyDate(startDateVerify: Date, endDateVerify: Date): void {
        const actualDate = this.withoutTime(new Date());

        if (this.startDate != null && this.endDate == null || this.startDate == null && this.endDate != null) {
            this.alertService.error(CommonEnum.msgFillBothDatesError);
        } else if (actualDate < this.withoutTime(startDateVerify)) {
            this.alertService.error(CommonEnum.msgInitialDateOverCurrentDateError);
        } else if (this.withoutTime(startDateVerify) > this.withoutTime(endDateVerify)) {
            this.alertService.error(CommonEnum.msgInitialDateOverFinalDate);
        } else if (actualDate < this.withoutTime(endDateVerify)) {
            this.alertService.error(CommonEnum.msgFinalDatePreviousInitialDateError);
        } else {
            this.fetchLoadShifts();
        }
    }

    public loadShifts(): void {
        if (this.startDate == null && this.endDate == null) {
            this.fetchLoadShifts();
        } else {
            this.verifyDate(this.startDate, this.endDate);
        }
        this.pageIndex = 1;
    }

    private async fetchLoadShifts(pageNumber: number = 0): Promise<void> {
        this.spinService.setSpinValue(true);
        this.alertService.clear();
        this.sort = [];
        this.sort.push('startDate,desc');
        const request = {
            ownerId: (this.SHIFT_NUMBER_LABEL === this.labelDropdown) ? null : this.ownerId,
            shiftId: (this.OPERATOR_NUMBER_LABEL === this.labelDropdown) ? null : this.shiftId,
            startLocalDate: this.startDate != null ? this.datePipe.transform(this.startDate, 'yyyy-MM-dd') : null,
            endLocalDate: this.endDate != null ? this.datePipe.transform(this.endDate, 'yyyy-MM-dd') : null,
            pageSize: this.defaultPageSize,
            page: pageNumber,
            sorters: this.sort
        };

        await this.previousShiftsService.getShifts(request)
            .then(response => {
                this.listShifts = response.data?.content;
                this.dataTotal = response.data.totalElements;
                if (this.listShifts.length === 0) {
                    this.printErrorMessages();
                }
            })
            .catch(err => {
                console.log('HTTP Error', err);
                this.printErrorMessages();
            });
        this.spinService.setSpinValue(false);
    }

    private printErrorMessages(): void {
        this.alertService.clear();
        const areDatesNull = this.startDate == null && this.endDate == null;
        const isOwnerIdNull = this.ownerId == null || this.ownerId.toString().length === 0;
        const isShiftIdNull = this.shiftId == null || this.shiftId.toString().length === 0;
        if (this.OPERATOR_NUMBER_LABEL === this.labelDropdown) {
            let msgError = 'Turnos de operador ' + this.ownerId + ' não encontrados para o intervalo de datas selecionado';
            if (isOwnerIdNull && areDatesNull) {
                msgError = 'Turnos não encontrados';
            } else if (!isOwnerIdNull && areDatesNull) {
                msgError = 'Turnos de operador ' + this.ownerId + ' não encontrados';
            } else if (isOwnerIdNull && !areDatesNull) {
                msgError = 'Turnos não encontrados para o intervalo de datas selecionado';
            }
            this.alertService.error(msgError);
        }
        if (this.SHIFT_NUMBER_LABEL === this.labelDropdown) {
            let msgError = 'Nº de turno ' + this.shiftId + ' não encontrado para o intervalo de datas selecionado';
            if (isShiftIdNull && areDatesNull) {
                msgError = 'Turnos não encontrados';
            } else if (!isShiftIdNull && areDatesNull) {
                msgError = 'Nº de turno ' + this.shiftId + ' não encontrado';
            } else if (isShiftIdNull && !areDatesNull) {
                msgError = 'Turnos não encontrados para o intervalo de datas selecionado';
            }
            this.alertService.error(msgError);
        }
    }

    public clearSearch(): void {
        this.ownerId = null;
        this.shiftId = null;
        this.startDate = null;
        this.endDate = null;
    }

    public async onIndexChange(value: any): Promise<void> {
        await this.fetchLoadShifts(value - 1);
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: 'Nº Turno', config: { colAlign: 'left', colWidth: '20%' } },
            { title: 'Data de Início', config: { colAlign: 'left', colWidth: '23%' } },
            { title: 'Operador', config: { colAlign: 'left', colWidth: '15%' } },
            { title: 'Nº Documento', config: { colAlign: 'center', colWidth: '9%' } },
            { title: 'Valor Total', config: { colAlign: 'center', colWidth: '15%' } },
            { title: null, config: { colAlign: 'center', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                name: 'id',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'startDate',
                config: { type: TableHeaderDataType.DATE, format: 'dd/MM/yyyy HH:mm:ss' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'ownerId',
                config: null,
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'documentNumber',
                config: { valAlign: 'center' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'total',
                config: { valAlign: 'center', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: null,
                config: { valAlign: 'center', money: false },
                button: {
                    titleButton: 'Emitir 2ª Via',
                    iconType: 'icon-imprimir icon-print',
                    buttonCSS: 'button-print'
                } as TableHeaderDataButton,
            } as TableHeaderData,
        ];
    }

    public previousView(): void {
        this.location.back();
    }

    public printShift(event): void {
        const request: PrintShiftRequest = {
            id: event.value,
            deviceId: this.deviceId,
            includeMiniReceipt: true,
            includeReceiptCopy: true
        };
        this.spinService.setSpinValue(true);

        this.previousShiftsService.printShift(request).then(response => {
            if (response.data) {
                this.alertService.success('A 2ª via do fecho de turno foi impressa com sucesso');
            } else {
                this.alertService.error(CommonEnum.msgPrintSecondShiftReceiptError);
            }
            this.spinService.setSpinValue(false);
        }).catch(err => {
            console.log('HTTP Error', err);
            this.alertService.error(CommonEnum.msgPrintSecondShiftReceiptError);
            this.spinService.setSpinValue(false);
        });
    }

    public checkBoxEvent(event: any): void {
        if (event.value) {
            this.selected = true;
            this.selectedId = event.id;
        } else {
            this.selected = false;
        }
    }

}
