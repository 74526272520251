<div nz-row nzJustify="start">
  <div nz-col nzSpan="5">
    <img *ngIf="hasCarrisLogo" src="assets/images/Carris_Logo.png" alt="carris_logo" style="max-width: 80%"/>
  </div>
  <div nz-col nzSpan="14">
    <div nz-row nzJustify="space-around">
      <div class="title" nz-col nzSpan="24">{{title}}</div>
    </div>
    <div nz-row nzJustify="space-around">
      <div class="subTitle" nz-col nzSpan="20">{{subTitle}}</div>
    </div>
  </div>
</div>
