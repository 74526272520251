export enum ReadingLoadingEnum {

    Action = 'LOAD_SCREEN_ACTION',
    ShoppingCartRequest = 'SHOPPING_CART_REQUEST',
    LoadContractRequest = 'LOAD_CONTRACT_REQUEST',
    NullifyContractRequest = 'NULL_CONTRACT_REQUEST',
    SwitchRequest = 'SWITCH_REQUEST',
    ReloadContractRequest = 'RELOAD_CONTRACT_REQUEST',

    LoadContract = 'LOAD_CONTRACT',
    LoadComposedTitle = 'LOAD_COMPOSED_TITLE',
    SwitchContract = 'SWITCH_CONTRACT',
    NullifyContract = 'NULLIFY_CONTRACT',
    AuthorizationCharge = 'AUTHORIZATION_CONTRACT',
    NullifyContractShopping = 'NULL_CONTRACT_SHOPPING',
    EmitGuide = 'EMIT_GUIDE',
    FromReadingEmitGuide = 'FROM_READING_EMIT_GUIDE',
    TransferContract = 'TRANSFER_CONTRACT',
    CreateReplGuideRequest = 'CREATE_REPLACEMENT_GUIDE_REQUEST',
    TransferContractRead = 'TRANSFER_CONTRACT_READ',
    RecoverGuide = 'RECOVER_GUIDE',
    CancelConsumeAuthorization = 'CANCEL_CONSUME_AUTH_REQUEST',
    LoadAuthorization = 'LOAD_AUTHORIZATION',
    ConsumeAuthorizationRequest = 'CONSUME_AUTHORIZATION_REQUEST',
    LoadFamilyTicket = 'LOAD_FAMILY_TICKET',
    ReloadPrevContract = 'RELOAD_PREV_CONTRACT',
    CustomersDetails = 'CUSTOMER_DETAILS',
    CleanReplacedCardContract = 'CLEAN_REPLACED_CARD_CONTRACT',
    CheckIfCleanCardContractWasSucessful = 'CHECK_IF_CLEAN_CARD_CONTRACT_WAS_SUCCESSFUL',
    DeleteContractRead = 'DELETE_CONTRACT_READ',
    DeleteContract = 'DELETE_CONTRACT',
    IsTUD = 'IS_TUD'
}
