<div class="m-t-30">
  <div nz-row nzJustify="center" class="m-b-50">
    <div nz-col [nzSpan]="16">
      <h1>Emissão de Guia de Substituição sem leitura</h1>
    </div>
  </div>
  <div nz-row nzJustify="center" class="m-b-50">
    <div nz-col [nzSpan]="12">
      <div nz-col [nzSpan]="6" id="lisboa-viva-img"></div>
    </div>
  </div>
  <div nz-row nzJustify="space-around" nzAlign="middle">
      <app-secondary-second-button
      (click)="issueReplacementGuide()"
      style="width:300px; height:72px"
      font-size="13px">
      Emitir Guia de Substituição
      </app-secondary-second-button>
  </div>
  <div nz-row nzJustify="space-around" nzAlign="middle" style="margin-top: 20px">
    <app-secondary-second-button
    nzAlign="middle"
    (click)="recoverReplacementGuide()"
    style="width: 250px;">
    <h3 style="font-size: 12px; margin-top:10px"> Recuperar Guia de Substituição</h3>
    </app-secondary-second-button>
</div>
</div>

<div class="footer-buttons">
  <div nz-col nzSpan="24" class="align-left">
    <app-primary-white-button id="home-button" (click)="homeClick()">
      <span class="btn-icon icon-anterior icon-middle float-left "></span>
      Voltar
    </app-primary-white-button>
  </div>
</div>
