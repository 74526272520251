import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import NavigationManagerService, { TicketingRoutes } from 'src/app/core/services/navigation-manager.service';
import { SpinService } from '../../../../core/services/spin.service';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';
import { Category, NonTicketingProduct, TariffType } from './model/commons-non-ticketing-product';
import { CommercialOfferService } from 'src/app/core/services/commercial-offer.service';
import { CommonEnum } from 'src/app/shared/enum/common.enum';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { TariffService } from 'src/app/core/services/tariff.service';
import { TableColumn } from 'src/app/shared/models/table-column';
import { TableHeaderData } from 'src/app/shared/models/table-header-data';
import { TableHeaderDataCheckbox } from 'src/app/shared/models/table-header-data-checkbox';
import { TableHeaderDataType } from 'src/app/shared/models/table-header-data-config';
import { NonTicketingProductRecord } from './model/non-ticketing-product-record';
import { async } from '@angular/core/testing';
import { PeripheralService } from 'src/app/core/services/peripheral.service';
import { TicketingNetworkService } from 'src/app/core/services/ticketing-network.service';
import { CampaignRequest } from '../non-ticketing-products/model/commons-non-ticketing-product';

@Component({
    selector: 'app-non-ticketing-products-select',
    templateUrl: './non-ticketing-products-select.html',
    styleUrls: ['./non-ticketing-products-select.less']
})
export class NonTicketingProductsSelectComponent implements OnInit {

    public listTariffType: TariffType[] = [];
    public request: NonTicketingProduct = {} as NonTicketingProduct;
    public listNonTicketingProducts = [];
    public category: Category = {} as Category;

    public isShoppingCartFull = false;
    public isNextDisabled = true;
    public selectedIds: Array<number> = [];
    public tariffSelected;
    public quantity = 0;

    public listTitleColumn: TableColumn[] = [];
    public listTitleHeader: TableHeaderData[] = [];
    public listRecords: NonTicketingProductRecord[] = [];
    public dataTotal: number;
    public readonly defaultPageSize = 5;
    private sort: string[] = [];
    private selectedNonTicketingProduct: NonTicketingProductRecord;
    private localId: number;
    
    public selected = false;
    private selectedId: number;


    constructor(
        private alertService: AlertService,
        private navigationManager: NavigationManagerService,
        private spinService: SpinService,
        private tariffService: TariffService,
        private storageManagerService: StorageManagerService,
        private peripheralService: PeripheralService,
        private ticketingNetworkService: TicketingNetworkService
    ) {
        if (this.navigationManager.getNavigation()?.extras?.state) { 
            this.category = this.navigationManager.getNavigation()?.extras?.state.category as Category;
        }
    }

    async ngOnInit(): Promise<void> {
        this.spinService.setSpinValue(true);
        const peripheralId = this.storageManagerService.session.get<string>(CommonEnum.peripheralId);
        if (peripheralId) {
            const machineNumber = await this.peripheralService.getMachineNumber();
            const response = await this.ticketingNetworkService.getTicketingInfo(machineNumber);
            if (!response?.data) {
                this.alertService.error(CommonEnum.msgNoLocalIdFound);
            } else {
                this.localId = response.data;
                this.prepareTableResult();
                await this.fetchNonTicketingProducts();
            }
        }
        this.spinService.setSpinValue(false);
    }

    public async onPageChanged(value: any): Promise<void> {
        await this.fetchNonTicketingProducts(value - 1);
    }

    private async fetchNonTicketingProducts(pageNumber: number = 0) {
        this.spinService.setSpinValue(true);
        this.sort = [];
        this.sort.push('title.description,desc');
        const request = {
            pageSize: this.defaultPageSize,
            page: pageNumber,
            sorters: this.sort
        };
        try {
            const response = await this.tariffService.getListNewTicketingProducts(this.category.id, this.localId, request);
            if (response?.data != null) {
                this.listRecords = response.data?.content;
                this.dataTotal = response.data.totalElements;
                this.spinService.setSpinValue(false);
            }
            if (response.data.empty) {
                this.spinService.setSpinValue(false);
                this.alertService.error(CommonEnum.msgSelectedCategoryWithoutProducts);
            }
        } catch (error) {
            console.log('Error: ', error);
            this.listRecords = [];
            this.spinService.setSpinValue(false);
            this.alertService.error(error.error.message);
        }
    }

    async getNonTicketingProductsCategories(): Promise<void> {
        const res: any = await this.tariffService.getListCategories();
        if (res && res.success && res.data) {
            res.data.forEach((product: { id: any; description: any; }) => {
                this.listNonTicketingProducts.push({
                    id: product.id,
                    name: product.description,
                    disabled: false
                })
            });
        }
    }

    private prepareTableResult(): void {
        this.listTitleColumn = [
            { title: null, config: { colAlign: 'left', colWidth: '5%' } },
            { title: 'PRODUTO', config: { colAlign: 'left', colWidth: '60%' } },
            { title: 'PREÇO COLAB.', config: { colAlign: 'left', colWidth: '15%' } },
            { title: 'IVA (%)', config: { colAlign: 'left', colWidth: '10%' } },
            { title: 'PREÇO', config: { colAlign: 'left', colWidth: '10%' } },
        ];
        this.listTitleHeader = [
            {
                visible: true,
                checkbox: { isChecked: 'annul' } as TableHeaderDataCheckbox
            } as TableHeaderData,
            {
                visible: true,
                name: 'product',
                config: { valAlign: 'left'},
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'collaboratorPrice',
                config: { valAlign: 'right', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'vat',
                config: { valAlign: 'right' },
                button: null,
            } as TableHeaderData,
            {
                visible: true,
                name: 'price',
                config: { valAlign: 'right', type: TableHeaderDataType.MONEY },
                button: null,
            } as TableHeaderData,
        ];
    }
    
    async previousView() {
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS);
    }

    async nextView() {
        this.selectedNonTicketingProduct
        const info = {
            collaboratorPrice: this.selectedNonTicketingProduct?.collaboratorPrice,
            product: this.selectedNonTicketingProduct?.product,
            id: this.selectedNonTicketingProduct?.id,
            price: this.selectedNonTicketingProduct?.price,
            vat: this.selectedNonTicketingProduct?.vat,
            categoryDescription: this.category.description,
            categoryId: this.category.id,
            imageURL: this.selectedNonTicketingProduct?.imageURL
        } as NonTicketingProductRecord;
        await this.navigationManager.go(TicketingRoutes.NON_TICKETING_PRODUCTS_DETAIL, {info});
    }

    public checkNextDisableState(): boolean {
        return this.selectedId == null;
    }

    public checkBoxEvent(event: any): void {
        if (event.value) {
            this.selected = true;
            this.selectedId = event.id;
            this.selectedNonTicketingProduct = this.listRecords.find(it => it.id === this.selectedId);
        } else {
            this.selected = false;
        }
    }

}
